import * as React from 'react';
import WithAndWithoutInveertCards from '../../elements/cards/WithAndWithoutInveertCards';
import {Capitalize, tpl, tr} from '../../utils/functions';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import styles from './propuesta-plan-comparison.module.css';

interface IPropuestaPlanComparisonComponentProps {
    goalCost: number;
    monthlySavingWithoutPlan: number;
    monthlySavingWithPlan: number;
    saving: number;
    performance: number;
    riskProfileAlias: string;
    cumulativeNetWorthChartData: Array<any>;
}

export function PropuestaPlanComparisonComponent(props: IPropuestaPlanComparisonComponentProps) {
    const {
        goalCost,
        saving,
        monthlySavingWithoutPlan,
        monthlySavingWithPlan,
        performance,
        cumulativeNetWorthChartData,
        riskProfileAlias,
    } = props;
    return (
        <inv-grid-row
            class={styles.withAndWithoutInveertContainer}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <inv-grid-col class="col-sm-4 col-12" style={{'--inv-grid-gutter-y': 0}}>
                <WithAndWithoutInveertCards
                    accNetChartData={cumulativeNetWorthChartData.map((item) => ({
                        ...item,
                        bar1: (item?.bar1 ?? 0) + (item?.bar2 ?? 0),
                        bar2: 0,
                    }))}
                    isSelected={false}
                    title={tr('El plan de siempre')}
                    subtitle={tr('Ahorrar en la cuenta corriente')}
                    inputMoney={goalCost}
                    performanceMoney={0}
                    monthlySaving={monthlySavingWithoutPlan}
                />
            </inv-grid-col>
            <inv-grid-col class={`col-12 col-sm-1 ${styles.vsCol}`}>
                <div className={styles.vsContainer}>
                    <inv-text-l
                        style={
                            {
                                '--inv-text-font-weight': 500,
                                '--inv-text-l-color': 'var(--accent-color-1)',
                                textAlign: 'center',
                            } as React.CSSProperties
                        }
                    >
                        vs
                    </inv-text-l>
                </div>
            </inv-grid-col>
            <inv-grid-col class="col-sm-4 col-12" style={{'--inv-grid-gutter-y': 0}}>
                <WithAndWithoutInveertCards
                    accNetChartData={cumulativeNetWorthChartData}
                    isSelected
                    title={tr('El plan de')}
                    subtitle={tpl(tr('Invertir con perfil {profile}'), {profile: tr(Capitalize(riskProfileAlias))})}
                    inputMoney={saving}
                    performanceMoney={performance}
                    monthlySaving={monthlySavingWithPlan}
                />
            </inv-grid-col>
        </inv-grid-row>
    );
}

export default PropuestaPlanComparisonComponent;
