import * as React from 'react';
import propTypes from 'prop-types';
import styles from './plan-contribution-pay-pending-footer.module.css';
import DotStepsFragment from '../../../../elements/fragments/DotStepsFragment';
import SolidDividers from '../../../../elements/dividers/SolidDividers';

/**
 * @typedef {Object} Props
 * @property {() => void} onBackClick
 * @property {() => void} onNextClick
 * @property {boolean} isNextDisabled
 * @property {string} nextButtonText
 * @property {string} backButtonText
 * @property {number} currentStep
 * @property {number} totalSteps
 */

/**
 * Componente que muestra el footer del diálogo de ponerte al día con las aportaciones
 * @return {JSX.Element}
 * @param {Props} props
 */
function PlanContributionPayPendingFooter({
    onBackClick,
    onNextClick,
    isNextDisabled,
    nextButtonText,
    backButtonText,
    totalSteps,
    currentStep,
}) {
    return (
        <div className={styles.main}>
            <inv-grid-row class={styles.dividerContainer}>
                <inv-grid-col class="col-12">
                    <SolidDividers orientation="horizontal" />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.buttonContainer}>
                <inv-grid-col class="col-4">
                    <inv-button onClick={onBackClick} class="button button--secondary-contained">
                        {backButtonText}
                    </inv-button>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <DotStepsFragment steps={totalSteps} activeStep={currentStep} />
                </inv-grid-col>
                <inv-grid-col class="col-4">
                    <inv-button
                        class="button button--primary-contained"
                        disabled={isNextDisabled === false ? undefined : true}
                        onClick={onNextClick}
                    >
                        {nextButtonText}
                    </inv-button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

PlanContributionPayPendingFooter.propTypes = {
    onNextClick: propTypes.func.isRequired,
    onBackClick: propTypes.func.isRequired,
    isNextDisabled: propTypes.bool,
    totalSteps: propTypes.number.isRequired,
    currentStep: propTypes.number.isRequired,
    nextButtonText: propTypes.string.isRequired,
    backButtonText: propTypes.string.isRequired,
};

PlanContributionPayPendingFooter.defaultProps = {
    isNextDisabled: false,
};

export default PlanContributionPayPendingFooter;
