import React from 'react';
import PropTypes from 'prop-types';
import {leadGeneration} from '../../modules/gtm/gtm.script';

export function IframePage(props) {
    const {content, height, width, title, id, properties} = props;
    const iframeRef = React.useRef(null);

    const sendMessageToIframe = () => {
        if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage({...properties, eventName: 'updateFormValues'}, '*'); // * should ideally be replaced with the origin of the iframe for security
        }
    };
    const handleIframeLoad = (e) => {
        if (e.data.eventName === 'onFormReady') {
            sendMessageToIframe();
        } else if (e.data.eventName === 'onFormSubmitted') {
            const {mobilephone, email} = e.data.data.submissionValues;
            leadGeneration(mobilephone, email);
        }
    };

    React.useEffect(() => {
        const iframe = iframeRef.current;
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(content);
        iframe.contentWindow.document.close();
        // eslint-disable-next-line no-undef
        window.addEventListener('message', handleIframeLoad);
        return () => {
            // eslint-disable-next-line no-undef
            window.removeEventListener('message', handleIframeLoad);
        };
    }, [content]);

    return <iframe ref={iframeRef} title={title} width={width} height={height} style={{border: 'none'}} id={id} />;
}

IframePage.propTypes = {
    content: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    properties: PropTypes.shape({
        firstname: PropTypes.string,
        url_pdf: PropTypes.string,
        fecha_nacimiento: PropTypes.string,
        zip: PropTypes.string,
    }),
};

IframePage.defaultProps = {
    title: 'Embedded iframe',
    width: '100%',
    height: '100%',
    id: 'myIframe',
    properties: {},
};

export default IframePage;
