import NumberFormat from 'react-number-format';
import React from 'react';
import * as yup from 'yup';
import {format} from 'date-fns';
import {GoalForm} from '../educacion/educacion-form.component';
import {PercentageSlider} from '../../../elements/slider/PercentageSlider';
import Selector from '../../../elements/selector/Selector';
import AdviceAlert from '../../../elements/alert/AdviceAlert';
import WCIcons from '../../../elements/icons/WCIcons';
import {GoalCostComponent} from '../goal-cost.component';
import vivienda from '../../../styles/Vivienda.module.css';
import '@inveert/ui-kit/src/input/input-date';
import '@inveert/ui-kit/src/input/input-int';
import {handleInputChange, handleInputFocusOut, tr} from '../../../utils/functions';

export function ViviendaFormComponent(props: GoalForm) {
    const {formik, questionIndex, inflation, minDate, maxDate} = props;
    const realStateValueScheme = yup
        .number()
        .typeError(tr('Debes introducir un valor numérico'))
        .required(tr('Introduce un valor numérico'))
        .min(1, tr('El coste tiene que ser mayor que cero'))
        .nullable();
    const objectScheme = {
        real_state_value: realStateValueScheme,
    };
    const amount = formik.values.real_state_value * (formik.values.percentage * 0.01);

    React.useEffect(() => {
        if (formik.values.percentage === 20) {
            if (formik.values.is_home === true) {
                formik.setFieldValue('percentage', 20, false);
            } else {
                formik.setFieldValue('percentage', 30, false);
            }
        }
    }, []);
    const handleFocusOut = (e: any) => handleInputFocusOut(e, formik);

    const handleChange = (e: any) => handleInputChange(e, formik, objectScheme);
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-date-focus-out', handleFocusOut);
        window.addEventListener('input-int-change', handleChange);
        window.addEventListener('input-int-focus-out', handleFocusOut);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-int-change', handleChange);
            window.removeEventListener('input-date-focus-out', handleFocusOut);
            window.removeEventListener('input-int-focus-out', handleFocusOut);
        };
    }, [handleFocusOut, handleChange]);

    const realStateSelectorData = [
        {
            title: tr('Vivienda habitual'),
            name: 'is_home',
            value: true,
        },
        {
            title: tr('Segunda vivienda'),
            name: 'is_home',
            value: false,
        },
    ];
    switch (questionIndex) {
        case 0:
            return (
                <>
                    <inv-grid-row
                        class={vivienda.marginTopQ1Element1}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        {realStateSelectorData.map((data) => (
                            <inv-grid-col key={data.title} class="col-auto col-sm-3">
                                <Selector
                                    title={data.title}
                                    /* @ts-ignore */
                                    name={data.name}
                                    selected={formik.values.is_home === data.value}
                                    onClick={() => {
                                        formik.setFieldValue(data.name, data.value, false);
                                    }}
                                    size="medium"
                                    /* eslint-disable-next-line no-nested-ternary */
                                    height="4.75em"
                                    /* eslint-disable-next-line no-nested-ternary */
                                    width="15.5em"
                                />
                            </inv-grid-col>
                        ))}
                    </inv-grid-row>
                    {formik.values.is_home !== null && (
                        <>
                            <inv-grid-row
                                class={vivienda.marginTopQ1Element2}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col class="col-12 col-sm-4">
                                    <inv-input-int
                                        class="input input--primary"
                                        value={formik.values.real_state_value}
                                        name="real_state_value"
                                        error={formik.errors.real_state_value}
                                        label={tr('Precio de la vivienda')}
                                        placeholder="200.000"
                                        suffix={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                    />
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row
                                class={vivienda.marginTopQ1Element3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col class="col-12 col-sm-6">
                                    <AdviceAlert
                                        icon={<WCIcons name="help" color="inherit" />}
                                        text={tr(
                                            'Recuerda añadir un 10% al coste de la vivienda, para tener en cuenta los gastos e impuestos asociados a la compra.'
                                        )}
                                    />
                                </inv-grid-col>
                            </inv-grid-row>
                        </>
                    )}
                    <div className={vivienda.marginBottomQ1Element3} />
                </>
            );
        case 1:
            return (
                <>
                    <inv-grid-row
                        class={vivienda.marginTopQ2Element1}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-10 col-sm-5">
                            <PercentageSlider
                                value={formik.values.percentage}
                                handleChange={formik.setFieldValue}
                                name="percentage"
                                valueLabelFormat={(value: number) => `${value}%`}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        class={vivienda.marginTopQ2Element2}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-auto">
                            <inv-text-xl
                                style={{
                                    '--inv-text-xl-color': 'var(--color-secondary-100)',
                                    '--inv-text-xl-font-weight': 600,
                                }}
                            >
                                <NumberFormat
                                    value={amount}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix={` ${process.env.REACT_APP_CURRENCY_SYMBOL || '€'}`}
                                />
                            </inv-text-xl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-s
                                style={{
                                    '--inv-text-s-color': 'var(--text-color-50)',
                                    '--inv-text-s-font-weight': 600,
                                }}
                            >
                                {tr('Es la cantidad que quieres para la entrada')}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={vivienda.marginQ2Element3} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col col-sm-6">
                            <AdviceAlert
                                icon={<WCIcons name="help" color="inherit" />}
                                text={
                                    formik.values.is_home
                                        ? tr(
                                              'Para viviendas habituales los bancos suelen pedir un 20% del valor de tasación.'
                                          )
                                        : tr(
                                              'Para segundas viendas los bancos suelen pedir un 30% del valor de tasación.'
                                          )
                                }
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 2:
            return (
                <inv-grid-row class={vivienda.marginQ3Element1} style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-grid-col class="col-12 col-sm-4">
                        <inv-input-date
                            class="input input--primary"
                            type="month"
                            min={format(minDate, 'yyyy-MM')}
                            max={format(maxDate, 'yyyy-MM')}
                            value={formik.values.init_date}
                            error={formik.errors.init_date}
                            name="init_date"
                            label={tr('Fecha estimada de la compra')}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 3:
            return <GoalCostComponent inflation={inflation} />;
        default:
            return null;
    }
}

export default ViviendaFormComponent;
