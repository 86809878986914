import Box from '@mui/material/Box';
import {keyframes, Slider, SliderMark, SliderThumb, SliderValueLabel} from '@mui/material';
import {styled} from '@mui/material/styles';
import {FormikErrors} from 'formik';
import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './PercentageSlider.module.css';

interface IPercentageSlider {
    noborders?: boolean;
    slidercolor?: string;
    name: string;
    value: number;
    valueLabelFormat: (value: number) => string | JSX.Element;
    handleChange: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<{recipient: string}>>;
}

const marks = [
    {
        value: 0,
    },
    {
        value: 5,
    },
    {
        value: 10,
    },
    {
        value: 15,
    },
    {
        value: 20,
    },
    {
        value: 25,
    },
    {
        value: 30,
    },
    {
        value: 35,
    },
    {
        value: 40,
    },
    {
        value: 45,
    },
    {
        value: 50,
    },
    {
        value: 55,
    },
    {
        value: 60,
    },
    {
        value: 65,
    },
    {
        value: 70,
    },
    {
        value: 75,
    },
    {
        value: 80,
    },
    {
        value: 85,
    },
    {
        value: 90,
    },
    {
        value: 95,
    },
    {
        value: 100,
    },
];

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`;

const StyledSlider = styled(Slider)((props) => ({
    color: '#3a8589',
    height: 3,
    padding: '0.65em 0',
    '& .MuiSlider-thumb': {
        zIndex: 10,
        height: '1.1em',
        width: '1.1em',
        outline: `0.1em solid var(${
            // @ts-ignore
            props?.isused ? '--accent-color-1' : '--color-primary-100'
        })`,
        backgroundColor: 'var(--color-primary-100)',
        '&:before': {
            content: '""',
            position: 'absolute',
            borderRadius: '50%',
            outline: `0.1em solid var(--accent-color-1)`,
            backgroundColor: 'var(--color-primary-100)',
            zIndex: 2,
            // @ts-ignore
            animation: props?.isused ? undefined : `${pulseAnimation} 2s infinite`,
        },
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .inner-bar': {
            height: '0.5em',
            width: '0.05em',
            backgroundColor: 'var(--accent-color-1)',
            marginLeft: 1,
            marginRight: 1,
            zIndex: 2,
            // @ts-ignore
            animation: props?.isused ? undefined : `${pulseAnimation} 2s infinite`,
        },
        '& .border': {
            backgroundColor: 'var(--color-primary-100)',
            borderRadius: '50%',
            width: '1.15em',
            height: '1.15em',
            outline: `0.1em solid var(${
                // @ts-ignore
                props?.isused ? '--accent-color-1' : '--color-primary-100'
            })`,
            position: 'absolute',
            zIndex: 2,
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: '1em',
        // @ts-ignore
        color: props?.slidercolor ?? 'var(--text-color-30)',
        fontWeight: 600,
        top: '-2.5em',
        backgroundColor: 'transparent',
        borderRadius: '5em',
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-track': {
        height: '3.7em',
        // @ts-ignore
        color: props?.slidercolor ?? 'var(--color-secondary-100)',
        // @ts-ignore
        borderRadius: props?.noborders ? '0' : '0.4em 0px 0px 0.4em',
    },
    '& .MuiSlider-rail': {
        color: 'var(--text-color-30)',
        opacity: 1,
        height: 1,
    },

    '& .MuiSlider-mark': {
        height: '0.8em',
        width: 1,
        background: 'var(--text-color-30)',
        opacity: 1,
        '&.MuiSlider-markActive': {
            height: '0.8em',
            width: 1,
            background: 'var(--accent-color-1)',
        },
    },
}));

function SliderThumbComponent(props: any) {
    const {children, st, ...other} = props;
    const parsedOther = {
        ...other,
        style: {left: `calc(${other.style.left} + 0.2%)`},
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SliderThumb {...parsedOther} st={st ? 'true' : 'false'}>
            {children}
            <span className="border" />
            <span className="inner-bar" />
            <span className="inner-bar" />
            <span className="inner-bar" />
            <span className={styles.outsideBar} />
        </SliderThumb>
    );
}

SliderThumbComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    st: PropTypes.bool, // Adjust the prop type based on your requirement
};

function SliderValueLabelComponent(props: any) {
    const {children, ...other} = props;
    // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
    return <SliderValueLabel {...other}>{children}</SliderValueLabel>;
}

SliderValueLabelComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

function MarkComponent(props: any) {
    const {children, ...other} = props;
    const markHeight =
        // eslint-disable-next-line no-nested-ternary
        other['data-index'] === 0 ? 0 : other['data-index'] % 5 === 0 ? '2' : '0.8';
    const parsedOther = {
        ...other,
        style: {...other.style, height: `${markHeight}em`},
    };
    // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
    return <SliderMark {...parsedOther}>{children}</SliderMark>;
}

MarkComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

export function PercentageSlider(props: IPercentageSlider) {
    const {value, handleChange, name, valueLabelFormat, noborders, slidercolor} = props;
    const [isUsed, setIsUsed] = React.useState('');
    const handleSliderChange = (event: any, newValue: any) => {
        setIsUsed('true');
        handleChange(name, newValue, true);
    };
    return (
        <Box sx={{width: '100%'}}>
            <StyledSlider
                // @ts-ignore
                noborders={noborders?.toString()}
                slidercolor={slidercolor}
                isused={isUsed ? 'true' : undefined}
                valueLabelDisplay="on"
                components={{
                    Thumb: SliderThumbComponent,
                    ValueLabel: SliderValueLabelComponent,
                    Mark: MarkComponent,
                }}
                step={1}
                marks={marks}
                value={value}
                /* eslint-disable-next-line @typescript-eslint/no-shadow */
                valueLabelFormat={(value) => valueLabelFormat(value)}
                /* eslint-disable-next-line @typescript-eslint/no-shadow */
                onChange={handleSliderChange}
            />
        </Box>
    );
}
PercentageSlider.defaultProps = {
    noborders: undefined,
    slidercolor: null,
};
PercentageSlider.propTypes = {
    value: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    valueLabelFormat: PropTypes.func.isRequired,
    noborders: PropTypes.bool,
    slidercolor: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    marks: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.node.isRequired,
        })
    ),
    // Add other propTypes based on your requirements
};

export default PercentageSlider;
