import {Backdrop, CircularProgress} from '@mui/material';
import React from 'react';

interface IPageLoading {
    open: boolean;
}

function PageLoading(props: IPageLoading) {
    const {open} = props;
    return (
        <Backdrop
            open={open}
            sx={{
                color: 'var(--color-secondary-100)',
                backgroundColor: 'transparent',
                background: 'rgba(6, 73, 173, 0.5)',
                backdropFilter: 'blur(12px)',
                zIndex: 1500,
            }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default PageLoading;
