import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import '../App.css';
import '../styles/globals.css';
import '../styles/scss/global.scss';
import LayoutComplete from '../styles/layouts/LayoutComplete';
import EmptyLayoutComplete from '../styles/layouts/EmptyLayoutComplete';

import PageLoading from '../elements/loading/PageLoading';
import Resultado from '../pages/propuesta/resultado';
import InicioPerfil from '../pages/perfil/inicio';
import InicioContratacion from '../pages/contratacion/inicio';
import InformacionUsuarioContratacion from '../pages/contratacion/informacion-usuario';
import CumplimientoNormativoContratacion from '../pages/contratacion/cumplimiento-normativo';
import FirmaContratacion from '../pages/contratacion/firma';
import Inicio from '../pages/inicio';
import {routerDev} from './router-dev';
import Plan from '../pages/plan';
import PlanMeta from '../pages/plan/meta';
import PlanInversiones from '../pages/plan/inversiones';

const Registro = React.lazy(() => import('../pages/registro'));
const Perfil = React.lazy(() => import('../pages/perfil'));
const Custom404 = React.lazy(() => import('../pages/404'));
const Jubilacion = React.lazy(() => import('../pages/meta/jubilacion'));
const Fin = React.lazy(() => import('../pages/meta/fin'));
const Propuesta = React.lazy(() => import('../pages/propuesta'));
const Login = React.lazy(() => import('../pages/login'));
const RestorePassword = React.lazy(() => import('../pages/restore-password'));
const Verificacion = React.lazy(() => import('../pages/verificacion'));

function RouterFinsei() {
    const [finanbestPass, setFinanbestPass] = React.useState('');
    return (
        <Routes>
            <Route
                path="*"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Custom404 />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/inicio"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Inicio />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route path="/" element={<Navigate to="/inicio" replace />} />
            <Route
                path="/meta/jubilacion"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Jubilacion />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/fin"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Fin />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/perfil"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Perfil />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/propuesta"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Propuesta />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/plan"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Plan />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/plan/meta"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <PlanMeta />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/plan/inversiones"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <PlanInversiones />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/registro"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Registro />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/login"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Login />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/restore-password"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <RestorePassword />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/verificacion"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Verificacion />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/propuesta/resultado"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Resultado />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/perfil/inicio"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <InicioPerfil />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/contratacion/inicio"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <InicioContratacion setFinanbestPass={setFinanbestPass} />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/contratacion/informacion-usuario"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <InformacionUsuarioContratacion finanbestPass={finanbestPass} />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/contratacion/cumplimiento-normativo"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <CumplimientoNormativoContratacion />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/contratacion/firma"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <FirmaContratacion />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            {process.env.REACT_APP_ENV !== 'pro' && routerDev.map((item) => item)}
        </Routes>
    );
}

export default RouterFinsei;
