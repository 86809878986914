import {Objective, OutputType} from './goal.type';

export const goalsArrayMockedData: Array<Objective> = [
    {
        id: 7,
        type: 'EDUCATION',
        output_type: OutputType.UNIQUE,
        protection_type: 'CAPITAL',
        name: 'Educación de sdfs',
        amount: 23234,
        duration: null,
        duration_type: null,
        init_date: '2023-03-15',
        end_date: '2024-03-15',
        is_active: true,
        probability: null,
        time_horizon: 0,
        percentage_completed: null,
        adjusted_objective: 23582.51,
    },
    {
        id: 9,
        type: 'RETIREMENT',
        output_type: OutputType.MONTHLY,
        protection_type: 'LONG_TERM',
        name: 'Complemento jubilación',
        amount: 368.36,
        duration: 312,
        duration_type: 'MONTHLY',
        init_date: '2056-10-02',
        end_date: '2082-10-02',
        is_active: true,
        probability: null,
        time_horizon: 35,
        percentage_completed: null,
        adjusted_objective: 232834.4853671255,
    },
];

export const outputChartRetirementMockedData: any = {
    '2022': {
        name: 2022,
        output: 0,
    },
    '2023': {
        name: 2023,
        output: 0,
    },
    '2024': {
        name: 2024,
        output: 0,
    },
    '2025': {
        name: 2025,
        output: 0,
    },
    '2026': {
        name: 2026,
        output: 0,
    },
    '2027': {
        name: 2027,
        output: 0,
    },
    '2028': {
        name: 2028,
        output: 0,
    },
    '2029': {
        name: 2029,
        output: 0,
    },
    '2030': {
        name: 2030,
        output: 0,
    },
    '2031': {
        name: 2031,
        output: 0,
    },
    '2032': {
        name: 2032,
        output: 0,
    },
    '2033': {
        name: 2033,
        output: 0,
    },
    '2034': {
        name: 2034,
        output: 0,
    },
    '2035': {
        name: 2035,
        output: 0,
    },
    '2036': {
        name: 2036,
        output: 0,
    },
    '2037': {
        name: 2037,
        output: 0,
    },
    '2038': {
        name: 2038,
        output: 0,
    },
    '2039': {
        name: 2039,
        output: 0,
    },
    '2040': {
        name: 2040,
        output: 0,
    },
    '2041': {
        name: 2041,
        output: 0,
    },
    '2042': {
        name: 2042,
        output: 0,
    },
    '2043': {
        name: 2043,
        output: 0,
    },
    '2044': {
        name: 2044,
        output: 0,
    },
    '2045': {
        name: 2045,
        output: 0,
    },
    '2046': {
        name: 2046,
        output: 0,
    },
    '2047': {
        name: 2047,
        output: 0,
    },
    '2048': {
        name: 2048,
        output: 0,
    },
    '2049': {
        name: 2049,
        output: 0,
    },
    '2050': {
        name: 2050,
        output: 0,
    },
    '2051': {
        name: 2051,
        output: 0,
    },
    '2052': {
        name: 2052,
        output: 0,
    },
    '2053': {
        name: 2053,
        output: 0,
    },
    '2054': {
        name: 2054,
        output: 0,
    },
    '2055': {
        name: 2055,
        output: 0,
    },
    '2056': {
        name: 2056,
        output: 0,
    },
    '2057': {
        name: 2057,
        output: 280.8714038962523,
    },
    '2058': {
        name: 2058,
        output: 1702.0807076112883,
    },
    '2059': {
        name: 2059,
        output: 1727.6119182254577,
    },
    '2060': {
        name: 2060,
        output: 1753.5260969988394,
    },
    '2061': {
        name: 2061,
        output: 1779.8289884538226,
    },
    '2062': {
        name: 2062,
        output: 1806.526423280629,
    },
    '2063': {
        name: 2063,
        output: 1833.6243196298378,
    },
    '2064': {
        name: 2064,
        output: 1861.1286844242863,
    },
    '2065': {
        name: 2065,
        output: 1889.0456146906504,
    },
    '2066': {
        name: 2066,
        output: 1917.3812989110093,
    },
    '2067': {
        name: 2067,
        output: 1946.1420183946743,
    },
    '2068': {
        name: 2068,
        output: 1975.334148670595,
    },
    '2069': {
        name: 2069,
        output: 2004.9641609006528,
    },
    '2070': {
        name: 2070,
        output: 2035.0386233141628,
    },
    '2071': {
        name: 2071,
        output: 2065.5642026638748,
    },
    '2072': {
        name: 2072,
        output: 2096.547665703833,
    },
    '2073': {
        name: 2073,
        output: 2127.9958806893906,
    },
    '2074': {
        name: 2074,
        output: 2159.915818899731,
    },
    '2075': {
        name: 2075,
        output: 2192.314556183226,
    },
    '2076': {
        name: 2076,
        output: 2225.199274525975,
    },
    '2077': {
        name: 2077,
        output: 2258.577263643864,
    },
    '2078': {
        name: 2078,
        output: 2292.4559225985217,
    },
    '2079': {
        name: 2079,
        output: 2326.8427614374996,
    },
    '2080': {
        name: 2080,
        output: 2361.745402859062,
    },
    '2081': {
        name: 2081,
        output: 2397.171583901948,
    },
    '2082': {
        name: 2082,
        output: 2229.3646283679527,
    },
};
export const outputChartEducationMockedData: any = {
    '2057': {
        name: 2057,
        output: 280.8714038962523,
    },
};

export const pensionApiResponse = {
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/simulate-retirement',
    },
    meta: {
        simulation: [
            {
                year: 63,
                pension_with_inflation: 1177.5597876043669,
                pension: 836.1111111111112,
            },
            {
                year: 64,
                pension_with_inflation: 1292.5087924524905,
                pension: 904.1666666666666,
            },
            {
                year: 65,
                pension_with_inflation: 1410.6413164938472,
                pension: 972.2222222222223,
            },
            {
                year: 66,
                pension_with_inflation: 1489.072973690905,
                pension: 1011.1111111111112,
            },
            {
                year: 67,
                pension_with_inflation: 1569.5401863076636,
                pension: 1050.0000000000002,
            },
        ],
    },
};

export const goalCostLongTermApiResponse = {
    data: [
        {
            attributes: {
                costs: [
                    995.3978236391264, 12123.945491924558, 12305.804674303425,
                    12490.391744417975, 12677.747620584245, 12867.913834893006,
                    13060.932542416402, 13256.846530552644, 13455.699228510932,
                    13657.534716938597, 13862.397737692676, 14070.333703758064,
                    14281.388709314433, 14495.609539954148, 14713.043683053458,
                    14933.73933829926, 15157.745428373746, 15385.11160979935,
                    15615.88828394634, 15850.126608205534, 16087.878507328613,
                    16329.196684938543, 16574.134635212617, 16822.746654740808,
                    17075.087854561916, 17331.214172380343, 16125.250519552212,
                ],
            },
            id: '',
            type: 'GoalCosts',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/simulations/goal-cost',
    },
};

export const goalCostShortTermApiResponse = {
    data: [
        {
            attributes: {
                costs: [12123.945491924558],
            },
            id: '',
            type: 'GoalCosts',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/simulations/goal-cost',
    },
};

export const goalsApiResponse = {
    data: [
        {
            attributes: {
                amount: 20000,
                created_at: '2022-07-29T09:23:01+02:00',
                init_date: '2026-07-29T09:22:47+02:00',
                is_active: true,
                name: 'Casa',
                output_type: 'UNIQUE',
                plan_id: 1,
                protection_type: 'CAPITAL',
                type: 'REAL_STATE',
                updated_at: '2022-07-29T09:23:07+02:00',
            },
            id: '3',
            type: 'Goal',
        },
        {
            attributes: {
                amount: 600,
                created_at: '2022-08-05T08:37:53+02:00',
                end_date: '2083-10-12T08:37:39+02:00',
                init_date: '2027-10-12T08:37:14+02:00',
                is_active: false,
                name: 'Jubilación',
                output_type: 'MONTHLY',
                plan_id: 1,
                protection_type: 'LONG_TERM',
                type: 'RETIREMENT',
                updated_at: '2022-08-05T08:37:54+02:00',
            },
            id: '2',
            type: 'Goal',
        },
        {
            attributes: {
                amount: 15000,
                created_at: '2022-08-10T09:32:37+02:00',
                end_date: '2029-04-21T00:00:00+02:00',
                init_date: '2025-04-21T00:00:00+02:00',
                is_active: true,
                name: 'Educación',
                output_type: 'ANNUAL',
                plan_id: 1,
                protection_type: 'CAPITAL',
                type: 'EDUCATION',
                updated_at: '2022-08-10T09:32:40+02:00',
            },
            id: '1',
            type: 'Goal',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/goals',
    },
};

export const goalsWithCostsApiResponse = {
    data: [
        {
            attributes: {
                amount: 20000,
                created_at: '2022-07-29T09:23:01+02:00',
                init_date: '2026-07-29T09:22:47+02:00',
                is_active: true,
                name: 'Casa',
                output_type: 'UNIQUE',
                plan_id: 1,
                protection_type: 'CAPITAL',
                type: 'REAL_STATE',
                updated_at: '2022-07-29T09:23:07+02:00',
            },
            id: '3',
            type: 'Goal',
        },
        {
            attributes: {
                amount: 600,
                created_at: '2022-08-05T08:37:53+02:00',
                end_date: '2083-10-12T08:37:39+02:00',
                init_date: '2057-10-12T08:37:14+02:00',
                is_active: false,
                name: 'Jubilación',
                output_type: 'MONTHLY',
                plan_id: 1,
                protection_type: 'LONG_TERM',
                type: 'RETIREMENT',
                updated_at: '2022-08-05T08:37:54+02:00',
            },
            id: '2',
            type: 'Goal',
        },
        {
            attributes: {
                amount: 15000,
                created_at: '2022-08-10T09:32:37+02:00',
                end_date: '2029-04-21T00:00:00+02:00',
                init_date: '2025-04-21T00:00:00+02:00',
                is_active: true,
                name: 'Educación',
                output_type: 'ANNUAL',
                plan_id: 1,
                protection_type: 'CAPITAL',
                type: 'EDUCATION',
                updated_at: '2022-08-10T09:32:40+02:00',
            },
            id: '1',
            type: 'Goal',
        },
    ],
    included: [
        {
            attributes: {
                costs: [20913.567499999994],
                goal_id: 3,
            },
            id: '1',
            type: 'GoalCosts',
        },
        {
            attributes: {
                costs: [
                    11113.616700930846, 12305.804674303425, 12490.391744417975,
                    12677.747620584245, 12867.913834893006, 13060.932542416402,
                    13256.846530552644, 13455.699228510932, 13657.534716938597,
                    13862.397737692676, 14070.333703758064, 14281.388709314433,
                    14495.609539954148, 14713.043683053458, 14933.73933829926,
                    15157.745428373746, 15385.11160979935, 15615.88828394634,
                    15850.126608205534, 16087.878507328613, 16329.196684938543,
                    16574.134635212617, 16822.746654740808, 17075.087854561916,
                    17331.214172380343, 17591.18238496605, 1487.9208433950448,
                ],
                goal_id: 2,
            },
            id: '2',
            type: 'GoalCosts',
        },
        {
            attributes: {
                costs: [
                    15453.374999999996, 15685.175624999994, 15920.453259374995,
                    16159.260058265618, 16401.6489591396,
                ],
                goal_id: 1,
            },
            id: '3',
            type: 'GoalCosts',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/goals',
    },
};

export default goalsArrayMockedData;
