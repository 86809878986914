import Box from '@mui/material/Box';
import * as React from 'react';
import {Slider, SliderMark, SliderMarkLabel, SliderThumb, useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {FormikErrors} from 'formik';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

interface NotInteractiveDiscreteSlider {
    sameHierarchy?: boolean;
    value: number;
    min?: number;
    max?: number;
    step?: number;
    marks?: Array<any>;
    name: string;
    profileNumberMax: number | string;
    handleChange?: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void | Promise<void> | Promise<FormikErrors<{recipient: string}>>;
}

const StyledSlider = styled(Slider)((props) => ({
    marginBottom: 0,
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        zIndex: 10,
        height: '1.1em',
        width: '1.1em',
        backgroundColor: 'var(--color-primary-100)',
        '&:hover': {
            boxShadow: '0 0 0 0.4em rgba(58, 133, 137, 0.16)',
        },
        '& .inner-bar': {
            height: '0.5em',
            width: '0.05em',
            backgroundColor: 'var(--accent-color-1)',
            marginLeft: '0.05em',
            marginRight: '0.05em',
            zIndex: 2,
        },
        '& .outside-bar': {
            height: '3.2em',
            width: '0.1em',
            backgroundColor: 'var(--color-primary-100)',
            marginLeft: '0.05em',
            marginRight: '0.05em',
            position: 'absolute',
            zIndex: 1,
        },
        '& .top-outside-bar': {
            height: '2.6em',
            width: '0.1em',
            backgroundColor: 'var(--color-primary-100)',
            marginLeft: '0.05em',
            marginRight: '0.05em',
            position: 'absolute',
            zIndex: 1,
            bottom: 0,
        },
        '& .border': {
            backgroundColor: 'var(--color-primary-100)',
            borderRadius: '50%',
            width: '1.15em',
            height: '1.15em',
            position: 'absolute',
            zIndex: 2,
        },
    },
    '& .MuiSlider-valueLabel': {
        color: 'var(--text-color-30)',
        fontSize: 20,
        fontWeight: 600,
        top: '-1.5em',
        backgroundColor: 'var(--accent-color-1)',
        border: `2px solid var(--text-color-10)`,
        borderRadius: 100,
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-track': {
        height: '0.4em',
        color: 'var(--color-secondary-100)',
        borderRadius: 0,
    },
    '& .MuiSlider-rail': {
        color: props['aria-label'] === 'hierarchy' ? 'var(--color-secondary-100)' : 'var(--text-color-30)',
        opacity: 1,
        height: props['aria-label'] === 'hierarchy' ? '0.4em' : '0.05em',
    },

    '& .MuiSlider-mark': {
        height: '0.75em',
        width: '0.75em',
        borderRadius: '50%',
        border:
            props['aria-label'] === 'hierarchy'
                ? `0.2em solid var(--color-primary-100)`
                : `0.05em solid var(--text-color-30)`,
        background: 'var(--accent-color-1)',
        opacity: 1,
        '&.MuiSlider-markActive': {
            border: `0.2em solid var(--color-primary-100)`,
        },
    },
}));

function SliderThumbComponent(props: any) {
    const {children, ...other} = props;
    // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
    return <SliderThumb {...other}>{children}</SliderThumb>;
}

SliderThumbComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

function SliderMarkLabelComponent(props: any) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {children, ...other} = props;
    const parsedOther = {
        ...other,
        style: {left: other.style.left, top: downMobile ? '-2.7em' : '-3.4em'},
    };
    // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
    return <SliderMarkLabel {...parsedOther}>{children}</SliderMarkLabel>;
}

SliderMarkLabelComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

function SliderMarkComponent(props: any) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const {children, ...other} = props;
    const profile = other.ownerState.componentsProps.valueLabel.value;
    const isSliderActive = other.ownerState.componentsProps.valueLabel.valueLabelDisplay === 'on';
    const parsedOther = {
        ...other,
        style: {
            ...other.style,
            left: `calc(${other.style.left} - ${downMobile ? '2.6' : '1.3'}%)`,
            background:
                // eslint-disable-next-line no-nested-ternary
                profile >= other['data-index'] + 1 && isSliderActive === false
                    ? 'var(--color-primary-100)'
                    : profile < other['data-index'] + 1
                    ? 'var(--text-color-10)'
                    : 'var(--accent-color-1)',
        },
    };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SliderMark {...parsedOther}>{children}</SliderMark>;
}

SliderMarkComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

export function NotInteractiveSlider(props: NotInteractiveDiscreteSlider) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {value, sameHierarchy, min, max, step, marks, handleChange, name, profileNumberMax} = props;
    const numericalProfileNumberMax =
        typeof profileNumberMax === 'string' ? parseInt(profileNumberMax, 10) : profileNumberMax;
    return (
        <Box
            sx={{
                // eslint-disable-next-line no-nested-ternary
                width: downMobile ? '100%' : marks ? 600 : 350,
            }}
        >
            <StyledSlider
                /*
                 * Using component props to pass aditional info to markcomponent
                 * In this case value has profile number and valueLabelDisplay is on when slider is active
                 */
                componentsProps={{
                    valueLabel: {
                        value: numericalProfileNumberMax,
                        valueLabelDisplay: handleChange ? 'on' : undefined,
                    },
                }}
                aria-label={sameHierarchy === true ? 'hierarchy' : undefined}
                valueLabelDisplay="off"
                components={{
                    ValueLabel: undefined,
                    Thumb: handleChange ? SliderThumb : undefined,
                    MarkLabel: SliderMarkLabelComponent,
                    Mark: SliderMarkComponent,
                }}
                sx={{
                    cursor: handleChange ? 'pointer' : 'default',
                }}
                step={step}
                marks={marks || true}
                min={min}
                max={max}
                value={value}
                /* eslint-disable-next-line @typescript-eslint/no-shadow */
                onChange={(event, value) => {
                    if (value <= numericalProfileNumberMax) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        handleChange ? handleChange(name, value) : null;
                    }
                }}
            />
        </Box>
    );
}

NotInteractiveSlider.defaultProps = {
    min: undefined,
    max: undefined,
    step: undefined,
    marks: undefined,
    handleChange: undefined,
    sameHierarchy: false,
};

export default NotInteractiveSlider;
