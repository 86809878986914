import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './toggle-switch.module.css';

/**
 * @typedef {Object} Props
 * @property {boolean} checked - estado del toggle
 */

/**
 * Componente que muestra un toggle switch
 * @return {JSX.Element}
 * @param {Props} props
 */
function ToggleSwitch({checked}) {
    const [isChecked, setIsChecked] = React.useState(false);
    React.useEffect(() => {
        setIsChecked(checked);
    }, [checked]);
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={styles.switch}>
            <input id="checkbox" type="checkbox" checked={isChecked} />
            <span className={styles.slider} />
        </label>
    );
}

ToggleSwitch.propTypes = {
    checked: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
    checked: false,
};

export default ToggleSwitch;
