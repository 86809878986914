import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import PageLoading from '../../elements/loading/PageLoading';
import {addAlert, selectApp} from '../../modules/app/app.slice';
import styles from '../../styles/Resultado.module.css';
import {
    getAccumulatedNetWorthChartData,
    getGlobalConfigWithoutInveert,
    getPlan,
    getPlanWithGlobalConfig,
} from '../../modules/propuesta/propuesta.service';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-xxl';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/button/button';
import '@inveert/ui-kit/src/icon/icon';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-xxs';
import {getErrorMessage, tr} from '../../utils/functions';
import {getAllRiskProfilesApi, getRiskProfileByUserId} from '../../modules/risk/risk.service';
import {PropuestaPlanComparisonComponent} from '../../modules/propuesta/propuesta-plan-comparison.component';
import Tpl from '../../elements/tpl/tpl';
import {getUserIdApi} from '../../modules/user/user.service';
import {Disclaimer} from '../../components/disclaimer/disclaimer';

function Resultado() {
    const scrollToTopRef = React.useRef(null);
    const dispatch = useDispatch();
    const {reloadApiFlag} = useSelector(selectApp);
    const router = useNavigate();
    const [planGlobalInfo, setPlanGlobalInfo] = React.useState({
        goal_cost_with_taxation: 0,
        initial_investment: 0,
        monthly_saving: 0,
        performance: 0,
        remaining_saving: 0,
        contributed: 0,
        monthly_saving_without_inveert: 0,
        amount: 0,
        taxation: 0,
        pending: 0,
    });
    const [loading, setLoading] = React.useState(true);
    const [riskProfileAlias, setRiskProfileAlias] = React.useState('');
    const [cumulativeNetWorthChartData, setCumulativeNetWorthChartData] = React.useState([
        {bar1: 0, bar2: 0, name: '0'},
    ]);
    const preventGoingBack = () => {
        router(1);
    };

    React.useEffect(() => {
        window.onpopstate = preventGoingBack;
    }, []);
    React.useEffect(() => {
        setLoading(true);
        const withoutInveertRes = getPlan().then((res) => {
            const planId = res[0]?.id;
            return new Promise((resolve, reject) => {
                if (planId) {
                    const global = getGlobalConfigWithoutInveert(planId);
                    const accNet = getAccumulatedNetWorthChartData(planId);
                    resolve(
                        Promise.all([global, accNet]).then((planRes) => ({
                            global: planRes[0],
                            accNet: planRes[1],
                        }))
                    );
                } else {
                    reject(new Error(tr('No existe un plan para el usuario')));
                }
            });
        });
        let resActiveRiskProfile = null;
        const storage = globalThis?.sessionStorage;
        let token = '';
        if (storage) {
            // @ts-ignore
            token = storage.getItem('token') ?? null;
            if (token) {
                resActiveRiskProfile = getUserIdApi(token).then((res) =>
                    getRiskProfileByUserId(res.attributes.user_id)
                );
            }
        }
        const resRiskProfiles = getAllRiskProfilesApi();
        const planWithGlobalInfoRes = getPlanWithGlobalConfig();
        Promise.all([planWithGlobalInfoRes, withoutInveertRes, resActiveRiskProfile, resRiskProfiles])
            .then((res) => {
                res[2] = res[2].find(
                    (riskProfile: {attributes: {is_active: boolean}}) => riskProfile.attributes.is_active
                );
                const userCurrentRisk = res[2];
                const aliasCurrentRisk = res[3].find(
                    // @ts-ignore
                    (riskItem: {id: any}) => riskItem.id === userCurrentRisk?.attributes?.risk_profile_id.toString()
                );
                setRiskProfileAlias(aliasCurrentRisk.attributes.name);
                const {global, accNet}: any = res[1];
                setCumulativeNetWorthChartData(accNet);
                // eslint-disable-next-line @typescript-eslint/no-shadow
                setPlanGlobalInfo({
                    ...res[0].included[0].attributes,
                    monthly_saving_without_inveert: global.attributes.monthly_savings,
                });
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
    }, [reloadApiFlag]);
    const nextStepRoute = `/${process.env.REACT_APP_RESULTADO_NEXT_STEP_PATH ?? 'registro'}`;
    return (
        <>
            <PageLoading open={loading} />
            <div className={styles.container} ref={scrollToTopRef}>
                <inv-grid-row class={styles.titleContainer}>
                    <inv-grid-col class="col-8" style={{display: 'flex'}}>
                        <inv-text-l
                            style={
                                {
                                    '--inv-text-l-gradient': 'var(--color-tertiary)',
                                    '--inv-text-l-font-weight': 600,
                                    textAlign: 'center',
                                } as React.CSSProperties
                            }
                        >
                            {tr('¿Y si comparamos?')}
                        </inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                    <inv-grid-col class="col-auto">
                        <inv-text-m
                            style={
                                {
                                    '--inv-text-m-font-weight': 600,
                                    textAlign: 'center',
                                } as React.CSSProperties
                            }
                        >
                            <Tpl
                                template={tr('Necesitas {costLessTaxes}{currency} para tus metas')}
                                values={{
                                    costLessTaxes: (
                                        <NumberFormat
                                            key="resultado-cost-less-taxes"
                                            value={(planGlobalInfo?.amount ?? 0) - (planGlobalInfo?.taxation ?? 0)}
                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                            decimalScale={0}
                                            allowNegative={false}
                                            displayType="text"
                                        />
                                    ),
                                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                }}
                            />
                        </inv-text-m>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row
                    class={styles.withAndWithoutInveertContainer}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <inv-grid-col class="col-12">
                        <PropuestaPlanComparisonComponent
                            goalCost={(planGlobalInfo?.amount ?? 0) - (planGlobalInfo?.taxation ?? 0)}
                            monthlySavingWithoutPlan={planGlobalInfo.monthly_saving_without_inveert}
                            monthlySavingWithPlan={planGlobalInfo.monthly_saving}
                            saving={planGlobalInfo.contributed}
                            performance={(planGlobalInfo?.performance ?? 0) - (planGlobalInfo?.taxation ?? 0)}
                            riskProfileAlias={riskProfileAlias}
                            cumulativeNetWorthChartData={cumulativeNetWorthChartData}
                        />
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.adviceContainer}>
                    <inv-grid-col class="col-12 col-sm-10">
                        <inv-grid-row
                            class={styles.borderedContainer}
                            style={{
                                border: `0.05em solid var(--text-color-30)`,
                                borderRadius: '0.8em',
                            }}
                        >
                            <inv-grid-col class="col-12 col-sm-7">
                                <inv-text-s
                                    style={{
                                        '--inv-text-s-color': 'var(--text-color-50)',
                                    }}
                                >
                                    <Tpl
                                        template={tr(
                                            'Con el plan de {siteTitle} sólo tendrías que aportar un {percentage} del total que necesitas'
                                        )}
                                        values={{
                                            siteTitle: process.env.REACT_APP_SITE_TITLE ?? '',
                                            percentage: (
                                                <inv-text-s
                                                    key="resultado-percentage"
                                                    style={{
                                                        '--inv-text-s-font-weight': 700,
                                                        '--inv-text-s-color': 'var(--text-color-50)',
                                                        '--inv-text-s-font-size': '1em',
                                                    }}
                                                >
                                                    <NumberFormat
                                                        value={
                                                            (((planGlobalInfo?.pending ?? 0) +
                                                                planGlobalInfo.initial_investment) /
                                                                ((planGlobalInfo?.pending ?? 0) +
                                                                    (planGlobalInfo?.initial_investment ?? 0) +
                                                                    ((planGlobalInfo?.performance ?? 0) -
                                                                        (planGlobalInfo?.taxation ?? 0)))) *
                                                            100
                                                        }
                                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                        decimalScale={2}
                                                        allowNegative={false}
                                                        displayType="text"
                                                        suffix="%"
                                                    />
                                                </inv-text-s>
                                            ),
                                        }}
                                    />
                                </inv-text-s>
                            </inv-grid-col>
                            <inv-grid-col class={`col-12 col-sm-5 ${styles.borderedContainerButton}`}>
                                <inv-button
                                    class="button button--primary-contained full-width"
                                    onClick={() => router(nextStepRoute)}
                                >
                                    {tr('Acceder al plan')}
                                </inv-button>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            {process.env.REACT_APP_SHOW_DISCLAIMER === 'true' && (
                <div className={styles.disclaimerContainer}>
                    <Disclaimer companyName={process.env.REACT_APP_SITE_TITLE} />
                </div>
            )}
        </>
    );
}

Resultado.layout = 'EmptyLayout';
Resultado.mobileLayout = 'MobileEmptyLayout';
export default Resultado;
