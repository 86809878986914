// @ts-ignore
import {Area, ComposedChart, Line, ReferenceDot, ReferenceLine, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import React from 'react';
import NumberFormat from 'react-number-format';
import {CustomReferenceDot} from './AreasChart';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import {useFontSize} from '../../utils/hooks';
import {tr} from '../../utils/functions';

function CustomLabel(props: any) {
    const {viewBox, lastRealEquity} = props;
    const {x, y} = viewBox;
    return (
        <g>
            <foreignObject x={x - 35} y={y - 45} width={70} height={70}>
                <div
                    style={{
                        width: 'fit-content',
                        height: 'fit-content',
                        background: 'var(--color-secondary-100)',
                        borderRadius: '0.4em',
                        padding: '0.4em',
                        margin: 'auto',
                        lineHeight: 0,
                    }}
                >
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-text-l
                                style={{
                                    '--inv-text-l-color': 'var(--accent-color-1)',
                                    '--inv-text-l-font-weight': 600,
                                    '--inv-text-l-font-size': '1.2em',
                                }}
                            >
                                <NumberFormat
                                    value={lastRealEquity ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={2}
                                    displayType="text"
                                    suffix="%"
                                />
                            </inv-text-l>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-text-xxs
                                style={{
                                    '--inv-text-xxs-color': 'var(--accent-color-1)',
                                    '--inv-text-xxs-font-weight': 600,
                                    '--inv-text-xxs-font-size': '0.6em',
                                }}
                            >
                                {tr('Hoy')}
                            </inv-text-xxs>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
            </foreignObject>
        </g>
    );
}

interface IFunnelChart {
    name: string;
    height?: number;
    tir: number;
    data: any;
}

function CustomReferenceLineLabel({tir, viewBox}: any) {
    const fontSize = useFontSize();
    return (
        <g>
            <foreignObject
                x={viewBox.x - 55}
                y={viewBox.y - 15}
                width={`${(fontSize ?? 0) * 3.4}px`}
                height={`${(fontSize ?? 0) * 2.1}px`}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        borderRadius: '0.2em',
                        flexDirection: 'column',
                    }}
                >
                    <inv-text-xxs
                        style={{
                            '--inv-text-xxs-color': 'var(--color-primary-100)',
                            '--inv-text-xxs-font-weight': 700,
                            '--inv-text-xxs-font-size': '0.9em',
                            marginRight: 0,
                        }}
                    >
                        {`${tir}%`}
                    </inv-text-xxs>
                    <br />
                    <inv-text-xxs
                        style={{
                            '--inv-text-xxs-color': 'var(--color-primary-100)',
                            '--inv-text-xxs-font-weight': 500,
                            '--inv-text-xxs-font-size': '0.6em',
                        }}
                    >
                        {tr('Planificado')}
                    </inv-text-xxs>
                </div>
            </foreignObject>
        </g>
    );
}

export default function FunnelChart(props: IFunnelChart) {
    const {tir, data} = props;
    const minValue = data.reduce((minDataValue: number, monthlyData: {real: number}) => {
        let thisMinDataValue = minDataValue;
        if (minDataValue > monthlyData.real) {
            thisMinDataValue = monthlyData.real;
        }
        return thisMinDataValue;
    }, 0);
    const maxValue = data.reduce((maxDataValue: any, monthlyData: {real: number}) => {
        let thisMaxDataValue = maxDataValue;
        if (thisMaxDataValue < monthlyData.real) {
            thisMaxDataValue = monthlyData.real;
        }
        return thisMaxDataValue;
    }, 0);
    const lastDate = data.reduce((date: any, item: {date: any}, index: number) => {
        let thisDate = date;
        if (data[index].real !== null && data[index + 1]?.real === null) {
            thisDate = item.date;
        }
        return thisDate;
    });
    const lastRealEquity = data.reduce((real: any, item: {real: any}, index: number) => {
        let thisReal = real;
        if (data[index].real !== null && data[index + 1]?.real === null) {
            thisReal = item.real;
        }
        return thisReal;
    });
    const lowestValue = minValue < data[0].min_performance[0] ? minValue : data[0].min_performance[0];
    const highestValue = maxValue > data[0].max_performance[0] ? maxValue : data[0].max_performance[0];

    const {name, height} = props;
    const containerRef = React.useRef();
    const [fontSize, setFontSize] = React.useState('0px');
    React.useEffect(() => {
        const element = containerRef.current;
        if (element) {
            const style = window.getComputedStyle(element);
            const chartFontSize = style.getPropertyValue('font-size');
            const fontSizeNumber = parseInt(chartFontSize, 10);
            setFontSize(`${fontSizeNumber * 0.7}px`);
        }
    }, []);
    return (
        // @ts-ignore
        <div ref={containerRef}>
            <ResponsiveContainer width="100%" height={height ?? (parseInt(fontSize, 10) / 0.7) * 20} key={name}>
                <ComposedChart data={data}>
                    <YAxis
                        type="number"
                        stroke="var(--text-color-50)"
                        fill="var(--text-color-50)"
                        padding={{top: 70, bottom: 20}}
                        domain={[Math.round(lowestValue * 10) / 10, Math.round(highestValue * 1.1 * 10) / 10]}
                        allowDataOverflow
                        tickLine={false}
                        fontFamily="Inter"
                        fontSize={fontSize}
                        unit="%"
                        axisLine={false}
                        tickCount={0}
                        width={100}
                    />
                    <XAxis
                        type="category"
                        allowDuplicatedCategory
                        ticks={[
                            data[0]?.date ?? 0,
                            data[Math.trunc(data.length / 6)]?.date ?? 0,
                            data[Math.trunc(data.length / 3)]?.date ?? 0,
                            data[Math.trunc(data.length / 2)]?.date ?? 0,
                            data[Math.trunc((2 * data.length) / 3)].date ?? 0,
                            data[Math.trunc((5 * data.length) / 6)].date ?? 0,
                            data[data.length - 1]?.date ?? 0,
                        ]}
                        domain={[data[0]?.date ?? 0, data[data.length - 1]?.date ?? 0]}
                        tickMargin={-10}
                        axisLine={false}
                        stroke="var(--text-color-50)"
                        fill="var(--text-color-50)"
                        dataKey="date"
                        tickLine={false}
                        fontFamily="Inter"
                        fontSize={fontSize}
                        tickFormatter={(tick: string, index: number) =>
                            `${index === 0 ? `${tr('Año')} ` : ''}${
                                typeof tick === 'string' ? tick?.split('-')[0] : ''
                            }`
                        }
                    />
                    <Area
                        type="monotone"
                        dataKey="max_performance"
                        fill="#01BCCB"
                        fillOpacity={0.2}
                        stroke="transparent"
                    />

                    <Area
                        type="monotone"
                        dataKey="min_performance"
                        fill="var(--color-secondary-60)"
                        fillOpacity={0.2}
                        stroke="transparent"
                    />
                    <ReferenceLine
                        y={tir}
                        label={<CustomReferenceLineLabel tir={tir} />}
                        strokeWidth={3}
                        stroke="var(--color-primary-100)"
                        isFront
                        height={10}
                        width={10}
                    />
                    <Line
                        type="monotone"
                        dataKey="real"
                        dot={false}
                        stroke="var(--color-secondary-100)"
                        strokeWidth={3}
                    />

                    <ReferenceLine
                        y={0}
                        label={{
                            fill: 'var(--text-color-30)',
                            position: 'left',
                            value: Math.abs(minValue) + Math.abs(maxValue) < 15 ? '0%' : '',
                            fontFamily: 'Inter',
                            fontSize,
                        }}
                        isFront
                        stroke="none"
                        height={10}
                        width={10}
                    />
                    <ReferenceLine
                        y={Math.round(highestValue * 10) / 10}
                        label={{
                            position: 'left',
                            value: `${Math.round(highestValue * 10) / 10}%`,
                            fill: 'var(--text-color-50)',
                            fontFamily: 'Inter',
                            fontSize,
                        }}
                        stroke="var(--text-color-30)"
                        strokeDasharray="1.5"
                        isFront
                        height={10}
                        width={10}
                    />
                    <ReferenceLine
                        y={Math.round(lowestValue * 10) / 10}
                        label={{
                            position: 'left',
                            value: `${Math.round(lowestValue * 10) / 10}%`,
                            fill: 'var(--text-color-50)',
                            fontFamily: 'Inter',
                            fontSize,
                        }}
                        stroke="var(--text-color-30)"
                        strokeDasharray="1.5"
                        isFront
                        height={10}
                        width={10}
                    />
                    <ReferenceLine
                        label={<CustomLabel lastRealEquity={lastRealEquity} />}
                        strokeDasharray="4"
                        stroke="var(--color-secondary-100)"
                        segment={[
                            {x: lastDate, y: Math.round(lowestValue * 10) / 10},
                            {
                                x: lastDate,
                                y: Math.round(highestValue * 1.1 * 10) / 10,
                            },
                        ]}
                    />
                    <ReferenceDot x={lastDate} y={lastRealEquity} shape={CustomReferenceDot} />
                    <ReferenceDot
                        x={data[data.length - 1].date}
                        y={data[data.length - 1].max_performance[0]}
                        shape={CustomReferenceDot}
                    />
                    <ReferenceDot
                        x={data[data.length - 1].date}
                        y={data[data.length - 1].expected_performance}
                        shape={CustomReferenceDot}
                    />
                    <ReferenceDot
                        x={data[data.length - 1].date}
                        y={data[data.length - 1].min_performance[0]}
                        shape={<CustomReferenceDot fillColor="var(--color-secondary-60)" />}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
}

FunnelChart.defaultProps = {
    height: null,
};
