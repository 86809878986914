import {Box, FormHelperText, InputLabel, outlinedInputClasses, Stack} from '@mui/material';
import {styled} from '@mui/material/styles';
import React from 'react';
import theme from '../../styles/theme';
import Text from '../../styles/textStyles';
import {StyledTextField} from './NumberInput';
import WCIcons from '../icons/WCIcons';

interface IFileInput {
    label: string;
    formik: any;
    name: string;
    error?: string;
    handleChange: (name: string, value: File, validate: boolean) => Promise<any>;
    helperLabel?: string;
    placeholder?: string;
}
const Input = styled('input')({
    display: 'none',
});

export function FileInput(props: IFileInput) {
    const {label, placeholder, formik, name, error, handleChange, helperLabel} = props;
    // @ts-ignore
    React.useEffect(() => import('@inveert/ui-kit/src/icon/icon'), []);
    return (
        <Box sx={{width: '100%'}}>
            <InputLabel
                sx={{
                    [theme.breakpoints.up('xs')]: {
                        marginBottom: theme.spacing(1),
                    },
                    [theme.breakpoints.up('xl')]: {
                        marginBottom: theme.spacing(2),
                    },
                }}
            >
                <Text size="s" weight="medium" fontColor="var(--text-color-50)">
                    {label}
                </Text>
            </InputLabel>
            <Stack direction="row" alignItems="center" spacing={2}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={name} style={{width: '100%'}}>
                    <Input
                        name={name}
                        id={name}
                        accept="application/pdf"
                        multiple
                        type="file"
                        onChange={(event) => {
                            const files = event?.currentTarget?.files;
                            if (files && files.length > 0) {
                                const file = files[0];
                                handleChange(name, file, false).finally(() => formik.validateField(name));
                            }
                        }}
                    />
                    <StyledTextField
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                color: 'var(--text-color-50)',
                                WebkitTextFillColor: 'inherit',
                            },
                            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
                                {
                                    border: `2px solid var(--text-color-30)`,
                                },
                            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.disabled}`]: {
                                background: 'inherit',
                            },
                            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
                                {
                                    border: `2px solid var(--error-color)`,
                                },
                        }}
                        disabled
                        fullWidth
                        placeholder={placeholder}
                        value={formik.values?.[name]?.name ?? 'Subir archivo'}
                        error={Boolean(error)}
                        /* eslint-disable-next-line react/jsx-no-duplicate-props */
                        InputProps={{
                            endAdornment: <WCIcons hoverColor="inherit" name="drive-folder-upload" />,
                        }}
                    />
                </label>
            </Stack>
            <FormHelperText
                sx={{
                    [theme.breakpoints.up('xs')]: {
                        marginTop: theme.spacing(1),
                    },
                    [theme.breakpoints.up('xl')]: {
                        marginTop: theme.spacing(2),
                    },
                }}
            >
                <Text size="xxs" weight="regular" fontColor="var(--text-color-30)">
                    {helperLabel}
                </Text>
            </FormHelperText>
            {Boolean(formik.errors[name]) && (
                <FormHelperText
                    error
                    sx={{
                        [theme.breakpoints.up('xs')]: {
                            marginTop: theme.spacing(1),
                        },
                        [theme.breakpoints.up('xl')]: {
                            marginTop: theme.spacing(2),
                        },
                    }}
                >
                    <Text size="xs" weight="regular" fontColor="inherit">
                        {error}
                    </Text>
                </FormHelperText>
            )}
        </Box>
    );
}

FileInput.defaultProps = {
    error: undefined,
    helperLabel: undefined,
    placeholder: '',
};

export default FileInput;
