import Box from '@mui/material/Box';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import {useMediaQuery} from '@mui/material';
import WCIcons from '../../elements/icons/WCIcons';
import theme from '../../styles/theme';

interface IPerformanceDialog {
    closeDialog: () => void;
}
export function PerformanceDialog(props: IPerformanceDialog) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const downXl = useMediaQuery(theme.breakpoints.down('xl'));
    const {closeDialog} = props;

    return (
        <Box
            sx={{
                width: '100%',
                // adding padding is a hotfix because of grid inside dialog mess with vertical position and adds a unwanted scrollbar
                [theme.breakpoints.up('xs')]: {
                    padding: theme.spacing(4),
                },
                [theme.breakpoints.up('xl')]: {
                    padding: theme.spacing(8),
                },
                [theme.breakpoints.down('mobile')]: {
                    padding: theme.spacing(0),
                },
            }}
        >
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                rowSpacing={{
                    xs: downMobile ? 8 : theme.spacing(9),
                    xl: theme.spacing(18),
                }}
            >
                {/* Need to wrap containers on item to apply maxwidth */}
                <Grid item sx={{width: '100%'}}>
                    {!downMobile && (
                        <Grid container justifyContent="flex-end">
                            <Grid
                                item
                                sx={{
                                    cursor: 'pointer',
                                    height: downXl ? '30' : '40',
                                }}
                                onClick={closeDialog}
                            >
                                <WCIcons
                                    hoverColor="currentColor"
                                    name="close-filled"
                                    height={downXl ? '30' : '40'}
                                    width={downXl ? '30' : '40'}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item sx={{padding: '40px'}}>
                    <img
                        alt="chart-performance"
                        src="/assets/images/performance-chart.png"
                        width="100%"
                        height="100%"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default PerformanceDialog;
