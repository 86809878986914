import * as React from 'react';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import styles from '../../styles/Selector.module.css';

interface ISelector {
    icon?: JSX.Element;
    iconColor?: string;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    selected: boolean;
    onClick?: () => void;
    height?: number | string;
    width?: number | string;
    disabled?: boolean;
    id?: string;
}

function Selector(props: ISelector) {
    const {id, icon, iconColor, title, subtitle, selected, onClick, height, width, disabled} = props;
    const isSquare = height && width && height === width;
    return (
        <button
            key={id}
            type="button"
            onClick={onClick}
            className={`input input--primary-radio ${styles.container} ${selected && styles.selected} ${
                disabled && styles.disabled
            }`}
            style={{
                height: height ?? 'auto',
                width: width ?? '-webkit-fill-available',
                borderRadius: isSquare ? '1.2em' : '5.2em',
            }}
        >
            <inv-grid-row class={styles['icon-container']}>
                {icon && (
                    <inv-grid-col style={{color: iconColor ?? 'inherit'}} class="col-auto col-sm-12">
                        {icon}
                    </inv-grid-col>
                )}
                <inv-grid-col class="col-auto">
                    <inv-grid-row
                        style={{
                            opacity: disabled === true ? 0.4 : 1,
                        }}
                    >
                        <inv-grid-col class="col-auto">
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 600,
                                    textAlign: 'center',
                                }}
                            >
                                {typeof title === 'string'
                                    ? title
                                          .split('\n')
                                          .map(
                                              (
                                                  line:
                                                      | boolean
                                                      | React.ReactChild
                                                      | React.ReactFragment
                                                      | React.ReactPortal
                                                      | null
                                                      | undefined
                                              ) => (
                                                  <span key={`selector-text-${Math.random()}`}>
                                                      {line}
                                                      <br />
                                                  </span>
                                              )
                                          )
                                    : title}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
            </inv-grid-row>
            {subtitle && (
                <inv-grid-row style={{justifyContent: 'center', marginTop: '0.4em'}}>
                    <inv-grid-col class="col-auto">
                        <inv-text-xs
                            style={{
                                '--inv-text-xs-color': 'var(--text-color-50)',
                                '--inv-text-xs-font-weight': 500,
                                textAlign: 'center',
                            }}
                        >
                            {subtitle}
                        </inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
            )}
        </button>
    );
}

Selector.defaultProps = {
    icon: undefined,
    iconColor: undefined,
    subtitle: undefined,
    onClick: undefined,
    height: undefined,
    width: undefined,
    disabled: undefined,
    id: undefined,
};

export default Selector;
