import {createSlice} from '@reduxjs/toolkit';
import {Auth} from './auth.type';

export const initialState: Auth = {
    isAuthDialogOpen: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthDialogOpen(state, action: {payload: boolean}) {
            return {...state, isAuthDialogOpen: action.payload};
        },
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state: any) => state.auth;

export const {setIsAuthDialogOpen} = authSlice.actions;

export default authSlice.reducer;
