import * as React from 'react';
import {Route} from 'react-router-dom';
import EmptyLayoutComplete from '../styles/layouts/EmptyLayoutComplete';
import PageLoading from '../elements/loading/PageLoading';
import DinamicoPerfil from '../pages/perfil/dinamico';
import DeleteUser from '../pages/dev/delete-user';

export const routerDev = [
    <Route
        key="perfil/dinamico"
        path="/perfil/dinamico"
        element={
            <EmptyLayoutComplete>
                <React.Suspense fallback={<PageLoading open />}>
                    <DinamicoPerfil />
                </React.Suspense>
            </EmptyLayoutComplete>
        }
    />,
    <Route
        key="delete-user"
        path="/dev/delete-user"
        element={
            <EmptyLayoutComplete>
                <React.Suspense fallback={<PageLoading open />}>
                    <DeleteUser />
                </React.Suspense>
            </EmptyLayoutComplete>
        }
    />,
];
export default routerDev;
