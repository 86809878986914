import * as React from 'react';
import propTypes from 'prop-types';
import styles from './plan-contribution-pay-pending-init.module.css';
import PlanContributionPayPendingCard from './plan-contribution-pay-pending-card.component';
import PlanContributionPayPendingFooter from './plan-contribution-pay-pending-footer.component';
import WCIcons from '../../../../elements/icons/WCIcons';
import AdviceAlert from '../../../../elements/alert/AdviceAlert';

/**
 * @typedef {Object} Props
 * @property {function} handleBackClick - Función que se ejecuta al hacer click en el icono
 * @property {function} handleNextClick - Función que se ejecuta al hacer click en el botón de siguiente
 */

/**
 * Componente que muestra la pantalla del paso 1 de la propuesta de aportación
 * @return {JSX.Element}
 * @param {Props} props
 */
function PlanContributionPayPendingResult({handleBackClick, handleNextClick}) {
    return (
        <div className={styles.main}>
            <div className={styles.titleContainer}>
                <inv-grid-row class={styles.titleFirstLine}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>Resultado</inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.titleSecondLine}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>Propuesta de aportación</inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.titleThirdLine}>
                    <inv-grid-col class="col-sm-7">
                        <inv-text-xxs>
                            Tenemos en cuenta el patrimonio acumulado, el tiempo
                            que falta para tus metas y tu perfil inversor
                        </inv-text-xxs>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.resultsContainer}>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-auto">
                        <PlanContributionPayPendingCard
                            title="Plan financiero actual"
                            subtitle="Aportación prevista"
                            contributionAmount={370}
                        />
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <PlanContributionPayPendingCard
                            title="Plan financiero nuevo"
                            subtitle="Nueva aportación"
                            contributionAmount={412}
                            isNew
                        />
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-sm-7">
                    <AdviceAlert
                        text="Como la evolución de tu patrimonio ha sido peor de lo esperado, tu nueva aportación sería superior a la actual"
                        icon={
                            <WCIcons
                                name="help"
                                width="25"
                                height="24"
                                color="var(--color-secondary-100)"
                            />
                        }
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-12">
                    <PlanContributionPayPendingFooter
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        nextButtonText="Ver plan financiero nuevo"
                        backButtonText="Atrás"
                        currentStep={1}
                        totalSteps={3}
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

PlanContributionPayPendingResult.propTypes = {
    handleBackClick: propTypes.func.isRequired,
    handleNextClick: propTypes.func.isRequired,
};

export default PlanContributionPayPendingResult;
