import Table from '@mui/material/Table';
import {useSelector} from 'react-redux';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled} from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import theme from '../../styles/theme';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/text/text-xs';
import styles from './Tables.module.css';
import {tpl, tr} from '../../utils/functions';
import {selectApp} from '../../modules/app/app.slice';

interface ITables {
    data: Array<{col1: string; col2: string; col3: string; col4: number; url?: string}>;
    borderActive?: boolean;
}

// eslint-disable-next-line react/jsx-props-no-spreading
export const StyledTable = styled((props: any) => <Table {...props} />)(() => ({
    borderRadius: 0,
    boxShadow: 'none',
}));

// eslint-disable-next-line react/jsx-props-no-spreading
export const StyledTableCell = styled((props: any) => <TableCell {...props} />)(() => ({
    borderBottom: 'none',
    minWidth: 100,
    padding: '0.6em 0.4em',
    '@media (mix-width: 576px)': {
        padding: '0.6em 0',
    },
}));

export function Tables(props: ITables) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {data, borderActive} = props;
    const numberOfColumns = data[0].col1 === undefined ? 3 : 4;
    const {locale} = useSelector(selectApp);
    return (
        <div className={styles.container}>
            <inv-grid-row>
                <inv-grid-col class="col-12">
                    <TableContainer
                        sx={{
                            border: borderActive === true ? `0.1em solid var(--text-color-10)` : undefined,
                            padding: borderActive === true ? `1.2em 0.8em` : undefined,
                            borderRadius: borderActive === true ? '0.4em' : undefined,
                            [theme.breakpoints.up('xs')]: {
                                maxWidth: '100%',
                            },
                            [theme.breakpoints.down('md')]: {
                                border: 'none',
                            },
                        }}
                    >
                        <StyledTable sx={{minWidth: downMobile ? 0 : 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {numberOfColumns === 4 && (
                                        <StyledTableCell>
                                            <inv-grid-row>
                                                <inv-grid-col class="col-auto">
                                                    <inv-text-xs
                                                        style={{
                                                            '--inv-text-xs-font-weight': 700,
                                                        }}
                                                    >
                                                        {tr('Productos actuales')}
                                                    </inv-text-xs>
                                                </inv-grid-col>
                                            </inv-grid-row>
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="right">
                                        <inv-grid-row
                                            style={{
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            <inv-grid-col class="col-auto">
                                                <inv-text-xs
                                                    style={{
                                                        '--inv-text-xs-font-weight': 700,
                                                    }}
                                                >
                                                    {tr('Clase activo')}
                                                </inv-text-xs>
                                            </inv-grid-col>
                                        </inv-grid-row>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <inv-grid-row style={{justifyContent: 'flex-start'}}>
                                            <inv-grid-col class="col-auto">
                                                <inv-text-xs
                                                    style={{
                                                        '--inv-text-xs-font-weight': 700,
                                                    }}
                                                >
                                                    {tr('Geografía')}
                                                </inv-text-xs>
                                            </inv-grid-col>
                                        </inv-grid-row>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <inv-grid-row style={{justifyContent: 'flex-start'}}>
                                            <inv-grid-col class="col-auto">
                                                <inv-text-xs
                                                    style={{
                                                        '--inv-text-xs-font-weight': 700,
                                                    }}
                                                >
                                                    {tr('Peso')}
                                                </inv-text-xs>
                                            </inv-grid-col>
                                        </inv-grid-row>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow
                                        key={`row-${index + 1}-${row.col2}-${row.col3}-${row.col4}`}
                                        sx={{
                                            '&:last-child td': {
                                                borderBottom: 'hidden',
                                            },
                                            '&:last-child th': {
                                                borderBottom: 'hidden',
                                            },
                                        }}
                                    >
                                        {numberOfColumns === 4 && (
                                            <StyledTableCell component="th" scope="row">
                                                <inv-grid-row>
                                                    <inv-grid-col
                                                        class="col-12"
                                                        style={{
                                                            maxWidth: '22em',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            marginRight: '2em',
                                                        }}
                                                    >
                                                        {row?.url ? (
                                                            <inv-text-xs class={styles['link-active']}>
                                                                <a
                                                                    href={tpl(row?.url, {lang: locale || 'es-ES'})}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {row.col1}
                                                                </a>
                                                            </inv-text-xs>
                                                        ) : (
                                                            <inv-text-xs class={styles.link}>{row.col1}</inv-text-xs>
                                                        )}
                                                    </inv-grid-col>
                                                </inv-grid-row>
                                            </StyledTableCell>
                                        )}
                                        <StyledTableCell align="right">
                                            <inv-grid-row
                                                style={{
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <inv-grid-col class="col-auto">
                                                    <inv-text-xs
                                                        style={{
                                                            '--inv-text-xs-color': 'rgba(0, 8, 38, 0.80)',
                                                        }}
                                                    >
                                                        {row.col2}
                                                    </inv-text-xs>
                                                </inv-grid-col>
                                            </inv-grid-row>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <inv-grid-row
                                                style={{
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <inv-grid-col class="col-auto">
                                                    <inv-text-xs
                                                        style={{
                                                            '--inv-text-xs-color': 'rgba(0, 8, 38, 0.80)',
                                                        }}
                                                    >
                                                        {row.col3}
                                                    </inv-text-xs>
                                                </inv-grid-col>
                                            </inv-grid-row>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <inv-grid-row
                                                style={{
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <inv-grid-col class="col-auto">
                                                    <inv-text-xs
                                                        style={{
                                                            '--inv-text-xs-color': 'rgba(0, 8, 38, 0.80)',
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={row.col4}
                                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                            decimalScale={2}
                                                            allowNegative={false}
                                                            displayType="text"
                                                            prefix=""
                                                            suffix="%"
                                                        />
                                                    </inv-text-xs>
                                                </inv-grid-col>
                                            </inv-grid-row>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
Tables.defaultProps = {
    borderActive: undefined,
};

export default Tables;
