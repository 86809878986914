import {addMonths, getMonth, getYear, parse, parseISO, subMonths} from 'date-fns';
import {fetchApi, uniq} from '../../utils/functions';
import {
    getRecommendationServiceMockedData,
    inputsChartApiResponse,
    notAccumulatedNetWorthApiResponse,
} from './propuesta.data';
// eslint-disable-next-line import/no-cycle
import {getObjectives} from '../goal/goal.service';
import {goalsApiResponse} from '../goal/goal.data';
import {goalColorByType} from '../../utils/componentData';
import {setPlanDataLayer} from '../gtm/gtm.script';

let settings: any;

export const getMinRecommendationPeriod = async () => {
    try {
        const response = await fetchApi('plan/v1/financial-services', 'get', undefined);
        const recommendedPeriods = response.map(
            (item: {attributes: {recommended_period: any}}) => item.attributes.recommended_period
        );
        // Como mínimo debe ser 1
        return Math.min(...recommendedPeriods, 1);
    } catch (error) {
        // @ts-ignore
        throw new Error(error);
    }
};

export const getConfig = async () => {
    if (!settings) {
        settings = await fetchApi('setting/v1/settings', 'get', undefined);
    }

    return settings.attributes;
};

export const getRecommendationsService = (planId: string) =>
    fetchApi(`plan/v1/plans/${planId}/recommendations?include=FinancialService`, 'get', {}).catch(() => ({
        ...getRecommendationServiceMockedData,
    }));

export const getFinancialServicesService = (serviceId: string) =>
    fetchApi(
        `plan/v1/financial-services/${serviceId}?include=FinancialProducts,FinancialProductsFinancialServices`,
        'get',
        {}
    );

export const createPlan = async () => {
    const response = fetchApi('plan/v1/plans', 'post', {
        data: {
            type: 'Plan',
            attributes: {
                initial_investment: 0,
            },
        },
    });
    return response;
};

export const getPlan = async () => {
    const response = fetchApi('plan/v1/plans', 'get', undefined);
    return response;
};

export const getTirExistingUser = (planId: number) => fetchApi(`plan/v1/plans/${planId}/tir`, 'get', {});

export const getPlanWithGlobalConfig = async () => {
    try {
        const config = await fetchApi('plan/v1/plans?include=PlanSummaryCalculated', 'get', undefined);
        setPlanDataLayer(config.included[0].attributes.monthly_saving);
        return config;
    } catch (e) {
        // @ts-ignore
        throw new Error(e);
    }
};

export const getPlanWithGlobalConfigWithPlanId = (planId: number) =>
    fetchApi(`plan/v1/plans/${planId}?include=PlanSummaryCalculated`, 'get', undefined);

export const getPlanWithGoalResult = () => fetchApi('plan/v1/plans?include=GoalResult', 'get', undefined);

export const getGlobalConfigWithoutInveert = (id: string) =>
    fetchApi(`plan/v1/plans/${id}/saving-without-investment`, 'get', undefined);

export const getOutputChartAllObjectives = () => fetchApi(`plan/v1/goals?include=GoalWithdrawals`, 'get', undefined);

export const getAccumulatedNetWorthChartData = (planId: number) =>
    fetchApi(`plan/v1/plans/${planId}/annual-net-worth-calculated`, 'get', undefined)
        .then((res) => {
            const longestArrayIndex = res.reduce(
                (dataOfLongest: any, current: {attributes: {inputs: string | object}}, index: any) => {
                    let value = dataOfLongest;
                    const inputs = Object.values(current.attributes.inputs ?? {});
                    if (inputs.length > value.size) {
                        value = {size: inputs.length, index};
                    }
                    return value;
                },
                {size: 0, index: 0}
            );
            const firstYear: string = Object.keys(res[longestArrayIndex.index]?.attributes?.inputs)[0];
            const longestInputs: number[] = Object.values(res[longestArrayIndex.index].attributes.inputs ?? {});
            const getVal = (array: number[], index: number): number => array[index] ?? array[array.length - 1];

            return longestInputs.map((item: any, index: number) => {
                const indexYear = parseInt(firstYear, 10) + index;
                const areaValue = res.reduce(
                    (
                        value: {bar1: number; bar2: number},
                        current: {
                            attributes: {
                                inputs: number[];
                                performances: number[];
                            };
                            length: any;
                        }
                    ) => ({
                        bar1: value.bar1 + getVal(Object.values(current?.attributes?.inputs ?? {}), index),
                        bar2: value.bar2 + getVal(Object.values(current?.attributes?.performances ?? {}), index),
                    }),
                    {bar1: 0, bar2: 0}
                );
                return {...areaValue, name: indexYear};
            });
        })
        .catch(() => [{bar1: 0, bar2: 0, name: 0}]);

export const getRealAccumulatedNetWorthChartData = (planId: number) =>
    fetchApi(`plan/v1/plans/${planId}/annual-net-worth-not-accumulated`, 'get', undefined);

export const getNotAccumulatedNetWorthChartData = (planId: number) => {
    const objectiveRes = getObjectives();
    const notAccumulatedRes = fetchApi(
        `plan/v1/plans/${planId}/annual-net-worth-not-accumulated-calculated`,
        'get',
        undefined
    );

    return Promise.all([objectiveRes, notAccumulatedRes])
        .then((res) => {
            const notAccumulatedByGoal = res[1];
            const longestArrayIndex = notAccumulatedByGoal.reduce(
                (dataOfLongest: {size: number}, current: {attributes: {data: any}}, index: any) => {
                    const currentData = current?.attributes?.data;
                    if (currentData && Object.keys(currentData).length > dataOfLongest.size) {
                        return {
                            size: Object.keys(currentData).length,
                            index,
                        };
                    }
                    return dataOfLongest;
                },
                {size: -1, index: null}
            );

            let monthlyDate: number | Date = parseISO(notAccumulatedByGoal[longestArrayIndex.index]?.attributes?.date);
            const data = Object.entries(notAccumulatedByGoal[longestArrayIndex.index].attributes.data).map(([key]) => {
                const indexYear = getYear(new Date(key));
                const indexMonth = getMonth(new Date(key)) + 1;
                const date = `${indexYear}-${indexMonth < 10 ? '0' : ''}${indexMonth}`;
                monthlyDate = addMonths(monthlyDate, 1);
                const areaValue = notAccumulatedByGoal.reduce(
                    (accValue: any, current: {attributes: {data: {[x: string]: any}}}) =>
                        accValue + (current.attributes?.data[key] ?? 0),
                    0
                );
                return {area1: areaValue, name: indexYear, date};
            });
            const dotsData = res[0].map((objective: {attributes: {init_date: string; type: any}}) =>
                data.reduce(
                    (
                        areaValue: {
                            name: number;
                            area1: number;
                            type: string;
                            date: string;
                        },
                        current: {name: number; area1: any; date: string},
                        index: number
                    ) => {
                        let value = areaValue;
                        const objectiveDate = parseISO(objective.attributes.init_date);
                        let objectiveInitYear = getYear(objectiveDate);
                        /*
                                add 1 because in getMonth January is 0
                                substract 1 because the chart ends a month after the end
                             */
                        const objectiveInitMonth = getMonth(subMonths(objectiveDate, 1)) + 1;

                        // En el caso de que el mes sea 12, significa que paso al año anterior
                        if (objectiveInitMonth === 12) {
                            objectiveInitYear -= 1;
                        }
                        const date = `${objectiveInitYear}-${objectiveInitMonth < 10 ? '0' : ''}${objectiveInitMonth}`;
                        if (current.date === date) {
                            value = {
                                name: data[index - 1].name,
                                area1: data[index - 1].area1,
                                type: objective.attributes.type,
                                date,
                            };
                        }
                        return value;
                    },
                    {area1: 0, name: 0, type: 'NONE', date: ''} // add `date` property here
                )
            );
            const isGoalAtLastPosition = dotsData.reduce((isInLastPosition: boolean, current: {date: string}) => {
                let isLast = isInLastPosition;
                if (current.date === data[data.length - 1].date) {
                    isLast = true;
                }
                return isLast;
            }, false);
            if (isGoalAtLastPosition) {
                const lastDate = data[data.length - 1].date;
                const parsedData = parse(lastDate, 'yyyy-MM', new Date());

                const monthsOfMarginTillEnd = Math.round(data.length * 0.03);

                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < monthsOfMarginTillEnd; i++) {
                    const date = addMonths(parsedData, i + 1);
                    const indexYear = getYear(date);
                    const indexMonth = getMonth(date) + 1;
                    const dateStr = `${indexYear}-${indexMonth < 10 ? '0' : ''}${indexMonth}`;
                    data.push({area1: 0, name: indexYear, date: dateStr});
                }
            }
            return {data, dotsData};
        })
        .catch(() => {
            const goalIdArray = notAccumulatedNetWorthApiResponse.data.map((item) => item.attributes.goal_id);
            const uniqId = uniq(goalIdArray);
            const notAccumulatedByGoal = uniqId.map((id) =>
                notAccumulatedNetWorthApiResponse.data.filter(
                    (notAccumulatedData) => notAccumulatedData.attributes.goal_id === id
                )
            );
            const longestArrayIndex = notAccumulatedByGoal.reduce(
                (dataOfLongest, current, index) => {
                    let value = dataOfLongest;
                    if (current.length > value.size) {
                        value = {size: current.length, index};
                    }
                    return value;
                },
                {size: 0, index: 0}
            );
            const data = notAccumulatedByGoal[longestArrayIndex.index].map((item, index) => {
                const indexYear = getYear(new Date()) + index;
                const areaValue = notAccumulatedByGoal.reduce(
                    (value, current) => value + (current[index]?.attributes?.annual_net_worth ?? 0),
                    0
                );
                return {area1: areaValue, name: indexYear};
            });
            const dotsData = goalsApiResponse.data.map((objective) =>
                data.reduce(
                    (areaValue: {name: number; area1: number; type: string}, current) => {
                        let value = areaValue;
                        const objectiveInitYear = getYear(parseISO(objective.attributes.init_date));
                        if (current.name === objectiveInitYear) {
                            value = {
                                name: objectiveInitYear,
                                area1: current.area1,
                                type: objective.attributes.type,
                            };
                        }
                        return value;
                    },
                    {area1: 0, name: 0, type: 'NONE'}
                )
            );
            return {data, dotsData};
        });
};

export const getInputChartSaving = (planId: number) => {
    const objectivesRes = getObjectives();
    const inputRes = fetchApi(`plan/v1/plans/${planId}/monthly-savings?include=Saving`, 'get', undefined);
    return Promise.all([inputRes, objectivesRes])
        .then((res) => {
            const inputChartData = res[0].data
                .map((objective: {attributes: {goal_id: any}}) =>
                    res[0].included.filter(
                        (chartData: {attributes: {goal_id: any}}) =>
                            chartData.attributes.goal_id === objective.attributes.goal_id
                    )
                )
                .sort((a: string | any[], b: string | any[]) => b.length - a.length);
            const longestInputChartIndex = inputChartData.reduce(
                (longestArrayIndex: any, data: string | any[], index: any) => {
                    let indexOfLongestArray = longestArrayIndex;
                    if (inputChartData[longestArrayIndex] < data.length) {
                        indexOfLongestArray = index;
                    }
                    return indexOfLongestArray;
                },
                0
            );
            const barsWithGoalId = {
                bar1: inputChartData[0] ? inputChartData[0][0].attributes.goal_id.toString() : undefined,
                bar2: inputChartData[1] ? inputChartData[1][0].attributes.goal_id.toString() : undefined,
                bar3: inputChartData[2] ? inputChartData[2][0].attributes.goal_id.toString() : undefined,
                bar4: inputChartData[3] ? inputChartData[3][0].attributes.goal_id.toString() : undefined,
            };
            const barsColorByType: {
                bar1?: string;
                bar2?: string;
                bar3?: string;
                bar4?: string;
            } = res[1].reduce(
                (objectiveData: {}, item: {attributes: {type: string}; id: string}) => {
                    let barsColor = objectiveData;
                    if (item.id === barsWithGoalId.bar1) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar1: goalColorByType[item.attributes.type],
                        };
                    } else if (item.id === barsWithGoalId.bar2) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar2: goalColorByType[item.attributes.type],
                        };
                    } else if (item.id === barsWithGoalId.bar3) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar3: goalColorByType[item.attributes.type],
                        };
                    } else if (item.id === barsWithGoalId.bar4) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar4: goalColorByType[item.attributes.type],
                        };
                    }
                    return barsColor;
                },
                {
                    bar1: undefined,
                    bar2: undefined,
                    bar3: undefined,
                    bar4: undefined,
                }
            );
            const finalInput = inputChartData[longestInputChartIndex].map(
                (data: {attributes: {year: any}}, index: string | number) => ({
                    name: data.attributes.year,
                    bar1: inputChartData[0] ? inputChartData[0][index]?.attributes?.monthly_saving : 0,
                    bar2: inputChartData[1] ? inputChartData[1][index]?.attributes?.monthly_saving : 0,
                    bar3: inputChartData[2] ? inputChartData[2][index]?.attributes?.monthly_saving : 0,
                    bar4: inputChartData[3] ? inputChartData[3][index]?.attributes?.monthly_saving : 0,
                })
            );
            return {
                inputData: finalInput,
                barsColor: barsColorByType,
                barsId: barsWithGoalId,
            };
        })
        .catch(() => {
            const inputChartData = inputsChartApiResponse.data
                .map((objective: {attributes: {goal_id: any}}) =>
                    inputsChartApiResponse.included.filter(
                        (chartData: {attributes: {goal_id: any}}) =>
                            chartData.attributes.goal_id === objective.attributes.goal_id
                    )
                )
                .sort((a, b) => b.length - a.length);
            const longestInputChartIndex = inputChartData.reduce(
                (longestArrayIndex: any, data: string | any[], index: any) => {
                    let indexOfLongestArray = longestArrayIndex;
                    if (inputChartData[longestArrayIndex].length <= data.length) {
                        indexOfLongestArray = index;
                    }
                    return indexOfLongestArray;
                },
                0
            );
            const barsWithGoalId = {
                bar1: inputChartData[0] ? inputChartData[0][0].attributes.goal_id.toString() : undefined,
                bar2: inputChartData[1] ? inputChartData[1][0].attributes.goal_id.toString() : undefined,
                bar3: inputChartData[2] ? inputChartData[2][0].attributes.goal_id.toString() : undefined,
                bar4: inputChartData[3] ? inputChartData[3][0].attributes.goal_id.toString() : undefined,
            };
            const barsColorByType: {
                bar1?: string;
                bar2?: string;
                bar3?: string;
                bar4?: string;
            } = goalsApiResponse.data.reduce(
                (objectiveData: {}, item: {attributes: {type: string}; id: string}) => {
                    let barsColor = objectiveData;
                    if (item.id === barsWithGoalId.bar1) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar1: goalColorByType[item.attributes.type],
                        };
                    } else if (item.id === barsWithGoalId.bar2) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar2: goalColorByType[item.attributes.type],
                        };
                    } else if (item.id === barsWithGoalId.bar3) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar3: goalColorByType[item.attributes.type],
                        };
                    } else if (item.id === barsWithGoalId.bar4) {
                        barsColor = {
                            ...barsColor,
                            // @ts-ignore
                            bar4: goalColorByType[item.attributes.type],
                        };
                    }
                    return barsColor;
                },
                {
                    bar1: undefined,
                    bar2: undefined,
                    bar3: undefined,
                    bar4: undefined,
                }
            );
            return {
                inputData: inputChartData[longestInputChartIndex].map((data, index) => ({
                    name: data.attributes.year,
                    bar1: inputChartData[0] ? inputChartData[0][index]?.attributes?.monthly_saving : 0,
                    bar2: inputChartData[1] ? inputChartData[1][index]?.attributes?.monthly_saving : 0,
                    bar3: inputChartData[2] ? inputChartData[2][index]?.attributes?.monthly_saving : 0,
                    bar4: inputChartData[3] ? inputChartData[3][index]?.attributes?.monthly_saving : 0,
                })),
                barsColor: barsColorByType,
                barsId: barsWithGoalId,
            };
        });
};
export default getConfig;
