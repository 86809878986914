import * as React from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import NumberFormat from 'react-number-format';
import {useNavigate} from 'react-router-dom';
import styles from './seleccionar.module.css';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import {Capitalize, getErrorMessage, tr} from '../../utils/functions';
import SolidDividers from '../../elements/dividers/SolidDividers';
import CircleChart from '../../components/chart/CircleChart';
import PageLoading from '../../elements/loading/PageLoading';
import {NotInteractiveSlider} from '../../elements/slider/NotInteractiveDiscreteSlider';
import {getAllRiskProfilesApi, postChangeToInferiorRiskProfile} from '../../modules/risk/risk.service';
import {addAlert} from '../../modules/app/app.slice';
import {useFontSize, useWindowSize} from '../../utils/hooks';
import {getUserIdApi} from '../../modules/user/user.service';
import DotStepsFragment from '../../elements/fragments/DotStepsFragment';
import Tpl from '../../elements/tpl/tpl';

export function SeleccionarPerfil() {
    const router = useNavigate();
    const dispatch = useDispatch();
    const fontSize = useFontSize();
    const size = useWindowSize();
    const downMobile = (size?.width ?? 0) < 576;
    const [loading, setLoading] = React.useState(false);
    const [riskProfileArray, setRiskProfileArray] = React.useState([]);
    const [selectedProfile, setSelectedProfile] = React.useState({});
    const validationSchema = yup.object({
        profile: yup.number().min(1).max(6),
    });
    const formik = useFormik({
        initialValues: {
            profile: 3,
        },
        validationSchema,
        onSubmit: () => {
            setLoading(true);
            setLoading(false);
        },
    });
    const getAllRiskProfiles = async () => {
        try {
            const response = await getAllRiskProfilesApi();
            setRiskProfileArray(response);
            setSelectedProfile(response[formik.values.profile - 1] ?? {});
            setLoading(false);
        } catch (e) {
            const message = await getErrorMessage(e);
            dispatch(
                addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                })
            );
            setLoading(false);
        }
    };
    const handleOnClickNext = async () => {
        // eslint-disable-next-line no-undef
        const storage = globalThis?.sessionStorage;
        let token = '';
        if (storage) {
            // @ts-ignore
            token = storage.getItem('token') ?? null;
            if (token) {
                try {
                    const userInfo = await getUserIdApi(token);
                    if (!userInfo?.attributes?.user_id || userInfo?.attributes?.user_id === '') {
                        dispatch(
                            addAlert({
                                message: tr('Usuario no encontrado'),
                                isError: true,
                                isOpen: true,
                            })
                        );
                        router('/');
                    } else {
                        await postChangeToInferiorRiskProfile(userInfo?.attributes?.user_id, formik.values.profile);
                        router('/propuesta/resultado');
                    }
                } catch (e) {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                }
            }
        }
    };
    React.useEffect(() => {
        getAllRiskProfiles().then();
    }, []);
    React.useEffect(() => {
        // eslint-disable-next-line no-undef
        const element = document.getElementById('profile-description');
        if (element) {
            const key = `REACT_APP_PROFILE_DESCRIPTION_${formik.values.profile}`;
            element.innerHTML = process.env[key] ?? '';
        }
        setSelectedProfile(riskProfileArray[formik.values.profile - 1] ?? {});
    }, [formik.values.profile]);
    const volatilityText = (volatility) => {
        switch (volatility) {
            case 'LOW':
                return tr('BAJA');
            case 'MID':
                return tr('MEDIA');
            case 'HIGH':
                return tr('ALTA');
            case 'MID_LOW':
                return tr('MEDIA-BAJA');
            case 'MID_HIGH':
                return tr('MEDIA-ALTA');
            default:
                return tr('NO DISPONIBLE');
        }
    };
    const profileDescription =
        (selectedProfile?.attributes?.max_equity_exposure ?? 0) === 0 ? (
            <Tpl
                key={`seleccionar-perfil-description-text-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`}
                template={tr(
                    'Un inversor como tú aspira a una rentabilidad media a largo plazo en torno al {tir} Para ello vamos a recomendarte invertir un {equity}% de tus ahorros en Renta Variable, asumiendo una volatilidad {volatility} en el corto plazo.'
                )}
                values={{
                    tir: (
                        <div
                            style={{display: 'inline'}}
                            key={`seleccionar-perfil-div-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`}
                        >
                            <b key="seleccionar-perfil-seleccionado-performance-bold">
                                <NumberFormat
                                    key="seleccionar-perfil-seleccionado-performance"
                                    value={(selectedProfile?.attributes?.performance ?? 0) * 100}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={2}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix="%"
                                />
                            </b>
                            .
                            <br key={`break-1-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`} />
                            <br key={`break-2-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`} />
                        </div>
                    ),
                    equity: (
                        <NumberFormat
                            key="seleccionar-perfil-seleccionado-max-equity-exposure"
                            value={selectedProfile?.attributes?.max_equity_exposure ?? 0}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={2}
                            allowNegative={false}
                            displayType="text"
                        />
                    ),
                    volatility: (
                        <b key="seleccionar-perfil-volatility">
                            {volatilityText(selectedProfile?.attributes?.volatility)}
                        </b>
                    ),
                }}
            />
        ) : (
            <Tpl
                key={`seleccionar-perfil-description-text-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`}
                template={tr(
                    'Un inversor como tú aspira a una rentabilidad media a largo plazo en torno al {tir}Para ello vamos a recomendarte invertir hasta un máximo del {equity}% de tus ahorros en Renta Variable, asumiendo una volatilidad {volatility} en el corto plazo.'
                )}
                values={{
                    tir: (
                        <div
                            style={{display: 'inline'}}
                            key={`seleccionar-perfil-div-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`}
                        >
                            <b key="seleccionar-perfil-seleccionado-performance-bold">
                                <NumberFormat
                                    key="seleccionar-perfil-seleccionado-performance"
                                    value={(selectedProfile?.attributes?.performance ?? 0) * 100}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={2}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix="%"
                                />
                            </b>
                            .
                            <br key={`break-1-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`} />
                            <br key={`break-2-${selectedProfile?.attributes?.max_equity_exposure ?? 0}`} />
                        </div>
                    ),
                    equity: (
                        <NumberFormat
                            key="seleccionar-perfil-seleccionado-max-equity-exposure"
                            value={(selectedProfile?.attributes?.max_equity_exposure ?? 0) * 100}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={2}
                            allowNegative={false}
                            displayType="text"
                        />
                    ),
                    volatility: (
                        <b key="seleccionar-perfil-volatility">
                            {volatilityText(selectedProfile?.attributes?.volatility)}
                        </b>
                    ),
                }}
            />
        );

    return (
        <div className={styles.container}>
            <PageLoading open={loading} />
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-9 col-sm-7" style={{display: 'flex'}}>
                    <inv-text-l
                        style={{
                            '--inv-text-l-gradient': 'var(--color-tertiary)',
                            '--inv-text-l-font-weight': 600,
                            textAlign: 'center',
                        }}
                    >
                        {tr('Selecciona el perfil de inversor que más se adapte a ti')}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.topCircleChartContainer}>
                <inv-grid-col class={`${styles.circleChartContainer} col-12`}>
                    <inv-grid-row
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <inv-grid-col
                            class="col-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CircleChart
                                width={(fontSize ?? 0) * 6.8}
                                height={(fontSize ?? 0) * 6.8}
                                data={[
                                    {
                                        name: 'equity',
                                        value:
                                            (riskProfileArray[formik.values.profile - 1]?.attributes
                                                .max_equity_exposure ?? 0) * 100,
                                        color: 'var(--color-secondary-100)',
                                    },
                                    {
                                        name: 'fixed',
                                        value:
                                            100 -
                                            (riskProfileArray[formik.values.profile - 1]?.attributes
                                                .max_equity_exposure ?? 0) *
                                                100,
                                        color: 'var(--fixed-income-color)',
                                    },
                                ]}
                            />
                        </inv-grid-col>
                        <inv-grid-col class="col-12">
                            <inv-grid-row
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    '--inv-grid-gutter-y': '0.2em',
                                }}
                            >
                                <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                    <inv-text-s
                                        style={{
                                            '--inv-text-s-font-weight': 600,
                                        }}
                                    >
                                        {tr('Máxima exposición')}
                                    </inv-text-s>
                                </inv-grid-col>
                                <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                    <inv-text-s
                                        style={{
                                            '--inv-text-s-font-weight': 600,
                                            '--inv-text-s-color': 'var(--color-secondary-100)',
                                        }}
                                    >
                                        <NumberFormat
                                            value={
                                                (riskProfileArray[formik.values.profile - 1]?.attributes
                                                    .max_equity_exposure ?? 0) * 100
                                            }
                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                            decimalScale={0}
                                            allowNegative={false}
                                            displayType="text"
                                            suffix={tr('% Renta variable')}
                                        />
                                    </inv-text-s>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.sliderContainer}>
                <inv-grid-col
                    class="col-8"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <NotInteractiveSlider
                        // @ts-ignore
                        handleChange={formik.setFieldValue}
                        sameHierarchy
                        name="profile"
                        marks={riskProfileArray.map((profile) => ({
                            value: parseInt(profile.id, 10),
                            label: (!downMobile ||
                                (downMobile && formik.values.profile === parseInt(profile.id, 10))) && (
                                <inv-grid-row style={{justifyContent: 'center', fontSize: '20px'}}>
                                    <inv-grid-col
                                        class="col-12"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CircleChart
                                            width={(fontSize ?? 0) * 1.2}
                                            height={(fontSize ?? 0) * 1.2}
                                            data={[
                                                {
                                                    name: 'equity',
                                                    value: (profile?.attributes.max_equity_exposure ?? 0) * 100,
                                                    color:
                                                        formik.values.profile === parseInt(profile.id, 10)
                                                            ? 'var(--color-secondary-100)'
                                                            : 'var(--text-color-10)',
                                                },
                                                {
                                                    name: 'fixed',
                                                    value: 100 - (profile?.attributes.max_equity_exposure ?? 0) * 100,
                                                    color:
                                                        formik.values.profile === parseInt(profile.id, 10)
                                                            ? 'var(--fixed-income-color)'
                                                            : 'var(--text-color-30)',
                                                },
                                            ]}
                                        />
                                    </inv-grid-col>
                                    <inv-grid-col
                                        class="col-12"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <inv-text-xxs
                                            style={{
                                                '--inv-text-xxs-font-weight': 600,
                                                '--inv-text-xxs-color':
                                                    // eslint-disable-next-line no-nested-ternary
                                                    formik.values.profile === parseInt(profile.id, 10)
                                                        ? 'var(--text-color-100)'
                                                        : 'var(--text-color-30)',
                                            }}
                                        >
                                            {tr(Capitalize(profile?.attributes.name ?? ''))}
                                        </inv-text-xxs>
                                    </inv-grid-col>
                                </inv-grid-row>
                            ),
                        }))}
                        value={formik.values.profile}
                        min={1}
                        max={riskProfileArray.length}
                        profileNumberMax={riskProfileArray.length}
                        step={1}
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center', marginTop: '3.15em'}}>
                <inv-grid-col class="col-10">
                    <inv-grid-row class={styles.profileDescriptionContainer}>
                        <inv-grid-col class="col-12 col-sm-5">
                            <inv-text-s>{profileDescription}</inv-text-s>
                        </inv-grid-col>
                        <inv-grid-col class={`${styles.circleChartContainer} col-2`}>
                            <inv-grid-row
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col
                                    class="col-12"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircleChart
                                        width={(fontSize ?? 0) * 6.8}
                                        height={(fontSize ?? 0) * 6.8}
                                        data={[
                                            {
                                                name: 'equity',
                                                value:
                                                    (riskProfileArray[formik.values.profile - 1]?.attributes
                                                        .max_equity_exposure ?? 0) * 100,
                                                color: 'var(--color-secondary-100)',
                                            },
                                            {
                                                name: 'fixed',
                                                value:
                                                    100 -
                                                    (riskProfileArray[formik.values.profile - 1]?.attributes
                                                        .max_equity_exposure ?? 0) *
                                                        100,
                                                color: 'var(--fixed-income-color)',
                                            },
                                        ]}
                                    />
                                </inv-grid-col>
                                <inv-grid-col class="col-12">
                                    <inv-grid-row
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            '--inv-grid-gutter-y': '0.2em',
                                        }}
                                    >
                                        <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                            <inv-text-s
                                                style={{
                                                    '--inv-text-s-font-weight': 600,
                                                }}
                                            >
                                                {tr('Máxima exposición')}
                                            </inv-text-s>
                                        </inv-grid-col>
                                        <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                            <inv-text-s
                                                style={{
                                                    '--inv-text-s-font-weight': 600,
                                                    '--inv-text-s-color': 'var(--color-secondary-100)',
                                                }}
                                            >
                                                <NumberFormat
                                                    value={
                                                        (riskProfileArray[formik.values.profile - 1]?.attributes
                                                            .max_equity_exposure ?? 0) * 100
                                                    }
                                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                    decimalScale={0}
                                                    allowNegative={false}
                                                    displayType="text"
                                                    suffix={tr('% Renta variable')}
                                                />
                                            </inv-text-s>
                                        </inv-grid-col>
                                    </inv-grid-row>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.divider}>
                <inv-grid-col class="col-12">
                    <div
                        style={{
                            maxWidth: 'calc(100% - var(--inv-grid-gutter-x,1.5em))',
                        }}
                    >
                        <SolidDividers orientation="horizontal" />
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.buttonsContainer}>
                <inv-grid-col class="col-12 col-sm-3" />
                <inv-grid-col class="col-auto">
                    <DotStepsFragment
                        steps={
                            process.env.REACT_APP_PERFIL_TEST_STEPS
                                ? parseInt(process.env.REACT_APP_PERFIL_TEST_STEPS, 10)
                                : 12
                        }
                        activeStep={
                            (process.env.REACT_APP_PERFIL_TEST_STEPS
                                ? parseInt(process.env.REACT_APP_PERFIL_TEST_STEPS, 10)
                                : 12) - 1
                        }
                    />
                </inv-grid-col>
                <inv-grid-col class="col-12 col-sm-3" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <inv-button class="button button--primary-contained full-width" onClick={handleOnClickNext}>
                        {tr('Continuar')}
                    </inv-button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

export default SeleccionarPerfil;
