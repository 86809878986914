import * as React from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
// @ts-ignore
import {useNavigate} from 'react-router-dom';
import {addYears, endOfDay, format, startOfMonth} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from '@mui/material';
import {es} from 'date-fns/locale';
import theme from '../../styles/theme';
import {lastQuestionIndex, safetyNetFormQuestionTitle, safetyNetQuestionNameOrder} from '../../utils/componentData';
import {EmergenciaFormComponent} from '../../modules/goal/emergencia/emergencia-form.component';
import {OutputType} from '../../modules/goal/goal.type';
import {addObjectiveApi, getObjectiveCostApi, modifyObjectiveApi} from '../../modules/goal/goal.service';
import {addLastObjectiveIdAndType, selectObjectives, setLastObjectiveForm} from '../../modules/goal/goal.slice';
import PageLoading from '../../elements/loading/PageLoading';
import {GoalFooterMeta} from '../../components/meta/GoalFooterMeta';
import {setIsAuthDialogOpen} from '../../modules/auth/auth.slice';
import {addAlert} from '../../modules/app/app.slice';
import {getConfig, getMinRecommendationPeriod} from '../../modules/propuesta/propuesta.service';
import {getErrorMessage, tr} from '../../utils/functions';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-xl';
import emergencia from '../../styles/Emergencia.module.css';

function Emergencia(props: any) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {goBack, finish, closeDialog} = props;
    // eslint-disable-next-line react/destructuring-assignment
    const router = useNavigate();
    const objectivesData = useSelector(selectObjectives);
    const dispatch = useDispatch();
    const storage = globalThis?.sessionStorage;
    const [inflation, setInflation] = React.useState(2);
    const [totalCost, setTotalCost] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [minRecommendationPeriod, setMinRecommendationPeriod] = React.useState(1);
    const [questionIndex, setQuestionIndex] = React.useState(0);
    const minDate = endOfDay(new Date());
    const goalName = tr('Fondo de emergencia');
    React.useEffect(() => {
        const logo = document.getElementById('header-onboarding');
        if (logo) {
            logo.scrollIntoView({behavior: 'smooth'});
        }
    }, [questionIndex]);
    React.useEffect(() => {
        setQuestionIndex(
            storage?.getItem('currentPath') === '/meta/fin' && !downMobile ? lastQuestionIndex.safetyNet : 0
        );
    }, [downMobile, storage]);

    const validationSchema = yup.object({
        months_income: yup.number().min(1).max(6),
        duration: yup.number().min(1).max(9),
        is_completed: yup.boolean().nullable().required(),
        expenses: yup
            .number()
            .required(tr('Introduce un valor numérico'))
            .min(0, tr('El gasto no puede ser negativo'))
            .nullable(),
    });
    const formik = useFormik({
        initialValues:
            objectivesData.lastObjectiveType === 'SAFETY_NET' && objectivesData.lastObjectiveForm
                ? {
                      ...objectivesData.lastObjectiveForm,
                  }
                : {
                      duration: 6,
                      months_income: 4,
                      expenses: null,
                      is_completed: null,
                  },
        validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
            const dateInit = startOfMonth(addYears(minDate, values.duration));
            const safetyNetObjective = {
                amount: values.expenses * values.months_income,
                type: 'SAFETY_NET' as const,
                output_type: OutputType.UNIQUE,
                protection_type: 'CAPITAL' as const,
                name: goalName,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: undefined,
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            if (objectivesData.lastObjectiveType === 'SAFETY_NET') {
                modifyObjectiveApi(safetyNetObjective, objectivesData.lastObjectiveId)
                    .then(() => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            } else {
                addObjectiveApi(safetyNetObjective)
                    .then((lastObjective: any) => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                            })
                        );
                        dispatch(
                            addLastObjectiveIdAndType({
                                lastObjectiveType: lastObjective.attributes.type,
                                lastObjectiveId: lastObjective.id,
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            }
        },
    });
    React.useEffect(() => {
        setLoading(true);
        const resMinDate = getMinRecommendationPeriod();
        const resConfig = getConfig();
        Promise.all([resMinDate, resConfig])
            .then(([minPeriod, config]) => {
                const configInflation = config.inflation;
                if (typeof configInflation === 'number') {
                    setInflation(configInflation);
                }
                setMinRecommendationPeriod(minPeriod);
                dispatch(setIsAuthDialogOpen(false));
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
        return () => {
            setLoading(false);
            setInflation(2);
        };
    }, []);
    React.useEffect(() => {
        if (questionIndex === 4) {
            setLoading(true);
            const dateInit = startOfMonth(addYears(minDate, formik.values.duration));
            const safetyNetObjective = {
                amount: formik.values.expenses * formik.values.months_income,
                type: 'SAFETY_NET' as const,
                output_type: OutputType.UNIQUE,
                protection_type: 'CAPITAL' as const,
                name: goalName,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: undefined,
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            getObjectiveCostApi(safetyNetObjective)
                .then((res) => {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const {withdrawal} = res.attributes;

                    let safetyNetTotalCost = 0;
                    if (withdrawal) {
                        safetyNetTotalCost = withdrawal.reduce((acc: any, current: any) => acc + current, 0);
                    }
                    setTotalCost(safetyNetTotalCost);
                    setLoading(false);
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                });
        }
        return () => {
            setLoading(false);
        };
    }, [questionIndex]);
    return (
        <div className={emergencia.container}>
            <PageLoading open={formik.isSubmitting || loading} />
            <inv-grid-row>
                <inv-grid-col class="col" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--error-color)',
                            '--inv-text-l-font-weight': 600,
                        }}
                    >
                        {goalName}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            {
                safetyNetFormQuestionTitle(
                    downMobile,
                    formik.values.expenses * formik.values.months_income,
                    format(addYears(minDate, formik.values.duration), 'MMMM yyyy', {
                        locale: es,
                    }),
                    totalCost
                )[questionIndex]
            }
            <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
                <inv-grid-col class="col">
                    <EmergenciaFormComponent
                        formik={formik}
                        questionIndex={questionIndex}
                        inflation={inflation}
                        minInitYear={minRecommendationPeriod}
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col">
                    <GoalFooterMeta
                        goalType="SAFETY_NET"
                        questionIndex={questionIndex}
                        goBack={goBack}
                        setQuestionIndex={setQuestionIndex}
                        formik={formik}
                        questionNameOrder={safetyNetQuestionNameOrder}
                        hideDots={questionIndex >= safetyNetQuestionNameOrder.length}
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
export default Emergencia;
