import React from 'react';
import {useLocation} from 'react-router-dom';
import './App.css';
import './styles/globals.css';
import './styles/scss/global.scss';
import {useDispatch, useSelector} from 'react-redux';

import RouterFinsei from './router/router-finsei';
import RouterB2b from './router/router-b2b';
import {tr, tpl} from './utils/functions';
import {selectApp, setLocale, toTop} from './modules/app/app.slice';

function App() {
    const location = useLocation();
    const {locale} = useSelector(selectApp);
    const dispatch = useDispatch();
    function extractUrlContent(inputString: string) {
        let urlContent = null;
        // Find the index of "url(" and ")"
        const startIndex = inputString.indexOf('url(');
        const endIndex = inputString.lastIndexOf(')');

        // Check if both "url(" and ")" are found
        if (startIndex !== -1 && endIndex !== -1) {
            // Extract the content between "url(" and ")"
            urlContent = inputString.substring(startIndex + 4, endIndex);
            return urlContent;
            // @ts-ignore
        }
        // Return null or an empty string if "url(" and ")" are not found
        return urlContent;
    }
    const extractFaviconName = (faviconString: string) => {
        const startIndex = faviconString.lastIndexOf('/') + 1; // Find the index of the last '/'
        const endIndex = faviconString.lastIndexOf(')'); // Find the index of the last ')'
        let extractedValue = 'favicon.ico';

        if (startIndex !== -1 && endIndex !== -1) {
            extractedValue = faviconString.substring(startIndex, endIndex);
        }

        return extractedValue;
    };

    React.useEffect(() => {
        const computedStyles = window.getComputedStyle(document.body);
        // Get the favicon value from the CSS variable
        const faviconValue = computedStyles.getPropertyValue('--favicon');
        const manifestValue = computedStyles.getPropertyValue('--manifest');
        const appleTouchIconValue = computedStyles.getPropertyValue('--apple-touch-icon');

        const manifestLink = document.querySelector("link[rel*='manifest']") || document.createElement('link');
        const appleTouchIconLink =
            document.querySelector("link[rel*='apple-touch-icon']") || document.createElement('link');
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        const faviconExtractedValue = extractFaviconName(faviconValue);
        const manifestExtractedValue = extractFaviconName(manifestValue);
        const appleTouchIconExtractedValue = extractUrlContent(appleTouchIconValue);
        // @ts-ignore
        manifestLink.rel = 'manifest';
        // @ts-ignore
        manifestLink.href = `/static/media/${manifestExtractedValue}`;

        // @ts-ignore
        link.type = 'image/x-icon';
        // @ts-ignore
        link.rel = 'icon';
        // @ts-ignore
        link.href = `/static/media/${faviconExtractedValue}`;

        // @ts-ignore
        appleTouchIconLink.rel = 'apple-touch-icon';
        // @ts-ignore
        appleTouchIconLink.type = 'image/png';
        // @ts-ignore
        appleTouchIconLink.href = appleTouchIconExtractedValue;

        // Append the favicon link to the document head
        document.head.appendChild(link);
        document.head.appendChild(manifestLink);
        document.head.appendChild(appleTouchIconLink);
    }, []);
    React.useEffect(
        // @ts-ignore
        // eslint-disable-next-line no-return-assign
        () => {
            const titles: {[key: string]: string} = {
                '/': process.env.REACT_APP_SITE_TITLE ?? '',
                '/meta': tr('Añade una meta'),
                '/meta/educacion': tr('Meta de educación'),
                '/meta/herencia': tr('Meta de herencia'),
                '/meta/jubilacion': tr('Meta de jubilacion'),
                '/meta/viaje': tr('Meta de herencia'),
                '/meta/vivienda': tr('Meta de vivienda'),
                '/meta/emergencia': tr('Meta de fondo de emergencia'),
                '/meta/personalizada': tr('Meta personalizada'),
                '/meta/fin': tr('Meta añadida'),
                '/propuesta': tr('Plan completo'),
                '/perfil/inicio': tr('Test perfil'),
                '/perfil': tr('Resultado perfil'),
                '/perfil/seleccionar': tr('Seleccionar perfil'),
                '/propuesta/resultado': tr('Propuesta valor'),
                '/registro': tpl(tr(`Regístrate en {siteTitle}`), {
                    siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la web',
                }),
                '/contratacion/inicio': tr('Contratación inicio'),
                '/contratacion/informacion-usuario': tr('Contratación usuario'),
                '/contratacion/cumplimiento-normativo': tr('Cumplimiento normativo'),
                '/contratacion/firma': tr('Contratación firma'),
                '/login': tr('Login'),
                '/restore-password': tr('Cambiar contraseña'),
                '/verificacion': tr('Verificacion'),
                '/planes-asesoramiento': tr('Planes de asesoramiento'),
            };
            dispatch(toTop());
            // @ts-ignore
            document.title = titles[location.pathname] ?? process.env.REACT_APP_SITE_TITLE;
        },
        [location]
    );
    React.useEffect(() => {
        if (!locale && navigator.language === 'ca') {
            dispatch(setLocale('ca'));
        }
    }, [locale]);
    React.useEffect(() => {}, [locale]);
    return process.env.REACT_APP_THEME === 'finsei' ? <RouterFinsei /> : <RouterB2b />;
}

export default App;
