import * as React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import styles from './plan-goals-info.module.css';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/text/text-s';
import SolidDividers from '../../elements/dividers/SolidDividers';
import WCIcons from '../../elements/icons/WCIcons';
import PropuestaTextStructureComponent from '../propuesta/propuesta-text-structure.component';
import FunnelChart from '../../components/chart/FunnelChart';
import {useFontSize} from '../../utils/hooks';
import IconGoal from '../../elements/icons/icon-goal';
import {tr} from '../../utils/functions';

/**
 * @typedef {Object} Props
 * @property {Array<"EDUCATION" | "RETIREMENT" | "HERITAGE" | "REAL_STATE" | "SAFETY_NET" | "CUSTOM">} goalTypes - tipos de objetivos
 * @property {number} total - el coste total de la meta
 * @property {number} contributed - lo que se ha aportado
 * @property {number} expectedContribution - la aportación esperada a dia de hoy
 * @property {string} probabilityLabel - label de la probabilidad
 * @property {number} probability - probabilidad de conseguirlo
 * @property {number} tir - valor numerico de la tir
 * @property {Array<>} chartData - datos para el funnel chart
 */

/**
 * Componente que muestra el seguimiento de los objetivos de un plan
 * @returns {JSX.Element}
 * @params {Props} props
 */

function PlanGoalsInfo({
    goalTypes,
    total,
    contributed,
    expectedContribution,
    probabilityLabel,
    probability,
    tir,
    chartData,
}) {
    const fontSize = useFontSize();
    let badgeBg = '#EDC20F40';
    let badgeColor = '#BB990B';
    if (probability >= 0.7) {
        badgeBg = '#33C5D140';
        badgeColor = '#29ADB7';
    } else if (probability >= 0.35) {
        badgeBg = '#64BF7840';
        badgeColor = '#54AA67';
    } else if (probability >= 0.3) {
        badgeBg = '#F16E0E33';
        badgeColor = '#F16E0E';
    }
    return (
        <div className={styles.main}>
            <inv-grid-row class={styles.header}>
                <inv-grid-col class="col-auto">
                    <inv-grid-row class={styles.titleWithTypeIcon}>
                        <inv-grid-col class="col-auto">
                            <inv-text-s>{tr('Probabilidad de conseguirlo')}</inv-text-s>
                        </inv-grid-col>
                        <inv-grid-col class={`col-auto ${styles.goalIconsContainer}`}>
                            <inv-grid-row
                                style={{
                                    alignContent: 'center',
                                }}
                            >
                                {goalTypes?.map((goalType) => (
                                    <inv-grid-col key={goalType + Math.random()} class="col-auto">
                                        <IconGoal type={goalType} width="2.2em" />
                                    </inv-grid-col>
                                ))}
                            </inv-grid-row>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
                <inv-grid-col
                    class="col-auto"
                    style={{'--probability-badge-bg': badgeBg, '--probability-badge-color': badgeColor}}
                >
                    <div className={styles.probability}>
                        <inv-text-xxs>{probabilityLabel}</inv-text-xxs>
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col-12">
                    <SolidDividers orientation="horizontal" />
                </inv-grid-col>
            </inv-grid-row>
            <div className={styles.section}>
                <inv-grid-row>
                    <inv-grid-col class="col-auto">
                        <inv-text-xs>{tr('Lo que depende de ti')}</inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.sectionSubtitle}>
                    <inv-grid-col class="col-auto" style={{height: '1em'}}>
                        <WCIcons height="1em" width="1em" name="message-success" />
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxs>{tr('Tus aportaciones están al día sigue así')}</inv-text-xxs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.sectionTextStructures}>
                    <inv-grid-col class="col-auto">
                        <PropuestaTextStructureComponent
                            label={tr('Total a aportar')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={total}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col style={{height: '2.5em'}} class="col-auto">
                        <SolidDividers orientation="vertical" />
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <PropuestaTextStructureComponent
                            label={tr('Aportado')}
                            unit="%"
                            componentAfterUnit={
                                <div
                                    style={{
                                        marginLeft: '0.5em',
                                        background: 'var(--text-color-10)',
                                        borderRadius: '1.35em',
                                        width: '8.3em',
                                        height: '0.65em',
                                    }}
                                >
                                    <div
                                        style={{
                                            background: 'var(--text-color-50)',
                                            borderRadius: '1.35em',
                                            width: `${(contributed / total) * 100}%`,
                                            height: '0.65em',
                                        }}
                                    />
                                </div>
                            }
                        >
                            <NumberFormat
                                value={(contributed / total) * 100}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <PropuestaTextStructureComponent
                            label={tr('Aportado hoy')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={contributed}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <div className={styles.vs}>
                            <inv-text-xxs>vs</inv-text-xxs>
                        </div>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <PropuestaTextStructureComponent
                            label={tr('Planificación hoy')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={expectedContribution}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <inv-grid-row>
                <inv-grid-col className="col-12">
                    <SolidDividers orientation="horizontal" />
                </inv-grid-col>
            </inv-grid-row>
            <div className={styles.section}>
                <inv-grid-row>
                    <inv-grid-col class="col-auto">
                        <inv-text-xs>{tr('Lo que depende de ti')}</inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.sectionSubtitle}>
                    <inv-grid-col class="col-auto" style={{height: '1em'}}>
                        <WCIcons height="1em" width="1em" name="message-success" />
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxs>
                            {tr('La rentabilidad media de tus inversiones va mejor de lo esperado')}
                        </inv-text-xxs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.funnelChartTitle}>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxs>{tr('Rentabilidad')}</inv-text-xxs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.funnelChart}>
                    <inv-grid-col class="col-11">
                        {chartData.length > 0 && (
                            <FunnelChart
                                height={(fontSize ?? 0) * 19.1}
                                name="funnel"
                                tir={Math.round(tir * 1000) / 10}
                                data={chartData}
                            />
                        )}
                    </inv-grid-col>
                </inv-grid-row>
            </div>
        </div>
    );
}

PlanGoalsInfo.propTypes = {
    goalTypes: PropTypes.arrayOf(
        PropTypes.oneOf([
            'EDUCATION',
            'RETIREMENT',
            'RETIREMENT_ONLY_FUND',
            'HERITAGE',
            'REAL_STATE',
            'SAFETY_NET',
            'CUSTOM',
        ])
    ).isRequired,
    total: PropTypes.number.isRequired,
    contributed: PropTypes.number.isRequired,
    expectedContribution: PropTypes.number.isRequired,
    probabilityLabel: PropTypes.string.isRequired,
    probability: PropTypes.number.isRequired,
    tir: PropTypes.number.isRequired,
    chartData: PropTypes.arrayOf().isRequired,
};

export default PlanGoalsInfo;
