import * as React from 'react';
import styles from './meta.module.css';
import PlanMetaComponent from '../../modules/plan/plan-meta.component';
import {Size, useWindowSize} from '../../utils/hooks';
import {HelpFooter} from '../../components/help/HelpFooter';

function PlanMeta() {
    const size: Size = useWindowSize();
    const downMobile = (size?.width ?? 0) < 576;
    return (
        <>
            <div className={styles.pageContainer}>
                <PlanMetaComponent />
            </div>
            {!downMobile && (
                <div style={{marginTop: '10em'}}>
                    <HelpFooter setIsContactDialogOpen={() => {}} />
                </div>
            )}
        </>
    );
}

export default PlanMeta;
