import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-xxl';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/button/button';
import '@inveert/ui-kit/src/icon/icon';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-xxs';
import NumberFormat from 'react-number-format';
import {useDispatch} from 'react-redux';
import perfilInicio from '../../styles/PerfilInicio.module.css';

import SolidDividers from '../../elements/dividers/SolidDividers';
import {getObjectivesWithCosts} from '../../modules/goal/goal.service';
import PageLoading from '../../elements/loading/PageLoading';
import {getErrorMessage, tr} from '../../utils/functions';
import {addAlert} from '../../modules/app/app.slice';

function InicioPerfil() {
    const dispatch = useDispatch();
    const router = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [goalCosts, setGoalCosts] = React.useState(0);
    const getGoalsAsync = async () => {
        setLoading(true);
        try {
            const resObjectives = await getObjectivesWithCosts();
            const totalCost = resObjectives.reduce((sum, goal) => sum + goal.totalCost, 0);
            setGoalCosts(totalCost);
        } catch (error: any) {
            const message = await getErrorMessage(error);
            dispatch(
                addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                })
            );
        } finally {
            setLoading(false);
        }
    };
    React.useEffect(() => {
        getGoalsAsync().then();
    }, []);
    const onClickNext = () => router('/perfil');
    return (
        <div className={perfilInicio.container}>
            <PageLoading open={loading} />
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-auto" style={{display: 'flex'}}>
                    <inv-text-l
                        style={
                            {
                                '--inv-text-l-gradient': 'var(--color-tertiary)',
                                '--inv-text-l-font-weight': 600,
                                textAlign: 'center',
                            } as React.CSSProperties
                        }
                    >
                        {tr('Conocer tu perfil inversor')}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={perfilInicio.contentContainer}>
                <inv-grid-col class={`${perfilInicio.borderedContainer} col-12 col-sm-7`}>
                    <inv-grid-row class={perfilInicio.secondTitle}>
                        <inv-grid-col class="col-auto" style={{display: 'flex'}}>
                            <inv-text-xs
                                style={
                                    {
                                        '--inv-text-s-font-weight': 600,
                                        textAlign: 'center',
                                    } as React.CSSProperties
                                }
                            >
                                {tr('¿Y si sólo tuvieses que ahorrar una fracción del dinero que necesitas?')}
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={perfilInicio.chartsContainer}>
                        <inv-grid-col class="col-auto">
                            <inv-grid-row style={{justifyContent: 'center'}}>
                                <inv-grid-col class="col-auto">
                                    <div className={perfilInicio.savingContainer} />
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row class={perfilInicio.chartElementTitle}>
                                <inv-grid-col class="col-auto">
                                    <inv-text-xxs style={{'--inv-text-xxs-font-weight': 600}}>
                                        {tr('Ahorro')}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                        <inv-grid-col class="col-auto">
                            <inv-grid-row style={{justifyContent: 'center', marginTop: '0.75em'}}>
                                <inv-grid-col class="col-auto">
                                    <div className={perfilInicio.profitContainer} />
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row class={perfilInicio.chartElementTitle}>
                                <inv-grid-col class="col-auto">
                                    <inv-text-xxs style={{'--inv-text-xxs-font-weight': 600}}>
                                        {tr('Rentabilidad')}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                        <inv-grid-col class="col-auto">
                            <inv-grid-row style={{justifyContent: 'center', marginTop: '0.75em'}}>
                                <inv-grid-col class="col-auto">
                                    <div className={perfilInicio.profitTotalContainer} />
                                    <div className={perfilInicio.savingTotalContainer} />
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row class={perfilInicio.chartElementTitle}>
                                <inv-grid-col class="col-auto">
                                    <inv-text-xxs style={{'--inv-text-xxs-font-weight': 600}}>
                                        <NumberFormat
                                            value={goalCosts}
                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                            decimalScale={0}
                                            displayType="text"
                                            suffix={` ${process.env.REACT_APP_CURRENCY_SYMBOL || '€'}`}
                                        />
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
            </inv-grid-row>
            {process.env.REACT_APP_SHOW_PERFIL_CNMV_SECTION === 'false' ? (
                <div className={perfilInicio.noCNMVContainer} />
            ) : (
                <inv-grid-row class={perfilInicio.lastTitle}>
                    <inv-grid-col
                        class="col-8 col-sm-7"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <inv-text-xs
                            style={
                                {
                                    '--inv-text-xs-font-weight': 300,
                                    textAlign: 'center',
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                } as React.CSSProperties
                            }
                        >
                            {tr(
                                'Al estar regulados por CNMV tenemos que analizar tu perfil inversor para saber qué rentabilidad puedes aspirar'
                            )}
                        </inv-text-xs>
                    </inv-grid-col>
                    <inv-grid-col
                        class="col-auto"
                        style={{
                            height: '3.5em',
                        }}
                    >
                        <img alt="cnmv-logo" style={{height: '3.5em'}} src="/assets/images/cnmv.png" />
                    </inv-grid-col>
                </inv-grid-row>
            )}
            <inv-grid-row class={perfilInicio.divider}>
                <inv-grid-col class="col-12">
                    <div
                        style={{
                            width: 'calc(100% + 1em',
                            marginLeft: '-1.25em',
                        }}
                    >
                        <SolidDividers orientation="horizontal" />
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={perfilInicio.buttonsContainer}>
                <inv-grid-col class={`col-12 col-sm-auto ${perfilInicio.buttonElement1}`}>
                    <inv-button
                        onClick={() => router('/meta/fin?aportacion=true')}
                        class="button button--secondary-contained full-width"
                    >
                        {tr('Atrás')}
                    </inv-button>
                </inv-grid-col>
                <inv-grid-col class={`col-12 col-sm-auto ${perfilInicio.buttonElement2}`}>
                    <inv-button class="button button--primary-contained full-width" onClick={onClickNext}>
                        {tr('Continuar')}
                    </inv-button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

InicioPerfil.layout = 'EmptyLayout';
InicioPerfil.mobileLayout = 'MobileEmptyLayout';
export default InicioPerfil;
