import * as React from 'react';
import NumberFormat from 'react-number-format';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/icon/icon';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-xxs';
import {useMediaQuery} from '@mui/material';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import styles from './risk-dialog.style.module.css';
import theme from '../../styles/theme';
import CircleChart from '../../components/chart/CircleChart';
import {Capitalize, getErrorMessage, tr} from '../../utils/functions';
import {NotInteractiveSlider} from '../../elements/slider/NotInteractiveDiscreteSlider';
// eslint-disable-next-line import/no-cycle
import Perfil from '../../pages/perfil';
import PageLoading from '../../elements/loading/PageLoading';
import {postChangeToInferiorRiskProfile} from './risk.service';
import {addAlert, reloadApi} from '../app/app.slice';
import {useFontSize} from '../../utils/hooks';
import WCIcons from '../../elements/icons/WCIcons';
import Tpl from '../../elements/tpl/tpl';
import {getConfig} from '../propuesta/propuesta.service';

interface RiskDialogComponent {
    profileNumber: number;
    profileNumberMax: number;
    riskProfileArray: Array<any>;
    isTestAvailable?: boolean;
    closeDialog?: () => void;
    financialWealth?: any;
    birthday?: any;
    userIncome?: any;
    objectives?: any;
    isSliderActive?: boolean;
    userId?: number;
}

export function RiskProfileDialogContent(props: RiskDialogComponent) {
    const dispatch = useDispatch();
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {
        riskProfileArray,
        profileNumber,
        isTestAvailable,
        closeDialog,
        financialWealth,
        birthday,
        userIncome,
        objectives,
        isSliderActive,
        profileNumberMax,
        userId,
    } = props;
    const fontSize = useFontSize();
    const [selectedProfile, setSelectedProfile] = React.useState<any>({});
    const [showTest, setShowTest] = React.useState(false);
    const [testIsPassed, setTestIsPassed] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingMifid, setLoadingMifid] = React.useState(false);
    const [hasMifidTest, setHasMifidTest] = React.useState(false);

    const hasTestMifid = async () => {
        setLoadingMifid(true);
        try {
            const config = await getConfig();
            setHasMifidTest(config.mifid);
            setLoadingMifid(false);
        } catch (e) {
            // @ts-ignore
            const message = await getErrorMessage(e);
            dispatch(
                addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                })
            );
            setLoadingMifid(false);
        }
    };

    React.useEffect(() => {
        hasTestMifid().then();
    }, []);

    const validationSchema = yup.object({
        profile: yup.number().min(1).max(6),
    });
    const formik = useFormik({
        initialValues: {
            profile: profileNumber,
        },
        validationSchema,
        onSubmit: (values) => {
            setLoading(true);

            postChangeToInferiorRiskProfile(userId ?? 0, values.profile)
                .then(() => {
                    dispatch(reloadApi());
                    setLoading(false);
                    formik.setSubmitting(false);
                    if (closeDialog) {
                        closeDialog();
                    }
                })
                .catch(() => {
                    setLoading(false);
                    formik.setSubmitting(false);
                });
        },
    });
    React.useEffect(() => {
        formik.setFieldValue('profile', profileNumber);
    }, [profileNumber]);

    React.useEffect(() => {
        // eslint-disable-next-line no-undef
        const element = document.getElementById('profile-description');
        if (element) {
            const key = `REACT_APP_PROFILE_DESCRIPTION_${formik.values.profile}`;
            element.innerHTML = process.env[key] ?? '';
        }
        setSelectedProfile(riskProfileArray[formik.values.profile - 1] ?? {});
    }, [formik.values.profile]);

    const volatilityText = (volatility: any) => {
        switch (volatility) {
            case 'LOW':
                return tr('BAJA');
            case 'MID':
                return tr('MEDIA');
            case 'HIGH':
                return tr('ALTA');
            case 'MID_LOW':
                return tr('MEDIA-BAJA');
            case 'MID_HIGH':
                return tr('MEDIA-ALTA');
            default:
                return tr('NO DISPONIBLE');
        }
    };

    const profileDescription =
        (selectedProfile?.attributes?.max_equity_exposure ?? 0) === 0 ? (
            <Tpl
                template={tr(
                    'Un inversor como tú aspira a una rentabilidad media a largo plazo en torno al {tir} Para ello vamos a recomendarte invertir un {equity}% de tus ahorros en Renta Variable, asumiendo una volatilidad {volatility} en el corto plazo.'
                )}
                values={{
                    tir: (
                        <div style={{display: 'inline'}} key="risk-dialog-component-performance">
                            <b>
                                <NumberFormat
                                    value={(selectedProfile?.attributes?.performance ?? 0) * 100}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={2}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix="%"
                                />
                            </b>
                            .
                            <br />
                            <br />
                        </div>
                    ),
                    equity: (
                        <NumberFormat
                            key="risk-dialog-component-max-equity-exposure"
                            value={selectedProfile?.attributes?.max_equity_exposure ?? 0}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={2}
                            allowNegative={false}
                            displayType="text"
                        />
                    ),
                    volatility: (
                        <b key="risk-dialog-component-volatility">
                            {volatilityText(selectedProfile?.attributes?.volatility)}
                        </b>
                    ),
                }}
            />
        ) : (
            <Tpl
                template={tr(
                    'Un inversor como tú aspira a una rentabilidad media a largo plazo en torno al {tir}Para ello vamos a recomendarte invertir hasta un máximo del {equity}% de tus ahorros en Renta Variable, asumiendo una volatilidad {volatility} en el corto plazo.'
                )}
                values={{
                    tir: (
                        <div style={{display: 'inline'}} key="risk-dialog-component-performance">
                            <b>
                                <NumberFormat
                                    value={(selectedProfile?.attributes?.performance ?? 0) * 100}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={2}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix="%"
                                />
                            </b>
                            .
                            <br />
                            <br />
                        </div>
                    ),
                    equity: (
                        <NumberFormat
                            key="risk-dialog-component-max-equity-exposure"
                            value={(selectedProfile?.attributes?.max_equity_exposure ?? 0) * 100}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={2}
                            allowNegative={false}
                            displayType="text"
                        />
                    ),
                    volatility: (
                        <b key="risk-dialog-component-volatility">
                            {volatilityText(selectedProfile?.attributes?.volatility)}
                        </b>
                    ),
                }}
            />
        );

    return (
        <>
            <PageLoading open={loading || loadingMifid} />
            {!downMobile && isTestAvailable && (
                <inv-grid-row style={{'--inv-grid-gutter-x': 0}}>
                    <inv-grid-col
                        class="col-12"
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                        }}
                    >
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <div
                            role="button"
                            onKeyDown={() => null}
                            tabIndex={0}
                            className={styles.closeIconContainer}
                            onClick={closeDialog}
                        >
                            <WCIcons name="close-filled" width="1.5em" height="1.5em" hoverColor="inherit" />
                        </div>
                    </inv-grid-col>
                </inv-grid-row>
            )}
            <div className={`${styles.container} ${!isTestAvailable && styles.containerNoPaddingTop}`}>
                <div>
                    {/* Need to wrap containers on item to apply maxwidth */}
                    {showTest === true ? (
                        <div>
                            <Perfil
                                data={{
                                    financialWealth,
                                    monthly_income: userIncome,
                                    birthday,
                                    objectives,
                                }}
                                finish={() => dispatch(reloadApi())}
                                hideShowTest={() => {
                                    setShowTest(false);
                                    setTestIsPassed(true);
                                }}
                                goBack={() => {
                                    setShowTest(false);
                                }}
                                closeDialog={closeDialog}
                            />
                        </div>
                    ) : (
                        <div>
                            <inv-grid-row
                                class={styles.titleContainer}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                    <inv-text-l
                                        style={{
                                            '--inv-text-l-font-weight': 600,
                                            '--inv-text-l-color': 'var(--color-tertiary)',
                                        }}
                                    >
                                        {tr('Tu perfil de inversor es:')}
                                    </inv-text-l>
                                </inv-grid-col>
                                <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                    <inv-text-l
                                        style={{
                                            '--inv-text-l-font-weight': 600,
                                        }}
                                    >
                                        {Capitalize(riskProfileArray[formik.values.profile - 1]?.attributes.name ?? '')}
                                    </inv-text-l>
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row class={`${styles.descriptionAndChartContainer}`}>
                                <inv-grid-col
                                    class="col-12 col-sm-5"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <inv-text-s
                                        style={{
                                            '--inv-text-s-font-weight': 500,
                                            '--inv-text-s-color': 'var(--text-color-50)',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {profileDescription}
                                    </inv-text-s>
                                </inv-grid-col>
                                <inv-grid-col class="col-12 col-sm-3">
                                    <inv-grid-row
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <inv-grid-col
                                            class={`col-12 ${styles.chartContainer}`}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CircleChart
                                                width={(fontSize ?? 0) * 6.8}
                                                height={(fontSize ?? 0) * 6.8}
                                                data={[
                                                    {
                                                        name: 'equity',
                                                        value:
                                                            (riskProfileArray[formik.values.profile - 1]?.attributes
                                                                .max_equity_exposure ?? 0) * 100,
                                                        color: 'var(--color-secondary-100)',
                                                    },
                                                    {
                                                        name: 'fixed',
                                                        value:
                                                            100 -
                                                            (riskProfileArray[formik.values.profile - 1]?.attributes
                                                                .max_equity_exposure ?? 0) *
                                                                100,
                                                        color: 'var(--text-color-30)',
                                                    },
                                                ]}
                                            />
                                        </inv-grid-col>
                                        <inv-grid-col class="col-12">
                                            <inv-grid-row
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    '--inv-grid-gutter-y': '0.2em',
                                                }}
                                            >
                                                <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                                    <inv-text-s
                                                        style={{
                                                            '--inv-text-s-font-weight': 600,
                                                        }}
                                                    >
                                                        {tr('Máxima exposición')}
                                                    </inv-text-s>
                                                </inv-grid-col>
                                                <inv-grid-col class="col-12" style={{display: 'flex'}}>
                                                    <inv-text-s
                                                        style={{
                                                            '--inv-text-s-font-weight': 600,
                                                            '--inv-text-s-color': 'var(--color-secondary-100)',
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={
                                                                (riskProfileArray[formik.values.profile - 1]?.attributes
                                                                    .max_equity_exposure ?? 0) * 100
                                                            }
                                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                            decimalScale={0}
                                                            allowNegative={false}
                                                            displayType="text"
                                                            suffix={tr('% Renta variable')}
                                                        />
                                                    </inv-text-s>
                                                </inv-grid-col>
                                            </inv-grid-row>
                                        </inv-grid-col>
                                    </inv-grid-row>
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row
                                class={styles.sliderContainer}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col
                                    class="col-11 col-sm-8"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <NotInteractiveSlider
                                        // @ts-ignore
                                        handleChange={isSliderActive === true ? formik.setFieldValue : undefined}
                                        sameHierarchy={!hasMifidTest}
                                        name="profile"
                                        marks={riskProfileArray.map((profile) => ({
                                            value: parseInt(profile.id, 10),
                                            label: (!downMobile ||
                                                (downMobile && formik.values.profile === parseInt(profile.id, 10))) && (
                                                <inv-grid-row
                                                    style={{
                                                        fontSize: '20px',
                                                    }}
                                                >
                                                    <inv-grid-col
                                                        class="col-12"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <CircleChart
                                                            width={(fontSize ?? 0) * 1.2}
                                                            height={(fontSize ?? 0) * 1.2}
                                                            data={[
                                                                {
                                                                    name: 'equity',
                                                                    value:
                                                                        (profile?.attributes.max_equity_exposure ?? 0) *
                                                                        100,
                                                                    color:
                                                                        formik.values.profile ===
                                                                        parseInt(profile.id, 10)
                                                                            ? 'var(--color-secondary-100)'
                                                                            : 'var(--text-color-10)',
                                                                },
                                                                {
                                                                    name: 'fixed',
                                                                    value:
                                                                        100 -
                                                                        (profile?.attributes.max_equity_exposure ?? 0) *
                                                                            100,
                                                                    color: 'var(--text-color-30)',
                                                                },
                                                            ]}
                                                        />
                                                    </inv-grid-col>
                                                    <inv-grid-col
                                                        class="col-12"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <inv-text-xxs
                                                            style={{
                                                                '--inv-text-xxs-font-weight': 600,
                                                                '--inv-text-xxs-color':
                                                                    // eslint-disable-next-line no-nested-ternary
                                                                    formik.values.profile === parseInt(profile.id, 10)
                                                                        ? 'var(--text-color-100)'
                                                                        : profileNumber > parseInt(profile.id, 10)
                                                                        ? 'var(--text-color-30)'
                                                                        : 'var(--text-color-10)',
                                                            }}
                                                        >
                                                            {Capitalize(profile?.attributes.name ?? '')}
                                                        </inv-text-xxs>
                                                    </inv-grid-col>
                                                </inv-grid-row>
                                            ),
                                        }))}
                                        value={formik.values.profile}
                                        min={1}
                                        max={riskProfileArray.length}
                                        profileNumberMax={profileNumberMax}
                                        step={1}
                                    />
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row class={styles.buttonsContainer} style={{'--inv-grid-gutter-y': '0.8em'}}>
                                {isTestAvailable === true && !showTest && (
                                    <>
                                        <inv-grid-col
                                            class="col-12 col-sm-auto"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <inv-grid-row class={styles.testAndSaveButtonsContainer}>
                                                <inv-grid-col
                                                    class="col-12 col-sm-auto"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <inv-button
                                                        class="button button--primary-contained full-width"
                                                        onClick={() => {
                                                            if (formik.values.profile !== profileNumber) {
                                                                formik.handleSubmit();
                                                            } else {
                                                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                                                closeDialog ? closeDialog() : null;
                                                            }
                                                        }}
                                                        disabled={
                                                            formik.values.profile === profileNumber &&
                                                            testIsPassed === false
                                                                ? true
                                                                : undefined
                                                        }
                                                    >
                                                        {tr('Guardar cambios')}
                                                    </inv-button>
                                                </inv-grid-col>
                                                {hasMifidTest && (
                                                    <inv-grid-col
                                                        className="col-12 col-sm-auto"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <inv-button
                                                            className="outlined button button--primary-outlined full-width"
                                                            onClick={() => setShowTest(true)}
                                                        >
                                                            {tr('Repetir test')}
                                                        </inv-button>
                                                    </inv-grid-col>
                                                )}
                                            </inv-grid-row>
                                        </inv-grid-col>
                                        <inv-grid-col
                                            class="col-12 col-sm-auto"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <inv-button
                                                onClick={() => (closeDialog ? closeDialog() : null)}
                                                class="button button--secondary-contained full-width"
                                            >
                                                {tr('Atrás')}
                                            </inv-button>
                                        </inv-grid-col>
                                    </>
                                )}
                            </inv-grid-row>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

RiskProfileDialogContent.defaultProps = {
    isTestAvailable: undefined,
    closeDialog: undefined,
    financialWealth: undefined,
    birthday: undefined,
    userIncome: undefined,
    objectives: undefined,
    isSliderActive: undefined,
    userId: 0,
};
export default RiskProfileDialogContent;
