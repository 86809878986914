import * as React from 'react';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import adviceAlert from '../../styles/AdviceAlert.module.css';

interface IAdviceAlert {
    text: string | JSX.Element;
    icon?: JSX.Element;
    isCentered?: boolean;
}

function AdviceAlert(props: IAdviceAlert) {
    const {text, icon, isCentered} = props;
    return (
        <inv-grid-row
            class={adviceAlert.container}
            style={{
                border: '0.05em solid var(--inv-advice-border-color,transparent)',
                lineHeight: 0,
                borderRadius: '0.8em',
                background: `var(--inv-advice-background,var(--color-secondary-20))`,
                padding: '0.8em',
                alignItems: 'center',
                justifyContent: isCentered ? 'center' : 'initial',
                '--inv-grid-gutter-x': '0.8em',
                textAlign: isCentered ? 'center' : 'initial',
            }}
        >
            <inv-grid-col class={isCentered ? 'col-auto' : 'col-1'} style={{color: 'var(--color-secondary-100)'}}>
                {icon}
            </inv-grid-col>
            <inv-grid-col class={isCentered ? 'col-auto' : 'col-9 col-sm-10'}>
                <inv-text-xs
                    style={{
                        lineHeight: '140%',
                        '--inv-text-xs-font-weight': 400,
                        '--inv-text-xs-color': 'var(--text-color-50)',
                    }}
                >
                    {text}
                </inv-text-xs>
            </inv-grid-col>
        </inv-grid-row>
    );
}

AdviceAlert.defaultProps = {
    icon: undefined,
    isCentered: false,
};

export default AdviceAlert;
