// @ts-ignore
import {BarChart, Bar, Cell, CartesianGrid, XAxis, YAxis, ResponsiveContainer, LabelList} from 'recharts';
import {useMediaQuery} from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';
import {getWordSizeInPixels, parseBarChartDataWhenMobile} from '../../utils/functions';

interface IBarsChart {
    name: string;
    data: Array<{
        name: string | number;
        bar1?: number;
        bar2?: number;
        bar3?: number;
        bar4?: number;
    }>;
    barColors: {bar1: string; bar2?: string; bar3?: string; bar4?: string};
    // eslint-disable-next-line react/no-unused-prop-types
    hoverBarColors?: {
        bar1: string;
        bar2?: string;
        bar3?: string;
        bar4?: string;
    };
    barsHandler?: any;
    helperHandler?: any;
    highlightPrevious?: boolean;
    hideXAxisTicks?: boolean;
    hideXAxis?: boolean;
    hideYAxis?: boolean;
    hideArrow?: boolean;
    objectiveId?: number | null;
    height?: number;
    hideStrokeDashArray?: boolean;
}

function CustomizedLabel(props: any) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {x, y, selectedName, name, barName, index, data, hideArrow, fontSize} = props;
    const isActiveBar1 =
        !(
            (data[index].bar2 && data[index].bar2 > 0) ||
            (data[index].bar3 && data[index].bar3 > 0) ||
            (data[index].bar4 && data[index].bar4 > 0)
        ) &&
        data[index].bar1 &&
        data[index].bar1 > 0;
    const isActiveBar2 =
        !((data[index].bar3 && data[index].bar3 > 0) || (data[index].bar4 && data[index].bar4 > 0)) &&
        data[index].bar2 &&
        data[index].bar2 > 0;
    const isActiveBar3 = !(data[index].bar4 && data[index].bar4 > 0) && data[index].bar3 && data[index].bar3 > 0;
    const isActiveBar4 = data[index].bar3 && data[index].bar3 > 0;
    let isActive;
    if (barName === 'bar1') {
        isActive = isActiveBar1;
    } else if (barName === 'bar2') {
        isActive = isActiveBar2;
    } else if (barName === 'bar3') {
        isActive = isActiveBar3;
    } else {
        isActive = isActiveBar4;
    }
    const arrowSize = downMobile ? '1.5em' : '2.8em';
    return selectedName === name && isActive && hideArrow !== true && !downMobile ? (
        <svg
            x={x - (fontSize ?? 0) * 1.45}
            y={y - (fontSize ?? 0) * 3.3}
            width={arrowSize}
            height={arrowSize}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.93848 13.25L12.3829 18.25M12.3829 18.25L16.8274 13.25M12.3829 18.25V5.75"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : null;
}
function BarsChart(props: IBarsChart) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {
        objectiveId,
        data,
        barColors,
        barsHandler,
        name,
        highlightPrevious,
        hideXAxisTicks,
        hideYAxis,
        hideXAxis,
        hideArrow,
        helperHandler,
        hideStrokeDashArray,
        height,
        hoverBarColors,
    } = props;
    const [yAxisWidth, setYAxisWidth] = React.useState(40);
    const parsedData = downMobile ? parseBarChartDataWhenMobile(data) : data;
    const maxValue = parsedData?.reduce((maxValueData, dataValue) => {
        let thisMaxValueData = maxValueData;
        const bar1Value = dataValue.bar1 ?? 0;
        const bar2Value = dataValue.bar2 ?? 0;
        const bar3Value = dataValue.bar3 ?? 0;
        const bar4Value = dataValue.bar4 ?? 0;

        // @ts-ignore
        if (bar1Value + bar2Value + bar3Value + bar4Value > maxValueData) {
            // @ts-ignore
            thisMaxValueData = bar1Value + bar2Value + bar3Value + bar4Value;
        }
        return thisMaxValueData;
    }, 0);
    const finalData = parsedData?.map((dataValue) => ({
        ...dataValue,
        gap: maxValue * 0.02,
    }));
    const [selectedName, setSelectedName] = React.useState(
        highlightPrevious === true ? finalData[finalData.length - 1]?.name : finalData[0]?.name
    );
    React.useEffect(() => {
        setSelectedName(highlightPrevious === true ? finalData[finalData.length - 1]?.name : finalData[0]?.name);
    }, [objectiveId]);
    React.useEffect(() => {
        setSelectedName(highlightPrevious === true ? finalData[finalData.length - 1]?.name : finalData[0]?.name);
    }, [data]);
    const [fontSize, setFontSize] = React.useState(0);
    React.useEffect(() => {
        const chartFontSize = window.getComputedStyle(document.body).getPropertyValue('font-size');
        const fontSizeNumber = parseInt(chartFontSize, 10) * 0.7;
        setFontSize(fontSizeNumber);

        const sizeOfMaxValue = getWordSizeInPixels(
            `${((Math.ceil((maxValue + maxValue * 0.1) / 10) * 10) / 2).toString()}€`,
            `${fontSizeNumber}px`,
            '400',
            'Inter'
        );
        setYAxisWidth(sizeOfMaxValue.width);
    }, [data]);
    return (
        <ResponsiveContainer width="100%" height={height ?? 300} key={name}>
            <BarChart data={finalData} maxBarSize={downMobile ? 4 : 12}>
                <CartesianGrid
                    strokeDasharray="1.5"
                    vertical={false}
                    stroke={hideStrokeDashArray ? 'transparent' : 'var(--text-color-30)'}
                />
                <XAxis
                    hide={hideXAxis === true}
                    axisLine={false}
                    fontWeight={400}
                    dataKey="name"
                    fontSize={`${fontSize}px`}
                    tickMargin={5}
                    tickLine={{stroke: 'var(--text-color-50)'}}
                    tick={{fill: 'var(--text-color-50)'}}
                    tickFormatter={(tick: number) =>
                        hideXAxisTicks === true && tick === 1 ? 'Año 1' : tick.toString()
                    }
                    interval="preserveStartEnd"
                />
                <YAxis
                    width={yAxisWidth ?? 40}
                    dx={-6}
                    hide={hideYAxis === true}
                    type="number"
                    interval="preserveStartEnd"
                    domain={[0, () => Math.ceil((maxValue + maxValue * 0.1) / 10) * 10]}
                    axisLine={false}
                    unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                    fontWeight={400}
                    tickLine={false}
                    fontSize={`${fontSize}px`}
                    orientation="right"
                    tickCount={4}
                    tick={{fill: 'var(--text-color-50)', width: '200px'}}
                    tickFormatter={(tick: number | bigint) => new Intl.NumberFormat('es').format(tick)}
                    ticks={[0, (Math.ceil((maxValue + maxValue * 0.1) / 10) * 10) / 2]}
                />

                {/* @ts-ignore */}
                <Bar
                    barSize={fontSize}
                    radius={[27, 27, 27, 27]}
                    dataKey="bar1"
                    stackId="a"
                    fill={barColors.bar1}
                    onMouseEnter={(mouseData: any) => {
                        if (hideArrow !== true) {
                            setSelectedName(mouseData.name);
                            if (barsHandler) {
                                barsHandler([
                                    mouseData?.bar1 ?? 0,
                                    mouseData?.bar2 ?? 0,
                                    mouseData?.bar3 ?? 0,
                                    mouseData?.bar4 ?? 0,
                                ]);
                            }
                            if (helperHandler) {
                                helperHandler(mouseData.name);
                            }
                        }
                    }}
                    isAnimationActive={false}
                >
                    <LabelList
                        content={
                            <CustomizedLabel
                                hideArrow={hideArrow}
                                data={finalData}
                                barName="bar1"
                                selectedName={selectedName}
                                fontSize={fontSize}
                            />
                        }
                    />
                    {finalData.map((entry) => {
                        let fill;
                        let fillOpacity;
                        if (highlightPrevious === true && entry.name <= selectedName) {
                            fillOpacity = 1;
                            fill = hoverBarColors?.bar1 ?? barColors.bar1;
                        } else if (highlightPrevious === true && entry.name > selectedName) {
                            fillOpacity = 0.5;
                            fill = barColors.bar1;
                        } else if (highlightPrevious !== true && entry.name === selectedName) {
                            fillOpacity = 1;
                            fill = hoverBarColors?.bar1 ?? barColors.bar1;
                        } else {
                            fillOpacity = 0.5;
                            fill = barColors.bar1;
                        }
                        return <Cell key={`${name}bar1`} fillOpacity={fillOpacity} fill={fill} />;
                    })}
                </Bar>
                <Bar barSize={fontSize} radius={[27, 27, 27, 27]} dataKey="gap" stackId="a" fill="transparent" />
                {/* @ts-ignore */}
                <Bar
                    barSize={fontSize}
                    radius={[27, 27, 27, 27]}
                    dataKey="bar2"
                    stackId="a"
                    fill={barColors.bar2}
                    onMouseEnter={(mouseData: any) => {
                        if (hideArrow !== true) {
                            setSelectedName(mouseData.name);
                            if (barsHandler) {
                                barsHandler([
                                    mouseData?.bar1 ?? 0,
                                    mouseData?.bar2 ?? 0,
                                    mouseData?.bar3 ?? 0,
                                    mouseData?.bar4 ?? 0,
                                ]);
                            }
                            if (helperHandler) {
                                helperHandler(mouseData.name);
                            }
                        }
                    }}
                    isAnimationActive={false}
                >
                    <LabelList
                        content={
                            <CustomizedLabel
                                hideArrow={hideArrow}
                                data={finalData}
                                barName="bar2"
                                selectedName={selectedName}
                                fontSize={fontSize}
                            />
                        }
                    />
                    {finalData.map((entry) => {
                        let fillOpacity;
                        if (highlightPrevious === true && entry.name <= selectedName) {
                            fillOpacity = 1;
                        } else if (highlightPrevious === true && entry.name > selectedName) {
                            fillOpacity = 0.5;
                        } else if (highlightPrevious !== true && entry.name === selectedName) {
                            fillOpacity = 1;
                        } else {
                            fillOpacity = 0.5;
                        }
                        return <Cell key={`${name}bar2`} fillOpacity={fillOpacity} />;
                    })}
                </Bar>
                {barColors?.bar3 && (
                    <>
                        <Bar
                            barSize={fontSize}
                            radius={[27, 27, 27, 27]}
                            dataKey="gap"
                            stackId="a"
                            fill="transparent"
                        />
                        {/* @ts-ignore */}
                        <Bar
                            barSize={fontSize}
                            radius={[27, 27, 27, 27]}
                            dataKey="bar3"
                            stackId="a"
                            fill={barColors.bar3}
                            onMouseEnter={(mouseData: any) => {
                                if (hideArrow !== true) {
                                    setSelectedName(mouseData.name);
                                    if (barsHandler) {
                                        barsHandler([
                                            mouseData?.bar1 ?? 0,
                                            mouseData?.bar2 ?? 0,
                                            mouseData?.bar3 ?? 0,
                                            mouseData?.bar4 ?? 0,
                                        ]);
                                    }
                                    if (helperHandler) {
                                        helperHandler(mouseData.name);
                                    }
                                }
                            }}
                            isAnimationActive={false}
                        >
                            <LabelList
                                content={
                                    <CustomizedLabel
                                        hideArrow={hideArrow}
                                        data={finalData}
                                        barName="bar3"
                                        selectedName={selectedName}
                                        fontSize={fontSize}
                                    />
                                }
                            />
                            {finalData.map((entry) => {
                                let fillOpacity;
                                if (highlightPrevious === true && entry.name <= selectedName) {
                                    fillOpacity = 1;
                                } else if (highlightPrevious === true && entry.name > selectedName) {
                                    fillOpacity = 0.5;
                                } else if (highlightPrevious !== true && entry.name === selectedName) {
                                    fillOpacity = 1;
                                } else {
                                    fillOpacity = 0.5;
                                }
                                return <Cell key={`${name}bar3`} fillOpacity={fillOpacity} />;
                            })}
                        </Bar>
                    </>
                )}
                {barColors?.bar4 && (
                    <>
                        <Bar
                            barSize={fontSize}
                            radius={[27, 27, 27, 27]}
                            dataKey="gap"
                            stackId="a"
                            fill="transparent"
                        />
                        {/* @ts-ignore */}
                        <Bar
                            barSize={fontSize}
                            radius={[27, 27, 27, 27]}
                            dataKey="bar4"
                            stackId="a"
                            fill={barColors.bar4}
                            onMouseEnter={(mouseData: any) => {
                                if (hideArrow !== true) {
                                    setSelectedName(mouseData.name);
                                    if (barsHandler) {
                                        barsHandler([
                                            mouseData?.bar1 ?? 0,
                                            mouseData?.bar2 ?? 0,
                                            mouseData?.bar3 ?? 0,
                                            mouseData?.bar4 ?? 0,
                                        ]);
                                    }
                                    if (helperHandler) {
                                        helperHandler(mouseData.name);
                                    }
                                }
                            }}
                        >
                            {finalData.map((entry) => {
                                let fillOpacity;
                                if (highlightPrevious === true && entry.name <= selectedName) {
                                    fillOpacity = 1;
                                } else if (highlightPrevious === true && entry.name > selectedName) {
                                    fillOpacity = 0.5;
                                } else if (highlightPrevious !== true && entry.name === selectedName) {
                                    fillOpacity = 1;
                                } else {
                                    fillOpacity = 0.5;
                                }
                                return <Cell key={`${name}bar4`} fillOpacity={fillOpacity} />;
                            })}
                        </Bar>
                    </>
                )}
            </BarChart>
        </ResponsiveContainer>
    );
}
BarsChart.defaultProps = {
    barsHandler: undefined,
    helperHandler: undefined,
    highlightPrevious: undefined,
    hideXAxisTicks: undefined,
    hideXAxis: undefined,
    hideYAxis: undefined,
    hideArrow: undefined,
    objectiveId: undefined,
    height: 300,
    hideStrokeDashArray: false,
    hoverBarColors: undefined,
};

export default BarsChart;
