import {fetchApi} from '../../utils/functions';
import {FinanbestTestAnswers} from './risk.type';
import {financialProvidersData, questionsOfTestMifid, riskProfileTestApiResponse, testMifidData} from './risk.data';
import {setUserDataLayer} from '../gtm/gtm.script';

export const getActiveRiskProfileService = async () => {
    const riskProfile = await fetchApi(
        'plan/v1/users-risk-profiles?include=RiskProfile&filter[is_active]=1&page[size]=1&sort=-id',
        'get',
        {}
    );
    setUserDataLayer(null, null, riskProfile.included[0].attributes.id);
    return riskProfile;
};

export const getMaxRiskProfileService = () =>
    fetchApi('plan/v1/users-risk-profiles?include=RiskProfile&filter[main]=1&page[size]=1&sort=-id', 'get', {});

export const postChangeToInferiorRiskProfile = (userId: number, riskProfileId: number) => {
    const payload = {
        data: {
            type: 'UserRiskProfile',
            attributes: {
                risk_profile_id: riskProfileId,
                user_id: userId,
                is_active: true,
                main: true,
            },
        },
    };
    setUserDataLayer(null, null, riskProfileId);
    return fetchApi(`plan/v1/users-risk-profiles`, 'post', payload);
};

export const getAllRiskProfilesApi = async () => {
    const allRiskProfilesRes = fetchApi('plan/v1/risk-profiles', 'get', undefined);
    return allRiskProfilesRes;
};

export const getAllRiskProfilesAndUserRiskProfile = () =>
    fetchApi(`plan/v1/users-risk-profiles?include=RiskProfile`, 'get', {});

export const getRiskProfile = (testMifidId: string) =>
    fetchApi(`mifid/v1/tests-mifid/${testMifidId}/calculate`, 'get', {});

export const getRiskProfileByUserId = (userId: string) =>
    fetchApi(`plan/v1/users-risk-profiles?filter[user_id]=${userId}`, 'get', {});

export const finanbestTestRiskProfileApi = async (testAnswers: FinanbestTestAnswers) => {
    const finanbestRiskProfileRes = fetchApi('finanbest/risk-profile', 'post', {
        answers: {...testAnswers},
    })
        .then((res) => res)
        .catch(() => riskProfileTestApiResponse);
    return finanbestRiskProfileRes;
};

export const postLowerProfileApi = async (identifier: string) => {
    fetchApi('risk-profile', 'post', {
        identifier,
        is_active: true,
    });
};

export const getFinancialProviderId = async (providerName?: string) => {
    const provider = providerName ?? 'Finanbest';
    return fetchApi(`mifid/v1/financial-providers?filter[name]=${provider}`, 'get', {})
        .then(
            (res) =>
                res.filter((providerData: {attributes: {name: string}}) => providerData.attributes.name === provider)[0]
                    .id
        )
        .catch(
            () =>
                financialProvidersData.data.filter(
                    (providerData: {attributes: {name: string}}) => providerData.attributes.name === provider
                )[0].id
        );
};

export const getTestMifidId = async (providerId?: string) => {
    const provider = providerId ?? '1';
    return fetchApi(`mifid/v1/tests-mifid?filter[financial_provider_id]=${provider}`, 'get', {})
        .then((res: Array<any>) => res[0].id)
        .catch(() => testMifidData.data[0].id);
};

export const getQuestionsByMifidId = async (mifidId?: string) => {
    const mifid = mifidId ?? '1';
    return fetchApi(`mifid/v1/questions?filter[test_id]=${mifid}`, 'get', {})
        .then((res: Array<any>) => res)
        .catch(() => questionsOfTestMifid.data);
};

export const postQuestion = (questionId: number, answer: number) => {
    const requestData = {
        data: {
            type: 'Answer',
            attributes: {
                answer,
                question_id: questionId,
            },
        },
    };
    return fetchApi('mifid/v1/answers', 'post', requestData);
};
