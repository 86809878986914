import * as React from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
// @ts-ignore
import {useNavigate} from 'react-router-dom';
import {addYears, differenceInYears, format, parseISO, sub, startOfMonth} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from '@mui/material';
import theme from '../../styles/theme';
import {heritageFormQuestionTitle, heritageQuestionNameOrder, lastQuestionIndex} from '../../utils/componentData';
import {HerenciaFormComponent} from '../../modules/goal/herencia/herencia-form.component';
import {OutputType} from '../../modules/goal/goal.type';
import {addObjectiveApi, getObjectiveCostApi, modifyObjectiveApi} from '../../modules/goal/goal.service';
import {addLastObjectiveIdAndType, selectObjectives, setLastObjectiveForm} from '../../modules/goal/goal.slice';
import PageLoading from '../../elements/loading/PageLoading';
import {GoalFooterMeta} from '../../components/meta/GoalFooterMeta';
import {setIsAuthDialogOpen} from '../../modules/auth/auth.slice';
import {addAlert} from '../../modules/app/app.slice';
import {getConfig} from '../../modules/propuesta/propuesta.service';
import {setUserDataLayer} from '../../modules/gtm/gtm.script';
import {selectUser, setUser} from '../../modules/user/user.slice';
import {getErrorMessage, tr} from '../../utils/functions';
import herencia from '../../styles/Herencia.module.css';

function Herencia(props: any) {
    const goalName = 'Dejar una herencia';
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {goBack, finish, closeDialog} = props;
    // eslint-disable-next-line react/destructuring-assignment
    const dispatch = useDispatch();
    const router = useNavigate();
    const {birthday} = useSelector(selectUser);
    const objectivesData = useSelector(selectObjectives);
    const storage = globalThis?.sessionStorage;
    const [hideBirthdayQuestion, setHideBirthdayQuestion] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [questionIndex, setQuestionIndex] = React.useState(0);
    const [inflation, setInflation] = React.useState(0);
    const [deathAge, setDeathAge] = React.useState(0);
    const [totalCost, setTotalCost] = React.useState(0);
    React.useEffect(() => {
        const logo = document.getElementById('header-onboarding');
        if (logo) {
            logo.scrollIntoView({behavior: 'smooth'});
        }
    }, [questionIndex]);
    React.useEffect(() => {
        setQuestionIndex(
            storage?.getItem('currentPath') === '/meta/fin' && !downMobile ? lastQuestionIndex.heritage : 0
        );
    }, [downMobile, storage]);

    const validationSchema = yup.object({
        birthday: yup
            .date()
            .typeError('La fecha no es valida')
            .nullable()
            .min(sub(Date.now(), {years: 90}), tr('Debes tener menos de 90'))
            .max(sub(Date.now(), {years: 18}), tr('Debes ser mayor de edad'))
            .required(tr('Fecha de nacimiento requerida')),
        amount: yup
            .number()
            .nullable(true)
            .required(tr('Introduce un valor numérico'))
            .min(1, tr('El coste tiene que ser mayor que cero')),
    });
    const formik = useFormik({
        initialValues:
            objectivesData.lastObjectiveType === 'HERITAGE' && objectivesData.lastObjectiveForm
                ? {
                      ...objectivesData.lastObjectiveForm,
                      birthday,
                  }
                : {
                      birthday: birthday === '' ? null : birthday,
                      amount: null,
                  },
        validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
            const dateInit = startOfMonth(addYears(parseISO(values.birthday), deathAge));
            const heritageObjective = {
                amount: values.amount,
                type: 'HERITAGE' as const,
                output_type: OutputType.UNIQUE,
                protection_type: 'CAPITAL' as const,
                name: goalName,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: undefined,
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            setUserDataLayer(null, differenceInYears(new Date(), parseISO(values.birthday ?? '')));
            if (objectivesData.lastObjectiveType === 'HERITAGE') {
                modifyObjectiveApi(heritageObjective, objectivesData.lastObjectiveId)
                    .then(() => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                birthday: values.birthday ? format(parseISO(values.birthday), 'yyyy-MM-dd') : '',
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.data?.message) {
                            dispatch(
                                addAlert({
                                    message: e?.response?.data?.message,
                                    isError: true,
                                    isOpen: true,
                                })
                            );
                        }
                        formik.setSubmitting(false);
                    });
            } else {
                addObjectiveApi(heritageObjective)
                    .then((lastObjective: any) => {
                        dispatch(
                            setUser({
                                birthday: values.birthday ? format(parseISO(values.birthday), 'yyyy-MM-dd') : '',
                            })
                        );
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                birthday: values.birthday ? format(parseISO(values.birthday), 'yyyy-MM-dd') : '',
                            })
                        );
                        dispatch(
                            addLastObjectiveIdAndType({
                                lastObjectiveType: lastObjective.attributes.type,
                                lastObjectiveId: lastObjective.id,
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            }
        },
    });
    React.useEffect(() => {
        setLoading(true);
        setHideBirthdayQuestion(birthday !== '');
        setQuestionIndex(birthday === '' ? 0 : 1);
        getConfig()
            .then((res) => {
                const configInflation = res.inflation;
                if (typeof configInflation === 'number') {
                    setInflation(configInflation);
                }
                const configDeathAge = res.death_age;
                if (typeof configDeathAge === 'number') {
                    setDeathAge(configDeathAge);
                }
                dispatch(setIsAuthDialogOpen(false));
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
        return () => {
            setInflation(0);
            setDeathAge(0);
        };
    }, []);
    React.useEffect(() => {
        if (questionIndex === 2) {
            setLoading(true);
            const dateInit = startOfMonth(addYears(parseISO(formik.values.birthday), deathAge));
            const heritageObjective = {
                amount: formik.values.amount,
                type: 'HERITAGE' as const,
                output_type: OutputType.UNIQUE,
                protection_type: 'CAPITAL' as const,
                name: goalName,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: undefined,
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            getObjectiveCostApi(heritageObjective)
                .then((res) => {
                    const {withdrawal} = res.attributes;

                    let customTotalCost = 0;
                    if (withdrawal) {
                        customTotalCost = withdrawal.reduce((acc: any, current: any) => acc + current, 0);
                    }
                    setTotalCost(customTotalCost);
                    setLoading(false);
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                });
        }
        return () => {
            setLoading(false);
        };
    }, [questionIndex]);
    return (
        <div className={herencia.container}>
            <PageLoading open={formik.isSubmitting || loading} />
            <inv-grid-row>
                <inv-grid-col class="col" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--heritage-color)',
                            '--inv-text-l-font-weight': 600,
                        }}
                    >
                        {goalName}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            {
                heritageFormQuestionTitle(formik.values.amount, questionIndex > 0 ? deathAge : 0, totalCost)[
                    questionIndex
                ]
            }
            <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
                <inv-grid-col class="col">
                    <HerenciaFormComponent formik={formik} questionIndex={questionIndex} inflation={inflation} />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col">
                    <GoalFooterMeta
                        hideDots={questionIndex === heritageQuestionNameOrder.length || hideBirthdayQuestion}
                        goalType="HERITAGE"
                        questionNameOrder={heritageQuestionNameOrder}
                        questionIndex={questionIndex}
                        goBack={goBack}
                        setQuestionIndex={setQuestionIndex}
                        formik={formik}
                        hideBirthdayQuestion={hideBirthdayQuestion}
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
export default Herencia;
