import * as React from 'react';
import NumberFormat from 'react-number-format';
import styles from './propuesta-three-values-percentage-bar.module.css';
import {Size, useWindowSize} from '../../utils/hooks';

interface PropuestaThreeValuesPercentageBarProps {
    value1: number;
    value2: number;
    value3: number;
    value4?: number;
}

function PropuestaThreeValuesPercentageBar(props: PropuestaThreeValuesPercentageBarProps) {
    const {value1, value2, value3, value4} = props;
    const size: Size = useWindowSize();
    const downMobile = (size?.width ?? 0) < 576;
    const total = value1 + value2 + value3;
    const totalWithoutValue4 = total - (value4 ?? 0);
    return (
        <div>
            <inv-grid-row
                style={{
                    justifyContent: 'space-between',
                    '--inv-grid-gutter-x': 0,
                    position: 'relative',
                }}
            >
                {value1 > 0 && (
                    <inv-grid-col
                        className="col-auto"
                        style={{
                            height: '0.9em',
                            width: `calc((100% - ${downMobile ? '0.4' : '1.6'}em) * ${value1 / total})`,
                            background: 'var(--text-color-50)',
                            borderRadius: '1.4em',
                        }}
                    />
                )}
                {value2 > 0 && (
                    <inv-grid-col
                        className="col-auto"
                        style={{
                            height: '0.9em',
                            width: `calc((100% - ${downMobile ? '0.4' : '1.6'}em) * ${value2 / total})`,
                            background: 'var(--text-color-30)',
                            borderRadius: '1.4em',
                        }}
                    />
                )}
                {value3 > 0 && (
                    <inv-grid-col
                        className="col-auto"
                        style={{
                            height: '0.9em',
                            width: `calc((100% - ${downMobile ? '0.4' : '1.6'}em) * ${value3 / total})`,
                            background: 'var(--color-secondary-100)',
                            borderRadius: '1.4em',
                        }}
                    />
                )}
                {typeof value4 !== 'undefined' && value4 > 0 && (
                    <inv-grid-col
                        className="col-auto"
                        style={{
                            height: '0.9em',
                            width: `calc((100% - ${downMobile ? '0.4' : '1.6'}em) * ${(value4 ?? 0) / total})`,
                            background: '#FF5F29',
                            borderRadius: '1.4em',
                            position: 'absolute',
                            right: 0,
                        }}
                    />
                )}
            </inv-grid-row>
            <inv-grid-row class={styles.apportationsPercentageContainer}>
                <inv-grid-col
                    class="col-auto"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: `calc((100% - ${downMobile ? '0.4' : '1.6'}em) * ${value1 / total})`,
                    }}
                >
                    <inv-text-xs
                        style={{
                            '--inv-text-xs-font-weight': 600,
                            '--inv-text-xs-color': 'var(--text-color-50)',
                        }}
                    >
                        <NumberFormat
                            value={(value1 / totalWithoutValue4) * 100}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={2}
                            allowNegative={false}
                            displayType="text"
                            suffix="%"
                        />
                    </inv-text-xs>
                </inv-grid-col>
                <inv-grid-col
                    class="col-auto"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: `calc((100% - ${downMobile ? '0.4' : '1.6'}em) * ${value2 / total})`,
                    }}
                >
                    <inv-text-xs
                        style={{
                            '--inv-text-xs-font-weight': 600,
                            '--inv-text-xs-color': 'var(--text-color-30)',
                        }}
                    >
                        <NumberFormat
                            value={(value2 / totalWithoutValue4) * 100}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={2}
                            allowNegative={false}
                            displayType="text"
                            suffix="%"
                        />
                    </inv-text-xs>
                </inv-grid-col>
                <inv-grid-col
                    class="col-auto"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: `calc((100% - ${downMobile ? '0.4' : '1.6'}em) * ${value3 / total})`,
                    }}
                >
                    <inv-text-xs
                        style={{
                            '--inv-text-xs-font-weight': 600,
                            '--inv-text-xs-color': 'var(--color-secondary-100)',
                        }}
                    >
                        <NumberFormat
                            value={((value3 - (value4 ?? 0)) / totalWithoutValue4) * 100}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={2}
                            allowNegative={false}
                            displayType="text"
                            suffix="%"
                        />
                    </inv-text-xs>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

PropuestaThreeValuesPercentageBar.defaultProps = {
    value4: 0,
};
export default PropuestaThreeValuesPercentageBar;
