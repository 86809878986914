import {Alert, Grid, Link, Snackbar, useMediaQuery} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {format} from 'date-fns';
import {es} from 'date-fns/locale';
import StepsFragments from '../../elements/fragments/StepsFragments';
import theme from '../../styles/theme';
import {
    Capitalize,
    formatPhoneNumber,
    getErrorMessage,
    setIsAuthDialogOpenInStorage,
    tpl,
    tr,
} from '../../utils/functions';
import PageLoading from '../../elements/loading/PageLoading';
import {planMenuElements} from '../../utils/componentData';
import Text from '../../styles/textStyles';
import {changeTabNamePlan, openNewPlanDialog, selectPlan} from '../../modules/propuesta/propuesta.slice';
import {selectAuth} from '../../modules/auth/auth.slice';
import {selectUser} from '../../modules/user/user.slice';
import {addAlert, closeRiskProfileDialog, resetAlert, selectApp, setLocale} from '../../modules/app/app.slice';
import WCIcons from '../../elements/icons/WCIcons';

import OnboardingMenu from '../../elements/menu/OnboardingMenu';

import PlanMenu from '../../elements/menu/PlanMenu';
import {AuthDialogComponent} from '../../modules/auth/auth-dialog.component';
import {RiskProfileDialogContent} from '../../modules/risk/risk-dialog.component';
import ContainerDialog from '../../elements/dialog/ContainerDialog';
import {PersonalDataDialogContent} from '../../modules/user/user-dialog.component';
import {deleteSession} from '../../modules/auth/auth.service';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/icon/icon';
import styles from './onboardingHeaderNavigation.module.css';
import {getCompanyInfo} from '../../modules/app/app.service';

export function OnboardingHeaderNavigation(props: any) {
    const scrollToTopRef = React.useRef(null);
    const storage = globalThis?.sessionStorage;
    let token = '';
    if (storage) {
        token = storage.getItem('token') ?? '';
    }
    const {showRiskProfileDialog, toTopFlag} = useSelector(selectApp);
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const dispatch = useDispatch();
    const {birthday, riskProfile, riskProfileArray, userId} = useSelector(selectUser);
    const auth = useSelector(selectAuth);
    const {alert, alertFlag, locale} = useSelector(selectApp);
    const {isAuthDialogOpen} = auth;
    const {name, financialWealth, userIncome, objectives} = props;
    const downXl = useMediaQuery(theme.breakpoints.down('xl'));
    const router = useNavigate();
    const location = useLocation();
    const showNavigation = !(
        location.pathname === '/' ||
        location.pathname === '/token' ||
        location.pathname === '/verificacion' ||
        location.pathname === '/restore-password' ||
        location.pathname === '/registro' ||
        location.pathname === '/login'
    );
    // @ts-ignore
    const hideHeader = !(
        // @ts-ignore
        (
            location?.pathname?.includes('meta') ||
            location.pathname?.includes('perfil') ||
            location.pathname?.includes('propuesta') ||
            location.pathname?.includes('plan') ||
            location.pathname === '/planes-asesoramiento'
        )
    );

    const showTopMenu = !(
        location.pathname === '/' ||
        location.pathname === '/token' ||
        location.pathname === '/verificacion' ||
        location.pathname === '/restore-password' ||
        location.pathname === '/registro' ||
        location.pathname === '/login' ||
        location.pathname === '/propuesta' ||
        location.pathname === '/planificacion-financiera' ||
        // @ts-ignore
        location.pathname.includes('contratacion') ||
        location.pathname.includes('/plan')
    );
    const showProfilePicture = location.pathname.includes('/plan');
    const [alertObject, setAlertObject] = React.useState(alert);
    const [isElHover, setIsElHover] = React.useState(false);
    const [isEl1Hover, setIsEl1Hover] = React.useState(false);
    const [isEl2Hover, setIsEl2Hover] = React.useState(false);
    const [isEl3Hover, setIsEl3Hover] = React.useState(false);
    const computedStyles = window.getComputedStyle(document.body);
    const [isRiskProfileDialogOpen, setIsRiskProfileDialogOpen] = React.useState(false);
    const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [fetching, setFetching] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [hasMifid, setHasMifid] = React.useState(false);
    const {tabName} = useSelector(selectPlan);
    const getPhone = async () => {
        setFetching(true);
        try {
            let companyPhone = '';
            let mifid = false;
            const companyInfo = await getCompanyInfo();
            if (companyInfo) {
                companyPhone =
                    companyInfo?.phones?.web ??
                    companyInfo?.phones?.free ??
                    companyInfo?.phones[Object.keys(companyInfo.phones)[0]];
                mifid = companyInfo?.mifid;
            }
            setFetching(false);
            setPhone(formatPhoneNumber(companyPhone));
            setHasMifid(mifid);
        } catch (e) {
            // @ts-ignore
            const message = await getErrorMessage(e);
            dispatch(
                addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                })
            );
            setFetching(false);
        }
    };
    React.useEffect(() => {
        getPhone().then();
    }, []);
    React.useEffect(() => {
        let selectedTab;
        if (location.pathname === '/plan/meta') {
            selectedTab = 'goals';
        } else if (location.pathname === '/plan/inversiones') {
            selectedTab = 'investments';
        } else if (location.pathname === '/plan') {
            selectedTab = 'plan';
        }
        dispatch(changeTabNamePlan(selectedTab));
    }, []);
    const changeTabName = (thisTabName: string) => dispatch(changeTabNamePlan(thisTabName));
    const menuItems =
        location.pathname === '/propuesta'
            ? [
                  {
                      title: tr('Mis datos'),
                      startIcon: (
                          <WCIcons
                              name="profile-single"
                              width="1.2em"
                              height="1.2em"
                              color={
                                  isEl1Hover
                                      ? computedStyles?.getPropertyValue('--navigation-color')
                                      : computedStyles?.getPropertyValue('--navigation-selected-color')
                              }
                          />
                      ),
                      onClick: () => setIsPersonalDataDialogOpen(true),
                      setIsHover: setIsEl1Hover,
                  },
                  {
                      title: tr('Perfil inversor'),
                      startIcon: (
                          <WCIcons
                              name="financial-freedom"
                              width="1.2em"
                              height="1.2em"
                              color={
                                  isEl2Hover
                                      ? computedStyles?.getPropertyValue('--navigation-color')
                                      : computedStyles?.getPropertyValue('--navigation-selected-color')
                              }
                          />
                      ),
                      onClick: () => setIsRiskProfileDialogOpen(true),
                      setIsHover: setIsEl2Hover,
                  },
                  {
                      title: tr('Cerrar sesión'),
                      startIcon: (
                          <WCIcons
                              name="log-out"
                              width="1.2em"
                              height="1.2em"
                              color={
                                  isEl3Hover
                                      ? computedStyles?.getPropertyValue('--navigation-color')
                                      : computedStyles?.getPropertyValue('--navigation-selected-color')
                              }
                          />
                      ),
                      setIsHover: setIsEl3Hover,
                      onClick: () => {
                          setLoading(true);
                          dispatch({type: 'reset'});
                          deleteSession(token).finally(() => {
                              if (storage) {
                                  storage.clear();
                              }
                              router('/login');
                          });
                      },
                  },
              ]
            : [
                  {
                      title: tr('Mis datos'),
                      startIcon: (
                          <WCIcons
                              name="profile-single"
                              width="1.2em"
                              height="1.2em"
                              color={isEl1Hover ? 'var(--color-primary-100)' : 'var(--accent-color-1)'}
                          />
                      ),
                      onClick: () => setIsPersonalDataDialogOpen(true),
                      setIsHover: setIsEl1Hover,
                  },
                  {
                      title: tr('Cerrar sesión'),
                      startIcon: (
                          <WCIcons
                              name="log-out"
                              width="1.2em"
                              height="1.2em"
                              color={isEl2Hover ? 'var(--color-primary-100)' : 'var(--accent-color-1)'}
                          />
                      ),
                      setIsHover: setIsEl2Hover,
                      onClick: () => {
                          setLoading(true);
                          dispatch({type: 'reset'});
                          deleteSession(token).finally(() => {
                              if (storage) {
                                  storage.clear();
                              }
                              router('/login');
                          });
                      },
                  },
              ];

    React.useEffect(() => {
        dispatch(closeRiskProfileDialog());
        dispatch(resetAlert());
        setLoading(true);
        if (isAuthDialogOpen === true) {
            setIsAuthDialogOpenInStorage('true');
        } else {
            setIsAuthDialogOpenInStorage('false');
        }
        setLoading(false);
    }, []);
    React.useEffect(() => {
        setAlertObject(alert);
    }, [alertFlag]);
    React.useEffect(() => {
        setLoading(false);
    }, [location.pathname]);
    React.useEffect(() => {
        if (scrollToTopRef.current) {
            (scrollToTopRef.current as HTMLDivElement).scrollIntoView({
                block: 'start',
            });
        }
    }, [toTopFlag]);
    const handleClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(resetAlert());
    };
    return (
        <div id="header-onboarding" ref={scrollToTopRef} className={styles.main}>
            {alertObject?.isOpen && (
                <Snackbar
                    open
                    autoHideDuration={10000}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    onClose={handleClose}
                >
                    {/* @ts-ignore */}
                    <Alert
                        severity={alertObject?.isError ? 'error' : 'success'}
                        sx={{width: '100%'}}
                        action={
                            // @ts-ignore
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <button type="button" onClick={handleClose}>
                                <div>
                                    <WCIcons hoverColor="inherit" name="close" />
                                </div>
                            </button>
                        }
                        // @ts-ignore
                        onClose={handleClose}
                    >
                        {alertObject?.message}
                    </Alert>
                </Snackbar>
            )}
            {false && (
                <ContainerDialog open onClose={() => null} scroll="body" maxWidth="xs">
                    <Grid item sx={{width: '100%'}}>
                        <Grid container justifyContent="center">
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    background: 'var(--accent-color-1)',
                                    borderRadius: '32px',
                                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                                    padding: '40px',
                                }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    rowSpacing={6}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            color: 'var(--error-color)',
                                        }}
                                    >
                                        <WCIcons name="message-failed" viewBox="0 0 25 24" height="40" width="40" />
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            rowSpacing={4}
                                        >
                                            <Grid item>
                                                <Text
                                                    size={downMobile ? 'm' : 'xl'}
                                                    weight="semibold"
                                                    align="center"
                                                    fontColor="var(--text-color-100)"
                                                >
                                                    {tr('Error al cargar')}
                                                </Text>
                                            </Grid>
                                            <Grid item>
                                                <Text
                                                    size={downMobile ? 'xxs' : 'xs'}
                                                    weight="regular"
                                                    align="center"
                                                    fontColor="var(--text-color-50)"
                                                >
                                                    {tr(
                                                        'Se ha producido un problema al cargar la prouesta, por favor, recargue la pagina de nuevo mas tarde'
                                                    )}
                                                </Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" alignItems="center" justifyContent="center">
                                            <Grid item>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <Link
                                                    onClick={() => router(0)}
                                                    sx={{
                                                        fontWeight: 700,
                                                        cursor: 'pointer',
                                                        fontSize: downMobile ? 12 : 10,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {tr('Recargar la página')}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContainerDialog>
            )}
            <PageLoading open={loading || fetching} />
            <ContainerDialog
                maxWidth="md"
                fullWidth
                scroll="body"
                open={isRiskProfileDialogOpen || showRiskProfileDialog}
                onClose={() => {
                    dispatch(closeRiskProfileDialog());
                    setIsRiskProfileDialogOpen(false);
                }}
            >
                <RiskProfileDialogContent
                    userId={userId}
                    isSliderActive
                    birthday={birthday}
                    userIncome={userIncome}
                    objectives={objectives ? objectives.map((objective: any) => objective) : undefined}
                    financialWealth={financialWealth}
                    isTestAvailable
                    closeDialog={() => {
                        dispatch(closeRiskProfileDialog());
                        setIsRiskProfileDialogOpen(false);
                    }}
                    profileNumber={riskProfile.profile_id}
                    profileNumberMax={!hasMifid ? riskProfileArray.length.toString() : riskProfile.max_profile_id}
                    riskProfileArray={riskProfileArray}
                />
            </ContainerDialog>
            <ContainerDialog
                scroll="body"
                open={isPersonalDataDialogOpen}
                onClose={() => setIsPersonalDataDialogOpen(false)}
            >
                <PersonalDataDialogContent closeDialog={() => setIsPersonalDataDialogOpen(false)} />
            </ContainerDialog>
            <ContainerDialog scroll="body" open={isAuthDialogOpen} onClose={() => null}>
                <AuthDialogComponent />
            </ContainerDialog>
            {location.pathname !== '/registro' && (
                <Grid
                    id="header"
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        height: downXl ? 100 : 120,
                        paddingLeft: theme.spacing(5),
                        paddingRight: theme.spacing(5),
                        '@media (max-width:950px)': {
                            paddingLeft: 0,
                            paddingRight: 0,
                        },
                        [theme.breakpoints.up('xl')]: {
                            width: 2000,
                            margin: 'auto',
                        },
                    }}
                >
                    <Grid
                        item
                        sx={{
                            minWidth: 300,
                            color: location.pathname.includes('/plan')
                                ? 'var(--color-secondary-100)'
                                : 'var(--color-primary-100)',
                        }}
                    >
                        <img
                            alt="logo"
                            style={{
                                content: 'var(--logo)',
                                maxWidth: 'var(--logo-width)',
                                maxHeight: 'var(--logo-height)',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        {location.pathname.includes('/plan') && (
                            <PlanMenu
                                elements={planMenuElements}
                                selectedElement={tabName}
                                onChangeTab={(tab: string) => {
                                    changeTabName(tab);
                                    if (tab === 'goals') {
                                        router('/plan/meta');
                                    } else if (tab === 'investments') {
                                        router('/plan/inversiones');
                                    } else if (tab === 'plan') {
                                        router('/plan');
                                    }
                                }}
                            />
                        )}
                        {showTopMenu && hideHeader !== true && (
                            <StepsFragments
                                steps={[
                                    process.env.REACT_APP_ONBOARDING_POINT_1_TEXT || tr('Mis metas'),
                                    process.env.REACT_APP_ONBOARDING_POINT_2_TEXT || tr('Aportación inicial'),
                                    process.env.REACT_APP_ONBOARDING_POINT_3_TEXT || tr('Perfil inversor'),
                                    process.env.REACT_APP_ONBOARDING_POINT_4_TEXT || tr('Resultado'),
                                ]}
                            />
                        )}
                        {
                            // @ts-ignore
                            location.pathname.includes('contratacion') && (
                                <StepsFragments
                                    steps={[tr('Información personal'), tr('Cumplimiento normativo'), tr('Contratos')]}
                                />
                            )
                        }
                    </Grid>
                    <Grid
                        item
                        sx={{
                            minWidth: 300,
                        }}
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                width: 'auto',
                                '& > *:not(:last-child)': {
                                    marginRight: location.pathname.includes('/plan') ? '0.8em' : '3.2em',
                                },
                            }}
                        >
                            {process.env.REACT_APP_SHOW_LANGUAGE_SWITCH === 'true' && (
                                <Grid item>
                                    <inv-text-m
                                        onClick={() => {
                                            dispatch(setLocale('es-ES'));
                                            if (location.pathname === '/gvc') {
                                                router(0);
                                            }
                                        }}
                                        style={{
                                            '--inv-text-m-color':
                                                locale === 'es-ES' || locale === ''
                                                    ? 'var(--color-secondary-100)'
                                                    : 'var(--header-on-surface-thin-color)',
                                            '--inv-text-m-font-weight': locale === 'es-ES' ? 600 : 400,
                                            cursor: 'pointer',
                                            pointerEvents: locale === 'es-ES' || locale === '' ? 'none' : 'auto',
                                        }}
                                    >
                                        ES
                                    </inv-text-m>
                                    <inv-text-m
                                        style={{
                                            '--inv-text-m-color': 'var(--header-on-surface-thin-color)',
                                            '--inv-text-m-font-weight': 400,
                                        }}
                                    >
                                        |
                                    </inv-text-m>
                                    <inv-text-m
                                        onClick={() => {
                                            dispatch(setLocale('ca-ES'));
                                            if (location.pathname === '/gvc') {
                                                router(0);
                                            }
                                        }}
                                        style={{
                                            '--inv-text-m-color':
                                                locale === 'ca-ES'
                                                    ? 'var(--color-secondary-100)'
                                                    : 'var(--header-on-surface-thin-color)',
                                            '--inv-text-m-font-weight': locale === 'ca-ES' ? 600 : 400,
                                            cursor: 'pointer',
                                            pointerEvents: locale === 'ca-ES' ? 'none' : 'auto',
                                        }}
                                    >
                                        CA
                                    </inv-text-m>
                                </Grid>
                            )}
                            {!location.pathname.includes('/plan') && (
                                <Grid
                                    item
                                    sx={{
                                        display: computedStyles?.getPropertyValue('--display-header-phone'),
                                    }}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        columnSpacing={1}
                                        sx={{
                                            width: 'auto',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                borderBottom: `1px solid ${computedStyles?.getPropertyValue(
                                                    '--header-on-surface-color'
                                                )}`,
                                            },
                                        }}
                                    >
                                        <Grid
                                            item
                                            sx={{
                                                position: 'relative',
                                                bottom: '-0.1em',
                                            }}
                                        >
                                            <WCIcons
                                                width="1em"
                                                height="1em"
                                                name="phone"
                                                color={computedStyles?.getPropertyValue('--header-on-surface-color')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <inv-text-m
                                                style={{
                                                    '--inv-text-m-color':
                                                        computedStyles?.getPropertyValue('--header-on-surface-color'),
                                                    '--inv-text-m-font-weight': 600,
                                                }}
                                            >
                                                {phone}
                                            </inv-text-m>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {location.pathname.includes('/plan') && (
                                <Grid item>
                                    <OnboardingMenu
                                        title="Modificar plan"
                                        borderColor="var(--color-secondary-100)"
                                        titleOnclick={() => dispatch(openNewPlanDialog())}
                                    />
                                </Grid>
                            )}
                            {location.pathname === '/propuesta' && (
                                <Grid item>
                                    <inv-text-s
                                        style={{
                                            '--inv-text-s-color': computedStyles?.getPropertyValue(
                                                '--header-on-surface-thin-color'
                                            ),
                                            '--inv-text-s-font-weight': 400,
                                        }}
                                    >
                                        {tr('Perfil inversor')}&nbsp;
                                    </inv-text-s>
                                    <inv-text-s
                                        onClick={() => setIsRiskProfileDialogOpen(true)}
                                        style={{
                                            '--inv-text-s-font-size': '1em',
                                            '--inv-text-s-color':
                                                computedStyles?.getPropertyValue('--header-on-surface-color'),
                                            '--inv-text-s-font-weight': 600,
                                            borderBottom: `0.05em solid ${computedStyles?.getPropertyValue(
                                                '--navigation-selected-color'
                                            )}`,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {tr(
                                            Capitalize(
                                                riskProfileArray[riskProfile.profile_id - 1]?.attributes?.name ?? ''
                                            )
                                        )}
                                    </inv-text-s>
                                </Grid>
                            )}
                            <Grid
                                item
                                sx={{
                                    display:
                                        location.pathname === '/propuesta' || location.pathname.includes('/plan')
                                            ? 'initial'
                                            : 'var(--login-display)',
                                }}
                                onClick={() => {
                                    if (
                                        location.pathname !== '/propuesta' &&
                                        location.pathname !== '/planes-asesoramiento' &&
                                        !location.pathname.includes('/plan')
                                    ) {
                                        router('/login');
                                    }
                                }}
                            >
                                {showNavigation &&
                                    !hideHeader &&
                                    process.env.REACT_APP_SHOW_PROPUESTA_TOP_RIGHT_MENU !== 'false' && (
                                        <OnboardingMenu
                                            setIsHover={(value: boolean | ((prevState: boolean) => boolean)) =>
                                                setIsElHover(value)
                                            }
                                            icon={
                                                location.pathname === '/propuesta' ||
                                                location.pathname === '/planes-asesoramiento' ? (
                                                    <WCIcons
                                                        style={{
                                                            marginRight: '0.4em',
                                                        }}
                                                        width="1.2em"
                                                        height="1.2em"
                                                        name="single-thin-circle"
                                                    />
                                                ) : (
                                                    <div style={{height: '1.2em'}}>
                                                        <WCIcons
                                                            style={{
                                                                marginRight: '0.4em',
                                                            }}
                                                            name="log-in"
                                                            width="1.2em"
                                                            height="1.2em"
                                                            color={
                                                                isElHover
                                                                    ? 'var(--accent-color-1)'
                                                                    : 'var(--accent-color-1)'
                                                            }
                                                        />
                                                    </div>
                                                )
                                            }
                                            showProfilePicture={showProfilePicture}
                                            title={
                                                location.pathname === '/propuesta' ||
                                                location.pathname === '/planes-asesoramiento'
                                                    ? name || ''
                                                    : 'Login'
                                            }
                                            options={
                                                location.pathname === '/propuesta' ||
                                                location.pathname === '/planes-asesoramiento'
                                                    ? menuItems
                                                    : undefined
                                            }
                                        />
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {location.pathname.includes('/plan') && (
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--accent-color-1)',
                                        '--inv-text-xxs-font-weight': 400,
                                    }}
                                >
                                    {' '}
                                    {tpl(tr('Datos a {date}'), {
                                        date: format(
                                            new Date(),
                                            /* @ts-ignore */
                                            "dd 'de' MMMM 'de' yyyy",
                                            /* @ts-ignore */
                                            {locale: es}
                                        ),
                                    })}
                                </inv-text-xxs>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
}

export default OnboardingHeaderNavigation;
