import {fetchApi, getApiUrl, originalFetch} from '../../utils/functions';
import {store} from '../../store';

interface IRegistrationOnFinanbest {
    email: string;
    last_name_1: string;
    last_name_2: string;
    password: string;
    mobile: string;
    document: string;
    type_id: string;
    gender: string;
    country: string;
    birth_country: string;
    birth_city: string;
    birth_region: string;
    postal_code: string;
    region: string;
    city: string;
    population: string;
    province: string;
    municipality: string;
    nationality: string;
    expiration_date: string;
    address_type: string;
    address: string;
    address_number: string;
    address_floor?: string;
    residence_country: string;
    marital_status: string;
    iban: string;
    job: string;
    laboral: string;
    varias_nacionalidades: boolean;
    primera_nacionalidad_extra?: string;
}

interface IKycOnFinanbest {
    fiscal_obligations: number;
    cecabank_relationship: string;
    company: string;
    public_functions: number;
    public_functions_family: number;
    other_sources_income: number;
}

export const getContractListService = (userId: number) => fetchApi(`user/v1/users/${userId}/contracts`, 'get', {});

export const postCreateContractService = (userId: number, recommendationId: string, provider: string = 'Finanbest') => {
    const payload = {
        data: {
            type: 'Contract',
            attributes: {
                identifier: recommendationId,
                provider,
            },
        },
    };
    return fetchApi(`user/v1/users/${userId}/contracts`, 'post', payload);
};

export const postCheckFinanbestMailExistenceService = (email: string, provider: string = 'finanbest') => {
    const payload = {
        data: {
            type: 'FinancialProviderUser',
            attributes: {
                email,
            },
        },
    };
    return fetchApi(`mifid/v1/financial-providers/${provider}/check-email`, 'post', payload);
};

export const postLoginFinanbestService = (
    userId: number,
    email: string,
    password: string,
    provider: string = 'Finanbest'
) => {
    const payload = {
        data: {
            type: 'FinancialProviderUser',
            attributes: {
                provider,
                username: email,
                password,
            },
        },
    };
    return fetchApi(`user/v1/users/${userId}/financial-provider-login`, 'post', payload);
};

export const postDniService = (base64DocData: unknown, isFrontSide: boolean, userId: number) => {
    const payload = {
        data: {
            type: 'Document',
            attributes: {
                type: isFrontSide ? 'DNI_F' : 'DNI_B',
                content: base64DocData,
            },
        },
    };
    return fetchApi(`user/v1/users/${userId}/documents`, 'post', payload);
};

export const postRegistrationFinanbestService = (
    registrationData: IRegistrationOnFinanbest,
    userId: number,
    provider: string = 'Finanbest'
) => {
    const data = {
        data: {
            type: 'FinancialProviderUser',
            attributes: {
                ...registrationData,
                provider,
            },
        },
    };
    return fetchApi(`user/v1/users/${userId}/financial-provider-register`, 'post', data);
};

export const finanbestCreateUser = async (data: any) => {
    const finanbestCreateUserResponse = await fetchApi('finanbest/user', 'post', data);
    return finanbestCreateUserResponse;
};

export const kycOnFinanbestApi = (data: IKycOnFinanbest, userId: number, provider: string = 'Finanbest') => {
    const parsedData = {
        data: {
            type: 'TestKnowYourClient',
            attributes: {
                ...data,
                provider,
            },
        },
    };
    return fetchApi(`user/v1/users/${userId}/test-know-your-client`, 'post', parsedData);
};

export const finanbestAuth = async (password: string, username: string) => {
    const finanbestAuthResponse = await fetchApi('finanbest/auth/authenticate', 'post', {
        username,
        password,
    });
    return finanbestAuthResponse;
};

export const finanbestSendDni = async (formData: FormData) => {
    const finanbestDniResponse = await fetchApi('user/upload-personal-document', 'post', formData);
    return finanbestDniResponse;
};

export const postFinanbestKycService = async (data: IKycOnFinanbest, userId: number) => {
    const parsedData = {
        data: {
            type: 'TestKnowYourClientRequest',
            attributes: {
                ...data,
                intervener_cc: 'Titular',
                intervener_cv: 'Titular',
            },
        },
    };

    return fetchApi(
        `user/v1/users/${userId}/financial-providers/finanbest/contracting/test-know-your-client`,
        'post',
        parsedData
    );
};

export const finanbestSendKyc = (algo: any) => algo;

export const getUserInfoCatalogsService = async () =>
    fetchApi(
        'setting/v1/catalogs?filter[id]=in|AddressType|Countries|IdentityDocuments|Labor|MaritalStatus|Municipalities|Occupations|Regions|Provinces|',
        'get',
        {}
    );
export const getKycCatalogsService = async () =>
    fetchApi('setting/v1/catalogs?filter[id]=CecabankRelationships', 'get', {});

export const getContractPdfService = async (userId: number, contractId: string) => {
    const apiUrl = getApiUrl();
    fetchApi(`user/v1/users/${userId}/contracts/${contractId}`, 'get', {});
    const storage = globalThis?.sessionStorage;
    const token = storage.getItem('token');
    const newHeaders = new Headers({
        Accept: 'application/pdf',
        'Content-Type': 'application/vnd.api+json',
    });
    if (token) {
        newHeaders.append('X-Auth-Token', token);
    } else {
        store.dispatch({type: 'reset'});
        const sessionResponse = await originalFetch(`${apiUrl}/api/v2/auth/v1/sessions`, {
            method: 'POST',
            headers: newHeaders,
        });
        if (sessionResponse.headers.get('X-Auth-token')) {
            storage.setItem('token', sessionResponse.headers.get('X-Auth-token') ?? '');
            newHeaders.append('X-Auth-Token', sessionResponse.headers.get('X-Auth-token') ?? '');
        }
    }
    const newConfig = {method: 'GET', headers: newHeaders};
    // request interceptor here
    const finalUrl = `${apiUrl}/api/v2/user/v1/users/${userId}/contracts/${contractId}`;
    return originalFetch(finalUrl, newConfig)
        .then((response) => response.blob())
        .then((blob) => {
            // Create a temporary anchor element
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `contrato-${contractId}.pdf`;

            // Programmatically trigger the download
            downloadLink.click();

            // Clean up the temporary anchor element
            URL.revokeObjectURL(downloadLink.href);
            return Promise.resolve();
        })
        .catch(() => Promise.reject(new Error('Error descargando el contrato')));
};

export default finanbestAuth;
