// @ts-ignore
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {add, differenceInYears, format, parseISO, startOfMonth} from 'date-fns';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {OutputType} from '../goal.type';
import {addObjectiveApi, modifyObjectiveApi} from '../goal.service';
import {addLastObjectiveIdAndType, setLastObjectiveForm} from '../goal.slice';
import {addAlert, selectApp} from '../../app/app.slice';
import {getErrorMessage, getYearAndMonth, tr, tpl, getDateFnsLocale} from '../../../utils/functions';

interface IEducacionFormik {
    objectivesData: {
        lastObjectiveId: string;
        lastObjectiveType: string | null;
        lastObjectiveForm: any;
    };
    finish: any;
    closeDialog: any;
    maxDate: Date;
    minDate: Date;
}

export const EducacionFormik = (props: IEducacionFormik) => {
    const dispatch = useDispatch();
    const router = useNavigate();
    const {locale} = useSelector(selectApp);
    const {objectivesData, finish, closeDialog, maxDate, minDate} = props;
    /*
    if (getDate(new Date()) < 20) {
    minDate = endOfDay(addMonths(new Date(), 13));
    minDateMessage = "Planifica tu meta con 13 meses de margen";
  } else {
    minDate = endOfDay(addMonths(new Date(), 14));
    minDateMessage = "Planifica tu meta con 14 meses de margen";
  }
   */
    const yearDifference = differenceInYears(minDate, new Date());
    const minDateMessage =
        yearDifference === 1
            ? tr('Planifica tu meta con 1 año de margen')
            : tpl(tr('Planifica tu meta con {yearDifference} años de margen'), {yearDifference});

    const validationSchema = yup.object({
        recipient: yup.string().max(20).required(),
        init_date: yup
            .date()
            .typeError('La fecha no es valida')
            .nullable()
            .min(minDate, minDateMessage)
            .max(
                maxDate,
                tpl(tr(`La fecha no puede ser posterior a {date}`), {
                    date: format(maxDate, 'MMMM yyyy', {locale: getDateFnsLocale(locale)}),
                })
            )
            .required('Fecha de inicio requerida'),
        duration: yup.number().min(1).max(6),
        amount: yup
            .number()
            .required('Introduce un valor numérico')
            .min(1, 'El coste tiene que ser mayor que cero')
            .nullable(),
    });
    const formik = useFormik({
        initialValues:
            objectivesData.lastObjectiveType === 'EDUCATION' && objectivesData.lastObjectiveForm
                ? {
                      ...objectivesData.lastObjectiveForm,
                      init_date: getYearAndMonth(objectivesData.lastObjectiveForm.init_date),
                  }
                : {
                      recipient: '',
                      init_date: null,
                      duration: 1,
                      amount: null,
                  },
        validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
            const dateInit = startOfMonth(parseISO(values.init_date));
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const end_date = add(dateInit, {
                years: values.duration - 1,
            });
            const educationObjective = {
                amount: values.amount,
                type: 'EDUCATION' as const,
                output_type: values.duration < 2 ? OutputType.UNIQUE : OutputType.ANNUAL,
                protection_type: values.duration < 2 ? ('CAPITAL' as const) : ('SHORT_TERM' as const),
                name: tpl(tr(`Educación de {recipient}`), {recipient: values.recipient}),
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: end_date ? format(end_date, 'yyyy-MM-dd') : '',
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            if (objectivesData.lastObjectiveType === 'EDUCATION') {
                modifyObjectiveApi(educationObjective, objectivesData.lastObjectiveId)
                    .then(() => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            } else {
                addObjectiveApi(educationObjective)
                    .then((lastObjective: any) => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                            })
                        );
                        dispatch(
                            addLastObjectiveIdAndType({
                                lastObjectiveType: lastObjective.attributes.type,
                                lastObjectiveId: lastObjective.id,
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            }
        },
    });
    return formik;
};
export default EducacionFormik;
