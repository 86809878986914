import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import {styled, alpha} from '@mui/material/styles';
import {Grid, useMediaQuery} from '@mui/material';
import Button from '@mui/material/Button';
// @ts-ignore
import {useLocation} from 'react-router-dom';
import {ComponentType} from 'react';
import theme from '../../styles/theme';
import WCIcons from '../icons/WCIcons';

interface Options {
    title: string;
    startIcon: JSX.Element | ComponentType<{}>;
    onClick?: () => void;
    setIsHover: any;
}
interface IOnboardingMenu {
    icon?: JSX.Element | ComponentType<{}>;
    title: string;
    options?: Array<Options>;
    menuOptions?: Array<Options>;
    showProfilePicture?: boolean;
    borderColor?: string;
    titleOnclick?: () => void;
    setIsHover?: any;
}

const StyledMenu = styled((props: any) => (
    // @ts-ignore
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
    />
    // eslint-disable-next-line @typescript-eslint/no-shadow
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        background: 'transparent',
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        /*
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
         */
        '& .MuiMenu-list': {
            [theme.breakpoints.up('xs')]: {
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                paddingLeft: theme.spacing(0),
                paddingRight: theme.spacing(0),
            },
            [theme.breakpoints.up('xl')]: {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                paddingLeft: theme.spacing(0),
                paddingRight: theme.spacing(0),
            },
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
                [theme.breakpoints.up('xs')]: {
                    marginRight: theme.spacing(0.75),
                },
                [theme.breakpoints.up('xl')]: {
                    marginRight: theme.spacing(1.5),
                },
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledButton = styled((props: any) => <Button {...props} />)(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({theme, bordercolor}) => ({
        lineHeight: '130%',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.8em',
        textTransform: 'none',
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        width: 'fit-content',
        backgroundColor: 'transparent',
        border: `2px solid ${bordercolor}`,
        borderRadius: 67,
        color: 'var(--navigation-selected-color)',
        '&:hover': {
            backgroundColor: 'var(--navigation-selected-color)',
            color: 'var(--navigation-selected-icon-color)',
        },
    })
);

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledGridItem = styled((props: any) => <Grid item {...props} />)(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({theme}) => ({
        [theme.breakpoints.up('xs')]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: theme.spacing(4),
        },
        width: 'fit-content',
    })
);

function OnboardingMenu(props: IOnboardingMenu) {
    const location = useLocation();
    const {titleOnclick, title, options, showProfilePicture, borderColor, menuOptions, icon, setIsHover} = props;
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const openMenu = Boolean(anchorMenu);
    const handleClick = (event: any) => {
        if (titleOnclick) {
            titleOnclick();
        }
        setAnchorEl(event?.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickMenu = (event: any) => {
        setAnchorMenu(event?.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorMenu(null);
    };
    let showProfilePictureComponent;
    if (showProfilePicture === true) {
        showProfilePictureComponent = (
            <inv-grid-row style={{'--inv-grid-gutter-x': '0.8em', alignItems: 'center'}}>
                <inv-grid-col style={{height: '1.6em'}} class="col-auto">
                    <WCIcons name="help-circle" />
                </inv-grid-col>
                <inv-grid-col style={{height: '1.6em'}} class="col-auto">
                    <img
                        style={{
                            height: '1.6em',
                            width: '1.6em',
                        }}
                        alt="profile"
                        src="/assets/images/default-profile-picture.png"
                    />
                </inv-grid-col>
            </inv-grid-row>
        );
    } else if (downMobile) {
        showProfilePictureComponent = (
            <Grid container columnSpacing={theme.spacing(2.5)}>
                {location.pathname.includes('/plan') && (
                    <Grid
                        item
                        onClick={(event: any) => handleClickMenu(event)}
                        sx={{
                            height: '1.2em',
                            cursor: 'pointer',
                        }}
                    >
                        <WCIcons
                            name="settings"
                            width="1.2em"
                            height="1.2em"
                            color="var(--navigation-selected-color)"
                        />
                    </Grid>
                )}
                {location.pathname !== '/propuesta' && location.pathname !== '/planes-asesoramiento' ? (
                    <StyledButton
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        // @ts-ignore
                        id="demo-customized-button"
                        aria-controls="demo-customized-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={(event: any) => handleClick(event)}
                        bordercolor={borderColor ?? 'var(--navigation-selected-color)'}
                        startIcon={icon}
                        sx={{
                            '.MuiButton-startIcon': {
                                height: '1.2em',
                            },
                        }}
                    >
                        {title}
                    </StyledButton>
                ) : (
                    <Grid
                        item
                        onClick={(event: any) => handleClick(event)}
                        sx={{
                            height: '1.2em',
                            cursor: 'pointer',
                        }}
                    >
                        <WCIcons
                            name="single-circle"
                            width="1.2em"
                            height="1.2em"
                            color="var(--navigation-selected-color)"
                        />
                    </Grid>
                )}
            </Grid>
        );
    } else {
        showProfilePictureComponent = (
            <button
                style={{
                    background: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                    fontSize: title === 'Login' ? '0.7em' : '0.8em',
                    fontWeight: 600,
                    fontFamily: 'inherit',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '3.4em',
                    padding: title === 'Login' ? '0.4em 0.8em' : '0.8em 1.2em',
                    border:
                        // eslint-disable-next-line no-nested-ternary
                        title === 'Login'
                            ? '0.1em solid var(--navigation-selected-color)'
                            : borderColor
                            ? `0.1em solid ${borderColor}`
                            : 'none',
                }}
                type="button"
                onMouseEnter={() => {
                    if (typeof setIsHover === 'function') {
                        setIsHover(true);
                    }
                }}
                onMouseLeave={() => {
                    if (typeof setIsHover === 'function') {
                        setIsHover(false);
                    }
                }}
                onClick={(event: any) => handleClick(event)}
            >
                {icon}
                {title}
            </button>
        );
    }
    return (
        <Box sx={{width: '100%', color: 'var(--navigation-selected-color)'}}>
            {showProfilePictureComponent}
            {options && (
                <StyledMenu
                    // @ts-ignore
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <Grid container direction="column" alignItems="flex-end">
                        {options?.map((option) => (
                            <StyledGridItem key={option.title}>
                                <StyledButton
                                    onMouseEnter={() => option.setIsHover(true)}
                                    onMouseLeave={() => option.setIsHover(false)}
                                    // @ts-ignore
                                    id="demo-customized-button"
                                    sx={{
                                        backgroundColor: 'var(--navigation-selected-icon-color)',
                                    }}
                                    aria-controls="demo-customized-menu"
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={() => {
                                        if (option.onClick) {
                                            option.onClick();
                                            handleClose();
                                        } else {
                                            handleClose();
                                        }
                                    }}
                                    // @ts-ignore
                                    startIcon={option.startIcon}
                                >
                                    {option.title}
                                </StyledButton>
                            </StyledGridItem>
                        ))}
                    </Grid>
                </StyledMenu>
            )}
            {menuOptions && (
                <StyledMenu
                    // @ts-ignore
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorMenu}
                    open={openMenu}
                    onClose={handleCloseMenu}
                >
                    <Grid container direction="column" alignItems="flex-end">
                        {menuOptions?.map((option) => (
                            <StyledGridItem key={option.title}>
                                <StyledButton
                                    // @ts-ignore
                                    id="demo-customized-button"
                                    sx={{
                                        backgroundColor: 'var(--navigation-selected-icon-color)',
                                    }}
                                    aria-controls="demo-customized-menu"
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={() => {
                                        if (option.onClick) {
                                            option.onClick();
                                            handleCloseMenu();
                                        } else {
                                            handleCloseMenu();
                                        }
                                    }}
                                    // @ts-ignore
                                    startIcon={<option.startIcon />}
                                >
                                    {option.title}
                                </StyledButton>
                            </StyledGridItem>
                        ))}
                    </Grid>
                </StyledMenu>
            )}
        </Box>
    );
}
OnboardingMenu.defaultProps = {
    options: undefined,
    menuOptions: undefined,
    showProfilePicture: undefined,
    borderColor: undefined,
    titleOnclick: undefined,
    icon: undefined,
    setIsHover: undefined,
};

export default OnboardingMenu;
