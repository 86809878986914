import * as React from 'react';
import NumberFormat from 'react-number-format';
import withAndWithoutInveertCards from '../../styles/WithAndWithoutInveertCards.module.css';
import BarsChart from '../../components/chart/BarsChart';
import {tpl, tr} from '../../utils/functions';

interface IWithAndWithoutInveertCards {
    inputMoney: number;
    performanceMoney: number;
    monthlySaving: number;
    title: string;
    subtitle: string;
    isSelected?: boolean;
    accNetChartData: Array<any>;
}

function WithAndWithoutInveertCards(props: IWithAndWithoutInveertCards) {
    const {inputMoney, performanceMoney, title, isSelected, monthlySaving, subtitle, accNetChartData} = props;

    const [fontSize, setFontSize] = React.useState<null | number>(null);

    React.useEffect(() => {
        const chartFontSize = window.getComputedStyle(document.body).getPropertyValue('font-size');
        const fontSizeNumber = parseInt(chartFontSize, 10);
        setFontSize(fontSizeNumber);
    }, []);

    return (
        <inv-grid-row class={withAndWithoutInveertCards.container}>
            <inv-grid-col class="col-12">
                <div
                    style={{
                        borderRadius: '0.8em',
                        background: isSelected ? 'var(--color-secondary-05)' : 'var(--text-color-01)',
                        padding: '1.6em 1.2em',
                    }}
                >
                    <inv-grid-row class={withAndWithoutInveertCards.title}>
                        <inv-grid-col class="col-auto">
                            <inv-text-l
                                style={
                                    {
                                        '--inv-text-l-font-weight': 600,
                                        '--inv-text-l-color': 'var(--color-primary-100)',
                                    } as React.CSSProperties
                                }
                            >
                                {title}
                            </inv-text-l>
                        </inv-grid-col>
                        {isSelected && (
                            <inv-grid-col
                                class="col-auto"
                                style={{height: '1em', display: 'flex', alignItems: 'center', marginLeft: '0.8em'}}
                            >
                                <img
                                    alt="logo"
                                    style={{
                                        content: 'var(--logo-propuesta-resultado)',
                                        maxWidth: '6.5em',
                                        height: 'auto',
                                    }}
                                />
                            </inv-grid-col>
                        )}
                    </inv-grid-row>
                    <inv-grid-row class={withAndWithoutInveertCards.subtitle}>
                        <inv-grid-col>
                            <inv-text-s style={{'--inv-text-s-font-weight': 600}}>{subtitle}</inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={withAndWithoutInveertCards.firstSubtitle}>
                        <inv-grid-col>
                            <inv-text-xs
                                style={{
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                    '--inv-text-xs-font-weight': 500,
                                }}
                            >
                                {isSelected ? tr('Debes invertir') : tr('Debes ahorrar')}
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row>
                        <inv-grid-col>
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 600,
                                }}
                            >
                                <NumberFormat
                                    value={monthlySaving}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            </inv-text-m>
                            <inv-text-s
                                class={withAndWithoutInveertCards.bigQuantity}
                                style={{
                                    '--inv-text-s-font-weight': 600,
                                }}
                            >
                                &nbsp;
                                {tpl(tr('{currency}/mes'), {currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€'})}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={withAndWithoutInveertCards.lastRow}>
                        <inv-grid-col class="col-auto" style={{marginRight: '0.8em'}}>
                            <inv-grid-row>
                                <inv-grid-col>
                                    <inv-text-xxs
                                        style={{
                                            '--inv-text-xxs-color': 'var(--text-color-50)',
                                            '--inv-text-xxs-font-weight': 500,
                                        }}
                                    >
                                        {isSelected ? tr('Total que aportarías') : tr('Total que ahorrarías')}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row>
                                <inv-grid-col>
                                    <inv-text-s
                                        style={{
                                            '--inv-text-s-font-weight': 600,
                                            '--inv-text-s-color': 'var(--text-color-50)',
                                        }}
                                    >
                                        <NumberFormat
                                            value={inputMoney}
                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                            decimalScale={0}
                                            allowNegative={false}
                                            displayType="text"
                                        />
                                    </inv-text-s>
                                    <inv-text-xxs
                                        class={withAndWithoutInveertCards.smallQuantity}
                                        style={{
                                            '--inv-text-xxs-font-weight': 700,
                                            '--inv-text-xxs-color': 'var(--text-color-30)',
                                        }}
                                    >
                                        &nbsp;{process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                        <inv-grid-col class="col-auto">
                            <inv-grid-row>
                                <inv-grid-col>
                                    <inv-text-xxs
                                        style={{
                                            '--inv-text-xxs-color': 'var(--text-color-50)',
                                            '--inv-text-xxs-font-weight': 500,
                                        }}
                                    >
                                        Rentabilidad estimada
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row>
                                <inv-grid-col>
                                    <inv-text-s
                                        style={{
                                            '--inv-text-s-font-weight': 600,
                                            '--inv-text-s-color': 'var(--color-secondary-100)',
                                        }}
                                    >
                                        <NumberFormat
                                            value={performanceMoney}
                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                            decimalScale={0}
                                            allowNegative={false}
                                            displayType="text"
                                        />
                                    </inv-text-s>
                                    <inv-text-xxs
                                        class={withAndWithoutInveertCards.smallQuantity}
                                        style={{
                                            '--inv-text-xxs-font-weight': 700,
                                            '--inv-text-xxs-color': 'var(--color-secondary-100)',
                                        }}
                                    >
                                        &nbsp;{process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={withAndWithoutInveertCards.chartContainer}>
                        <inv-grid-col class="col-12">
                            <BarsChart
                                highlightPrevious
                                hideArrow
                                hideXAxis
                                hideStrokeDashArray
                                height={8 * (fontSize ?? 0)}
                                hideYAxis
                                data={accNetChartData}
                                barColors={{
                                    bar1: 'var(--text-color-30)',
                                    bar2: 'var(--color-secondary-100)',
                                }}
                                name="performace"
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
            </inv-grid-col>
        </inv-grid-row>
    );
}

export default WithAndWithoutInveertCards;

WithAndWithoutInveertCards.defaultProps = {
    isSelected: false,
};
