import * as React from 'react';
import NumberFormat from 'react-number-format';
import WCIcons from '../../elements/icons/WCIcons';
import AdviceAlert from '../../elements/alert/AdviceAlert';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/tooltip/tooltip';
import styles from './propuesta-taxation-dialog.module.css';
import PropuestaTextStructureComponent from './propuesta-text-structure.component';
import {tr} from '../../utils/functions';
import {useIsMobile} from '../../utils/hooks';

interface ITaxationDialogContent {
    closeDialog: () => void;
    taxation: number;
    saving: number;
    performance: number;
    totalCost: number;
}
export function TaxationDialogContent(props: ITaxationDialogContent) {
    const {closeDialog, taxation, saving, performance, totalCost} = props;
    const isMobile = useIsMobile();
    return (
        <div className={styles.container}>
            <inv-grid-row class={styles.closeRow}>
                <inv-grid-col class={`col-auto ${styles.closeContainer}`}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div className={styles.closeIconContainer} onClick={closeDialog}>
                        <WCIcons
                            name="close-filled"
                            width={isMobile ? '1.2em' : '2em'}
                            height={isMobile ? '1.2em' : '2em'}
                            hoverColor="inherit"
                        />
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <div className={styles.titleContainer}>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>{tr('También estimamos los impuestos')}</inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.subtitleContainer}>
                    <inv-grid-col class="col-10">
                        <inv-text-m
                            style={{
                                '--inv-text-m-color': 'var(--text-color-50)',
                            }}
                        >
                            {tr(
                                'Todo está calculado para que, al rescatar tu inversión, tuvieses la cantidad necesaria para cada meta.'
                            )}
                        </inv-text-m>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <inv-grid-row class={styles.taxationOperationContainer}>
                <inv-grid-col
                    class="col-auto"
                    style={{
                        '--inv-text-l-color': 'var(--text-color-30)',
                        '--inv-text-xs-color': 'var(--text-color-30)',
                    }}
                >
                    <PropuestaTextStructureComponent
                        label={tr('Total que aportas')}
                        unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                    >
                        <NumberFormat
                            value={saving}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={0}
                            displayType="text"
                        />
                    </PropuestaTextStructureComponent>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--text-color-30)',
                        }}
                    >
                        +
                    </inv-text-l>
                </inv-grid-col>
                <inv-grid-col
                    class="col-auto"
                    style={{
                        '--inv-text-l-color': 'var(--color-secondary-100)',
                        '--inv-text-xs-color': 'var(--color-secondary-100)',
                    }}
                >
                    <PropuestaTextStructureComponent
                        label={tr('Rentabilidad bruta')}
                        unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                    >
                        <NumberFormat
                            value={performance}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={0}
                            displayType="text"
                        />
                    </PropuestaTextStructureComponent>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--text-color-30)',
                        }}
                    >
                        -
                    </inv-text-l>
                </inv-grid-col>
                <inv-grid-col
                    class="col-auto"
                    style={{
                        '--inv-text-l-color': '#FF5F29',
                        '--inv-text-xs-color': '#FF5F29',
                    }}
                >
                    <PropuestaTextStructureComponent
                        label={tr('Proyección impuestos')}
                        unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        labelIcon={
                            !isMobile && (
                                <inv-tooltip>
                                    <div style={{width: '1em', height: '1em'}} slot="tooltiped-content">
                                        <WCIcons color="var(--text-color-50)" height="1em" width="1em" name="info" />
                                    </div>
                                    <div slot="tooltip-content" style={{width: '10em', margin: 0}}>
                                        <inv-grid-row>
                                            <inv-grid-col class="col-auto">
                                                <WCIcons
                                                    height="1em"
                                                    width="1em"
                                                    name="message-info"
                                                    color="var(--color-primary-100)"
                                                />
                                            </inv-grid-col>
                                            <inv-grid-col class="col-9" style={{lineHeight: 0}}>
                                                <inv-text-xs
                                                    style={{
                                                        '--inv-text-xs-font-size': '0.8em',
                                                        '--inv-text-xs-font-weight': 500,
                                                        '--inv-text-xs-color': 'var(--text-color-50)',
                                                    }}
                                                >
                                                    {tr(
                                                        'Se trata de un cálculo simple y estimativo, que no pretende ser exacto.'
                                                    )}
                                                </inv-text-xs>
                                            </inv-grid-col>
                                        </inv-grid-row>
                                    </div>
                                </inv-tooltip>
                            )
                        }
                    >
                        <NumberFormat
                            value={taxation}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={0}
                            displayType="text"
                        />
                    </PropuestaTextStructureComponent>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--text-color-30)',
                        }}
                    >
                        =
                    </inv-text-l>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <PropuestaTextStructureComponent
                        label={tr('Total que necesitas')}
                        unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                    >
                        <NumberFormat
                            value={totalCost}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={0}
                            displayType="text"
                        />
                    </PropuestaTextStructureComponent>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.adviceRow}>
                <inv-grid-col class="col-12 col-sm-8">
                    <AdviceAlert
                        icon={<WCIcons name="info" color="#F5931F" />}
                        text={tr(
                            'Esto no puede considerarse asesoramiento fiscal. Se trata de una estimación, que asume numerosas hipótesis.'
                        )}
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

export default TaxationDialogContent;
