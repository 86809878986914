import * as React from 'react';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/input/input-int';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {useFormik} from 'formik';
import styles from './plan-contribution-extra-dialog.module.css';
import CloseDialog from '../../../components/close-dialog/close-dialog.component';

/**
 * @typedef {Object} Props
 * @property {function} handleCloseDialogClick - Función que se ejecuta al hacer click en el icono
 */

/**
 * Componente que muestra un diálogo para realizar una aportación extra
 * @return {JSX.Element}
 * @param {Props} props
 */
function PlanContributionExtraDialog({handleCloseDialogClick}) {
    const validationSchema = yup.object({
        amount: yup
            .number()
            .required('Introduce una cantidad numérica')
            .min(1, 'El coste tiene que ser mayor que cero')
            .nullable(),
    });
    const formik = useFormik({
        initialValues: {
            amount: null,
        },
        validationSchema,
        validateOnBlur: true,
        onSubmit: () => {},
    });
    return (
        <>
            <CloseDialog closeDialog={handleCloseDialogClick} />
            <div className={styles.main}>
                <div className={styles.titleContainer}>
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-text-l>Aportación extra</inv-text-l>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-text-m>¿Cómo vas hacer la nueva aportación?</inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <div className={styles.radioContainer}>
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <form>
                                <input type="radio" id="bank" name="contribution_type" value="bank" />
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor="bank">
                                    <inv-text-xxs>Realizando una transferencia bancaria</inv-text-xxs>
                                </label>
                                <br />
                                <input type="radio" id="investment" name="contribution_type" value="investment" />
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor="investment">
                                    <inv-text-xxs>Traspasando otro producto de inversión</inv-text-xxs>
                                </label>
                                <br />
                            </form>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-sm-5">
                        <inv-input-int
                            class="input input--primary"
                            value={formik.values.amount}
                            name="amount"
                            error={formik.errors.amount}
                            label="Importe"
                            placeholder="Ej. 1000"
                            suffix={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        />
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row
                    style={{
                        justifyContent: 'center',
                        '--inv-grid-gutter-x': '0.8em',
                    }}
                >
                    <inv-grid-col class="col-auto">
                        <inv-button onClick={() => null} class="outlined button button--primary-outlined">
                            <div style={{width: '2.5em'}} />
                            Cancelar
                            <div style={{width: '2.5em'}} />
                        </inv-button>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-button class="button button--primary-contained" onClick={() => null}>
                            <div style={{width: '2.8em'}} />
                            Aportar
                            <div style={{width: '2.8em'}} />
                        </inv-button>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
        </>
    );
}

PlanContributionExtraDialog.propTypes = {
    handleCloseDialogClick: PropTypes.func.isRequired,
};

export default PlanContributionExtraDialog;
