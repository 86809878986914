import {fetchApi} from '../../utils/functions';

let cachedCompanyInfo = null;

export const getCompanyInfo = async () => {
    try {
        if (cachedCompanyInfo) {
            // If the data is already cached, return it immediately
            return cachedCompanyInfo;
        }

        const response = await fetchApi('setting/v1/settings?include=Company', 'get', {});

        if (response?.data?.attributes && response?.included[0]?.attributes) {
            cachedCompanyInfo = {
                ...response?.data?.attributes,
                ...response?.included[0]?.attributes,
            };
        } else {
            cachedCompanyInfo = null;
        }

        return cachedCompanyInfo;
    } catch (e) {
        throw new Error(e);
    }
};

export default getCompanyInfo;
