import React from 'react';

interface IWCIcons {
    name: string;
    width?: string;
    height?: string;
    viewBox?: string;
    color?: string;
    hoverColor?: string;
    src?: string;
    style?: any;
}

function WCIcons(props: IWCIcons) {
    const {name, style, src, width, height, viewBox, color, hoverColor} = props;
    const [isHover, setIsHover] = React.useState(false);
    // @ts-ignore
    // eslint-disable-next-line import/extensions
    React.useEffect(() => import('@inveert/ui-kit/src/icon/icon.js'), []);
    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
            style={{
                height: 'fit-content',
                cursor: hoverColor ? 'pointer' : 'initial',
                ...style,
            }}
            onMouseEnter={() => setIsHover(true)}
            /* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */
            onMouseOut={() => setIsHover(false)}
        >
            <inv-icon
                src={src ?? process.env.REACT_APP_ICONS_DIR}
                color={isHover ? hoverColor ?? color : color}
                name={name}
                width={width}
                height={height}
                viewBox={viewBox}
            />
        </div>
    );
}

WCIcons.defaultProps = {
    width: undefined,
    height: undefined,
    viewBox: undefined,
    color: undefined,
    hoverColor: undefined,
    src: undefined,
    style: undefined,
};

export default WCIcons;
