import * as React from 'react';
import propTypes from 'prop-types';
import WCIcons from '../../elements/icons/WCIcons';
import styles from './plan-alert.module.css';

/**
 * @typedef {Object} PrimaryAction
 * @property {string} action - acción que se ejecuta al hacer click en el botón
 * @property {string} value - valor del botón
 *
 *
 * Componente que muestra un alerta en el plan
 * @return {JSX.Element}
 * @param {string} description - Descripción de la alerta
 * @param {PrimaryAction} primaryAction - Acción principal
 * @param {Function} handleCloseAlertClick
 * @param {string} id
 */
function PlanAlert({primaryAction, handleCloseAlertClick, id, description}) {
    return (
        <div id={id} className={styles.alertContainer}>
            <inv-grid-row class={styles.alert}>
                <inv-grid-col class="col-auto">
                    <inv-grid-row
                        style={{
                            alignItems: 'center',
                            '--inv-grid-gutter-x': '1.2em',
                        }}
                    >
                        <inv-grid-col class="col-auto">
                            <inv-text-xxs
                                style={{
                                    '--inv-text-xxs-color': 'var(--text-color-95)',
                                }}
                            >
                                {description}
                            </inv-text-xxs>
                        </inv-grid-col>
                        <inv-grid-col class="col-auto">
                            <inv-button
                                class={styles.alertButton}
                                onClick={() => {
                                    // eslint-disable-next-line no-undef
                                    if (primaryAction.action === 'Url' && window) {
                                        // eslint-disable-next-line no-undef
                                        window.open(primaryAction.value, '_blank');
                                    }
                                }}
                            >
                                {primaryAction?.value}
                            </inv-button>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
                <inv-grid-col class="col-auto" style={{height: '1.2em'}}>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        onClick={() => handleCloseAlertClick(id)}
                        type="button"
                        style={{
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        <WCIcons hoverColor="inherit" name="close" width="1.2em" height="1.2em" />
                    </button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

PlanAlert.propTypes = {
    primaryAction: propTypes.shape({action: propTypes.string, value: propTypes.string}).isRequired,
    handleCloseAlertClick: propTypes.func.isRequired,
    id: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
};

export default PlanAlert;
