import * as React from 'react';
import propTypes from 'prop-types';
import CloseDialog from '../../../../components/close-dialog/close-dialog.component';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xxs';
import PlanContributionPayPendingInit from './plan-contribution-pay-pending-init.component';
import PlanContributionPayPendingResult from './plan-contribution-pay-pending-result.component';
import PlanContributionPayPendingNew from './plan-contribution-pay-pending-new.component';

/**
 * @typedef {Object} Props
 * @property {function} setDialogWidth - Función que se ejecuta al hacer click en el icono
 * @property {function} handleCloseDialogClick - Función que se ejecuta al hacer click en el icono
 */

/**
 * Componente que muestra un diálogo para ponerte al dia con las aportaciones
 * @return {JSX.Element}
 * @param {Props} props
 */
function PlanContributionPayPendingDialog({
    handleCloseDialogClick,
    setDialogWidth,
}) {
    const [step, setStep] = React.useState(0);

    React.useEffect(() => {
        if (step === 2) {
            setDialogWidth('lg');
        } else {
            setDialogWidth('md');
        }
    }, [step]);
    const handleNextClick = () => {
        setStep(step + 1);
    };
    const handleBack = () => {
        if (step > 0) {
            setStep(step - 1);
        } else {
            handleCloseDialogClick();
        }
    };
    const currentContent = () => {
        switch (step) {
            case 0:
                return (
                    <PlanContributionPayPendingInit
                        pendingAmount={6543}
                        handleBackClick={handleNextClick}
                        handleNextClick={handleNextClick}
                    />
                );
            case 1:
                return (
                    <PlanContributionPayPendingResult
                        handleNextClick={handleNextClick}
                        handleBackClick={handleBack}
                    />
                );
            case 2:
                return (
                    <PlanContributionPayPendingNew
                        handleNextClick={handleNextClick}
                        handleBackClick={handleBack}
                        handleCloseClick={handleCloseDialogClick}
                    />
                );
            default:
                return (
                    <PlanContributionPayPendingInit
                        pendingAmount={6543}
                        handleBackClick={handleBack}
                        handleNextClick={handleNextClick}
                    />
                );
        }
    };
    return (
        <>
            <CloseDialog closeDialog={handleCloseDialogClick} />
            {currentContent()}
        </>
    );
}

PlanContributionPayPendingDialog.propTypes = {
    handleCloseDialogClick: propTypes.func.isRequired,
    setDialogWidth: propTypes.func.isRequired,
};

export default PlanContributionPayPendingDialog;
