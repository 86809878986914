import {ObjectiveType} from '../../modules/goal/goal.type';

const routeByGoal = {
    RETIREMENT: '/meta/jubilacion',
    EDUCATION: '/meta/educacion',
    REAL_STATE: '/meta/vivienda',
    SAFETY_NET: '/meta/emergencia',
    HERITAGE: '/meta/herencia',
    CUSTOM: '/meta/personalizada',
};

interface ISelectorGoalsEvents {
    setLoading: (loading: boolean) => void;
    isRetirementDisabled: boolean;
    isSafetyNetDisabled: boolean;
    setGoalType?: (goalType: string) => void;
    router: any;
    goalType: ObjectiveType;
    isDialog?: boolean;
}

// eslint-disable-next-line consistent-return
export const onClickSelector = (props: ISelectorGoalsEvents) => {
    const {goalType, setGoalType, setLoading, isRetirementDisabled, isSafetyNetDisabled, router, isDialog} = props;
    if (
        ((goalType === 'RETIREMENT' || goalType === 'RETIREMENT_ONLY_FUND') && isRetirementDisabled === true) ||
        (goalType === 'SAFETY_NET' && isSafetyNetDisabled === true)
    ) {
        return null;
    }
    if (!setGoalType && !isDialog) {
        // eslint-disable-next-line no-console
        setLoading(true);
        // @ts-ignore
        try {
            // @ts-ignore
            router(routeByGoal[goalType]);
        } catch {
            setLoading(false);
        }
    } else if (setGoalType) {
        setGoalType(goalType);
    }
};

export default onClickSelector;
