import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from '@mui/material';
// @ts-ignore
import {add, format, isValid, parseISO, startOfMonth} from 'date-fns';
import theme from '../../styles/theme';
import {EducacionFormComponent} from '../../modules/goal/educacion/educacion-form.component';
import {educationFormQuestionTitle, educationQuestionNameOrder, lastQuestionIndex} from '../../utils/componentData';
import {selectObjectives} from '../../modules/goal/goal.slice';
import PageLoading from '../../elements/loading/PageLoading';
import {EducacionFormik} from '../../modules/goal/educacion/educacion.script';
import {GoalFooterMeta} from '../../components/meta/GoalFooterMeta';
import {addAlert, selectApp} from '../../modules/app/app.slice';
import {setIsAuthDialogOpen} from '../../modules/auth/auth.slice';
import {getConfig, getMinRecommendationPeriod} from '../../modules/propuesta/propuesta.service';
import {getObjectiveCostApi} from '../../modules/goal/goal.service';
import {OutputType} from '../../modules/goal/goal.type';
import {getDateFnsLocale, getErrorMessage, tpl, tr} from '../../utils/functions';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import educacion from '../../styles/Educacion.module.css';
import {selectUser} from '../../modules/user/user.slice';
import {useGoalsDate} from '../../utils/hooks';

function Educacion(props: any) {
    const dispatch = useDispatch();
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {birthday} = useSelector(selectUser);
    const {locale} = useSelector(selectApp);
    const {goBack, finish, closeDialog} = props;
    const [loading, setLoading] = React.useState(true);
    // eslint-disable-next-line react/destructuring-assignment
    const storage = globalThis?.sessionStorage;
    const objectivesData = useSelector(selectObjectives);
    const [questionIndex, setQuestionIndex] = React.useState(0);
    const [inflation, setInflation] = React.useState(2);
    const [deathAge, setDeathAge] = React.useState(0);
    const [totalCost, setTotalCost] = React.useState(0);

    const [minRecommendationPeriod, setMinRecommendationPeriod] = React.useState(1);

    const [minDate, maxDate] = useGoalsDate(deathAge, birthday, minRecommendationPeriod);
    const formik = EducacionFormik({
        objectivesData,
        finish,
        closeDialog,
        minDate,
        maxDate,
    });

    React.useEffect(() => {
        const logo = document.getElementById('header-onboarding');
        if (logo) {
            logo.scrollIntoView({behavior: 'smooth'});
        }
    }, [questionIndex]);
    React.useEffect(() => {
        setQuestionIndex(
            storage?.getItem('currentPath') === '/meta/fin' && !downMobile ? lastQuestionIndex.education : 0
        );
    }, [downMobile, storage]);
    React.useEffect(() => {
        setLoading(true);
        const resMinDate = getMinRecommendationPeriod();
        const resConfig = getConfig();
        Promise.all([resMinDate, resConfig])
            .then(([minPeriod, config]) => {
                const configInflation = config.inflation;
                if (typeof configInflation === 'number') {
                    setInflation(configInflation);
                }
                if (typeof config.death_age === 'number') {
                    setDeathAge(config.death_age);
                }
                setMinRecommendationPeriod(minPeriod);
                dispatch(setIsAuthDialogOpen(false));
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
        return () => {
            setLoading(false);
            setInflation(2);
            setDeathAge(0);
        };
    }, []);
    const goalName = tpl(tr(`Educación de {recipient}`), {recipient: formik?.values?.recipient ?? ''});
    React.useEffect(() => {
        if (questionIndex === 3) {
            setLoading(true);
            const parsedInitDate = isValid(formik.values.init_date)
                ? formik.values.init_date
                : parseISO(formik.values.init_date);
            const dateInit = startOfMonth(parsedInitDate);
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const end_date = add(dateInit, {
                years: formik.values.duration - 1,
            });
            const educationObjective = {
                amount: formik.values.amount,
                type: 'EDUCATION' as const,
                output_type: formik.values.duration < 2 ? OutputType.UNIQUE : OutputType.ANNUAL,
                protection_type: formik.values.duration < 2 ? ('CAPITAL' as const) : ('SHORT_TERM' as const),
                name: goalName,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: end_date ? format(end_date, 'yyyy-MM-dd') : '',
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            getObjectiveCostApi(educationObjective)
                .then((res) => {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const {withdrawal, yearly_withdrawals} = res.attributes;

                    const arrayToReduce = yearly_withdrawals ?? withdrawal;
                    let educationGoalCost = 0;
                    if (arrayToReduce) {
                        educationGoalCost = arrayToReduce.reduce((acc: any, current: any) => acc + current, 0);
                    }
                    setTotalCost(educationGoalCost);

                    setLoading(false);
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                });
        }
        return () => {
            setLoading(false);
        };
    }, [questionIndex]);
    return (
        <div className={educacion.container}>
            <PageLoading open={formik.isSubmitting || loading} />
            <inv-grid-row>
                <inv-grid-col class="col" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--education-color)',
                            '--inv-text-l-font-weight': 600,
                        }}
                    >
                        {formik.values.recipient && questionIndex > 0 ? goalName : tr('Educación')}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            {educationFormQuestionTitle(
                questionIndex,
                formik.values.duration,
                formik.values.amount,
                format(formik.values.init_date ? new Date(formik.values.init_date) : 0, 'MMMM yyyy', {
                    locale: getDateFnsLocale(locale),
                }),
                totalCost
            )}
            <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
                <inv-grid-col class="col">
                    <EducacionFormComponent
                        formik={formik}
                        questionIndex={questionIndex}
                        inflation={inflation}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col">
                    <GoalFooterMeta
                        hideDots={questionIndex >= educationQuestionNameOrder.length}
                        goalType="EDUCATION"
                        questionNameOrder={educationQuestionNameOrder}
                        questionIndex={questionIndex}
                        goBack={goBack}
                        setQuestionIndex={setQuestionIndex}
                        formik={formik}
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
export default Educacion;
