import * as React from 'react';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/button/button';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {SocialSignButton} from '../../elements/buttons/SocialSignButton';
import {deleteUserService, getUserDataApi, getUserDataService} from '../../modules/user/user.service';
import {addAlert} from '../../modules/app/app.slice';
import PageLoading from '../../elements/loading/PageLoading';
import {getErrorMessage, tr, tpl, getApiUrl} from '../../utils/functions';
import styles from './delete-user.module.css';
import {LoginFormik} from '../../modules/auth/auth.script';
import TextInput from '../../elements/input/TextInput';
import {handleMessageSocialLogin} from '../../utils/components.functions';

function DeleteUser() {
    const dispatch = useDispatch();
    const router = useNavigate();
    const apiUrl = getApiUrl();
    const [registerWithMail, setRegisterWithMail] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState(null);
    const formik = LoginFormik({
        dispatch,
        reloadPage: true,
    });
    React.useEffect(() => {
        setLoading(true);
        const storage = globalThis?.sessionStorage;
        const token = storage.getItem('token');
        if (!token) {
            dispatch(
                addAlert({
                    message: tr('No existe token de sesión'),
                    isError: true,
                    isOpen: true,
                })
            );
            router('/meta');
        } else {
            getUserDataService(token)
                .then((res) => {
                    if (res?.attributes?.user_id) {
                        getUserDataApi(res.attributes.user_id)
                            .then((userDataRes) => {
                                setUserEmail(userDataRes.attributes.email ?? null);
                                setLoading(false);
                            })
                            .catch(async (e) => {
                                const message = await getErrorMessage(e);
                                dispatch(
                                    addAlert({
                                        message,
                                        isError: true,
                                        isOpen: true,
                                    })
                                );
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                });
        }
    }, []);
    const handleMessage = (event: any) => handleMessageSocialLogin(event, dispatch, setLoading, router, true);
    React.useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            // @ts-ignore
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return (
        <>
            <PageLoading open={loading || formik.isSubmitting} />
            {userEmail ? (
                <div className={styles.container}>
                    <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-xxxl
                                style={{
                                    '--inv-text-xxxl-color': 'var(--color-tertiary)',
                                    '--inv-text-xxxl-font-weight': 600,
                                }}
                            >
                                Confirmación
                            </inv-text-xxxl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-xl
                                style={{
                                    '--inv-text-xl-color': 'var(--text-color-50)',
                                    '--inv-text-xl-font-weight': 600,
                                }}
                            >
                                Confirmas que deseas eliminar al <br /> {`Usuario: ${userEmail ?? 'no existe'}`}
                            </inv-text-xl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'space-around'}}>
                        <inv-grid-col class="col-5">
                            <inv-button
                                class="button button--error-contained"
                                style={{
                                    '--inv-button-font-size': '1.2em',
                                }}
                                onClick={() => {
                                    setLoading(true);
                                    deleteUserService()
                                        .then(() => {
                                            const storage = globalThis?.sessionStorage;

                                            storage.clear();
                                            dispatch({type: 'reset'});
                                            dispatch(
                                                addAlert({
                                                    message: tpl(tr(`Usuario {userEmail} eliminado correctamente`), {
                                                        userEmail,
                                                    }),
                                                    isError: true,
                                                    isOpen: true,
                                                })
                                            );
                                            router('/meta');
                                        })
                                        .catch(async (e) => {
                                            const message = await getErrorMessage(e);
                                            dispatch(
                                                addAlert({
                                                    message,
                                                    isError: true,
                                                    isOpen: true,
                                                })
                                            );
                                            setLoading(false);
                                        });
                                }}
                            >
                                Si, eliminar usuario
                            </inv-button>
                        </inv-grid-col>
                        <inv-grid-col
                            class="col-5"
                            style={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                            }}
                        >
                            <inv-button
                                onClick={() => router('/meta')}
                                style={{
                                    '--inv-button-font-size': '1.2em',
                                    display: 'flex',
                                    width: 'fit-content',
                                }}
                                class="outlined button button--primary-outlined"
                            >
                                No, regresar
                            </inv-button>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
            ) : (
                <div className={styles.container}>
                    <PageLoading open={loading} />
                    <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-xxxl
                                style={{
                                    '--inv-text-xxxl-color': 'var(--color-tertiary)',
                                    '--inv-text-xxxl-font-weight': 600,
                                }}
                            >
                                Elimina tu cuenta
                            </inv-text-xxxl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-7">
                            <inv-text-xl
                                style={{
                                    '--inv-text-xl-color': 'var(--text-color-50)',
                                    '--inv-text-xl-font-weight': 600,
                                }}
                            >
                                Primero tienes que loguearte con el usuario que quieres borrar
                            </inv-text-xl>
                        </inv-grid-col>
                    </inv-grid-row>
                    {registerWithMail ? (
                        <inv-grid-row style={{justifyContent: 'center'}}>
                            <inv-grid-col class="col-6">
                                <TextInput
                                    value={formik.values.email ?? ''}
                                    handleChange={formik.setFieldValue}
                                    formik={formik}
                                    name="email"
                                    label="Correo electrónico"
                                    // @ts-ignore
                                    error={formik.errors.email}
                                    placeholder="luis@gmail.com"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col-6">
                                <TextInput
                                    isPassword
                                    value={formik.values.password ?? ''}
                                    handleChange={formik.setFieldValue}
                                    formik={formik}
                                    name="password"
                                    label="Contraseña"
                                    // @ts-ignore
                                    error={formik.errors.password}
                                    placeholder="*******"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col-6" style={{marginTop: '1.2em'}}>
                                <inv-button
                                    onClick={() => formik.handleSubmit()}
                                    class="button button--primary-contained"
                                >
                                    Logueate con tu correo
                                </inv-button>
                            </inv-grid-col>
                            <inv-grid-col class="col-6">
                                <inv-button
                                    onClick={() => setRegisterWithMail(false)}
                                    class="button button--secondary-contained"
                                >
                                    Atrás
                                </inv-button>
                            </inv-grid-col>
                        </inv-grid-row>
                    ) : (
                        <inv-grid-row class={styles.social} style={{justifyContent: 'space-around'}}>
                            <inv-grid-col class="col-6">
                                <SocialSignButton
                                    href={`${apiUrl}/api/v2/oauth/v1/provider/google/request-token`}
                                    text="Inicia sesión con Google"
                                    provider="google"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col-6">
                                <SocialSignButton
                                    href={`${apiUrl}/api/v2/oauth/v1/provider/twitter/request-token`}
                                    text="Inicia sesión con Twitter"
                                    provider="twitter"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col-6">
                                <SocialSignButton
                                    href={`${apiUrl}/api/v2/oauth/v1/provider/facebook/request-token`}
                                    text="Inicia sesión con Facebook"
                                    provider="facebook"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col-6">
                                <SocialSignButton
                                    onClick={() => setRegisterWithMail(true)}
                                    text="Inicia sesión con tu correo"
                                    provider="email"
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                    )}
                </div>
            )}
        </>
    );
}

export default DeleteUser;
