import React from 'react';
import NumberFormat from 'react-number-format';
import {format, parse} from 'date-fns';
import {es} from 'date-fns/locale';
import styles from './AreasChartTooltip.module.css';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xs';
import {Capitalize} from '../../utils/functions';

interface AreasChartTooltipProps {
    active: boolean;
    payload: Array<any>;
    label: string;
    position: {x: number; y: number};
}

function AreasChartTooltip(props: AreasChartTooltipProps) {
    const {active, payload, label, position} = props;
    if (active && payload && payload.length) {
        return (
            position?.x &&
            position?.y && (
                <div className={styles.container}>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-m>
                                <NumberFormat
                                    value={payload[0]?.payload?.real ?? payload[0]?.payload?.area1}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    displayType="text"
                                    suffix={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                />
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-xs>
                                {Capitalize(
                                    format(parse(label, 'yyyy-MM', new Date()), 'MMM yyyy', {
                                        locale: es,
                                    })
                                )}
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
            )
        );
    }

    return null;
}

export default AreasChartTooltip;
