import {Grid, Link, useMediaQuery, Alert, Snackbar, SwipeableDrawer} from '@mui/material';
import * as React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import theme from '../../styles/theme';
import StepsFragments from '../../elements/fragments/StepsFragments';
import OnboardingMenu from '../../elements/menu/OnboardingMenu';
import {Capitalize, getErrorMessage, setIsAuthDialogOpenInStorage, tr} from '../../utils/functions';
import PageLoading from '../../elements/loading/PageLoading';
import {selectUser} from '../../modules/user/user.slice';
import Text from '../../styles/textStyles';
import {
    addAlert,
    closeRiskProfileDialog,
    openRiskProfileDialog,
    resetAlert,
    selectApp,
    setLocale,
} from '../../modules/app/app.slice';
import {selectAuth} from '../../modules/auth/auth.slice';
import WCIcons from '../../elements/icons/WCIcons';
import ContainerDialog from '../../elements/dialog/ContainerDialog';
import {AuthDialogComponent} from '../../modules/auth/auth-dialog.component';
import {PersonalDataDialogContent} from '../../modules/user/user-dialog.component';
import {RiskProfileDialogContent} from '../../modules/risk/risk-dialog.component';
import {deleteSession} from '../../modules/auth/auth.service';
import {getCompanyInfo} from '../../modules/app/app.service';

export function MobileHeaderNavigation(props: any) {
    const scrollToTopRef = React.useRef(null);
    const {toTopFlag} = useSelector(selectApp);
    const computedStyles = window.getComputedStyle(document.body);
    const storage = globalThis?.sessionStorage;
    let token = '';
    if (storage) {
        token = storage.getItem('token') ?? '';
    }
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const router = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const {alert, alertFlag, locale} = useSelector(selectApp);
    const {isAuthDialogOpen} = useSelector(selectAuth);
    const [alertObject, setAlertObject] = React.useState(alert);
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const {financialWealth, userIncome, objectives} = props;
    const showNavigation = !(
        location.pathname === '/' ||
        location.pathname === '/token' ||
        location.pathname === '/verificacion' ||
        location.pathname === '/restore-password' ||
        location.pathname === '/registro' ||
        location.pathname === '/login'
    );
    const [hasMifid, setHasMifid] = React.useState(false);
    const [isElHover, setIsElHover] = React.useState(false);
    const [isEl1Hover, setIsEl1Hover] = React.useState(false);
    const [isEl2Hover, setIsEl2Hover] = React.useState(false);
    const [isEl3Hover, setIsEl3Hover] = React.useState(false);
    const {birthday, riskProfile, riskProfileArray, userId} = useSelector(selectUser);
    const {showRiskProfileDialog} = useSelector(selectApp);
    const [isRiskProfileDialogOpen, setIsRiskProfileDialogOpen] = React.useState(false);
    const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [fetching, setFetching] = React.useState(false);
    const getHasMifid = async () => {
        setFetching(true);
        try {
            let mifid = false;
            const companyInfo = await getCompanyInfo();
            if (companyInfo) {
                mifid = companyInfo?.mifid;
            }
            setFetching(false);
            setHasMifid(mifid);
        } catch (e) {
            // @ts-ignore
            const message = await getErrorMessage(e);
            dispatch(
                addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                })
            );
            setFetching(false);
        }
    };
    React.useEffect(() => {
        getHasMifid().then();
    }, []);
    /*
  
    const changeTabName = (tabName: string) =>
    dispatch(changeTabNamePlan(tabName));
    const menuOptions = [
    {
      startIcon: FinancialPlanIcon,
      title: "Plan financiero",
      onClick: () => changeTabName("plan"),
    },
    {
      startIcon: GoalIcon,
      title: "Metas",
      onClick: () => changeTabName("goals"),
    },
    {
      startIcon: BenefitsIcon,
      title: "Beneficios",
      onClick: () => changeTabName("benefit"),
    },
    {
      startIcon: HelpIcon,
      title: "Ayuda",
      onClick: () => changeTabName("help"),
    },
  ];
   */
    const menuItems =
        location.pathname === '/propuesta'
            ? [
                  {
                      title: tr('Mis datos'),
                      startIcon: (
                          <WCIcons
                              name="profile-single"
                              width="32"
                              height="32"
                              color={isEl1Hover ? 'var(--color-primary-100)' : 'var(--accent-color-1)'}
                          />
                      ),
                      setIsHover: setIsEl1Hover,
                      onClick: () => setIsPersonalDataDialogOpen(true),
                  },
                  {
                      title: tr('Perfil inversor'),
                      startIcon: (
                          <WCIcons
                              name="financial-freedom"
                              width="32"
                              height="32"
                              color={isEl2Hover ? 'var(--color-primary-100)' : 'var(--accent-color-1)'}
                          />
                      ),
                      setIsHover: setIsEl2Hover,
                      onClick: () => setIsRiskProfileDialogOpen(true),
                  },
                  {
                      title: tr('Cerrar sesión'),
                      startIcon: (
                          <WCIcons
                              name="log-out"
                              width="32"
                              height="32"
                              color={isEl3Hover ? 'var(--color-primary-100)' : 'var(--accent-color-1)'}
                          />
                      ),
                      setIsHover: setIsEl3Hover,
                      onClick: () => {
                          setLoading(true);
                          dispatch({type: 'reset'});
                          deleteSession(token).finally(() => {
                              if (storage) {
                                  storage.clear();
                              }
                              router('/login');
                          });
                      },
                  },
              ]
            : [
                  {
                      title: tr('Mis datos'),
                      startIcon: (
                          <WCIcons
                              name="profile-single"
                              width="32"
                              height="32"
                              color={isEl1Hover ? 'var(--color-primary-100)' : 'var(--accent-color-1)'}
                          />
                      ),
                      setIsHover: setIsEl1Hover,
                      onClick: () => setIsPersonalDataDialogOpen(true),
                  },
                  {
                      title: tr('Cerrar sesión'),
                      startIcon: (
                          <WCIcons
                              name="log-out"
                              width="32"
                              height="32"
                              color={isEl2Hover ? 'var(--color-primary-100)' : 'var(--accent-color-1)'}
                          />
                      ),
                      setIsHover: setIsEl2Hover,
                      onClick: () => {
                          setLoading(true);
                          dispatch({type: 'reset'});
                          deleteSession(token).finally(() => {
                              if (storage) {
                                  storage.clear();
                              }
                              router('/login');
                          });
                      },
                  },
              ];
    React.useEffect(() => {
        if (scrollToTopRef.current) {
            (scrollToTopRef.current as HTMLDivElement).scrollIntoView({
                block: 'start',
            });
        }
    }, [toTopFlag]);
    React.useEffect(() => {
        dispatch(resetAlert());
        setLoading(true);
        if (isAuthDialogOpen === true) {
            setIsAuthDialogOpenInStorage('true');
        } else {
            setIsAuthDialogOpenInStorage('false');
        }
        setLoading(false);
    }, []);
    React.useEffect(() => {
        setAlertObject(alert);
    }, [alertFlag]);
    React.useEffect(() => {
        setLoading(false);
    }, [location.pathname]);
    const handleClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(resetAlert());
    };
    return (
        <div id="header-onboarding" ref={scrollToTopRef}>
            {alertObject?.isOpen && (
                <Snackbar
                    open
                    autoHideDuration={location.pathname === '/propuesta' && !isAuthDialogOpen ? undefined : 10000}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    onClose={location.pathname === '/propuesta' && !isAuthDialogOpen ? undefined : handleClose}
                >
                    {/* @ts-ignore */}
                    <Alert
                        severity={alertObject?.isError ? 'error' : 'success'}
                        sx={{width: '100%'}}
                        // @ts-ignore
                        onClose={location.pathname === '/propuesta' && !isAuthDialogOpen ? undefined : handleClose}
                    >
                        {alertObject?.message}
                    </Alert>
                </Snackbar>
            )}
            {alertObject?.isOpen && location.pathname === '/propuesta' && !isAuthDialogOpen && (
                <ContainerDialog open onClose={() => null} scroll="body" maxWidth="xs">
                    <Grid item sx={{width: '100%'}}>
                        <Grid container justifyContent="center">
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    background: 'var(--accent-color-1)',
                                    borderRadius: '32px',
                                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                                    padding: '40px',
                                }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    rowSpacing={6}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            color: 'var(--error-color)',
                                        }}
                                    >
                                        <WCIcons name="message-failed" viewBox="0 0 25 24" height="40" width="40" />
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            rowSpacing={4}
                                        >
                                            <Grid item>
                                                <Text
                                                    size={downMobile ? 'm' : 'xl'}
                                                    weight="semibold"
                                                    align="center"
                                                    fontColor="var(--text-color-100)"
                                                >
                                                    {tr('Error al cargar')}
                                                </Text>
                                            </Grid>
                                            <Grid item>
                                                <Text
                                                    size={downMobile ? 'xxs' : 'xs'}
                                                    weight="regular"
                                                    align="center"
                                                    fontColor="var(--text-color-50)"
                                                >
                                                    {tr(
                                                        'Se ha producido un problema al cargar la prouesta, por favor, recargue la pagina de nuevo mas tarde'
                                                    )}
                                                </Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" alignItems="center" justifyContent="center">
                                            <Grid item>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <Link
                                                    onClick={() => router(0)}
                                                    sx={{
                                                        fontWeight: 700,
                                                        cursor: 'pointer',
                                                        fontSize: downMobile ? 12 : 10,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {tr('Recargar la página')}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContainerDialog>
            )}
            <PageLoading open={loading || fetching} />
            <SwipeableDrawer
                sx={{
                    '.MuiDrawer-paper': {
                        // 31 px are the half of the 62px height of mobile header
                        maxHeight: 'calc(100vh - 101px)',
                        borderRadius: '1.2em 1.2em 0px 0px',
                    },
                }}
                anchor="bottom"
                disableSwipeToOpen
                open={isRiskProfileDialogOpen || showRiskProfileDialog}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                onClose={() => {
                    dispatch(closeRiskProfileDialog());
                    setIsRiskProfileDialogOpen(false);
                }}
                onOpen={() => {
                    dispatch(openRiskProfileDialog());
                    setIsRiskProfileDialogOpen(true);
                }}
            >
                <RiskProfileDialogContent
                    userId={userId}
                    isSliderActive
                    birthday={birthday}
                    userIncome={userIncome}
                    objectives={objectives ? objectives.map((objective: any) => objective) : undefined}
                    financialWealth={financialWealth}
                    isTestAvailable
                    closeDialog={() => {
                        dispatch(closeRiskProfileDialog());
                        setIsRiskProfileDialogOpen(false);
                    }}
                    profileNumber={riskProfile.profile_id}
                    profileNumberMax={!hasMifid ? riskProfileArray.length.toString() : riskProfile.max_profile_id}
                    riskProfileArray={riskProfileArray}
                />
            </SwipeableDrawer>
            <SwipeableDrawer
                sx={{
                    '.MuiDrawer-paper': {
                        // 31 px are the half of the 62px height of mobile header
                        maxHeight: 'calc(100vh - 101px)',
                        borderRadius: '24px 24px 0px 0px',
                        padding: theme.spacing(8),
                    },
                }}
                anchor="bottom"
                disableSwipeToOpen
                open={isPersonalDataDialogOpen}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                onClose={() => setIsPersonalDataDialogOpen(false)}
                onOpen={() => setIsPersonalDataDialogOpen(true)}
            >
                <PersonalDataDialogContent closeDialog={() => setIsPersonalDataDialogOpen(false)} />
            </SwipeableDrawer>
            <ContainerDialog scroll="body" open={isAuthDialogOpen} onClose={() => null}>
                <AuthDialogComponent />
            </ContainerDialog>
            <Grid container direction="column">
                <Grid item>
                    <Grid
                        id="header"
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            padding: theme.spacing(4),
                            background: 'var(--color-primary-100)',
                        }}
                    >
                        <Grid item sx={{display: 'flez'}}>
                            <img
                                alt="logo"
                                style={{
                                    content: 'var(--logo-mobile)',
                                    maxWidth: 'var(--logo-mobile-width)',
                                    maxHeight: 'var(--logo-mobile-height)',
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{
                                minWidth: 139,
                                display: 'var(--login-display)',
                            }}
                        >
                            <Grid container justifyContent="flex-end">
                                <Grid
                                    item
                                    onClick={() => {
                                        if (
                                            location.pathname !== '/propuesta' &&
                                            location.pathname !== '/planes-asesoramiento'
                                        ) {
                                            router('/login');
                                        }
                                    }}
                                >
                                    {showNavigation && (
                                        <OnboardingMenu
                                            setIsHover={(value: boolean | ((prevState: boolean) => boolean)) =>
                                                setIsElHover(value)
                                            }
                                            icon={
                                                location.pathname === '/propuesta' ||
                                                location.pathname === '/planes-asesoramiento' ? undefined : (
                                                    <WCIcons
                                                        name="log-out"
                                                        width="24"
                                                        height="24"
                                                        color={
                                                            isElHover
                                                                ? 'var(--color-primary-100)'
                                                                : 'var(--accent-color-1)'
                                                        }
                                                    />
                                                )
                                            }
                                            title={'Login' || ''}
                                            options={
                                                location.pathname === '/propuesta' ||
                                                location.pathname === '/planes-asesoramiento'
                                                    ? menuItems
                                                    : undefined
                                            }
                                            // menuOptions={menuOptions}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {showNavigation && !location.pathname.includes('/plan') && location.pathname !== '/propuesta' && (
                <Grid
                    container
                    justifyContent="center"
                    sx={{
                        background: 'var(--accent-color-1-light)',
                        backdropFilter: 'blur(4px)',
                        padding: theme.spacing(2),
                    }}
                >
                    {process.env.REACT_APP_SHOW_LANGUAGE_SWITCH === 'true' && (
                        <Grid
                            item
                            sx={{
                                position: 'absolute',
                                left: '1.2em',
                            }}
                        >
                            <inv-text-xs
                                onClick={() => {
                                    dispatch(setLocale('es-ES'));
                                    if (location.pathname === '/gvc') {
                                        router(0);
                                    }
                                }}
                                style={{
                                    '--inv-text-xs-color':
                                        locale === 'es-ES' || locale === ''
                                            ? 'var(--color-secondary-100)'
                                            : 'var(--header-on-surface-thin-color)',
                                    '--inv-text-xs-font-weight': locale === 'es-ES' ? 600 : 400,
                                    cursor: 'pointer',
                                    pointerEvents: locale === 'es-ES' || locale === '' ? 'none' : 'auto',
                                }}
                            >
                                ES
                            </inv-text-xs>
                            <inv-text-xs
                                style={{
                                    '--inv-text-xs-color': 'var(--header-on-surface-thin-color)',
                                    '--inv-text-xs-font-weight': 400,
                                }}
                            >
                                |
                            </inv-text-xs>
                            <inv-text-xs
                                onClick={() => {
                                    dispatch(setLocale('ca-ES'));
                                    if (location.pathname === '/gvc') {
                                        router(0);
                                    }
                                }}
                                style={{
                                    '--inv-text-xs-color':
                                        locale === 'ca-ES'
                                            ? 'var(--color-secondary-100)'
                                            : 'var(--header-on-surface-thin-color)',
                                    '--inv-text-xs-font-weight': locale === 'ca-ES' ? 600 : 400,
                                    cursor: 'pointer',
                                    pointerEvents: locale === 'ca-ES' ? 'none' : 'auto',
                                }}
                            >
                                CA
                            </inv-text-xs>
                        </Grid>
                    )}
                    {location.pathname !== '/gvc' && (
                        <Grid item>
                            <StepsFragments
                                steps={[
                                    process.env.REACT_APP_ONBOARDING_POINT_1_TEXT || tr('Mis metas'),
                                    process.env.REACT_APP_ONBOARDING_POINT_2_TEXT || tr('Aportación inicial'),
                                    process.env.REACT_APP_ONBOARDING_POINT_3_TEXT || tr('Perfil inversor'),
                                    process.env.REACT_APP_ONBOARDING_POINT_4_TEXT || tr('Resultado'),
                                ]}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
            {location.pathname === '/propuesta' && (
                <Grid
                    container
                    sx={{
                        background: 'var(--accent-color-1-light)',
                        backdropFilter: 'blur(4px)',
                        padding: theme.spacing(2),
                    }}
                >
                    {process.env.REACT_APP_SHOW_LANGUAGE_SWITCH === 'true' && (
                        <Grid
                            item
                            sx={{
                                position: 'absolute',
                                left: '1.2em',
                            }}
                        >
                            <inv-text-xs
                                onClick={() => dispatch(setLocale('es-ES'))}
                                style={{
                                    '--inv-text-xs-color':
                                        locale === 'es-ES' || locale === ''
                                            ? 'var(--color-secondary-100)'
                                            : 'var(--header-on-surface-thin-color)',
                                    '--inv-text-xs-font-weight': locale === 'es-ES' ? 600 : 400,
                                    cursor: 'pointer',
                                }}
                            >
                                ES
                            </inv-text-xs>
                            <inv-text-xs
                                style={{
                                    '--inv-text-xs-color': 'var(--header-on-surface-thin-color)',
                                    '--inv-text-xs-font-weight': 400,
                                }}
                            >
                                |
                            </inv-text-xs>
                            <inv-text-xs
                                onClick={() => dispatch(setLocale('ca-ES'))}
                                style={{
                                    '--inv-text-xs-color':
                                        locale === 'ca-ES'
                                            ? 'var(--color-secondary-100)'
                                            : 'var(--header-on-surface-thin-color)',
                                    '--inv-text-xs-font-weight': locale === 'ca-ES' ? 600 : 400,
                                    cursor: 'pointer',
                                }}
                            >
                                CA
                            </inv-text-xs>
                        </Grid>
                    )}
                    <Grid
                        item
                        sx={{
                            position: 'absolute',
                            right: '1.2em',
                        }}
                    >
                        <inv-text-xs
                            style={{
                                '--inv-text-xs-color': computedStyles?.getPropertyValue(
                                    '--header-on-surface-thin-color'
                                ),
                                '--inv-text-xs-font-weight': 400,
                            }}
                        >
                            {tr('Perfil inversor')}&nbsp;
                        </inv-text-xs>
                        <inv-text-xs
                            onClick={() => setIsRiskProfileDialogOpen(true)}
                            style={{
                                '--inv-text-xs-color': computedStyles?.getPropertyValue('--header-on-surface-color'),
                                '--inv-text-xs-font-weight': 600,
                                borderBottom: `0.05em solid ${computedStyles?.getPropertyValue(
                                    '--navigation-selected-color'
                                )}`,
                                cursor: 'pointer',
                            }}
                        >
                            {tr(Capitalize(riskProfileArray[riskProfile.profile_id - 1]?.attributes?.name ?? ''))}
                        </inv-text-xs>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default MobileHeaderNavigation;
