import {useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import theme from '../../styles/theme';
import WCIcons from '../../elements/icons/WCIcons';
import Homepage from '../../pages';
import {tr} from '../../utils/functions';

interface UserDialogComponent {
    closeDialog: () => void;
}

export function PersonalDataDialogContent(props: UserDialogComponent) {
    const downXl = useMediaQuery(theme.breakpoints.down('xl'));
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {closeDialog} = props;

    return (
        <Box
            sx={{
                width: '100%',
                // adding padding is a hotfix because of grid inside dialog mess with vertical position and adds a unwanted scrollbar
                [theme.breakpoints.up('xs')]: {
                    padding: theme.spacing(3),
                },
                [theme.breakpoints.up('xl')]: {
                    padding: theme.spacing(6),
                },
                [theme.breakpoints.down('mobile')]: {
                    padding: 0,
                },
            }}
        >
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                rowSpacing={{xs: theme.spacing(7), xl: theme.spacing(14)}}
            >
                {/* Need to wrap containers on item to apply maxwidth */}
                <Grid
                    item
                    sx={{
                        width: '100%',
                    }}
                >
                    {!downMobile && (
                        <Grid container justifyContent="flex-end">
                            <Grid
                                item
                                sx={{
                                    cursor: 'pointer',
                                    height: downXl ? '30' : '40',
                                }}
                                onClick={closeDialog}
                            >
                                <WCIcons
                                    name="close-filled"
                                    height={downXl ? '30' : '40'}
                                    width={downXl ? '30' : '40'}
                                    hoverColor="inherit"
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    sx={{
                        width: '100%',
                    }}
                >
                    <Homepage
                        finish={() => {
                            closeDialog();
                        }}
                        title={tr('Mis datos')}
                        subtitle={tr('Aquí puedes consultar los datos asociados a tu perfil.')}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default PersonalDataDialogContent;
