import * as React from 'react';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import WCIcons from '../icons/WCIcons';

interface IContractSignCards {
    title: string;
    description: string;
    isSigned: boolean;
    buttonAction: () => void;
    isDisabled?: boolean;
}

function ContractSignCards(props: IContractSignCards) {
    const {title, description, isSigned, buttonAction, isDisabled} = props;

    // @ts-ignore
    // eslint-disable-next-line import/extensions
    React.useEffect(() => import('@inveert/ui-kit/src/text/text-l.js'), []);
    // @ts-ignore
    // eslint-disable-next-line import/extensions
    React.useEffect(() => import('@inveert/ui-kit/src/icon/icon'), []);
    // @ts-ignore
    // eslint-disable-next-line import/extensions
    React.useEffect(() => import('@inveert/ui-kit/src/text/text-m.js'), []);
    // @ts-ignore
    // eslint-disable-next-line import/extensions
    React.useEffect(() => import('@inveert/ui-kit/src/text/text-xs.js'), []);
    // @ts-ignore
    // eslint-disable-next-line import/extensions
    React.useEffect(() => import('@inveert/ui-kit/src/button/button'), []);
    return (
        <div
            style={{
                background: 'var(--text-color-10)',
                border: `0.05em solid var(--text-color-10)`,
                borderRadius: '0.4em',
                padding: '2.1em 1.95em 2em',
            }}
        >
            <inv-grid-row
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <inv-grid-col class="col-6">
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-text-l style={{'--inv-text-l-font-weight': 500}}>{title}</inv-text-l>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{marginTop: '0.55em'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-m
                                style={{
                                    '--inv-text-m-color': 'var(--text-color-50)',
                                }}
                            >
                                {description}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <inv-grid-row
                        style={{
                            alignItems: 'center',
                            '--inv-grid-gutter-x': '0.8em',
                        }}
                    >
                        <inv-grid-col class="col-auto">
                            {isSigned ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '0.5em',
                                        borderRadius: '1.8em',
                                        background: 'rgba(74, 219, 175, 0.14)',
                                        padding: '0.5em 0.9em',
                                    }}
                                >
                                    <WCIcons
                                        style={{height: '1.4em'}}
                                        name="message-success"
                                        height="1em"
                                        width="1em"
                                    />
                                    <inv-text-xs
                                        style={{
                                            '--inv-text-xs-color': 'var(--success-color)',
                                            '--inv-text-xs-font-weight': 600,
                                        }}
                                    >
                                        Firmado
                                    </inv-text-xs>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '0.5em',
                                        borderRadius: '1.8em',
                                        background: '#FAEEFC',
                                        padding: '0.5em 0.9em',
                                    }}
                                >
                                    <WCIcons
                                        style={{height: '1.4em'}}
                                        name="message-warning"
                                        height="1em"
                                        width="1em"
                                    />
                                    <inv-text-xs
                                        style={{
                                            '--inv-text-xs-color': 'var(--error-color)',
                                            '--inv-text-xs-font-weight': 600,
                                        }}
                                    >
                                        Pendiente
                                    </inv-text-xs>
                                </div>
                            )}
                        </inv-grid-col>
                        <inv-grid-col class="col-auto">
                            <inv-button
                                disabled={isDisabled && isSigned ? true : undefined}
                                onClick={buttonAction}
                                class="outlined button button--primary-outlined"
                                style={{'--inv-button-font-size': '0.8em'}}
                            >
                                <WCIcons
                                    style={{height: '1.2em'}}
                                    color="var(--color-primary-100)"
                                    name={isSigned ? 'investment-tracing' : 'edit'}
                                    width="1.2em"
                                    height="1.2em"
                                />
                                {isSigned === true ? 'Ver contrato' : 'Firmar contrato'}
                            </inv-button>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

ContractSignCards.defaultProps = {
    isDisabled: false,
};

export default ContractSignCards;
