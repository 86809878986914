import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './index.module.css';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-xxs';
import {tr} from '../../utils/functions';

const ListItems = (items) =>
    items.map((item, index) => (
        <inv-grid-row key={`${item.title}`} style={{justifyContent: 'center', '--inv-grid-gutter-x': '0.4em'}}>
            <inv-grid-col class="col-auto">
                <div className={styles.listNumber}>
                    <inv-text-xxs>
                        {index + 1} {/* This should be dynamic */}
                    </inv-text-xxs>
                </div>
            </inv-grid-col>
            <inv-grid-col class="col-auto col-sm-8">
                <inv-grid-row>
                    <inv-grid-col class="col-auto">
                        <inv-text-xs style={{'--inv-text-xs-font-weight': 600}}>
                            {item.title} {/* This should be dynamic */}
                        </inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxs
                            style={{
                                '--inv-text-xxs-color': 'var(--text-color-50)',
                                '--inv-text-xxs-font-weight': 400,
                            }}
                        >
                            {item.subtitle} {/* This should be dynamic */}
                        </inv-text-xxs>
                    </inv-grid-col>
                </inv-grid-row>
            </inv-grid-col>
        </inv-grid-row>
    ));

const items = [
    {
        title: tr('Calcular tu pensión publica'),
        subtitle: tr('Lo que ingresarás de la Seguridad Social al jubilarte '),
    },
    {
        title: tr('Añadir un complemento a esa pensión'),
        subtitle: tr('¡Tu meta! Un ingreso extra para mantener tu nivel de vida'),
    },
    {
        title: tr('Analizar tu perfil inversor'),
        subtitle: tr('Para poder asesorarte, tal y nos como exige la CNMV'),
    },
    {
        title: tr('Tu plan de ahorro e inversión'),
        subtitle: tr('Con toda la información para que sepas cómo conseguirlo'),
    },
];

function Index() {
    const router = useNavigate();
    return (
        <div className={styles.pageContainer}>
            <div className={styles.titleContainer}>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l
                            style={{
                                '--inv-text-l-color': 'var(--color-tertiary)',
                                '--inv-text-l-font-weight': 600,
                            }}
                        >
                            {tr('Tu plan para jubilarte')}
                        </inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-12 col-sm-7" style={{textAlign: 'center'}}>
                        <inv-text-xs
                            style={{
                                '--inv-text-xs-font-weight': 500,
                                '--inv-text-xs-color': 'var(--text-color-50)',
                            }}
                        >
                            {tr('Queremos que lo consigas ahorrando e invirtiendo el mínimo necesario.')}
                        </inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.itemList}>{ListItems(items)}</div>
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-auto">
                    <inv-button class="button button--primary-contained" onClick={() => router('/meta/jubilacion')}>
                        {tr('Empezar')}
                    </inv-button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
export default Index;
