import React from 'react';
import propTypes from 'prop-types';

/**
 * Props for the Tpl component.
 *
 * @typedef {Object} ReplacementProps
 * @property {string} template - The template string with placeholders wrapped in curly braces.
 * @property {Object.<string, string | React.ReactElement>} [values] - An object mapping placeholder keys to replacement values.
 */

/**
 * A React component that replaces placeholders in a template string with provided values.
 * Placeholders in the template should be wrapped in curly braces.
 *
 * @param {ReplacementProps} props
 * @returns {React.ReactElement}
 */
function Tpl({template, values}) {
    const parts = template.split(/(\{[^}]+\})/g);
    return (
        <>
            {parts.map((part) => {
                if (part.startsWith('{') && part.endsWith('}') && values) {
                    const key = part.slice(1, -1);
                    return values[key] || null; // Render the value or null if the key is not found.
                }
                return part; // Render plain text for non-placeholder parts.
            })}
        </>
    );
}

Tpl.propTypes = {
    template: propTypes.string.isRequired,
    values: propTypes.objectOf(propTypes.oneOfType([propTypes.string, propTypes.element])),
};

Tpl.defaultProps = {
    values: {},
};

export default Tpl;
