import * as React from 'react';
import styles from './inversiones.module.css';
import {Size, useWindowSize} from '../../utils/hooks';
import {HelpFooter} from '../../components/help/HelpFooter';
import PlanInversionesComponent from '../../modules/plan/plan-inversiones.component';

function PlanInversiones() {
    const size: Size = useWindowSize();
    const downMobile = (size?.width ?? 0) < 576;
    return (
        <>
            <div className={styles.pageContainer}>
                <PlanInversionesComponent />
            </div>
            {!downMobile && (
                <div style={{marginTop: '10em'}}>
                    <HelpFooter setIsContactDialogOpen={() => {}} />
                </div>
            )}
        </>
    );
}

export default PlanInversiones;
