import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import {MobileStepper} from '@mui/material';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import WCIcons from '../icons/WCIcons';

interface IDotStepsFragment {
    steps: number;
    activeStep: number;
    size?: 'big';
    handleBack?: () => void;
    handleNext?: () => void;
    activeDotColor?: string;
    inactiveDotColor?: string;
}

const StyledMobileStepper = styled((props: IDotStepsFragment) => {
    const {inactiveDotColor, activeDotColor, ...other} = props;
    return (
        <MobileStepper
            backButton={undefined}
            nextButton={undefined}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        />
    );
})(({activeDotColor, inactiveDotColor}) => ({
    position: 'relative',
    '&.MuiPaper-root': {
        background: 'transparent',
    },
    '.MuiMobileStepper-dot': {
        backgroundColor: inactiveDotColor ?? 'var(--dots-inactive-background)',
        marginRight: '0.2em',
        marginLeft: '0.2em',
    },
    '.MuiMobileStepper-dotActive': {
        backgroundColor: activeDotColor ?? 'var(--dots-active-background)',
        width: '0.6em',
        height: '0.6em',
        marginRight: '0.2em',
        marginLeft: '0.2em',
    },
    '.MuiMobileStepper-dots': {
        alignItems: 'center',
    },
}));

const StyledBigMobileStepper = styled((props: IDotStepsFragment) => {
    const {inactiveDotColor, activeDotColor, handleNext, handleBack, ...other} = props;
    const [isEl1Hover, setIsEl1Hover] = React.useState(false);
    const [isEl2Hover, setIsEl2Hover] = React.useState(false);
    return (
        <MobileStepper
            backButton={
                handleBack ? (
                    <Grid
                        onMouseEnter={() => setIsEl1Hover(true)}
                        onMouseLeave={() => setIsEl1Hover(false)}
                        onClick={() => {
                            let scrollAmount = 0;
                            const scrollMin = 0;
                            handleBack();
                            document.getElementById('goalContainer')?.scrollTo({
                                top: 0,
                                left: Math.min((scrollAmount -= 500), scrollMin),
                                behavior: 'smooth',
                            });
                        }}
                        sx={{
                            cursor: 'pointer',
                            transform: 'rotate(180deg)',
                            height: 24,
                            color: 'var(--accent-color-1)',
                            '&:hover': {
                                color: `var(--color-primary-100)`,
                            },
                        }}
                    >
                        <WCIcons
                            name="arrow-right"
                            hoverColor="var(--navigation-selected-color)"
                            color={isEl1Hover ? 'var(--color-primary-100)' : 'var(--navigation-selected-color)'}
                        />
                    </Grid>
                ) : undefined
            }
            nextButton={
                handleNext ? (
                    <Grid
                        onMouseEnter={() => setIsEl2Hover(true)}
                        onMouseLeave={() => setIsEl2Hover(false)}
                        onClick={() => {
                            let scrollAmount = 0;
                            const scrollMax = document.getElementById('goalContent')?.clientWidth;
                            handleNext();
                            document.getElementById('goalContainer')?.scrollTo({
                                top: 0,
                                left: Math.max((scrollAmount += 500), scrollMax ?? 0),
                                behavior: 'smooth',
                            });
                        }}
                        sx={{
                            cursor: 'pointer',
                            height: 24,
                            color: 'var(--accent-color-1)',
                            '&:hover': {
                                color: `var(--color-primary-100)`,
                            },
                        }}
                    >
                        <WCIcons
                            name="arrow-right"
                            color={isEl2Hover ? 'var(--color-primary-100)' : 'var(--navigation-selected-color)'}
                            hoverColor="var(--navigation-selected-color)"
                        />
                    </Grid>
                ) : undefined
            }
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...other}
        />
    );
})(({activeDotColor, inactiveDotColor}) => ({
    position: 'relative',
    zIndex: 0,
    '&.MuiPaper-root': {
        background: 'transparent',
    },
    '.MuiMobileStepper-dot': {
        backgroundColor: inactiveDotColor ?? 'var(--dots-inactive-background)',
        marginRight: '0.2em',
        marginLeft: '0.2em',
    },
    '.MuiMobileStepper-dotActive': {
        backgroundColor: activeDotColor ?? 'var(--dots-active-background)',
        width: '0.6em',
        height: '0.6em',
        marginRight: '0.2em',
        marginLeft: '0.2em',
    },
    '.MuiMobileStepper-dots': {
        alignItems: 'center',
    },
}));

function DotStepsFragment(props: IDotStepsFragment) {
    const {steps, activeStep, size, handleNext, handleBack, activeDotColor, inactiveDotColor} = props;
    return (
        <Box sx={{width: '100%'}}>
            {size === 'big' ? (
                <StyledBigMobileStepper
                    inactiveDotColor={inactiveDotColor}
                    activeDotColor={activeDotColor}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    steps={steps}
                    activeStep={activeStep}
                />
            ) : (
                <StyledMobileStepper
                    inactiveDotColor={inactiveDotColor}
                    activeDotColor={activeDotColor}
                    steps={steps}
                    activeStep={activeStep}
                />
            )}
        </Box>
    );
}
DotStepsFragment.defaultProps = {
    size: undefined,
    handleBack: undefined,
    handleNext: undefined,
    activeDotColor: undefined,
    inactiveDotColor: undefined,
};
export default DotStepsFragment;
