import {Link} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {tr} from '../../utils/functions';

import theme from '../../styles/theme';
import Text from '../../styles/textStyles';
import {LoginFormik, ResendVerificationFormik, SendChangePassFormik} from './auth.script';
import PageLoading from '../../elements/loading/PageLoading';
import {AuthLoginFormComponent} from './auth-login-form.component';
import {addAlert, selectApp} from '../app/app.slice';
import {authResendVerificationUser} from './auth.service';
import WCIcons from '../../elements/icons/WCIcons';
import '@inveert/ui-kit/src/text/text-xxl';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/icon/icon';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/button/button';

const AuthResendVerificationFormComponent = React.lazy(() => import('./auth-resend-verification-form.component'));
const AuthSendChangePassFormComponent = React.lazy(() => import('./auth-send-change-pass-form.component'));

interface IAuthDialogComponent {
    activeForm?: 'register' | 'login' | 'verification';
}

export function AuthDialogComponent(props: IAuthDialogComponent) {
    const dispatch = useDispatch();
    const {alert, alertFlag} = useSelector(selectApp);
    const {activeForm} = props;
    const [alertObject, setAlertObject] = React.useState(alert);
    const [formName, setFormName] = React.useState<'login' | 'verification' | 'register' | 'forgot'>('login');
    const formik = LoginFormik({
        dispatch,
    });
    const resendFormik = ResendVerificationFormik();
    const sendEmailChangePassFormik = SendChangePassFormik();
    React.useEffect(() => {
        setFormName(activeForm ?? 'login');
    }, [activeForm]);

    React.useEffect(() => {
        const listener = (event: {code: string; preventDefault: () => void}) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                if (formName === 'login') {
                    formik.handleSubmit();
                } else if (formName === 'verification') {
                    resendFormik.handleSubmit();
                } else {
                    sendEmailChangePassFormik.handleSubmit();
                }
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);
    React.useEffect(() => {
        setAlertObject(alert);
    }, [alertFlag]);
    return (
        <>
            <PageLoading
                open={formik.isSubmitting || resendFormik.isSubmitting || sendEmailChangePassFormik.isSubmitting}
            />
            {/* eslint-disable-next-line no-nested-ternary */}
            {formName === 'login' &&
            alertObject?.message === 'El correo electrónico introducido no ha sido validado' ? (
                <Box
                    sx={{
                        width: '100%',
                        // adding padding is a hotfix because of grid inside dialog mess with vertical position and adds a unwanted scrollbar
                        [theme.breakpoints.up('xs')]: {
                            padding: theme.spacing(12),
                            width: 400,
                        },
                        [theme.breakpoints.up('xl')]: {
                            padding: theme.spacing(14),
                        },
                        [theme.breakpoints.down('mobile')]: {
                            padding: 5,
                            width: '100%',
                        },
                    }}
                >
                    <Grid container direction="column">
                        <Grid
                            item
                            sx={{
                                width: '100%',
                            }}
                        >
                            <Grid container direction="column" alignItems="center" rowSpacing={4}>
                                <Grid item>
                                    <WCIcons name="message-failed" viewBox="0 0 25 24" height="50" width="50" />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" alignItems="center" rowSpacing={2}>
                                        <Grid item>
                                            <Text size="xl" weight="semibold" align="center">
                                                Verifica tu cuenta
                                            </Text>
                                        </Grid>
                                        <Grid item>
                                            <Text
                                                size="xs"
                                                weight="medium"
                                                fontColor="var(--text-color-30)"
                                                align="center"
                                            >
                                                Para poder acceder necesitas verificar tu correo, no te olvides de
                                                revisar tu bandeja de spam por si el correo de verificación estuviese
                                                allí.
                                            </Text>
                                        </Grid>
                                        <Grid item>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <Link
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: 12,
                                                }}
                                                onClick={async () => {
                                                    authResendVerificationUser(formik.values.email ?? '')
                                                        .then(() => {
                                                            dispatch(
                                                                addAlert({
                                                                    message: tr('Verificacion reenviada'),
                                                                    isError: false,
                                                                    isOpen: true,
                                                                })
                                                            );
                                                        })
                                                        .catch((e) => {
                                                            if (e?.response?.data?.message) {
                                                                dispatch(
                                                                    addAlert({
                                                                        message: e.response.data.message,
                                                                        isError: true,
                                                                        isOpen: true,
                                                                    })
                                                                );
                                                            }
                                                        });
                                                }}
                                            >
                                                ¿No te ha llegado? Reenvia el correo de verificación
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <div>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-xxl
                                style={{
                                    '--inv-text-xxl-gradient': 'var(--color-tertiary)',
                                    '--inv-text-xxl-font-weight': 700,
                                }}
                            >
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    formName === 'login'
                                        ? 'Login'
                                        : /* eslint-disable-next-line no-nested-ternary */
                                        formName === 'register'
                                        ? 'Registro'
                                        : formName === 'verification'
                                        ? 'Verificación'
                                        : 'Cambiar contraseña'
                                }
                            </inv-text-xxl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{marginTop: '2.5em', justifyContent: 'center'}}>
                        <inv-grid-col class="col-6">
                            {
                                // eslint-disable-next-line no-nested-ternary
                                formName === 'login' ? (
                                    <AuthLoginFormComponent formik={formik} />
                                ) : formName === 'verification' ? (
                                    <AuthResendVerificationFormComponent formik={resendFormik} />
                                ) : (
                                    <AuthSendChangePassFormComponent formik={sendEmailChangePassFormik} />
                                )
                            }
                            <inv-grid-row
                                style={{
                                    justifyContent: 'center',
                                    marginTop: '1.6em',
                                }}
                            >
                                <inv-grid-col class="col-12">
                                    {
                                        // eslint-disable-next-line no-nested-ternary
                                        formName === 'login' ? (
                                            <inv-button
                                                onClick={() => formik.handleSubmit()}
                                                class="button button--primary-contained"
                                            >
                                                Iniciar sesión
                                            </inv-button>
                                        ) : formName === 'verification' ? (
                                            <inv-button
                                                onClick={() => resendFormik.handleSubmit()}
                                                class="button button--primary-contained"
                                            >
                                                Reenviar correo
                                            </inv-button>
                                        ) : (
                                            <inv-button
                                                onClick={() => sendEmailChangePassFormik.handleSubmit()}
                                                class="button button--primary-contained"
                                            >
                                                Enviar correo
                                            </inv-button>
                                        )
                                    }
                                </inv-grid-col>
                            </inv-grid-row>
                            {formName === 'login' && (
                                <inv-grid-row
                                    style={{
                                        justifyContent: 'space-between',
                                        marginTop: '3.2em',
                                    }}
                                >
                                    <inv-grid-col class="col-auto">
                                        <a
                                            style={{
                                                color: '#3874CB',
                                                textDecoration: 'underline',
                                                fontSize: '0.6em',
                                                fontWeight: 600,
                                            }}
                                            href="/registro"
                                        >
                                            ¿Aun no estás registrado?
                                        </a>
                                    </inv-grid-col>
                                    <inv-grid-col class="col-auto">
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a
                                            style={{
                                                color: '#3874CB',
                                                textDecoration: 'underline',
                                                fontSize: '0.6em',
                                                fontWeight: 600,
                                            }}
                                            onClick={() => {
                                                setFormName('forgot');
                                                formik.resetForm();
                                                resendFormik.resetForm();
                                                sendEmailChangePassFormik.resetForm();
                                            }}
                                            href="#"
                                        >
                                            ¿Has olvidado la contraseña?
                                        </a>
                                    </inv-grid-col>
                                </inv-grid-row>
                            )}
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
            )}
        </>
    );
}
AuthDialogComponent.defaultProps = {
    activeForm: undefined,
};

export default AuthDialogComponent;
