export const userMockedData = {
    name: 'Pablo',
    gender: null,
    birthday: '1992-10-02',
    studies: null,
    zip_code: null,
    has_partner: null,
    name_partner: null,
    gender_partner: null,
    birthday_partner: null,
    studies_partner: null,
    current_children: null,
    dependent_people: [],
    riskProfileArray: [],
    riskProfile: {profile_id: '1', alias: 'Conservador'},
};

export const userFinancialWealthMockedData = {
    initial_investment: 3000,
    bank_account: null,
    investment_funds: null,
    stock_shares: null,
    private_pension_plan: 5000,
    company_pension_plan: null,
    other_pension: null,
    investment_insurance: null,
    num_personal_loan: null,
    health_insurance: null,
    life_insurance: null,
    non_stock_exchange_company: null,
    personal_loan: [],
};
