import * as React from 'react';
import propTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import styles from './plan-contribution-pay-pending-init.module.css';
import PlanContributionPayPendingFooter from './plan-contribution-pay-pending-footer.component';

/**
 * @typedef {Object} Props
 * @property {number} pendingAmount - Cantidad pendiente de aportar
 * @property {() => void} handleNextClick - Función que se ejecuta al hacer click en el botón siguiente
 * @property {() => void} handleBackClick - Función que se ejecuta al hacer click en el botón atrás
 */

/**
 * Componente que muestra la pantalla inicial del diálogo para ponerte al dia con las aportaciones
 * @return {JSX.Element}
 * param {Props} props
 */
function PlanContributionPayPendingInit({
    pendingAmount,
    handleNextClick,
    handleBackClick,
}) {
    return (
        <div className={styles.main}>
            <div className={styles.titleContainer}>
                <inv-grid-row class={styles.titleFirstLine}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>Ponerme al día</inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.titleSecondLine}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>Aportaciones pendientes</inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.pendingContainer}>
                <inv-grid-row>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxs>
                            Te falta por aportar un total de
                        </inv-text-xxs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>
                            <NumberFormat
                                value={pendingAmount}
                                thousandSeparator={
                                    process.env.REACT_APP_THOUSAND_SEPARATOR
                                }
                                decimalSeparator={
                                    process.env.REACT_APP_DECIMAL_SEPARATOR
                                }
                                decimalScale={0}
                                allowNegative={false}
                                displayType="text"
                                suffix=" €"
                            />
                        </inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <inv-grid-row
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    lineHeight: 0,
                }}
            >
                <inv-grid-col class="col-sm-7">
                    <inv-text-xxs
                        style={{
                            '--inv-text-xxs-font-weight': 300,
                            '--inv-text-xxs-color': 'var(--text-color-50)',
                        }}
                    >
                        No te preocupes, podemos recalcular el plan de
                        aportaciones mensuales, de manera que te pongas al día
                        poco a poco, ¡sin estrés!
                    </inv-text-xxs>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col className="col-12">
                    <PlanContributionPayPendingFooter
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        nextButtonText="Calcular nueva aportación"
                        backButtonText="Atrás"
                        currentStep={1}
                        totalSteps={3}
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

PlanContributionPayPendingInit.propTypes = {
    pendingAmount: propTypes.number.isRequired,
    handleBackClick: propTypes.func.isRequired,
    handleNextClick: propTypes.func.isRequired,
};

export default PlanContributionPayPendingInit;
