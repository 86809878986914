import * as React from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
// @ts-ignore
import {useNavigate} from 'react-router-dom';
import {differenceInYears, format, isValid, parseISO, startOfMonth} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from '@mui/material';
import theme from '../../styles/theme';
import {lastQuestionIndex, realStateFormQuestionTitle} from '../../utils/componentData';
import {ViviendaFormComponent} from '../../modules/goal/vivienda/vivienda-form.component';
import {OutputType} from '../../modules/goal/goal.type';
import {addObjectiveApi, getObjectiveCostApi, modifyObjectiveApi} from '../../modules/goal/goal.service';
import {addLastObjectiveIdAndType, selectObjectives, setLastObjectiveForm} from '../../modules/goal/goal.slice';
import PageLoading from '../../elements/loading/PageLoading';
import {GoalFooterMeta} from '../../components/meta/GoalFooterMeta';
import {setIsAuthDialogOpen} from '../../modules/auth/auth.slice';
import {addAlert, selectApp} from '../../modules/app/app.slice';
import {getConfig, getMinRecommendationPeriod} from '../../modules/propuesta/propuesta.service';
import {getDateFnsLocale, getErrorMessage, getYearAndMonth, tpl, tr} from '../../utils/functions';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/text/text-s';
import vivienda from '../../styles/Vivienda.module.css';
import {selectUser} from '../../modules/user/user.slice';
import {useGoalsDate} from '../../utils/hooks';

function Vivienda(props: any) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const goalName = 'Entrada vivienda';
    const {birthday} = useSelector(selectUser);
    const {locale} = useSelector(selectApp);
    const {goBack, finish, closeDialog} = props;
    // eslint-disable-next-line react/destructuring-assignment
    const router = useNavigate();
    const dispatch = useDispatch();
    const storage = globalThis?.sessionStorage;
    const [loading, setLoading] = React.useState(true);
    const [questionIndex, setQuestionIndex] = React.useState(0);
    const [inflation, setInflation] = React.useState(2);
    const [totalCost, setTotalCost] = React.useState(0);
    const [deathAge, setDeathAge] = React.useState(0);
    const [minRecommendationPeriod, setMinRecommendationPeriod] = React.useState(1);

    const [minDate, maxDate] = useGoalsDate(deathAge, birthday, minRecommendationPeriod);
    const yearDifference = differenceInYears(minDate, new Date());
    const minDateMessage =
        yearDifference === 1
            ? tr('Planifica tu meta con 1 año de margen')
            : tpl(tr('Planifica tu meta con {yearDifference} años de margen'), {yearDifference});

    React.useEffect(() => {
        const logo = document.getElementById('header-onboarding');
        if (logo) {
            logo.scrollIntoView({behavior: 'smooth'});
        }
    }, [questionIndex]);
    React.useEffect(() => {
        setQuestionIndex(
            storage?.getItem('currentPath') === '/meta/fin' && !downMobile ? lastQuestionIndex.realState : 0
        );
    }, [downMobile, storage]);
    const objectivesData = useSelector(selectObjectives);

    const validationSchema = yup.object({
        init_date: yup
            .date()
            .typeError(tr('La fecha no es valida'))
            .nullable()
            .min(minDate, minDateMessage)
            .max(
                maxDate,
                tpl(tr(`La fecha no puede ser posterior a {date}`), {
                    date: format(maxDate, 'MMMM yyyy', {locale: getDateFnsLocale(locale)}),
                })
            )
            .required(tr('Fecha de inicio requerida')),
        real_state_value: yup
            .number()
            .typeError(tr('Debes introducir un valor numérico'))
            .required(tr('Introduce un valor numérico'))
            .min(1, tr('El coste tiene que ser mayor que cero'))
            .nullable(),
        percentage: yup.number(),
        is_home: yup.boolean().nullable().required(),
    });
    const formik = useFormik({
        initialValues:
            objectivesData.lastObjectiveType === 'REAL_STATE' && objectivesData.lastObjectiveForm
                ? {
                      ...objectivesData.lastObjectiveForm,
                      init_date: objectivesData.lastObjectiveForm?.init_date
                          ? getYearAndMonth(objectivesData.lastObjectiveForm?.init_date)
                          : null,
                  }
                : {
                      init_date: null,
                      real_state_value: null,
                      percentage: 20,
                      is_home: null,
                  },
        validationSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
            const parsedInitDate = isValid(values.init_date) ? values.init_date : parseISO(values.init_date);
            const dateInit = startOfMonth(parsedInitDate);
            const realStateObjective = {
                // @ts-ignore
                amount: Math.round(values.real_state_value * (values.percentage * 0.01)),
                type: 'REAL_STATE' as const,
                output_type: OutputType.UNIQUE,
                protection_type: 'CAPITAL' as const,
                name: goalName,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: undefined,
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            if (objectivesData.lastObjectiveType === 'REAL_STATE') {
                modifyObjectiveApi(realStateObjective, objectivesData.lastObjectiveId)
                    .then(() => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            } else {
                addObjectiveApi(realStateObjective)
                    .then((lastObjective: any) => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                            })
                        );
                        dispatch(
                            addLastObjectiveIdAndType({
                                lastObjectiveType: lastObjective.attributes.type,
                                lastObjectiveId: lastObjective.id,
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            }
        },
    });
    React.useEffect(() => {
        setLoading(true);
        const resMinDate = getMinRecommendationPeriod();
        const resConfig = getConfig();
        Promise.all([resMinDate, resConfig])
            .then(([minPeriod, config]) => {
                const configInflation = config.inflation;
                if (typeof configInflation === 'number') {
                    setInflation(configInflation);
                }
                if (typeof config.death_age === 'number') {
                    setDeathAge(config.death_age);
                }
                setMinRecommendationPeriod(minPeriod);
                dispatch(setIsAuthDialogOpen(false));
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
        return () => {
            setInflation(0);
            setLoading(false);
        };
    }, []);
    React.useEffect(() => {
        if (questionIndex === 3) {
            setLoading(true);
            const dateInit = startOfMonth(parseISO(formik?.values?.init_date));
            const realStateObjective = {
                // @ts-ignore
                amount: Math.round(formik.values.real_state_value * (formik.values.percentage * 0.01)),
                type: 'REAL_STATE' as const,
                output_type: OutputType.UNIQUE,
                protection_type: 'CAPITAL' as const,
                name: goalName,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date: undefined,
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            getObjectiveCostApi(realStateObjective)
                .then((res) => {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const {withdrawal} = res.attributes;

                    let realStateTotalCost = 0;
                    if (withdrawal) {
                        realStateTotalCost = withdrawal.reduce((acc: any, current: any) => acc + current, 0);
                    }
                    setTotalCost(realStateTotalCost);
                    setLoading(false);
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                });
        }
        return () => {
            setLoading(false);
        };
    }, [questionIndex]);
    return (
        <div className={vivienda.container}>
            <PageLoading open={formik.isSubmitting || loading} />
            <inv-grid-row>
                <inv-grid-col class="col" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--real-state-color)',
                            '--inv-text-l-font-weight': 600,
                        }}
                    >
                        {tr('Entrada vivienda')}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            {
                realStateFormQuestionTitle(
                    downMobile,
                    Math.round(formik.values.real_state_value * (formik.values.percentage * 0.01)),
                    format(formik?.values?.init_date ? parseISO(formik.values.init_date) : 0, 'MMMM yyyy', {
                        locale: getDateFnsLocale(locale),
                    }),
                    totalCost
                )[questionIndex]
            }
            <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
                <inv-grid-col class="col">
                    <ViviendaFormComponent
                        minDate={minDate}
                        maxDate={maxDate}
                        formik={formik}
                        questionIndex={questionIndex}
                        inflation={inflation}
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col">
                    <GoalFooterMeta
                        hideDots={questionIndex >= 3}
                        questionIndex={questionIndex}
                        goBack={goBack}
                        setQuestionIndex={setQuestionIndex}
                        formik={formik}
                        questionNameOrder={[1, 2, 3]}
                        goalType="REAL_STATE"
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
export default Vivienda;
