import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import * as React from 'react';
import {Divider} from '@mui/material';

interface ISolidDividers {
    orientation: 'vertical' | 'horizontal';
}

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledDividerHorizontal = styled((props: any) => <Divider {...props} />)(
    () => ({
        height: '0.05em',
        width: '100%',
        background: 'var(--text-color-10)',
        borderBottomWidth: 0,
    })
);

const StyledDividerVertical = styled((props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Divider orientation="vertical" {...props} />
))(() => ({
    width: '0.05em',
    background: 'var(--solid-divider-color,var(--text-color-05))',
    borderRadius: 8,
    height: '100%',
}));

export default function SolidDividers(props: ISolidDividers) {
    const {orientation} = props;
    const finalWidth = orientation === 'horizontal' ? '100%' : 'fit-content';
    const finalHeight = orientation === 'vertical' ? '100%' : 'fit-content';
    return (
        <Box sx={{width: finalWidth, height: finalHeight}}>
            {orientation === 'vertical' ? (
                <StyledDividerVertical />
            ) : (
                <StyledDividerHorizontal />
            )}
        </Box>
    );
}
