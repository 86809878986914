import {setUserDataLayer} from '../modules/gtm/gtm.script';
import {getUserIdApi} from '../modules/user/user.service';
import {addAlert} from '../modules/app/app.slice';
import {getErrorMessage, tr} from './functions';

export const handleMessageSocialLogin = (
    event: any,
    dispatch: any,
    setLoading: (isLoading: boolean) => void,
    router: any,
    reloadPage?: boolean
) => {
    if (event.data.error?.message) {
        setLoading(false);
    } else if (event.data?.data?.token) {
        setLoading(true);
        setUserDataLayer(event.data?.data?.email);
        const storage = globalThis?.sessionStorage;
        if (storage) {
            storage.setItem('token', event.data?.data?.token ?? '');
            getUserIdApi(event.data?.data?.token)
                .then(() => {
                    setLoading(false);
                    if (reloadPage) {
                        router(0);
                    } else {
                        router('/meta');
                    }
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                });
        } else {
            dispatch(
                addAlert({
                    message: tr('No se ha podido iniciar sesión'),
                    isError: true,
                    isOpen: true,
                })
            );
            setLoading(false);
        }
    }
};

export default handleMessageSocialLogin;
