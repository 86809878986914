import {useCallback, useState, RefObject, useEffect, useRef} from 'react';
import {add, addYears, endOfDay, format, parseISO, subYears} from 'date-fns';
// See: https://usehooks-ts.com/react-hook/use-event-listener

export interface Size {
    width: number | undefined;
    height: number | undefined;
}

function useEventListener<K extends keyof WindowEventMap>(
    eventName: K,
    handler: (event: WindowEventMap[K]) => void
): void;
function useEventListener<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(
    eventName: K,
    handler: (event: HTMLElementEventMap[K]) => void,
    element: RefObject<T>
): void;

function useEventListener<
    KW extends keyof WindowEventMap,
    KH extends keyof HTMLElementEventMap,
    T extends HTMLElement | void = void
>(
    eventName: KW | KH,
    handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
    element?: RefObject<T>
) {
    // Create a ref that stores handler
    const savedHandler = useRef<typeof handler>();

    useEffect(() => {
        // Define the listening target
        const targetElement: T | Window = element?.current || window;
        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }

        // Update saved handler if necessary
        if (savedHandler.current !== handler) {
            savedHandler.current = handler;
        }

        // Create event listener that calls handler function stored in ref
        const eventListener: typeof handler = (event) => {
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!savedHandler?.current) {
                savedHandler.current(event);
            }
        };

        targetElement.addEventListener(eventName, eventListener);

        // Remove event listener on cleanup
        // eslint-disable-next-line consistent-return
        return () => {
            targetElement.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element, handler]);
}

export default function useElementSize<T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, Size] {
    // Mutable values like 'ref.current' aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // Instead, we use a state as a ref to be reactive.
    const [ref, setRef] = useState<T | null>(null);
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    });

    // Prevent too many rendering using useCallback
    const handleSize = useCallback(() => {
        setSize({
            width: ref?.offsetWidth || 0,
            height: ref?.offsetHeight || 0,
        });
    }, [ref?.offsetHeight, ref?.offsetWidth]);

    useEventListener('resize', handleSize);

    useEffect(() => {
        handleSize();
    }, [ref?.offsetHeight, ref?.offsetWidth]);

    return [setRef, size];
}

export function useWindowSize(): Size {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export function useIsMobile(): boolean {
    const {width} = useWindowSize();
    return typeof width === 'number' && width < 575;
}

export function useFontSize() {
    const [fontSize, setFontSize] = useState(0);

    useEffect(() => {
        const chartFontSize = window.getComputedStyle(document.body).getPropertyValue('font-size');
        const fontSizeNumber = parseInt(chartFontSize, 10);
        setFontSize(fontSizeNumber);
    }, []);

    return fontSize;
}

export function useGoalsDate(deathAge: any, birthday: any, minRecommendationPeriod: any) {
    const [minDate, setMinDate] = useState<Date>(new Date());
    const [maxDate, setMaxDate] = useState<Date>(new Date());

    useEffect(() => {
        const minDateGoal = format(endOfDay(add(new Date(), {years: minRecommendationPeriod, months: 1})), 'yyyy-MM');
        setMinDate(parseISO(minDateGoal));

        const parsedBirthday = birthday ? parseISO(birthday) : subYears(new Date(), 18);
        const maxDateGoal = format(endOfDay(addYears(parsedBirthday, deathAge)), 'yyyy-MM');
        setMaxDate(parseISO(maxDateGoal));
    }, [deathAge, birthday]);

    return [minDate, maxDate];
}
