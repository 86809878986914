import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import {Grid, StepLabel, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
// @ts-ignore
import {useLocation} from 'react-router-dom';
import {parse} from 'qs';
import {useSelector} from 'react-redux';
import theme from '../../styles/theme';
import WCIcons from '../icons/WCIcons';
import {selectApp} from '../../modules/app/app.slice';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-m';
import styles from './StepsFragments.module.css';

interface IStepsFragments {
    steps: Array<string>;
}

// @ts-ignore
const QontoStepIconRoot = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

function QontoStepIcon(props: any) {
    const {active, completed, className, icon, sx} = props;
    let iconComponent;
    if (completed) {
        iconComponent = (
            <Grid container className="QontoStepIcon-filled-circle" alignItems="center" justifyContent="center">
                <WCIcons name="check" color="var(--inv-step-on-surface)" />
            </Grid>
        );
    } else if (active) {
        iconComponent = (
            <Grid container className="QontoStepIcon-filled-circle" alignItems="center" justifyContent="center">
                <inv-text-s
                    style={{
                        '--inv-text-s-font-weight': '600',
                        '--inv-text-s-color': active ? 'var(--inv-step-on-surface)' : 'var(--inv-step-disable)',
                    }}
                >
                    {icon}
                </inv-text-s>
            </Grid>
        );
    } else {
        iconComponent = (
            <Grid container className="QontoStepIcon-circle" alignItems="center" justifyContent="center">
                <inv-text-s
                    style={{
                        '--inv-text-s-font-weight': '600',
                        '--inv-text-s-color': active ? 'var(--inv-step-on-surface)' : 'var(--inv-step-disable)',
                    }}
                >
                    {icon}
                </inv-text-s>
            </Grid>
        );
    }
    return (
        // @ts-ignore
        <QontoStepIconRoot sx={sx} ownerState={{active}} className={className}>
            {iconComponent}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.defaultProps = {
    active: undefined,
    className: undefined,
    completed: undefined,
};

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

function StepsFragments(props: IStepsFragments) {
    const {onboardingStep} = useSelector(selectApp);
    const location = useLocation();
    const params = location.search.substring(1);
    const {aportacion} = parse(params);
    const downLg = useMediaQuery(theme.breakpoints.down('lg'));
    let activeStep: number | undefined;

    if (location.pathname.includes('meta/fin') && aportacion === 'true') {
        activeStep = 1;
    } else if (location.pathname.includes('meta/fin') && process.env.REACT_APP_THEME === 'finsei') {
        activeStep = 2;
    } else if (location.pathname.includes('/plan/meta')) {
        activeStep = 1;
    } else if (location.pathname.includes('meta')) {
        if (process.env.REACT_APP_THEME === 'finsei' && location.pathname.includes('jubilacion')) {
            activeStep = onboardingStep;
        } else {
            activeStep = 0;
        }
    } else if (location.pathname.includes('perfil')) {
        activeStep = 2;
    } else if (location.pathname.includes('resultado')) {
        activeStep = 3;
    } else if (location.pathname === '/propuesta') {
        activeStep = 4;
    } else {
        activeStep = 0;
    }
    const {steps} = props;
    /*
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step:any) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        // @ts-ignore
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    */
    return (
        <Box className={styles.container} sx={{width: '100%'}}>
            <Stepper nonLinear activeStep={activeStep} connector={null}>
                {steps.map((label, index) => {
                    let displaySx: string;
                    if (index === activeStep) {
                        displaySx = 'inherit';
                    } else if (downLg) {
                        displaySx = 'none';
                    } else {
                        displaySx = 'inherit';
                    }
                    // @ts-ignore
                    return (
                        <Step
                            className="step step--primary"
                            sx={{
                                display: displaySx,
                                fontSize: 'inherit',
                            }}
                            key={label}
                            completed={undefined}
                        >
                            <StepLabel
                                StepIconProps={{
                                    sx: {
                                        '& .QontoStepIcon-filled-circle': {
                                            width: '1.6em',
                                            height: '1.6em',
                                            borderRadius: '3.35em',
                                            backgroundColor: 'var(--inv-step-surface)',
                                            color: 'var(--inv-step-on-surface)',
                                            border: `0.1em solid var(--inv-step-on-surface)`,
                                        },
                                        '& .QontoStepIcon-circle': {
                                            width: '1.6em',
                                            height: '1.6em',
                                            borderRadius: '3.35em',
                                            border: `0.1em solid ${'var(--inv-step-disable)'}`,
                                            color: 'var(--inv-step-disable)',
                                        },
                                    },
                                }}
                                StepIconComponent={QontoStepIcon}
                                sx={{
                                    '& .MuiStepLabel-label.Mui-active': {
                                        fontSize: 'inherit',
                                        color: 'var(--inv-step-surface)',
                                    },
                                    '& .MuiStepLabel-label.Mui-completed': {
                                        fontSize: 'inherit',
                                        color: 'var(--inv-step-surface)',
                                    },
                                    '& .MuiStepLabel-label': {
                                        fontSize: 'inherit',
                                        color: 'var(--inv-step-disable)',
                                    },
                                }}
                            >
                                <inv-text-m
                                    style={{
                                        '--inv-text-m-color':
                                            index === activeStep
                                                ? 'var(--inv-step-on-surface)'
                                                : 'var(--inv-step-disable)',
                                        '--inv-text-m-font-weight': '600',
                                    }}
                                >
                                    {label}
                                </inv-text-m>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}

export default StepsFragments;
