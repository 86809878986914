import Box from '@mui/material/Box';
import {
    FormHelperText,
    InputLabel,
    Grid,
    outlinedInputClasses,
} from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';
import {StyledTextField} from './NumberInput';
import WCIcons from '../icons/WCIcons';
import '@inveert/ui-kit/src/text/text-s';

interface ITextInput {
    value: string;
    handleChange: (
        name: string,
        value: string | null,
        validation: boolean
    ) => Promise<any>;
    formik: any;
    error?: string;
    name: string;
    label?: string;
    helperLabel?: string;
    placeholder?: string;
    focused?: boolean;
    disabled?: boolean;
    isPassword?: boolean;
    isCode?: boolean;
}

function TextInput(props: ITextInput) {
    const {
        name,
        value,
        handleChange,
        formik,
        error,
        label,
        helperLabel,
        placeholder,
        focused,
        disabled,
        isPassword,
        isCode,
    } = props;
    const [isPasswordVisible, setIsPasswordVisible] =
        React.useState(isPassword);
    const [isHover, setIsHover] = React.useState(false);
    const EyeOnComponent = (
        <Grid
            item
            sx={{
                cursor: 'pointer',
                height: 24,
                width: 25,
                color: 'var(--text-color-30)',
                '&:hover': {
                    color: 'var(--text-color-50)',
                },
            }}
            onClick={() => setIsPasswordVisible(true)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <WCIcons
                name="eye-on"
                color={
                    isHover ? 'var(--text-color-50)' : 'var(--text-color-30)'
                }
            />
        </Grid>
    );
    const EyeOffComponent = (
        <Grid
            item
            sx={{
                cursor: 'pointer',
                height: 24,
                width: 25,
                color: 'var(--text-color-30)',
                '&:hover': {
                    color: 'var(--text-color-50)',
                },
            }}
            onClick={() => setIsPasswordVisible(false)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <WCIcons
                name="eye-off"
                color={
                    isHover ? 'var(--text-color-50)' : 'var(--text-color-30)'
                }
            />
        </Grid>
    );
    // @ts-ignore
    return (
        <Box
            sx={
                isCode
                    ? {
                          [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                              {
                                  border: `2px solid var(--text-color-30)`,
                                  borderRadius: '26px',
                              },
                      }
                    : undefined
            }
        >
            <InputLabel
                sx={{
                    [theme.breakpoints.up('xs')]: {
                        marginBottom: theme.spacing(1),
                    },
                    [theme.breakpoints.up('xl')]: {
                        marginBottom: theme.spacing(2),
                    },
                }}
            >
                <inv-text-s
                    style={{
                        '--inv-text-s-color': 'var(--text-color-50)',
                        '--inv-text-s-font-weight': 500,
                    }}
                >
                    {label}
                </inv-text-s>
            </InputLabel>
            <StyledTextField
                sx={{
                    borderRadius: '26px',
                }}
                type={
                    (isPassword && isPasswordVisible === true) === true
                        ? 'password'
                        : undefined
                }
                disabled={disabled}
                fullWidth
                inputRef={(input: {focus: () => any}) =>
                    focused === true ? input && input.focus() : null
                }
                placeholder={placeholder}
                id={name}
                name={name}
                value={value}
                onChange={(event) => {
                    if (isPassword && name === 'passwordConfirmation') {
                        handleChange(name, event.target.value, false).then(() =>
                            formik.validateField(name)
                        );
                    } else if (formik.touched[name] === true) {
                        handleChange(name, event.target.value, false).then(() =>
                            formik.validateField(name)
                        );
                    } else {
                        handleChange(name, event.target.value, false).then(
                            () => null
                        );
                    }
                }}
                error={Boolean(error)}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                    // eslint-disable-next-line
                    endAdornment: isPassword
                        ? isPasswordVisible === true
                            ? EyeOffComponent
                            : EyeOnComponent
                        : undefined,
                    inputProps: isCode
                        ? {
                              style: {textAlign: 'center'},
                          }
                        : undefined,
                }}
                onBlur={() =>
                    formik
                        .setFieldTouched(name, true, false)
                        .then(() => formik.validateField(name))
                }
            />
            <FormHelperText
                sx={{
                    [theme.breakpoints.up('xs')]: {
                        marginTop: theme.spacing(1),
                    },
                    [theme.breakpoints.up('xl')]: {
                        marginTop: theme.spacing(2),
                    },
                }}
            >
                <inv-text-s
                    style={{
                        '--inv-text-s-color': 'var(--text-color-30)',
                        '--inv-text-s-font-weight': 500,
                    }}
                >
                    {helperLabel}
                </inv-text-s>
            </FormHelperText>
            {Boolean(formik.errors[name]) && (
                <FormHelperText
                    error
                    sx={{
                        [theme.breakpoints.up('xs')]: {
                            marginTop: theme.spacing(1),
                        },
                        [theme.breakpoints.up('xl')]: {
                            marginTop: theme.spacing(2),
                        },
                    }}
                >
                    <inv-text-s
                        style={{
                            '--inv-text-s-color': 'var(--error-color)',
                            '--inv-text-s-font-weight': 500,
                        }}
                    >
                        {error}
                    </inv-text-s>
                </FormHelperText>
            )}
        </Box>
    );
}

TextInput.defaultProps = {
    error: undefined,
    label: undefined,
    helperLabel: undefined,
    placeholder: undefined,
    focused: undefined,
    disabled: undefined,
    isPassword: undefined,
    isCode: undefined,
};

export default TextInput;
