import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {format} from 'date-fns';
import {IframePage} from '../../components/iframe/iframe-page';
import hubspotHtml from '../../iframe/gvc/hubspot-es.html';
import styles from './index.module.css';
import {useFontSize} from '../../utils/hooks';
import {selectUser} from '../../modules/user/user.slice';
import PageLoading from '../../elements/loading/PageLoading';
import {tr} from '../../utils/functions';
import {getPlan} from '../../modules/propuesta/propuesta.service';
import {addAlert, selectApp} from '../../modules/app/app.slice';

export function DescargarPlanificacion() {
    const dispatch = useDispatch();
    const {locale} = useSelector(selectApp);
    const {name, zipCode, birthday} = useSelector(selectUser);
    const [urlPdf, setUrlPdf] = React.useState('');
    const createPdfUrl = async () => {
        // eslint-disable-next-line no-undef
        const storage = globalThis?.sessionStorage;
        const token = storage.getItem('token');
        if (!token) {
            throw new Error(tr('No se ha encontrado el token'));
        } else {
            const resPlan = await getPlan();
            const planId = resPlan[0]?.id;
            if (planId) {
                setUrlPdf(
                    `https://rest.gvcgaesco.es/ser-wealth/financial/proxy?auth-token=${token}&plan-id=${planId}${
                        locale ? `&lang=${locale}` : '&lang=es-ES'
                    }${process.env.REACT_APP_ENV === 'pro' ? '' : `&nodo=${process.env.REACT_APP_ENV}`}`
                );
            } else {
                throw new Error(tr('No se ha encontrado el plan'));
            }
        }
    };
    React.useEffect(() => {
        createPdfUrl()
            .then()
            .catch(() => {
                dispatch(
                    addAlert({
                        message: tr('No se ha podido crear el pdf, intentelo de nuevo más tarde'),
                        isError: true,
                        isOpen: true,
                    })
                );
            });
    }, []);
    const fontSize = useFontSize();

    /**
     * Pasa la fecha de formato ISO a formato dd/mm/yyyy
     * @param {string} date Fecha en formato ISO
     * @returns {string} Fecha en formato dd/mm/yyyy
     */
    const parseDate = (date) => {
        const dateObj = new Date(date);
        return format(dateObj, 'yyyy-MM-dd');
    };
    return urlPdf ? (
        <div className={styles.container}>
            <IframePage
                properties={{firstname: name, url_pdf: urlPdf, fecha_nacimiento: parseDate(birthday), zip: zipCode}}
                content={hubspotHtml}
                height={(fontSize ?? 0) * 40}
            />
        </div>
    ) : (
        <PageLoading open />
    );
}
export default DescargarPlanificacion;
