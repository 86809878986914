import {addYears, isAfter, parseISO} from 'date-fns';
import {
    getNotAccumulatedNetWorthChartData,
    getPlan,
    getRealAccumulatedNetWorthChartData, getTirExistingUser,
} from '../propuesta/propuesta.service';
import {getObjectives} from '../goal/goal.service';
import {getPlanSummaryService, getRealPerformanceService} from './plan.service';

/**
 * Funcion que obtiene el resumen del plan
 * @param {Function} setLoading - Funcion que cambia el estado de carga
 * @param {Function} setPlanSummary - Funcion que cambia el estado del resumen del plan
 * @param {Function} setActiveGoals - Funcion que cambia el estado de las metas activas
 * @param {Function} [setLongTermGoals] - Funcion que cambia el estado de las metas a largo plazo
 * @param {Function} [setShortTermGoals] - Funcion que cambia el estado de las metas a corto plazo
 * @param {Function} [setNetWorthChart] - Funcion que cambia el estado de la grafica de patrimonio
 * @param {Function} [setNotifications] - Funcion que añade las notificaciones existentes del plan
 * @return {Promise<void>}
 */
export const getPlanSummaryAsync = async (
    setLoading,
    setPlanSummary,
    setActiveGoals,
    setLongTermGoals,
    setShortTermGoals,
    setNetWorthChart,
    setNotifications
) => {
    try {
        const shortTermYears = 4;
        setLoading(true);
        const resPlan = await getPlan();
        const activePlan = resPlan.find(
            /**
             * @param {Object} plan - The plan object
             * @param {Object} plan.attributes - The attributes object
             * @param {string} plan.attributes.status - The status property of the attributes object
             * @returns {boolean} - Whether the plan's status is 'ACTIVE'
             */
            (plan) => plan.attributes.status === 'ACTIVE'
        );
        const planId = activePlan?.id;
        if (planId) {
            const promiseNetWorthChart =
                getNotAccumulatedNetWorthChartData(planId);
            const promiseRealNetWorthChart =
                await getRealAccumulatedNetWorthChartData(planId);
            const promiseTir = getTirExistingUser(planId);
            const promiseSummary = getPlanSummaryService(planId);
            const promiseRealPerformance = getRealPerformanceService(planId);
            const promiseGoals = getObjectives();
            const [resSummary, resGoals] = await Promise.all([
                promiseSummary,
                promiseGoals,
            ]);
            if(setNotifications && resSummary?.meta?.notifications){
                setNotifications(resSummary?.meta?.notifications)
            }
            const goalsSummary = resSummary.included.filter(item=>item.type==='GoalSummary');
            const probability = resSummary.included.find(item=>item.type==='ProbabilityPlan');
            const scenarios = resSummary.included.find(item=>item.type==='Scenarios');
            let longTerm = {
                total: 0,
                contributed: 0,
                estimated: 0,
                goals: [],
                isActive: false,
            };
            let shortTerm = {
                total: 0,
                contributed: 0,
                estimated: 0,
                goals: [],
                isActive: false,
            };
            resGoals.forEach(
                /**
                 * @param {Object} goal - The goal object
                 * @param {string} goal.id - The ID of the goal
                 * @param {Object} goal.attributes - The attributes object of the goal
                 * @param {string} goal.attributes.init_date - The initial date of the goal
                 * @param {string} goal.attributes.type - The type of the goal
                 */
                (goal) => {
                    const goalSummary = goalsSummary.find(
                        /**
                         * @param {Object} objective - The objective object
                         * @param {string} objective.id - The ID of the objective
                         * @param {Object} goal - The goal object
                         * @param {string} goal.id - The ID of the goal
                         * @returns {boolean} - Whether the ID of the objective matches the ID of the goal
                         */
                        (objective) => objective.id === goal.id
                    );
                    if (
                        isAfter(
                            parseISO(goal.attributes.init_date),
                            addYears(new Date(), shortTermYears)
                        )
                    ) {
                        longTerm = {
                            total:
                                longTerm.total + goalSummary.attributes.total,
                            contributed:
                                longTerm.contributed +
                                goalSummary.attributes.contributed,
                            estimated:
                                longTerm.estimated +
                                goalSummary.attributes.estimated,
                            goals: longTerm.goals.concat(goal.attributes.type),
                            isActive: true,
                        };
                    } else {
                        shortTerm = {
                            total:
                                shortTerm.total + goalSummary.attributes.total,
                            contributed:
                                shortTerm.contributed +
                                goalSummary.attributes.contributed,
                            estimated:
                                shortTerm.estimated +
                                goalSummary.attributes.estimated,
                            goals: shortTerm.goals.concat(goal.attributes.type),
                            isActive: true,
                        };
                    }
                }
            );
            setLongTermGoals(longTerm);
            setShortTermGoals(shortTerm);
            setActiveGoals(longTerm);
            const [tirRes, realPerformanceRes] = await Promise.all([promiseTir, promiseRealPerformance]);
            const tir = tirRes?.attributes?.tir ?? 0;
            const firstYear = parseInt(Object.keys(scenarios.attributes.optimistic)[0].split('-')[0],10);
            const chartData=Object.keys(scenarios.attributes.optimistic).map((key)=>{
                const year= parseInt(key.split('-')[0],10);
                const month= parseInt(key.split('-')[1],10);
                const real = realPerformanceRes?.attributes?.[key]??0;
                return {
                    date:`${(year-firstYear+1)}-${month}`,
                    max_performance:[scenarios.attributes.optimistic[key]*100,tir*100],
                    min_performance:[scenarios.attributes.pessimistic[key]*100,tir*100],
                    real:real===0?null:real*100,
                }
            })
            setPlanSummary({summary:resSummary.data.attributes,probability,scenarios:{...scenarios,attributes:{...scenarios.attributes,tir,chart:chartData}}});

            const [resNetWorthChart, resRealNetWorthChart] = await Promise.all([
                promiseNetWorthChart,
                promiseRealNetWorthChart,
            ]);
            let realAcc = 0;
            const processedRealNetWorth = Object.keys(
                resRealNetWorthChart[0].attributes.data
            ).map((key) => {
                realAcc += resRealNetWorthChart[0].attributes.data[key];
                const calculated = resNetWorthChart.data.find(
                    (item) => item.date === key
                );
                return {
                    ...calculated,
                    real: realAcc,
                };
            });

            resNetWorthChart.data = resNetWorthChart.data.map((data, index) => {
                let finalData = data;
                const matchingData = processedRealNetWorth.find(
                    (item) => item.date === data.date
                );
                if (matchingData) {
                    finalData = matchingData;
                } else if (index === 0) {
                    finalData = {
                        ...data,
                        real: 0,
                    };
                }
                return finalData;
            });
            setNetWorthChart(resNetWorthChart);
        }
        setLoading(false);
    } catch (e) {
        setLoading(false);
    }
};
export default getPlanSummaryAsync;
