import * as React from 'react';
import propTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import styles from './plan-contribution-pay-pending-init.module.css';
import {PropuestaConInveertComponent} from '../../../propuesta/propuesta-con-inveert.component';
import {propuestaMockedData, propuestaWithGlobalInfoApiResponse} from '../../../propuesta/propuesta.data';
import {getPlanWithScenarios, getPropuestaDataAsync} from '../../../propuesta/propuesta.script';
import PageLoading from '../../../../elements/loading/PageLoading';
import SolidDividers from '../../../../elements/dividers/SolidDividers';

/**
 * Componente que muestra el resultado de la propuesta con el nuevo plan
 * @param {Function} handleNextClick - Función que se ejecuta cuando se hace click en el botón de siguiente
 * @param {Function} handleBackClick - Función que se ejecuta cuando se hace click en el botón de atrás
 * @param {Function} handleCloseClick - Función que se ejecuta cuando se hace click en el botón de cerrar
 * @return {JSX.Element}
 */
function PlanContributionPayPendingNew({handleNextClick, handleBackClick, handleCloseClick}) {
    const [loading, setLoading] = React.useState(false);
    const [loadingUser, setLoadingUser] = React.useState(false);
    const router = useNavigate();
    const [initialData] = React.useState(propuestaMockedData);
    const [objectives, setObjectives] = React.useState([]);
    const [inputChartData, setInputChartData] = React.useState({
        inputData: [{name: '2022', bar1: 0, bar2: 0, bar3: 0, bar4: 0}],
        barsColor: {
            bar1: undefined,
            bar2: undefined,
            bar3: undefined,
            bar4: undefined,
        },
        barsId: {
            bar1: undefined,
            bar2: undefined,
            bar3: undefined,
            bar4: undefined,
        },
        orderedObjectives: [
            {
                id: 1,
                attributes: {
                    type: 'EDUCATION',
                    name: 'Educacion',
                },
            },
        ],
    });
    const [tir, setTir] = React.useState(0);

    const [cumulativeNetWorthChartData, setCumulativeNetWorthChartData] = React.useState([{bar1: 0, bar2: 0, name: 0}]);
    const investmentDurationInYears =
        cumulativeNetWorthChartData.map((key, index) => ({
            name: index + 1,
        })).length - 1;
    const [globalInfo, setGlobalInfo] = React.useState(propuestaWithGlobalInfoApiResponse.included[0].attributes);
    const plan = getPlanWithScenarios(initialData.plan.plan, initialData.scenarios);
    React.useEffect(() => {
        getPropuestaDataAsync(
            setLoading,
            setLoadingUser,
            setGlobalInfo,
            setTir,
            setCumulativeNetWorthChartData,
            setInputChartData,
            setObjectives,
            router
        ).then();
    }, []);
    return (
        <div className={styles.main}>
            <PageLoading open={loading || loadingUser} />
            <div className={styles.titleContainer}>
                <inv-grid-row class={styles.titleFirstLine}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>Resultado</inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.titleSecondLine}>
                    <inv-grid-col class="col-auto">
                        <inv-text-l>Plan financiero nuevo</inv-text-l>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.titleThirdLine}>
                    <inv-grid-col class="col-sm-4">
                        <inv-text-xxs>
                            Tenemos en cuenta el patrimonio acumulado, el tiempo que falta para tus metas y tu perfil
                            inversor
                        </inv-text-xxs>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-12">
                        <PropuestaConInveertComponent
                            saving={globalInfo.saving}
                            initial_investment={globalInfo.initial_investment}
                            remaining_saving={globalInfo.remaining_saving}
                            adjusted_objective_without_taxation={globalInfo.goal_cost}
                            performance={globalInfo.performance}
                            plan={plan}
                            objectives={objectives}
                            finalInputChart={inputChartData}
                            totalCumulativeChart={cumulativeNetWorthChartData}
                            planTax={globalInfo.taxation ?? 0}
                            tir={tir}
                            investmentDurationInYears={investmentDurationInYears}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <inv-grid-row class={styles.dividerContainer}>
                <inv-grid-col class="col-12">
                    <SolidDividers orientation="horizontal" />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.buttonContainer}>
                <inv-grid-col class="col-auto">
                    <inv-button onClick={handleBackClick} class="button button--secondary-contained">
                        Atrás
                    </inv-button>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-button onClick={handleCloseClick} class="outlined button button--primary-outlined">
                                Cerrar y quedarme con el actual
                            </inv-button>
                        </inv-grid-col>
                        <inv-grid-col class="col-auto">
                            <inv-button class="button button--primary-contained" onClick={handleNextClick}>
                                Confirmar el plan nuevo
                            </inv-button>
                        </inv-grid-col>
                    </inv-grid-row>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

PlanContributionPayPendingNew.propTypes = {
    handleNextClick: propTypes.func.isRequired,
    handleBackClick: propTypes.func.isRequired,
    handleCloseClick: propTypes.func.isRequired,
};

export default PlanContributionPayPendingNew;
