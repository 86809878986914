import * as React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import styles from './planTotalsWithPercentageBar.module.css';
import PropuestaTextStructureComponent from '../../modules/propuesta/propuesta-text-structure.component';
import SolidDividers from '../../elements/dividers/SolidDividers';
import PropuestaThreeValuesPercentageBar from '../../modules/propuesta/propuesta-three-values-percentage-bar.component';

import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import WCIcons from '../../elements/icons/WCIcons';
import {tpl, tr} from '../../utils/functions';
import {useIsMobile} from '../../utils/hooks';

/**
 * @typedef {Object} Props
 * @property {number} investment - Aportacion invertida por el usuario
 * @property {number} pending - Aportacion pendiente por invertir
 * @property {number} performance - Rentabilidad bruta
 * @property {number} taxes - Impuestos sobre la rentabilidad
 * @property {number} [total] - Total de la propuesta
 * @property {string} totalTitle - Titulo del total
 * @property {string} investmentTitle - Titulo de la aportacion invertida
 * @property {string} pendingTitle - Titulo de la aportacion pendiente
 * @property {string} performanceTitle - Titulo de la rentabilidad bruta
 * @property {function} performanceInfoClickHandler - Funcion que se ejecuta al hacer click en el icono de informacion
 */

/**
 * Funcion que muestra la barra de porcentajes de la propuesta con los valores numericos
 * y una barra con sus porcentajes
 * @returns {JSX.Element} - representacion JSX del componente
 * @param {Props} props - las props del componente
 */
function PlanTotalsWithPercentageBar({
    totalTitle,
    performance,
    pending,
    taxes,
    investment,
    pendingTitle,
    performanceTitle,
    investmentTitle,
    performanceInfoClickHandler,
    total,
}) {
    const isMobile = useIsMobile();
    return (
        <inv-grid-row id="main-card" class={`${styles.main}`}>
            <inv-grid-col class={`col-12 col-sm-auto ${styles.totalContainer}`}>
                <PropuestaTextStructureComponent label={totalTitle} unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}>
                    <NumberFormat
                        value={total ?? pending + investment + performance - taxes}
                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                        decimalScale={0}
                        displayType="text"
                    />
                </PropuestaTextStructureComponent>
            </inv-grid-col>
            <inv-grid-col class={`col-auto ${styles.divider}`}>
                <SolidDividers orientation="vertical" />
            </inv-grid-col>
            <inv-grid-col class={`col-11 col-sm-auto ${styles.barWithTextContainer}`}>
                <inv-grid-row class={`${styles.textsOfBarContainer} ${styles.apportationsContainer}`}>
                    <inv-grid-col class="col-auto">
                        <inv-grid-row class={styles.containerOfInitialAndMonthly}>
                            <inv-grid-col
                                class="col-auto"
                                style={{
                                    '--inv-text-l-color': 'var(--text-color-50)',
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                }}
                            >
                                <PropuestaTextStructureComponent
                                    label={investmentTitle}
                                    unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                >
                                    <NumberFormat
                                        value={investment}
                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                        decimalScale={0}
                                        displayType="text"
                                    />
                                </PropuestaTextStructureComponent>
                            </inv-grid-col>
                            <inv-grid-col
                                class="col-auto"
                                style={{
                                    '--inv-text-l-color': 'var(--text-color-30)',
                                    '--inv-text-xs-color': 'var(--text-color-30)',
                                }}
                            >
                                <PropuestaTextStructureComponent
                                    label={pendingTitle}
                                    unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                >
                                    <NumberFormat
                                        value={pending}
                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                        decimalScale={0}
                                        displayType="text"
                                    />
                                </PropuestaTextStructureComponent>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                    <inv-grid-col
                        class="col-auto"
                        style={{
                            '--inv-text-l-color': 'var(--color-secondary-100)',
                            '--inv-text-xs-color': 'var(--color-secondary-100)',
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={performanceTitle}
                            labelIcon={
                                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                <button
                                    type="button"
                                    onClick={performanceInfoClickHandler}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer',
                                        fontFamily: 'inherit',
                                        fontSize: 'inherit',
                                        padding: 0,
                                        width: '1em',
                                        height: '1em',
                                    }}
                                >
                                    <WCIcons
                                        color="var(--text-color-50)"
                                        height="1em"
                                        width="1em"
                                        name="info"
                                        hoverColor="var(--text-color-50)"
                                    />
                                </button>
                            }
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                            helperElement={
                                <div className={styles.helperText}>
                                    <inv-text-xs>
                                        <NumberFormat
                                            value={-taxes}
                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                            decimalScale={0}
                                            displayType="text"
                                        />
                                    </inv-text-xs>
                                    <inv-text-xxs>
                                        {isMobile
                                            ? tpl(tr('{currency} impuestos'), {
                                                  currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                              })
                                            : tpl(tr('{currency} de impuestos'), {
                                                  currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                              })}
                                    </inv-text-xxs>
                                </div>
                            }
                        >
                            <NumberFormat
                                value={performance}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.barContainer}>
                    <inv-grid-col class="col-12">
                        <PropuestaThreeValuesPercentageBar
                            value1={investment}
                            value2={pending}
                            value3={performance}
                            value4={taxes}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            </inv-grid-col>
        </inv-grid-row>
    );
}

PlanTotalsWithPercentageBar.propTypes = {
    investment: PropTypes.number.isRequired,
    pending: PropTypes.number.isRequired,
    performance: PropTypes.number.isRequired,
    taxes: PropTypes.number.isRequired,
    totalTitle: PropTypes.string.isRequired,
    investmentTitle: PropTypes.string.isRequired,
    pendingTitle: PropTypes.string.isRequired,
    performanceTitle: PropTypes.string.isRequired,
    performanceInfoClickHandler: PropTypes.func.isRequired,
    total: PropTypes.number,
};

PlanTotalsWithPercentageBar.defaultProps = {
    total: undefined,
};

export default PlanTotalsWithPercentageBar;
