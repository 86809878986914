import React from 'react';
import * as yup from 'yup';
import {subYears} from 'date-fns';
import {GoalCostComponent} from '../goal-cost.component';
import herencia from '../../../styles/Herencia.module.css';
import '@inveert/ui-kit/src/input/input-date';
import '@inveert/ui-kit/src/input/input-int';
import {formatDateIntoYyyyMmDd, handleInputChange, handleInputFocusOut, tr} from '../../../utils/functions';

export interface GoalForm {
    formik: any;
    questionIndex: number;
    inflation: number;
}
export function HerenciaFormComponent(props: GoalForm) {
    const {formik, questionIndex, inflation} = props;
    const amountScheme = yup
        .number()
        .nullable(true)
        .required(tr('Introduce un valor numérico'))
        .min(1, tr('El coste tiene que ser mayor que cero'));

    const objectScheme = {
        amount: amountScheme,
    };
    const handleFocusOut = (e: any) => handleInputFocusOut(e, formik);
    const handleChange = (e: any) => handleInputChange(e, formik, objectScheme);
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-date-focus-out', handleFocusOut);
        window.addEventListener('input-int-change', handleChange);
        window.addEventListener('input-int-focus-out', handleFocusOut);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-int-change', handleChange);
            window.removeEventListener('input-date-focus-out', handleFocusOut);
            window.removeEventListener('input-int-focus-out', handleFocusOut);
        };
    }, [handleFocusOut, handleChange]);
    switch (questionIndex) {
        case 0:
            return (
                <inv-grid-row class={herencia.marginQ1Element1} style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-grid-col class="col-12 col-sm-4">
                        <inv-input-date
                            class="input input--primary"
                            value={formik.values.birthday}
                            error={formik.errors.birthday}
                            name="birthday"
                            label={tr('Fecha de nacimiento')}
                            max={formatDateIntoYyyyMmDd(subYears(new Date(), 18))}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 1:
            return (
                <inv-grid-row class={herencia.marginQ1Element1} style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-grid-col class="col-12 col-sm-4">
                        <inv-input-int
                            class="input input--primary"
                            value={formik.values.amount}
                            name="amount"
                            error={formik.errors.amount}
                            label={tr('Importe total de la herencia')}
                            placeholder="5000"
                            suffix={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 2:
            return <GoalCostComponent inflation={inflation} />;
        default:
            return null;
    }
}

export default HerenciaFormComponent;
