import * as React from 'react';
import propTypes from 'prop-types';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import NumberFormat from 'react-number-format';
import styles from './plan-contribution-pay-pending-card.module.css';
import PropuestaTextStructureComponent from '../../../propuesta/propuesta-text-structure.component';

/**
 * @typedef {Object} Props
 * @property {number} contributionAmount - Cantidad de la aportación
 * @property {boolean} [isNew] - Indica si la aportación es pasada
 * @property {string} title - Título de la tarjeta
 * @property {string} subtitle - Subtítulo de la tarjeta
 */

/**
 * Componente que muestra la tarjeta con la información de la aportación prevista
 * @return {JSX.Element}
 * @param {Props} props
 */
function PlanContributionPayPendingCard({
    contributionAmount,
    isNew,
    title,
    subtitle,
}) {
    return (
        <div
            className={`${styles.main} ${
                isNew === false ? styles.disabled : ''
            }`}
        >
            <inv-grid-row>
                <inv-grid-col class="col-auto">
                    <inv-text-s>{title}</inv-text-s>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col-auto">
                    <PropuestaTextStructureComponent
                        label={subtitle}
                        unit="€/mes"
                    >
                        <NumberFormat
                            value={contributionAmount}
                            thousandSeparator={
                                process.env.REACT_APP_THOUSAND_SEPARATOR
                            }
                            decimalSeparator={
                                process.env.REACT_APP_DECIMAL_SEPARATOR
                            }
                            decimalScale={0}
                            allowNegative={false}
                            displayType="text"
                        />
                    </PropuestaTextStructureComponent>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

PlanContributionPayPendingCard.propTypes = {
    contributionAmount: propTypes.number.isRequired,
    title: propTypes.string.isRequired,
    subtitle: propTypes.string.isRequired,
    isNew: propTypes.bool,
};

PlanContributionPayPendingCard.defaultProps = {
    isNew: false,
};

export default PlanContributionPayPendingCard;
