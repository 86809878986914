import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {FormControl, InputBase, useMediaQuery} from '@mui/material';
import {FormikErrors} from 'formik';
import React from 'react';
import theme from '../../styles/theme';
import '@inveert/ui-kit/src/text/text-l';

interface IGoalTextInput {
    name: string;
    text?: string;
    color: string;
    value: string;
    handleChange: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<{recipient: string}>>;
}

function GoalTextInput(props: IGoalTextInput) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const downXl = useMediaQuery(theme.breakpoints.down('xl'));
    const {text, color, value, handleChange, name} = props;
    return (
        <Box sx={{width: '100%'}}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={{
                    xs: downMobile ? theme.spacing(2) : theme.spacing(0),
                }}
                sx={{
                    [theme.breakpoints.up('xs')]: {
                        width: 'auto',
                    },
                }}
            >
                {text && (
                    <Grid item>
                        <inv-text-l
                            style={{
                                '--inv-text-l-color': color,
                                '--inv-text-l-font-weight': 600,
                            }}
                        >
                            {`${text}\xa0`}
                        </inv-text-l>
                    </Grid>
                )}
                <Grid item>
                    <FormControl sx={{width: 'fit-content'}}>
                        {downXl ? (
                            <span
                                style={{
                                    whiteSpace: 'pre',
                                    width: 'fit-content',
                                    color: 'transparent',
                                    // minWidth: 200,
                                    fontWeight: 600,
                                    fontSize: 'var(--inv-text-l-font-size, 1.2em)',
                                }}
                            >
                                {value?.length === 0 ? '....................' : value}
                            </span>
                        ) : (
                            <span
                                style={{
                                    whiteSpace: 'pre',
                                    width: 'fit-content',
                                    color: 'transparent',
                                    minWidth: 200,
                                    fontSize: 'var(--inv-text-l-font-size, 1.2em)',
                                    fontWeight: 500,
                                }}
                            >
                                {value?.length === 0 ? '....................' : value}
                            </span>
                        )}
                        <InputBase
                            sx={{
                                color,
                                fontWeight: 500,
                                position: 'absolute',
                                width: 'inherit',
                                height: '100%',
                                '&.MuiInputBase-root': {
                                    whiteSpace: 'pre',
                                    fontSize: 'var(--inv-text-l-font-size, 1.2em)',
                                },
                                input: {
                                    '&::placeholder': {
                                        color,
                                        opacity: 1,
                                    },
                                },
                            }}
                            inputProps={{
                                maxLength: 20,
                            }}
                            value={value}
                            placeholder="...................."
                            inputRef={(input) => input && input.focus()}
                            onChange={(event) => handleChange(name, event.target.value, false)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}

GoalTextInput.defaultProps = {
    text: undefined,
};

export default GoalTextInput;
