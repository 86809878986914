import Box from '@mui/material/Box';
import * as React from 'react';
import {Slider, SliderMarkLabel, SliderThumb, SliderValueLabel, useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {FormikErrors} from 'formik';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';
import {useIsMobile} from '../../utils/hooks';

interface IDiscreteSlider {
    name: string;
    value: number;
    min?: number;
    max?: number;
    step?: number;
    valueLabelFormat: (value: number) => string;
    marks?: Array<any>;
    handleChange: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void | Promise<void> | Promise<FormikErrors<{recipient: string}>>;
}

function valuetext(value: any) {
    return `${value}°C`;
}

// eslint-disable-next-line @typescript-eslint/no-shadow
const StyledSlider = styled(Slider)(() => ({
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        zIndex: 10,
        height: '1.2em',
        width: '1.2em',
        backgroundColor: 'var(--color-primary-100)',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .inner-bar': {
            height: '0.5em',
            width: 1,
            backgroundColor: 'var(--accent-color-1)',
            marginLeft: 1,
            marginRight: 1,
            zIndex: 2,
        },
        '& .outside-bar': {
            height: '3.2em',
            width: 2,
            backgroundColor: 'var(--color-primary-100)',
            marginLeft: 1,
            marginRight: 1,
            position: 'absolute',
            zIndex: 1,
        },
        '& .top-outside-bar': {
            height: '4.6em',
            width: 2,
            backgroundColor: 'var(--color-primary-100)',
            marginLeft: 1,
            marginRight: 1,
            position: 'absolute',
            zIndex: 1,
            bottom: 0,
        },
        '& .border': {
            backgroundColor: 'var(--color-primary-100)',
            borderRadius: '50%',
            width: '1.15em',
            height: '1.15em',
            position: 'absolute',
            zIndex: 2,
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: '1em',
        padding: '0.3em 0.65em',
        color: 'var(--text-color-30)',
        fontWeight: 600,
        top: '-1.4em',
        backgroundColor: 'var(--accent-color-1)',
        border: `2px solid var(--text-color-10)`,
        borderRadius: 100,
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-track': {
        height: '0.4em',
        color: 'var(--color-secondary-100)',
    },
    '& .MuiSlider-rail': {
        color: 'var(--text-color-30)',
        opacity: 1,
        height: 1,
    },

    '& .MuiSlider-mark': {
        height: '0.75em',
        width: '0.75em',
        borderRadius: '50%',
        border: `1px solid var(--text-color-30)`,
        background: 'var(--accent-color-1)',
        opacity: 1,
        '&.MuiSlider-markActive': {
            border: `0.2em solid var(--color-primary-100)`,
        },
    },
}));

function SliderThumbComponent(props: any) {
    const isMobile = useIsMobile();
    const {children, ...other} = props;
    const isMarks = Array.isArray(other.ownerState.marks);
    const parsedOther = {
        ...other,
        style: {
            left: `calc(${other.style.left} + ${isMarks === true ? 2 : 2.12}% + ${isMobile ? '2%' : '0%'})`,
        },
    };
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SliderThumb {...parsedOther}>
            {children}
            <span className="border" />
            <span className="inner-bar" />
            <span className="inner-bar" />
            <span className="inner-bar" />
            <span className={isMarks === true ? 'top-outside-bar' : 'outside-bar'} />
        </SliderThumb>
    );
}

SliderThumbComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

function SliderValueLabelComponent(props: any) {
    const {children, ...other} = props;
    const isMarks = Array.isArray(other.ownerState.marks);
    const parsedOther = {
        ...other,
        style: {top: isMarks === true ? -42 : -30},
    };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SliderValueLabel {...parsedOther}>{children}</SliderValueLabel>;
}

SliderValueLabelComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

function SliderMarkLabelComponent(props: any) {
    const {children, ...other} = props;
    const parsedOther = {
        ...other,
        style: {left: `calc(${other.style.left} + 1.12%)`, top: -32},
    };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SliderMarkLabel {...parsedOther}>{children}</SliderMarkLabel>;
}

SliderMarkLabelComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

export function DiscreteSlider(props: IDiscreteSlider) {
    const {value, min, max, step, handleChange, name, valueLabelFormat, marks} = props;
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    return (
        // 17px of margin right is the size of the last point in the slider that gets outside the width of the component
        // so we add margin to center the component
        <Box
            sx={{
                margin: 'auto',
                // eslint-disable-next-line no-nested-ternary
                width: downMobile ? '100%' : marks ? 600 : 350,
            }}
        >
            <StyledSlider
                valueLabelDisplay="on"
                components={{
                    Thumb: SliderThumbComponent,
                    ValueLabel: SliderValueLabelComponent,
                    MarkLabel: SliderMarkLabelComponent,
                }}
                aria-label="Temperature"
                getAriaValueText={valuetext}
                step={step}
                marks={marks || true}
                min={min}
                max={max}
                value={value}
                /* eslint-disable-next-line @typescript-eslint/no-shadow */
                valueLabelFormat={(value) => valueLabelFormat(value)}
                /* eslint-disable-next-line @typescript-eslint/no-shadow */
                onChange={(event, value) => handleChange(name, value, false)}
            />
        </Box>
    );
}

DiscreteSlider.defaultProps = {
    min: undefined,
    max: undefined,
    step: undefined,
    marks: undefined,
};

export default DiscreteSlider;
