import {createSlice} from '@reduxjs/toolkit';
import {Objectives} from './goal.type';

export const initialState: {
    lastObjectiveId: string | null;
    lastObjectiveType: string | null;
    lastObjectiveForm: any;
    objectives: Objectives[];
} = {
    lastObjectiveId: null,
    lastObjectiveType: null,
    objectives: [],
    lastObjectiveForm: null,
};

export const goalSlice = createSlice({
    name: 'objectives',
    initialState,
    reducers: {
        pushObjective(state, action) {
            // @ts-ignore
            state.objectives(action.payload);
        },
        deleteAllObjectives() {
            return initialState;
        },
        addAllObjectives(state, action) {
            return {...state, objectives: action.payload};
        },
        addLastObjectiveIdAndType(state, action) {
            return {...state, ...action.payload};
        },
        resetLastObjectiveIdAndType(state) {
            return {
                ...state,
                lastObjectiveId: null,
                lastObjectiveType: null,
                lastObjectiveForm: null,
            };
        },
        setLastObjectiveForm(state, action) {
            return {...state, lastObjectiveForm: action.payload};
        },
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectObjectives = (state: any) => state.objectives;

export const {
    pushObjective,
    deleteAllObjectives,
    addAllObjectives,
    addLastObjectiveIdAndType,
    resetLastObjectiveIdAndType,
    setLastObjectiveForm,
} = goalSlice.actions;

export default goalSlice.reducer;
