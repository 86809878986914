import * as React from 'react';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/icon/icon';
import NumberFormat from 'react-number-format';
import {differenceInYears, isPast, parseISO} from 'date-fns';
import styles from './plan-inversion.module.css';
import PropuestaTextStructureComponent from '../propuesta/propuesta-text-structure.component';
import '@inveert/ui-kit/src/text/text-m';
import InvestmentProductCardComponent from '../../components/investment/investment-product-card.component';
import CircleChart from '../../components/chart/CircleChart';
import {useFontSize} from '../../utils/hooks';
import SolidDividers from '../../elements/dividers/SolidDividers';
import ContainerDialog from '../../elements/dialog/ContainerDialog';
import PlanContributionExtraDialog from './contribution/plan-contribution-extra-dialog.component';
import PlanContributionRefundDialog from './contribution/plan-contribution-refund-dialog.component';
import {getAllRiskProfilesAndUserRiskProfile, getAllRiskProfilesApi} from '../risk/risk.service';
import PageLoading from '../../elements/loading/PageLoading';
import {Capitalize, tr, trn} from '../../utils/functions';
import {getObjectives} from '../goal/goal.service';
import {getPlan, getRecommendationsService} from '../propuesta/propuesta.service';
import {processRecommendation} from '../propuesta/propuesta.script';
import {IRecommendation} from '../propuesta/propuesta.type';

function PlanInversionesComponent() {
    const fontSize = useFontSize();
    const [goals, setGoals] = React.useState<any>(null);
    const [recommendation, setRecommendation] = React.useState<any>(null);
    const [timeHorizon, setTimeHorizon] = React.useState(0);
    const [userRiskProfile, setUserRiskProfile] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [showExtraContribution, setShowExtraContribution] = React.useState(false);
    const [showRefund, setShowRefund] = React.useState(false);
    const getRiskProfileAsync = async () => {
        try {
            setLoading(true);
            const riskProfile = getAllRiskProfilesAndUserRiskProfile();
            const allRiskProfiles = getAllRiskProfilesApi();
            const promiseGoals = getObjectives();
            const promisePlan = getPlan();
            const [resRiskProfile, resAllRiskProfiles, resGoals, resPlan] = await Promise.all([
                riskProfile,
                allRiskProfiles,
                promiseGoals,
                promisePlan,
            ]);

            setGoals(resGoals);
            const activePlan = resPlan.find(
                (plan: {attributes: {status: string}}) => plan.attributes.status === 'ACTIVE'
            );
            const planId = activePlan?.id;

            if (planId) {
                const resRecommendation = await getRecommendationsService(planId);
                const finalRecommendation = await processRecommendation(resRecommendation);
                setRecommendation(finalRecommendation);

                let maxYears = 0;

                resGoals.forEach((goal: {attributes: {end_date: any; init_date: any}}) => {
                    const dateToUse = goal.attributes.end_date || goal.attributes.init_date;
                    const date = parseISO(dateToUse);

                    if (!isPast(date)) {
                        const years = differenceInYears(date, new Date());
                        if (years > maxYears) {
                            maxYears = years;
                        }
                    }
                });
                setTimeHorizon(maxYears);
                const activeRiskProfile = resRiskProfile.data.find(
                    (rp: {attributes: {is_active: any}}) => rp.attributes.is_active
                );
                const activeRiskProfileData = resAllRiskProfiles.find(
                    (rp: {id: any}) => rp.id.toString() === activeRiskProfile.attributes.risk_profile_id.toString()
                );
                setUserRiskProfile(activeRiskProfileData);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };
    React.useEffect(() => {
        getRiskProfileAsync().then();
    }, []);
    return (
        <>
            <PageLoading open={loading} />
            <ContainerDialog
                open={showRefund}
                onClose={() => setShowRefund(false)}
                scroll="body"
                maxWidth="sm"
                fullWidth
            >
                <PlanContributionRefundDialog handleCloseDialogClick={() => setShowRefund(false)} />
            </ContainerDialog>
            <ContainerDialog
                open={showExtraContribution}
                onClose={() => setShowExtraContribution(false)}
                scroll="body"
                maxWidth="sm"
                fullWidth
            >
                <PlanContributionExtraDialog handleCloseDialogClick={() => setShowExtraContribution(false)} />
            </ContainerDialog>
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <inv-grid-row style={{alignItems: 'center', '--inv-grid-gutter-x': '3.2em'}}>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxxl
                            style={{
                                '--inv-text-xxxl-color': 'var(--accent-color-1)',
                                '--inv-text-xxxl-font-weight': '700',
                            }}
                        >
                            {tr('Inversiones del Plan')}
                        </inv-text-xxxl>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.headerContainer}>
                <inv-grid-row
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}
                >
                    <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
                        <PropuestaTextStructureComponent label="Perfil inversor">
                            {userRiskProfile?.attributes?.name
                                ? Capitalize(userRiskProfile?.attributes?.name)
                                : tr('No asignado')}
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
                        <PropuestaTextStructureComponent
                            label={tr('Horizonte temporal')}
                            unit={trn(tr('año'), tr('años'), timeHorizon)}
                        >
                            {/*
                    TODO horizonte temporal
                  */}
                            <NumberFormat
                                value={timeHorizon}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
                        <PropuestaTextStructureComponent
                            label={tr('Máxima exposición a RV')}
                            unit={tr('% renta variable')}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    bottom: '-0.15em',
                                }}
                            >
                                <CircleChart
                                    // 4 is the size of the circle in em
                                    width={(fontSize ?? 0) * 1.81}
                                    height={(fontSize ?? 0) * 1.81}
                                    data={[
                                        {
                                            name: 'fixed',
                                            value: 100 - (userRiskProfile?.attributes?.max_equity_exposure ?? 0) * 100,
                                            color: 'var(--text-color-50)',
                                        },
                                        {
                                            name: 'equity',
                                            value: (userRiskProfile?.attributes?.max_equity_exposure ?? 0) * 100,
                                            color: 'var(--color-secondary-100)',
                                        },
                                    ]}
                                />
                            </div>
                            <NumberFormat
                                value={(userRiskProfile?.attributes?.max_equity_exposure ?? 0) * 100}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={2}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col style={{height: '4.8em'}} class="col-auto">
                        <SolidDividers orientation="vertical" />
                    </inv-grid-col>
                    <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
                        <PropuestaTextStructureComponent label={tr('Productos contratados')} unit={tr('productos')}>
                            <NumberFormat
                                value={recommendation?.length ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.whiteContainer} style={{right: 0}} />
                <div className={styles.whiteContainer} style={{left: 0}} />
                <div className={styles.titleWithSubtitleContainer}>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 700,
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                {tr('Evolución de los productos contratados')}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <div className={styles.productsContainer}>
                    {recommendation?.map((objectiveData: IRecommendation) => {
                        const description =
                            objectiveData?.attributes?.services.find(
                                (service) => service?.type === 'FinancialServiceType'
                            )?.attributes?.description ?? '';
                        const equity = objectiveData.attributes.max_equity_exposure;

                        const fixedIncome = 1 - (objectiveData.attributes.max_equity_exposure ?? 0);

                        const {services} = objectiveData.attributes;

                        const products = services?.filter((product) => product.type === 'FinancialProduct');
                        const productsService = services?.filter(
                            (product) => product.type === 'FinancialProductFinancialService'
                        );
                        const finalProducts = products?.map((product) => ({
                            ...product,
                            attributes: {
                                ...product?.attributes,
                                ...productsService?.find(
                                    (item) => item?.attributes?.financial_product_id === parseInt(product?.id, 10)
                                )?.attributes,
                            },
                        }));

                        const {management} = objectiveData.attributes;
                        const commission = objectiveData.attributes.fees_and_comissions_specification;

                        const availability = objectiveData.attributes.money_availability;
                        const risk = objectiveData.attributes.risk ?? '';
                        const recommendedPeriod = objectiveData.attributes.recommended_period ?? 0;

                        const transfer = objectiveData.attributes.transfer_cost ?? 0;
                        return (
                            <inv-grid-row style={{'--inv-grid-gutter-x': 0}} key={`recomendattion-${objectiveData.id}`}>
                                <inv-grid-col class="col-12">
                                    <InvestmentProductCardComponent
                                        // @ts-ignore
                                        objectiveData={objectiveData}
                                        objectives={goals}
                                        description={description}
                                        equity={equity}
                                        fixedIncome={fixedIncome}
                                        management={management}
                                        commission={commission}
                                        availability={availability}
                                        risk={risk}
                                        transfer={transfer}
                                        recommendedPeriod={recommendedPeriod}
                                        // @ts-ignore
                                        products={finalProducts}
                                    />
                                </inv-grid-col>
                            </inv-grid-row>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default PlanInversionesComponent;
