import * as React from 'react';
import NumberFormat from 'react-number-format';
import styles from './plan.module.css';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xs';
import PropuestaTextStructureComponent from '../propuesta/propuesta-text-structure.component';
import {AreasChart} from '../../components/chart/AreasChart';

import {Size, useFontSize, useWindowSize} from '../../utils/hooks';
import PageLoading from '../../elements/loading/PageLoading';
import PlanTotalsWithPercentageBar from '../../components/planTotalsWithPercentageBar/planTotalsWithPercentageBar';
import PlanGoalsInfo from './plan-goals-info.component';
import SolidDividers from '../../elements/dividers/SolidDividers';
import ContainerDialog from '../../elements/dialog/ContainerDialog';
import PlanContributionPayPendingDialogComponent from './contribution/pay-pending/plan-contribution-pay-pending-dialog.component';
import PlanAlert from './plan-alert.component';
import {getPlanSummaryAsync} from './plan.script';
import {tr} from '../../utils/functions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function PlanComponent(props: any) {
    const size: Size = useWindowSize();
    const fontSize = useFontSize();
    const [transitionFlag, setTransitionFlag] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [planSummary, setPlanSummary] = React.useState<null | {summary: any; probability: any; scenarios: any}>(null);
    const [isLongSelected, setIsLongSelected] = React.useState<boolean>(true);
    const [shortTermGoals, setShortTermGoals] = React.useState<any>(null);
    const [longTermGoals, setLongTermGoals] = React.useState<any>(null);
    const [activeGoals, setActiveGoals] = React.useState<any>(null);
    const [netWorthChart, setNetWorthChart] = React.useState<any>({
        data: [
            {name: '1', area1: 100},
            {name: '2', area1: 140},
            {name: '3', area1: 200},
            {name: '4', area1: 220},
            {name: '5', area1: 140},
            {name: '6', area1: 200},
            {name: '7', area1: 280},
            {name: '8', area1: 220},
            {name: '9', area1: 190},
        ],
        dotsData: [
            {name: '3', area1: 220, type: 'REAL_STATE'},
            {name: '7', area1: 280, type: 'EDUCATION'},
        ],
    });
    const [notifications, setNotifications] = React.useState<Array<any>>([]);

    React.useEffect(() => {
        setLoading(true);
        getPlanSummaryAsync(
            setLoading,
            setPlanSummary,
            setActiveGoals,
            setLongTermGoals,
            setShortTermGoals,
            setNetWorthChart,
            setNotifications
        ).then();
    }, []);
    const handleCloseAlertClick = (alertId: string) => {
        const alert = document.getElementById(alertId);
        if (alert) {
            alert.style.display = 'none';
        }
    };
    const [dialogWidth, setDialogWidth] = React.useState('md');
    const [showRefund, setShowRefund] = React.useState<boolean>(false);
    return (
        <>
            <ContainerDialog
                open={showRefund}
                onClose={() => setShowRefund(false)}
                scroll="body"
                // @ts-ignore
                maxWidth={dialogWidth}
                fullWidth
            >
                <PlanContributionPayPendingDialogComponent
                    setDialogWidth={setDialogWidth}
                    handleCloseDialogClick={() => setShowRefund(false)}
                />
            </ContainerDialog>
            <PageLoading open={loading} />
            <div className={styles.contentHeaderContainer}>
                <inv-grid-row class={styles.titleContainer}>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxxl
                            style={{
                                '--inv-text-xxxl-font-weight': 700,
                                '--inv-text-xxxl-color': 'var(--accent-color-1)',
                            }}
                        >
                            {tr('Situación global del plan financiero')}
                        </inv-text-xxxl>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.infoHeaderContainer}>
                    <inv-grid-col
                        class={`col-12 col-sm-auto ${styles.nextApportationContainer}`}
                        style={{
                            '--inv-text-structure-label-color': 'var(--accent-color-1)',
                            '--inv-text-l-color': 'var(--accent-color-1)',
                            '--inv-text-xs-color': 'var(--accent-color-1)',
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={
                                (size.width ?? 0) < 576 ? tr('Próxima aportación') : tr('Próxima aportación mensual')
                            }
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            {/*
                             */}
                            <NumberFormat
                                value={planSummary?.summary?.next}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col style={{height: '3.2em'}} class="col-auto">
                        <SolidDividers orientation="vertical" />
                    </inv-grid-col>
                    <inv-grid-col
                        class={`col-auto ${styles.accumulatedContainer}`}
                        style={{
                            '--inv-text-structure-label-color': 'var(--accent-color-1)',
                            '--inv-text-l-color': 'var(--accent-color-1)',
                            '--inv-text-xs-color': 'var(--accent-color-1)',
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={(size.width ?? 0) < 576 ? tr('Acumulado') : tr('Capital acumulado')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={
                                    (planSummary?.summary?.contributed ?? 0) + (planSummary?.summary?.performance ?? 0)
                                }
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-grid-row class={styles.infoHeaderSmallQuantities}>
                            <inv-grid-col
                                class={`col-auto ${styles.smallHeaderTextStructures}`}
                                style={{
                                    '--inv-text-structure-label-color': 'var(--accent-color-1)',
                                    '--inv-text-l-color': 'var(--accent-color-1)',
                                    '--inv-text-xs-color': 'var(--accent-color-1)',
                                }}
                            >
                                <PropuestaTextStructureComponent
                                    label={(size.width ?? 0) < 576 ? tr('T. aportado') : tr('Total aportado')}
                                    unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                >
                                    <NumberFormat
                                        value={planSummary?.summary?.contributed ?? 0}
                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                        decimalScale={0}
                                        displayType="text"
                                    />
                                </PropuestaTextStructureComponent>
                            </inv-grid-col>
                            <inv-grid-col
                                class={`col-auto ${styles.smallHeaderTextStructures}`}
                                style={{
                                    '--inv-text-structure-label-color': 'var(--color-secondary-100)',
                                    '--inv-text-l-color': 'var(--color-secondary-100)',
                                    '--inv-text-xs-color': 'var(--color-secondary-100)',
                                }}
                            >
                                <PropuestaTextStructureComponent
                                    label={tr('Rentabilidad')}
                                    unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                >
                                    <NumberFormat
                                        value={planSummary?.summary?.performance ?? 0}
                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                        decimalScale={0}
                                        displayType="text"
                                    />
                                </PropuestaTextStructureComponent>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.headerContainer}>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-auto">
                        <PlanTotalsWithPercentageBar
                            investment={
                                (planSummary?.summary?.contributed ?? 0) + (planSummary?.summary?.performance ?? 0)
                            }
                            pending={planSummary?.summary?.pending ?? 0}
                            performance={
                                (planSummary?.summary?.amount ?? 0) -
                                (planSummary?.summary?.performance ?? 0) -
                                (planSummary?.summary?.pending ?? 0) -
                                (planSummary?.summary?.contributed ?? 0)
                            }
                            taxes={planSummary?.summary?.taxation ?? 0}
                            totalTitle={tr('Necesitas para tus metas')}
                            investmentTitle={tr('Capital acumulado')}
                            pendingTitle={tr('Aportaciones pendientes')}
                            performanceTitle={tr('Rentabilidad necesaria')}
                            performanceInfoClickHandler={() => null}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.whiteContainer} style={{right: 0}} />
                <div className={styles.whiteContainer} style={{left: 0}} />
                <div>
                    {notifications.length > 0 && (
                        <div className={styles.alertContainer}>
                            {notifications.map((notification, index) => (
                                // @ts-ignore
                                <PlanAlert
                                    description={notification?.description ?? ''}
                                    id={`plan-alert-${index}`}
                                    primaryAction={notification?.primary_action}
                                    handleCloseAlertClick={handleCloseAlertClick}
                                />
                            ))}
                        </div>
                    )}
                    <inv-grid-row class="row" style={{'--inv-grid-gutter-x': 0}}>
                        <inv-grid-col class="col-12">
                            <AreasChart
                                height={23 * (fontSize ?? 0)}
                                data={netWorthChart?.data}
                                name="not-accumulated-net-worth"
                                customDotsData={netWorthChart?.dotsData}
                                xPropertyName="date"
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        class="row"
                        style={{
                            justifyContent: 'center',
                            position: 'relative',
                            zIndex: 1,
                            marginTop: '0.8em',
                        }}
                    >
                        <inv-grid-col class="col-auto">
                            <inv-text-xxs
                                style={{
                                    '--inv-text-xxs-color': 'var(--text-color-50)',
                                    '--inv-text-xxs-font-weight': '400',
                                }}
                            >
                                {tr('Evolución de tu patrimonio durante la vida de tu planificación')}
                            </inv-text-xxs>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <inv-grid-row
                    style={{
                        justifyContent: 'center',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <inv-grid-col class={`col-10 ${styles.contentCol10}`} style={{'--inv-grid-gutter-x': 0}}>
                        <inv-grid-row class="row" style={{justifyContent: 'center'}}>
                            <inv-grid-col class="col-auto">
                                <inv-text-m
                                    style={{
                                        '--inv-text-m-font-weight': 700,
                                    }}
                                >
                                    {tr('¿Qué posibilidad tienes de cumplir tu plan?')}
                                </inv-text-m>
                            </inv-grid-col>
                        </inv-grid-row>
                        {shortTermGoals?.isActive && longTermGoals?.isActive && (
                            <inv-grid-row style={{justifyContent: 'center'}}>
                                <inv-grid-col class="col-auto">
                                    <inv-grid-row
                                        style={{
                                            justifyContent: 'center',
                                            '--inv-grid-gutter-x': '0.4em',
                                            padding: '0.4em',
                                            borderRadius: '2em',
                                            border: '0.1em solid var(--text-color-05)',
                                        }}
                                    >
                                        <inv-grid-col class="col-auto">
                                            <inv-button
                                                onClick={() => {
                                                    setTransitionFlag(false);
                                                    setTimeout(() => {
                                                        setTransitionFlag(true);
                                                        setIsLongSelected(true);
                                                        setActiveGoals(longTermGoals);
                                                    }, 200);
                                                }}
                                                class="outlined button button--primary-outlined"
                                                style={{
                                                    '--inv-button-background': isLongSelected
                                                        ? 'var(--color-primary-100)'
                                                        : 'transparent',
                                                    '--inv-button-contained-background': isLongSelected
                                                        ? undefined
                                                        : 'transparent',
                                                }}
                                            >
                                                {tr('Para tus metas a largo plazo')}
                                            </inv-button>
                                        </inv-grid-col>
                                        <inv-grid-col class="col-auto">
                                            <inv-button
                                                onClick={() => {
                                                    setTransitionFlag(false);
                                                    setTimeout(() => {
                                                        setTransitionFlag(true);
                                                        setIsLongSelected(false);
                                                        setActiveGoals(shortTermGoals);
                                                    }, 200);
                                                }}
                                                class="outlined button button--primary-outlined"
                                                style={{
                                                    '--inv-button-background': !isLongSelected
                                                        ? 'var(--color-primary-100)'
                                                        : 'transparent',
                                                    '--inv-button-contained-background': !isLongSelected
                                                        ? undefined
                                                        : 'transparent',
                                                }}
                                            >
                                                {tr('Para tus metas a corto plazo')}
                                            </inv-button>
                                        </inv-grid-col>
                                    </inv-grid-row>
                                </inv-grid-col>
                            </inv-grid-row>
                        )}
                        <inv-grid-row class={`${styles.goalContainer} ${transitionFlag ? styles.show : styles.hide}`}>
                            <inv-grid-col class="col-12">
                                <PlanGoalsInfo
                                    chartData={planSummary?.scenarios?.attributes?.chart ?? []}
                                    tir={planSummary?.scenarios?.attributes?.tir ?? 0}
                                    probabilityLabel={planSummary?.probability?.attributes?.label ?? ''}
                                    probability={planSummary?.probability?.attributes?.probability ?? 0}
                                    goalTypes={activeGoals?.goals ?? []}
                                    contributed={activeGoals?.contributed ?? 0}
                                    total={activeGoals?.total ?? 0}
                                    expectedContribution={activeGoals?.estimated ?? 0}
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
        </>
    );
}

export default PlanComponent;
