import * as React from 'react';
import NumberFormat from 'react-number-format';
import {tr} from './functions';
import {OutputType} from '../modules/goal/goal.type';
import WCIcons from '../elements/icons/WCIcons';
import styles from '../styles/Jubilacion.module.css';
import Tpl from '../elements/tpl/tpl';

export const goalIconByType: {
    [key: string]: string;
} = {
    RETIREMENT: '/assets/images/goals/retirement.svg',
    RETIREMENT_ONLY_FUND: '/assets/images/goals/retirement.svg',
    EDUCATION: '/assets/images/goals/education.svg',
    REAL_STATE: '/assets/images/goals/real-state.svg',
    SAFETY_NET: '/assets/images/goals/safety.svg',
    HERITAGE: '/assets/images/goals/herencia.svg',
    CUSTOM: '/assets/images/goals/custom.svg',
};

export const goalColorByType: {[key: string]: string} = {
    RETIREMENT: 'var(--retirement-color)',
    RETIREMENT_ONLY_FUND: 'var(--retirement-color)',
    EDUCATION: 'var(--education-color)',
    REAL_STATE: 'var(--real-state-color)',
    SAFETY_NET: 'var(--safety-color)',
    HERITAGE: 'var(--heritage-color)',
    CUSTOM: 'var(--custom-color)',
};

export const goalSecondaryColorByType: {[key: string]: string} = {
    RETIREMENT: 'var(--retirement-color-light)',
    RETIREMENT_ONLY_FUND: 'var(--retirement-color-light)',
    EDUCATION: 'var(--education-color-light)',
    REAL_STATE: 'var(--real-state-color-light)',
    SAFETY_NET: 'var(--safety-color-light)',
    HERITAGE: 'var(--heritage-color-light)',
    CUSTOM: 'var(--custom-color-light)',
};

export const goalTertiaryColorByType: {[key: string]: string} = {
    RETIREMENT: 'var(--retirement-color-lighter)',
    RETIREMENT_ONLY_FUND: 'var(--retirement-color-lighter)',
    EDUCATION: 'var(--education-color-lighter)',
    REAL_STATE: 'var(--real-state-color-lighter)',
    SAFETY_NET: 'var(--safety-color-lighter)',
    HERITAGE: 'var(--heritage-color-lighter)',
    CUSTOM: 'var(--custom-color-lighter)',
};

export const educationFormQuestionTitle = (
    questionIndex: number,
    duration: number,
    amount: number | null,
    initData: string | null,
    totalCost: number | null
) => {
    let outputTimeframe: JSX.Element | string = '';
    const element = (
        <NumberFormat
            key={`goal-cost--amount-education-${duration}`}
            value={amount}
            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
            decimalScale={0}
            allowNegative={false}
            displayType="text"
        />
    );
    if (duration < 2) {
        outputTimeframe = (
            <Tpl
                key="subtitle-text-goal-cost-education-unique"
                template={tr('Que supone el equivalente a {amount}{currency} en {initDate}')}
                values={{
                    amount: element,
                    initDate: initData ?? '',
                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                }}
            />
        );
    } else {
        outputTimeframe = (
            <Tpl
                key="subtitle-text-goal-cost-education-annual"
                template={tr(
                    'Que supone el equivalente a {amount} {currency}/año, durante {duration} años, a partir de {initDate}'
                )}
                values={{
                    amount: element,
                    initDate: initData ?? '',
                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                    duration: (
                        <NumberFormat
                            key="goal-cost-education-output-time-frame"
                            value={duration}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={0}
                            allowNegative={false}
                            displayType="text"
                        />
                    ),
                }}
            />
        );
    }
    switch (questionIndex) {
        case 0:
            return (
                <inv-grid-row key="education-question-1">
                    <inv-grid-col
                        class={`col ${styles.marginTopText}`}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-text-xs
                            style={{
                                textAlign: 'center',
                                '--inv-text-xs-font-weight': 600,
                            }}
                        >
                            {tr('¿Para quién serán estos estudios?')}
                        </inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 1:
            return (
                <inv-grid-row key="education-question-2" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-grid-col
                        class={`col-sm-auto col-10 ${styles.marginTopText}`}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-text-xs
                            style={{
                                textAlign: 'center',
                                '--inv-text-xs-font-weight': 600,
                            }}
                        >
                            {tr('¿Cuándo comienzan y cuánto duran sus estudios?')}
                        </inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 2:
            return (
                <inv-grid-row key="education-question-3">
                    <inv-grid-col
                        class={`col ${styles.marginTopText}`}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-text-xs
                            style={{
                                textAlign: 'center',
                                '--inv-text-xs-font-weight': 600,
                            }}
                        >
                            <Tpl
                                key="cost-education"
                                template={tr('Indica el coste {temporality} de la educación')}
                                values={{temporality: duration < 2 ? 'total' : 'anual'}}
                            />
                        </inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 3:
            return (
                <>
                    <inv-grid-row key="education-question-4">
                        <inv-grid-col
                            class={`col ${styles.marginTopText}`}
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <inv-text-m class={styles.goalCostTitle} style={{'--inv-text-m-font-weight': 700}}>
                                <Tpl
                                    key="goal-cost-education-number-text"
                                    template={tr('Calculamos que necesitarás {totalCost}{currency}')}
                                    values={{
                                        totalCost: (
                                            <NumberFormat
                                                key="goal-cost-education-number"
                                                value={totalCost}
                                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                decimalScale={0}
                                                allowNegative={false}
                                                displayType="text"
                                            />
                                        ),
                                        currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                    }}
                                />
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        class={styles.goalCostFirstSubtitle}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col col-sm-7" style={{display: 'flex', justifyContent: 'center'}}>
                            <inv-text-xs
                                style={{
                                    '--inv-text-xs-font-weight': 300,
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                    margin: 0,
                                    textAlign: 'center',
                                }}
                            >
                                {outputTimeframe}
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        default:
            return null;
    }
};

export const customFormQuestionTitle = (
    outputType: OutputType | null,
    amount: number,
    initDate: string,
    endDate: string,
    totalCost: number,
    duration: number
) => {
    let priceType = '';
    let outputTimeframe: string | JSX.Element = '';
    const element = (
        <NumberFormat
            key={`goal-cost-custom-amount-${outputType}`}
            value={amount}
            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
            decimalScale={0}
            allowNegative={false}
            displayType="text"
        />
    );
    if (outputType === OutputType.ANNUAL) {
        priceType = tr('anual');
        outputTimeframe = (
            <Tpl
                template={tr(
                    `Que supone el equivalente a {amount}{currency}/año durante {duration} años, a partir de {initDate}`
                )}
                values={{
                    amount: element,
                    initDate: initDate ?? '',
                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                    duration: (
                        <NumberFormat
                            key={`goal-cost-custom-duration-${outputType}`}
                            value={duration}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={0}
                            allowNegative={false}
                            displayType="text"
                        />
                    ),
                }}
            />
        );
    } else if (outputType === OutputType.MONTHLY) {
        priceType = tr('mensual');
        outputTimeframe = (
            <Tpl
                template={tr(
                    `Que supone el equivalente a {amount}{currency}/mes, durante {duration} meses, a partir de {initDate}`
                )}
                values={{
                    amount: element,
                    initDate: initDate ?? '',
                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                    duration: (
                        <NumberFormat
                            key={`goal-cost-custom-duration-${outputType}`}
                            value={duration}
                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                            decimalScale={0}
                            allowNegative={false}
                            displayType="text"
                        />
                    ),
                }}
            />
        );
    } else {
        priceType = tr('total');
        outputTimeframe = (
            <Tpl
                template={tr(`Que supone el equivalente a {amount}{currency} en {initDate}`)}
                values={{
                    amount: element,
                    initDate: initDate ?? '',
                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                }}
            />
        );
    }
    return [
        <>
            <inv-grid-row>
                <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-xs
                        style={{
                            textAlign: 'center',
                            '--inv-text-xs-font-weight': 600,
                        }}
                    >
                        {tr('¡Genial! Vamos a comenzar a configurar tu meta,')}
                    </inv-text-xs>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-xs
                        style={{
                            textAlign: 'center',
                            '--inv-text-xs-font-weight': 600,
                        }}
                    >
                        {tr('¿Cómo la vas a llamar?')}
                    </inv-text-xs>
                </inv-grid-col>
            </inv-grid-row>
        </>,
        <inv-grid-row>
            <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-xs
                    style={{
                        textAlign: 'center',
                        '--inv-text-xs-font-weight': 600,
                    }}
                >
                    {tr('¿Cómo quieres recibir el dinero para tu meta?')}
                </inv-text-xs>
            </inv-grid-col>
        </inv-grid-row>,
        <inv-grid-row>
            <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-xs
                    style={{
                        textAlign: 'center',
                        '--inv-text-xs-font-weight': 600,
                    }}
                >
                    <Tpl
                        template={tr(`¿Cuál es el precio {temporality} de la meta?`)}
                        values={{temporality: priceType}}
                    />
                </inv-text-xs>
            </inv-grid-col>
        </inv-grid-row>,
        <>
            <inv-grid-row>
                <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-m class={styles.goalCostTitle} style={{'--inv-text-m-font-weight': 700}}>
                        <Tpl
                            template={tr('Calculamos que necesitarás {totalCost}{currency}')}
                            values={{
                                totalCost: (
                                    <NumberFormat
                                        key={`goal-cost-custom-total-cost-${totalCost}`}
                                        value={totalCost}
                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                        decimalScale={0}
                                        allowNegative={false}
                                        displayType="text"
                                    />
                                ),
                                currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                            }}
                        />
                    </inv-text-m>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={styles.goalCostFirstSubtitle} style={{display: 'flex', justifyContent: 'center'}}>
                <inv-grid-col class="col col-sm-7" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-xs
                        style={{
                            '--inv-text-xs-font-weight': 300,
                            '--inv-text-xs-color': 'var(--text-color-50)',
                            margin: 0,
                            textAlign: 'center',
                        }}
                    >
                        {outputTimeframe}
                    </inv-text-xs>
                </inv-grid-col>
            </inv-grid-row>
        </>,
    ];
};

export const heritageFormQuestionTitle = (amount: number, deathAge: number, totalCost: number) => [
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('¿Cuál es tu fecha de nacimiento?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('¿A cuánto asciende la herencia que quieres dejar?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <>
        <inv-grid-row>
            <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-m class={styles.goalCostTitle} style={{'--inv-text-m-font-weight': 700}}>
                    <Tpl
                        template={tr('Calculamos que necesitarás {totalCost}{currency}')}
                        values={{
                            totalCost: (
                                <NumberFormat
                                    key="goal-cost-heritage-total-cost"
                                    value={totalCost ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                        }}
                    />
                </inv-text-m>
            </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row class={styles.goalCostFirstSubtitle} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-grid-col class="col col-sm-6" style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-xs
                    style={{
                        '--inv-text-xs-font-weight': 300,
                        '--inv-text-xs-color': 'var(--text-color-50)',
                        margin: 0,
                        textAlign: 'center',
                    }}
                >
                    <Tpl
                        template={tr('Que supone el equivalente a {amount}{currency} cuando tengas {deathAge} años')}
                        values={{
                            amount: (
                                <NumberFormat
                                    key="goal-cost-heritage-amount"
                                    value={amount ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                            deathAge: (
                                <NumberFormat
                                    key="goal-cost-heritage-death-age"
                                    value={deathAge ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                        }}
                    />
                </inv-text-xs>
            </inv-grid-col>
        </inv-grid-row>
    </>,
];

export const heritageQuestionNameOrder = ['birthday', 'amount'];

export const educationQuestionNameOrder = ['recipient', 'init_date', 'amount'];

export const realStateFormQuestionTitle = (
    downMobile: boolean,
    amount: number,
    initDate: string,
    totalCost: number
) => [
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('¿Qué tipo de vivienda quieres?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('¿Cuánto necesitas para la entrada?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('¿Cuándo necesitas el dinero de la entrada?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <>
        <inv-grid-row>
            <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-m class={styles.goalCostTitle} style={{'--inv-text-m-font-weight': 700}}>
                    <Tpl
                        template={tr('Calculamos que necesitarás {totalCost}{currency}')}
                        values={{
                            totalCost: (
                                <NumberFormat
                                    key="goal-cost-real-state-number"
                                    value={totalCost ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                        }}
                    />
                </inv-text-m>
            </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row class={styles.goalCostFirstSubtitle} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-grid-col class="col col-sm-5" style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-xs
                    style={{
                        '--inv-text-xs-font-weight': 300,
                        '--inv-text-xs-color': 'var(--text-color-50)',
                        margin: 0,
                        textAlign: 'center',
                    }}
                >
                    <Tpl
                        template={tr('Que supone el equivalente a {amount}{currency} en {initDate}')}
                        values={{
                            amount: (
                                <NumberFormat
                                    key="goal-cost-real-state-amount"
                                    value={amount ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                            initDate: initDate ?? '',
                        }}
                    />
                </inv-text-xs>
            </inv-grid-col>
        </inv-grid-row>
    </>,
];

export const realStateQuestionNameOrder = ['init_date', 'real_state_value', 'percentage'];

export const safetyNetFormQuestionTitle = (
    downMobile: boolean,
    amount: number,
    initDate: string,
    totalCost: number
) => [
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('¿Qué cantidad necesitas para afrontar un mes corriente?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('¿Cuántos meses de gastos debe cubrir tu colchón?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                <Tpl
                    template={tr('Tienes ya esos {amount}{currency} ahorrados')}
                    values={{
                        amount: (
                            <NumberFormat
                                key="saving-amount-safety-net"
                                value={amount ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                allowNegative={false}
                                displayType="text"
                            />
                        ),
                        currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                    }}
                />
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                <Tpl
                    template={tr('Quiero este colchón de {amount}{currency} dentro de')}
                    values={{
                        amount: (
                            <NumberFormat
                                key="goal-cost-safety-net-amount"
                                value={amount ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                allowNegative={false}
                                displayType="text"
                            />
                        ),
                        currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                        initDate: initDate ?? '',
                    }}
                />
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <>
        <inv-grid-row>
            <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-m class={styles.goalCostTitle} style={{'--inv-text-m-font-weight': 700}}>
                    <Tpl
                        template={tr('Calculamos que necesitarás {totalCost}{currency}')}
                        values={{
                            totalCost: (
                                <NumberFormat
                                    key="goal-cost-safety-net-total-cost"
                                    value={totalCost ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                        }}
                    />
                </inv-text-m>
            </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row class={styles.goalCostFirstSubtitle} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-grid-col class="col col-sm-5" style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-xs
                    style={{
                        '--inv-text-xs-font-weight': 300,
                        '--inv-text-xs-color': 'var(--text-color-50)',
                        margin: 0,
                        textAlign: 'center',
                    }}
                >
                    <Tpl
                        template={tr('Que supone el equivalente a {amount}{currency} en {initDate}')}
                        values={{
                            amount: (
                                <NumberFormat
                                    key="goal-cost-safety-net-final-amount"
                                    value={amount ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                            initDate: initDate ?? '',
                        }}
                    />
                </inv-text-xs>
            </inv-grid-col>
        </inv-grid-row>
    </>,
];

export const safetyNetQuestionNameOrder = ['expenses', 'months_income', 'is_completed', 'duration'];

export const retirementFormQuestionTitle = (
    retirementAge: number | null,
    downMobile: boolean,
    amount: number | null,
    retirementAmount: number | null,
    deathAge: number | null,
    totalCost: number | null
) => [
    <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
        <inv-grid-col
            class={`col-8 col-sm-auto ${styles.marginTopText} ${styles.marginBottomTextFirstQuestion}`}
            style={{display: 'flex', justifyContent: 'center'}}
        >
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 600,
                }}
            >
                {tr('¿Cuál es tu situación laboral?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 600,
                }}
            >
                {tr('Completa esta información para estimar tu pensión pública')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                {tr('Tu pensión dependerá de cuando te jubiles...')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row style={{justifyContent: 'center'}}>
        <inv-grid-col
            class={`col-12 col-sm-7 ${styles.marginTopText}`}
            style={{display: 'flex', justifyContent: 'center'}}
        >
            <inv-text-xs style={{textAlign: 'center', '--inv-text-xs-font-weight': 600}}>
                <Tpl
                    template={tr(
                        "Desplaza el 'Complemento pensión' que necesitarás para tener un buen nivel de vida a partir de tus {retirementAge} años"
                    )}
                    values={{
                        retirementAge: (
                            <NumberFormat
                                key="slider-retirement-complement"
                                value={retirementAge ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                allowNegative={false}
                                displayType="text"
                            />
                        ),
                    }}
                />
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <>
        <inv-grid-row>
            <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-m class={styles.goalCostTitle} style={{'--inv-text-m-font-weight': 700}}>
                    <Tpl
                        key="total-cost-retirement-text"
                        template={tr('Calculamos que necesitarás {totalCost}{currency}')}
                        values={{
                            totalCost: (
                                <NumberFormat
                                    key="total-cost-retirement"
                                    value={totalCost ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                        }}
                    />
                </inv-text-m>
            </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row class={styles.goalCostFirstSubtitle} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-grid-col class="col col-sm-7" style={{display: 'flex', justifyContent: 'center'}}>
                <inv-text-xs
                    style={{
                        '--inv-text-xs-font-weight': 300,
                        '--inv-text-xs-color': 'var(--text-color-50)',
                        margin: 0,
                        textAlign: 'center',
                    }}
                >
                    <Tpl
                        template={tr(
                            'Que supone el equivalente a {amount} €/mes entre tus {retirementAge} y {deathAge} años'
                        )}
                        values={{
                            amount: (
                                <NumberFormat
                                    key={`goal-cost-retirement-amount-${amount}`}
                                    value={amount ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            retirementAge: (
                                <NumberFormat
                                    key={`goal-cost-retirement-retirementAge-${retirementAge}`}
                                    value={retirementAge ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                            deathAge: (
                                <NumberFormat
                                    key={`goal-cost-retirement-deathAge-${deathAge}`}
                                    value={deathAge ?? 0}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                />
                            ),
                        }}
                    />
                </inv-text-xs>
            </inv-grid-col>
        </inv-grid-row>
    </>,
];

export const profileQuestionNameOrder = [
    '',
    '',
    'stability',
    'expenses',
    'wealth',
    'studies',
    'years_investing',
    'experience',
    'goal',
    'risk',
    'attitude',
];

export const profileFormQuestionTitle = (monthlyIncome: number) => [
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr(process.env.REACT_APP_PERFIL_TEST_FIRST_QUESTION_TITLE || '') ||
                    tr('Para dirigirnos a ti y conocer de dónde eres')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Cuánto dinero entra en casa de manera habitual al mes?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Cómo de estables son tus ingresos?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
        <inv-grid-col
            class={`col col-sm-7 ${styles.marginTopText}`}
            style={{display: 'flex', justifyContent: 'center'}}
        >
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                <Tpl
                    template={tr('De esos {monthlyIncome}€/mes de ingresos, ¿Qué cantidad destinas a gastos fijos?')}
                    values={{
                        monthlyIncome: (
                            <NumberFormat
                                value={monthlyIncome ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                allowNegative={false}
                                displayType="text"
                            />
                        ),
                    }}
                />
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Cuanto tienes ahorrado en la actualidad?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Cuál es tu nivel de estudios?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Cuántos años llevas invirtiendo?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Has desempeñado actividad profesional en mercados financieros?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Cuál es el objetivo de tu inversión?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row>
        <inv-grid-col class={`col ${styles.marginTopText}`} style={{display: 'flex', justifyContent: 'center'}}>
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('¿Qué te transmite la palabra “riesgo”?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
    <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
        <inv-grid-col
            class={`col-12 col-sm-4 ${styles.marginTopText}`}
            style={{display: 'flex', justifyContent: 'center'}}
        >
            <inv-text-xs
                style={{
                    textAlign: 'center',
                    '--inv-text-xs-font-weight': 500,
                    '--inv-text-xs-color': 'var(--text-color-95)',
                }}
            >
                {tr('Si el valor de una inversión cae, ¿cuándo empiezas a preocuparte?')}
            </inv-text-xs>
        </inv-grid-col>
    </inv-grid-row>,
];

export const lastQuestionIndex = {
    education: 2,
    retirement: 2,
    heritage: 2,
    realState: 1,
    custom: 2,
    safetyNet: 1,
};

export const planMenuElements: Array<{
    icon: any;
    title: string;
    name: 'plan' | 'goals' | 'benefits' | 'help' | 'details' | 'investments';
    height: string;
    width: string;
}> = [
    {
        icon: <WCIcons name="financial-plan" color="var(--accent-color-1)" width="1.2em" height="1.2em" />,
        title: tr('Seguimiento plan'),
        name: 'plan',
        width: '1.2em',
        height: '1.2em',
    },
    {
        icon: <WCIcons name="goals" color="var(--accent-color-1)" width="1.2em" height="1.2em" />,
        title: tr('Mis metas'),
        name: 'goals',
        width: '1.2em',
        height: '1.2em',
    },
    {
        icon: <WCIcons name="financial-freedom-2" color="var(--accent-color-1)" width="1.2em" height="1.2em" />,
        title: tr('Inversiones'),
        name: 'investments',
        width: '1.2em',
        height: '1.2em',
    },
];
