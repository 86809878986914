import * as yup from 'yup';
import * as React from 'react';
import {format} from 'date-fns';
import GoalTextInput from '../../../elements/input/GoalTextInput';
import Selector from '../../../elements/selector/Selector';
import {AmountType, OutputType} from '../goal.type';
import {GoalCostComponent} from '../goal-cost.component';
import personalizada from '../../../styles/Personalizada.module.css';
import '@inveert/ui-kit/src/input/input-date';
import '@inveert/ui-kit/src/input/input-int';
import {handleInputChange, handleInputFocusOut, tpl, tr} from '../../../utils/functions';

export interface GoalForm {
    formik: any;
    questionIndex: number;
    inflation: number;
    minDate: Date;
    maxDate: Date;
}

export function PersonalizadaFormComponent(props: GoalForm) {
    const {formik, questionIndex, inflation, minDate, maxDate} = props;
    const [initDateLabel, setInitDateLabel] = React.useState(tr('¿Cuándo lo quieres?'));
    const amountSchema = yup
        .number()
        .required(tr('Introduce una cantidad numérica'))
        .min(1, tr('El coste tiene que ser mayor que cero'))
        .nullable();
    const durationSchema = yup
        .number()
        .when(['output_type'], (output_type) =>
            output_type === 'ANNUAL'
                ? yup
                      .number()
                      .nullable()
                      .required(tr('Duración de la meta requerido'))
                      .min(2, tr("Si solo es 1 año escoge la opción 'De una vez'"))
                : yup.number().nullable()
        );
    const objectSchema = {
        amount: amountSchema,
        duration: durationSchema,
    };
    // const minDate = endOfDay(addYears(new Date(), 4));
    const handleFocusOut = (e: any) => handleInputFocusOut(e, formik);
    const handleChange = (e: any) => handleInputChange(e, formik, objectSchema);
    React.useEffect(() => {
        if (formik.values.output_type === OutputType.UNIQUE) {
            setInitDateLabel(tr('¿Cuándo lo quieres?'));
        } else {
            setInitDateLabel(tr('Fecha inicio de la meta (aprox.)'));
        }
    }, [formik.values.output_type]);
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-date-focus-out', handleFocusOut);
        window.addEventListener('input-int-change', handleChange);
        window.addEventListener('input-int-focus-out', handleFocusOut);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-int-change', handleChange);
            window.removeEventListener('input-date-focus-out', handleFocusOut);
            window.removeEventListener('input-int-focus-out', handleFocusOut);
        };
    }, [handleFocusOut, handleChange]);
    const customSelectorData = [
        {
            title: tr('De una vez'),
            name: 'output_type',
            subtitle: tr('Recíbelo de una vez'),
            value: AmountType.UNIQUE,
        },
        {
            title: tr('Mes a mes'),
            subtitle: tr('Recíbelo mensualmente'),
            name: 'output_type',
            value: AmountType.MONTHLY,
        },
        {
            title: tr('Año a año'),
            subtitle: tr('Recíbelo anualmente'),
            name: 'output_type',
            value: AmountType.ANNUAL,
        },
    ];
    switch (questionIndex) {
        case 0:
            return (
                <inv-grid-row
                    class={`${personalizada.marginQ1Element1}`}
                    style={{display: 'flex', justifyContent: 'center'}}
                >
                    <inv-grid-col class="col-12 col-sm-6">
                        <GoalTextInput
                            handleChange={formik.setFieldValue}
                            color="var(--custom-color)"
                            value={formik.values.name}
                            name="name"
                        />
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 1:
            return (
                <>
                    <inv-grid-row
                        class={personalizada.marginQ2Element1}
                        style={{
                            '--inv-grid-gutter-y': '0.8em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {customSelectorData.map((data) => (
                            <inv-grid-col key={data.title} class="col-12 col-sm-3">
                                <Selector
                                    title={data.title}
                                    /* @ts-ignore */
                                    name={data.name}
                                    selected={formik.values.output_type === data.value}
                                    onClick={() => {
                                        formik.setFieldValue(data.name, data.value, false);
                                    }}
                                    size="medium"
                                    subtitle={data.subtitle}
                                />
                            </inv-grid-col>
                        ))}
                    </inv-grid-row>
                    {formik.values.output_type !== null && (
                        <>
                            <inv-grid-row
                                class={`${personalizada.marginQ2Element2} ${
                                    formik.values.output_type === AmountType.UNIQUE
                                        ? personalizada.marginQ2Element2Last
                                        : ''
                                }`}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col class="col-12 col-sm-4">
                                    <inv-input-date
                                        class="input input--primary"
                                        type="month"
                                        value={formik.values.init_date}
                                        error={formik.errors.init_date}
                                        name="init_date"
                                        label={initDateLabel}
                                        min={format(minDate, 'yyyy-MM')}
                                        max={format(maxDate, 'yyyy-MM')}
                                    />
                                </inv-grid-col>
                            </inv-grid-row>
                            {formik.values.output_type === AmountType.MONTHLY && (
                                <inv-grid-row
                                    class={personalizada.marginQ2Element3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <inv-grid-col class="col-12 col-sm-4">
                                        <inv-input-date
                                            class="input input--primary"
                                            type="month"
                                            value={formik.values.end_date}
                                            error={formik.errors.end_date}
                                            name="end_date"
                                            label={tr('Fecha fin de la meta (aprox.)')}
                                            max={format(maxDate, 'yyyy-MM')}
                                        />
                                    </inv-grid-col>
                                </inv-grid-row>
                            )}
                            {formik.values.output_type === AmountType.ANNUAL && (
                                <inv-grid-row
                                    class={personalizada.marginQ2Element3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <inv-grid-col class="col-12 col-sm-4">
                                        <inv-input-int
                                            class="input input--primary"
                                            value={formik.values.duration}
                                            name="duration"
                                            error={formik.errors.duration}
                                            label={tr('Durante cuantos años')}
                                            placeholder="5"
                                        />
                                    </inv-grid-col>
                                </inv-grid-row>
                            )}
                        </>
                    )}
                </>
            );
        case 2:
            return (
                <inv-grid-row
                    class={personalizada.marginQ3Element1}
                    style={{display: 'flex', justifyContent: 'center'}}
                >
                    <inv-grid-col class="col-12 col-sm-4">
                        <inv-input-int
                            class="input input--primary"
                            value={formik.values.amount}
                            name="amount"
                            error={formik.errors.amount}
                            label={tpl(tr('Importe {temporality}'), {
                                temporality:
                                    /* eslint-disable-next-line no-nested-ternary */
                                    formik.values.output_type === OutputType.ANNUAL
                                        ? tr('anual')
                                        : formik.values.output_type === OutputType.MONTHLY
                                        ? tr('mensual')
                                        : tr('total'),
                            })}
                            placeholder={
                                /* eslint-disable-next-line no-nested-ternary */
                                formik.values.output_type === OutputType.ANNUAL
                                    ? '6000'
                                    : formik.values.output_type === OutputType.MONTHLY
                                    ? '600'
                                    : '20000'
                            }
                            suffix={tpl('{currency}{temporality}', {
                                currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                temporality:
                                    /* eslint-disable-next-line no-nested-ternary */
                                    formik.values.output_type === OutputType.ANNUAL
                                        ? tr('/año')
                                        : formik.values.output_type === OutputType.MONTHLY
                                        ? tr('/mes')
                                        : '',
                            })}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 3:
            return <GoalCostComponent inflation={inflation} />;
        default:
            return null;
    }
}
