import {styled} from '@mui/material/styles';
import {Grid} from '@mui/material';
import * as React from 'react';
import theme from '../../styles/theme';

export const IndexContainer = styled((props: any) => (
    <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        rowSpacing={{xs: theme.spacing(10), xl: theme.spacing(10)}}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
    />
))(() => ({}));

export const IndexContainerTitle = styled((props: any) => (
    <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        rowSpacing={{xs: theme.spacing(4), xl: theme.spacing(4)}}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
    />
    // eslint-disable-next-line @typescript-eslint/no-shadow
))(({theme}) => ({
    [theme.breakpoints.up('mobile')]: {
        maxWidth: 530,
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 790,
    },
}));

export const IndexContainerForm = styled(
    (props: {
        downmobile: boolean;
        className: string;
        children: React.ReactNode;
    }) => (
        <Grid
            container
            direction="column"
            justifyContent="center"
            rowSpacing={{
                xs: theme.spacing(props.downmobile ? 8 : 4),
                xl: theme.spacing(4),
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...{...props, downmobile: undefined}}
        />
    )
)(() => ({
    maxWidth: 816,
}));

export const IndexContainerButtonItem = styled(
    // eslint-disable-next-line react/jsx-props-no-spreading
    (props: {className: string; children: React.ReactNode}) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Grid item {...props} />
    )
    // eslint-disable-next-line @typescript-eslint/no-shadow
)(({theme}) => ({
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.up('mobile')]: {
        paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
        paddingBottom: theme.spacing(10),
    },
}));
