import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import React from 'react';
import {useMediaQuery} from '@mui/material';
import {subYears} from 'date-fns';
import {useSelector} from 'react-redux';
import theme from '../../styles/theme';
import {PercentageSlider} from '../../elements/slider/PercentageSlider';
import Selector from '../../elements/selector/Selector';
import AdviceAlert from '../../elements/alert/AdviceAlert';
import WCIcons from '../../elements/icons/WCIcons';
import perfil from '../../styles/Perfil.module.css';
import '@inveert/ui-kit/src/input/input-text';
import '@inveert/ui-kit/src/input/input-date';
import '@inveert/ui-kit/src/input/input-int';
import {formatDateIntoYyyyMmDd, handleInputChange, handleInputFocusOut, tpl, tr} from '../../utils/functions';
// eslint-disable-next-line import/no-cycle
import {RiskProfileDialogContent} from './risk-dialog.component';
import {selectUser} from '../user/user.slice';

interface RiskFormComponent {
    formik: any;
    questionIndex: number;
    monthly_income: number;
    hideBirthdayQuestion: boolean;
    handleSubmitQuestion: (value: any) => void;
    riskProfileArray: any;
}

export function RiskProfileForm(props: RiskFormComponent) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const user = useSelector(selectUser);
    const {
        formik,
        questionIndex,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        monthly_income,
        hideBirthdayQuestion,
        handleSubmitQuestion,
        riskProfileArray,
    } = props;
    const monthlyIncomeSchema = yup.number().required('Introduce un valor numérico').nullable();
    const otherAndRentIncomeSchema = yup.number().nullable();
    const wealthSchema = yup
        .number()
        .nullable()
        .required('Introduce un valor numérico')
        .min(0, 'La cantidad debe ser positiva')
        .max(9999999999999.99, 'La cantidad excede el máximo permitido');
    const objectSchema = {
        wealth: wealthSchema,
        monthly_income: monthlyIncomeSchema,
        rent_income: otherAndRentIncomeSchema,
        other_income: otherAndRentIncomeSchema,
    };
    const handleBlur = (e: any) => handleInputFocusOut(e, formik);
    const handleChange = (e: any) => handleInputChange(e, formik, objectSchema);
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-date-focus-out', handleBlur);
        window.addEventListener('input-text-change', handleChange);
        window.addEventListener('input-text-focus-out', handleBlur);
        window.addEventListener('input-int-change', handleChange);
        window.addEventListener('input-int-focus-out', handleBlur);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-date-focus-out', handleBlur);
            window.removeEventListener('input-text-focus-out', handleBlur);
            window.removeEventListener('input-text-change', handleChange);
            window.removeEventListener('input-int-focus-out', handleBlur);
            window.removeEventListener('input-int-change', handleChange);
        };
    }, [handleBlur, handleChange]);
    const profileQuestion1SelectorData = [
        {
            title: tr('Poco'),
            name: 'stability',
            value: 1,
        },
        {
            title: tr('Bastante'),
            name: 'stability',
            value: 2,
        },
        {
            title: tr('Mucho'),
            name: 'stability',
            value: 3,
        },
    ];

    const profileQuestion3SelectorData = [
        {
            title: tr('Enseñanza obligatoria'),
            name: 'studies',
            value: 1,
        },
        {
            title: tr('Formación profesional'),
            name: 'studies',
            value: 2,
        },
        {
            title: tr('Universitaria o superior'),
            name: 'studies',
            value: 3,
        },
    ];

    const profileQuestion4SelectorData = [
        {
            title: tr('Más de 5 años'),
            name: 'years_investing',
            value: 1,
        },
        {
            title: tr('Entre 1 y 5 años'),
            name: 'years_investing',
            value: 2,
        },
        {
            title: tr('Menos de 1 año'),
            name: 'years_investing',
            value: 3,
        },
        {
            title: tr('Nunca'),
            name: 'years_investing',
            value: 4,
        },
    ];

    const profileQuestion5SelectorData = [
        {
            title: tr('Sí'),
            name: 'experience',
            value: 1,
        },
        {
            title: tr('No, nunca'),
            name: 'experience',
            value: 2,
        },
    ];

    const profileQuestion6SelectorData = [
        {
            title: tr('Rentabilidad ALTA'),
            subtitle: tr('Aunque exija tiempo y riesgo'),
            name: 'goal',
            value: 1,
        },
        {
            title: tr('EQUILIBRIO'),
            subtitle: tr('Entre rentabilidad y estabilidad'),
            name: 'goal',
            value: 2,
        },
        {
            title: tr('Riesgo BAJO'),
            subtitle: tr('Batir la inflación y no perder'),
            name: 'goal',
            value: 3,
        },
    ];

    const profileQuestion7SelectorData = [
        {
            title: tr('Pérdida'),
            name: 'risk',
            value: 1,
        },
        {
            title: tr('Incertidumbre'),
            name: 'risk',
            value: 2,
        },
        {
            title: tr('Oportunidad'),
            name: 'risk',
            value: 3,
        },
        {
            title: tr('Emoción'),
            name: 'risk',
            value: 4,
        },
    ];

    const profileQuestion8SelectorData = [
        {
            title: tr('Hasta -3%'),
            name: 'attitude',
            value: 1,
        },
        {
            title: tr('Hasta -7%'),
            name: 'attitude',
            value: 2,
        },
        {
            title: tr('Hasta -14%'),
            name: 'attitude',
            value: 3,
        },
        {
            title: tr('Hasta -20%'),
            name: 'attitude',
            value: 4,
        },
    ];
    switch (questionIndex) {
        case 0:
            return (
                <>
                    <inv-grid-row class={perfil.marginQ1Element1} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-text
                                class="input input--primary"
                                value={formik.values.name}
                                name="name"
                                error={formik.errors.name}
                                label={tr('Tu nombre de pila')}
                                placeholder="Luis"
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    {!hideBirthdayQuestion && (
                        <inv-grid-row
                            class={perfil.marginQ1Element2}
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <inv-grid-col class="col-12 col-sm-4">
                                <inv-input-date
                                    class="input input--primary"
                                    value={formik.values.birthday}
                                    error={formik.errors.birthday}
                                    name="birthday"
                                    label={tr('Fecha de nacimiento')}
                                    max={formatDateIntoYyyyMmDd(subYears(new Date(), 18))}
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                    )}
                    <inv-grid-row class={perfil.marginQ1Element3} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-text
                                class="input input--primary"
                                value={formik.values.zip_code}
                                name="zip_code"
                                error={formik.errors.zip_code}
                                label={tr('Código postal de tu residencia fiscal')}
                                placeholder="41011"
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 1:
            return (
                <>
                    <inv-grid-row
                        key="monthly_income"
                        class={perfil.marginQ2Element1}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-int
                                class="input input--primary"
                                value={formik.values.monthly_income}
                                name="monthly_income"
                                error={formik.errors.monthly_income}
                                label={tr('Por trabajo')}
                                placeholder="1200"
                                suffix={tpl(tr('{currency}/mes'), {
                                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                })}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        key="rent_income"
                        class={perfil.marginQ2Element2}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-int
                                class="input input--primary"
                                value={formik.values.rent_income}
                                name="rent_income"
                                error={formik.errors.rent_income}
                                label={tr('En alquileres')}
                                placeholder="600"
                                suffix={tpl(tr('{currency}/mes'), {
                                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                })}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        key="other"
                        class={perfil.marginQ2Element3}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-int
                                class="input input--primary"
                                value={formik.values.other_income}
                                name="other_income"
                                error={formik.errors.other_income}
                                label={tr('Otros')}
                                placeholder="400"
                                suffix={tpl(tr('{currency}/mes'), {
                                    currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                })}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 2:
            return (
                <inv-grid-row
                    class={perfil.marginQ3Element1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '--inv-grid-gutter-y': '0.8em',
                    }}
                >
                    {profileQuestion1SelectorData.map((data) => (
                        <inv-grid-col key={data.title} class="col-12 col-sm-auto">
                            <Selector
                                title={data.title}
                                selected={formik.values.stability === data.value}
                                onClick={() => {
                                    formik.setFieldValue(data.name, data.value, false).then(() =>
                                        handleSubmitQuestion({
                                            stability: data.value,
                                        })
                                    );
                                }}
                                width={downMobile ? undefined : '12em'}
                                height={downMobile ? 'fit-content' : '12em'}
                            />
                        </inv-grid-col>
                    ))}
                </inv-grid-row>
            );
        case 3:
            return (
                <>
                    <inv-grid-row class={perfil.marginQ4Element1} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-xl
                                style={{
                                    '--inv-text-xl-font-weight': 600,
                                    '--inv-text-xl-color': 'var(--text-color-50)',
                                }}
                            >
                                <NumberFormat
                                    value={formik.values.expenses * 0.01 * monthly_income}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix={` ${process.env.REACT_APP_CURRENCY_SYMBOL || '€'}`}
                                />
                            </inv-text-xl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={perfil.marginQ4Element2} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-6">
                            <PercentageSlider
                                value={formik.values.expenses}
                                handleChange={formik.setFieldValue}
                                name="expenses"
                                valueLabelFormat={(value: number) => `${value}%`}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={perfil.marginQ4Element3} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-6">
                            <AdviceAlert
                                text="Hablamos de gastos básicos: (1) vivienda, (2) suministros, (3) alimentación, (4) salud y (5) personas a tu cargo."
                                icon={<WCIcons name="help" width="25" height="24" color="var(--color-secondary-100)" />}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 4:
            return (
                <>
                    <inv-grid-row class={perfil.marginQ5Element1} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-int
                                class="input input--primary"
                                value={formik.values.wealth}
                                name="wealth"
                                error={formik.errors.wealth}
                                label={tr('Cantidad total (aprox.)')}
                                placeholder="50000"
                                suffix={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row class={perfil.marginQ5Element2} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-6">
                            <AdviceAlert
                                text={tr(
                                    'Incluye: (1) Cuentas corrientes, (2) Depósitos, (3) Fondos de inversión, (4) Acciones y (5) Seguros unit linked.'
                                )}
                                icon={<WCIcons name="help" width="25" height="24" color="var(--color-secondary-100)" />}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 5:
            return (
                <inv-grid-row
                    class={perfil.marginQ3Element1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '--inv-grid-gutter-y': '0.8em',
                    }}
                >
                    {profileQuestion3SelectorData.map((data) => (
                        <inv-grid-col key={data.title} class="col-12 col-sm-auto">
                            <Selector
                                title={data.title}
                                selected={formik.values.studies === data.value}
                                onClick={() => {
                                    formik.setFieldValue(data.name, data.value, false).then(() =>
                                        handleSubmitQuestion({
                                            studies: data.value,
                                        })
                                    );
                                }}
                                width={downMobile ? undefined : '12em'}
                                height={downMobile ? 'fit-content' : '12em'}
                            />
                        </inv-grid-col>
                    ))}
                </inv-grid-row>
            );
        case 6:
            return (
                <inv-grid-row
                    class={perfil.marginQ3Element1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '--inv-grid-gutter-y': '0.8em',
                    }}
                >
                    {profileQuestion4SelectorData.map((data) => (
                        <inv-grid-col key={data.title} class="col-12 col-sm-auto">
                            <Selector
                                title={data.title}
                                selected={formik.values.years_investing === data.value}
                                onClick={() => {
                                    formik.setFieldValue(data.name, data.value, false).then(() =>
                                        handleSubmitQuestion({
                                            years_investing: data.value,
                                        })
                                    );
                                }}
                                width={downMobile ? undefined : '12em'}
                                height={downMobile ? 'fit-content' : '12em'}
                            />
                        </inv-grid-col>
                    ))}
                </inv-grid-row>
            );
        case 7:
            return (
                <inv-grid-row
                    class={perfil.marginQ3Element1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '--inv-grid-gutter-y': '0.8em',
                    }}
                >
                    {profileQuestion5SelectorData.map((data) => (
                        <inv-grid-col key={data.title} class="col-12 col-sm-auto">
                            <Selector
                                title={data.title}
                                selected={formik.values.experience === data.value}
                                onClick={() => {
                                    formik.setFieldValue(data.name, data.value, false).then(() =>
                                        handleSubmitQuestion({
                                            experience: data.value,
                                        })
                                    );
                                }}
                                width={downMobile ? undefined : '12em'}
                                height={downMobile ? 'fit-content' : '12em'}
                            />
                        </inv-grid-col>
                    ))}
                </inv-grid-row>
            );
        case 8:
            return (
                <inv-grid-row
                    class={perfil.marginQ3Element1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '--inv-grid-gutter-y': '0.8em',
                    }}
                >
                    {profileQuestion6SelectorData.map((data) => (
                        <inv-grid-col key={data.title} class="col-12 col-sm-auto">
                            <Selector
                                title={data.title}
                                subtitle={data.subtitle}
                                selected={formik.values.goal === data.value}
                                onClick={() => {
                                    formik.setFieldValue(data.name, data.value, false).then(() =>
                                        handleSubmitQuestion({
                                            goal: data.value,
                                        })
                                    );
                                }}
                                width={downMobile ? undefined : '12em'}
                                height={downMobile ? 'fit-content' : '12em'}
                            />
                        </inv-grid-col>
                    ))}
                </inv-grid-row>
            );
        case 9:
            return (
                <inv-grid-row
                    class={perfil.marginQ3Element1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '--inv-grid-gutter-y': '0.8em',
                    }}
                >
                    {profileQuestion7SelectorData.map((data) => (
                        <inv-grid-col key={data.title} class="col-12 col-sm-auto">
                            <Selector
                                title={data.title}
                                selected={formik.values.risk === data.value}
                                onClick={() => {
                                    formik.setFieldValue(data.name, data.value, false).then(() =>
                                        handleSubmitQuestion({
                                            risk: data.value,
                                        })
                                    );
                                }}
                                width={downMobile ? undefined : '12em'}
                                height={downMobile ? 'fit-content' : '12em'}
                            />
                        </inv-grid-col>
                    ))}
                </inv-grid-row>
            );
        case 10:
            return (
                <inv-grid-row
                    class={perfil.marginQ3Element1}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '--inv-grid-gutter-y': '0.8em',
                    }}
                >
                    {profileQuestion8SelectorData.map((data) => (
                        <inv-grid-col key={data.title} class="col-12 col-sm-auto">
                            <Selector
                                title={data.title}
                                selected={formik.values.attitude === data.value}
                                onClick={() => {
                                    formik
                                        .setFieldValue(data.name, data.value, false)
                                        .then(() =>
                                            handleSubmitQuestion({
                                                attitude: data.value,
                                            })
                                        )
                                        .finally(() => formik.handleSubmit());
                                }}
                                width={downMobile ? undefined : '12em'}
                                height={downMobile ? 'fit-content' : '12em'}
                            />
                        </inv-grid-col>
                    ))}
                </inv-grid-row>
            );
        case 11:
            return (
                <RiskProfileDialogContent
                    profileNumberMax={user?.riskProfile?.profile_id}
                    profileNumber={user?.riskProfile?.profile_id}
                    riskProfileArray={riskProfileArray}
                />
            );
        default:
            return null;
    }
}

export default RiskProfileForm;
