import * as React from 'react';
// @ts-ignore
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {addAllObjectives, selectObjectives} from '../../modules/goal/goal.slice';
import SolidDividers from '../../elements/dividers/SolidDividers';
import PageLoading from '../../elements/loading/PageLoading';
import SelectorGoals from '../../components/goals/SelectorGoals';
import {
    checkIsRetirementDisabled,
    checkIsSafetyNetDisabled,
    getApiUrl,
    getErrorMessage,
    tr,
} from '../../utils/functions';
import {MetaContainer, MetaContainerButtonItem, MetaContainerTitle} from '../../modules/goal/goal-index.style';
import {setIsAuthDialogOpen} from '../../modules/auth/auth.slice';
import {getObjectives} from '../../modules/goal/goal.service';
import {addAlert} from '../../modules/app/app.slice';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/button/button';
import '@inveert/ui-kit/src/button/button-fab/button-fab';
import {Size, useWindowSize} from '../../utils/hooks';
import meta from '../../styles/Meta.module.css';
import {getUserIdApi} from '../../modules/user/user.service';
import {authLoginUser, postRegisterUserWithEmailAndPostalCode} from '../../modules/auth/auth.service';
import {selectUser} from '../../modules/user/user.slice';

function Meta(props: any) {
    const {goBack, setSelectedGoalType} = props;
    const {name, zipCode} = useSelector(selectUser);
    const apiUrl = getApiUrl();
    const objectivesData = useSelector(selectObjectives);
    const dispatch = useDispatch();
    const router = useNavigate();
    const size: Size = useWindowSize();
    const [loading, setLoading] = React.useState(true);
    const [objectives, setObjectives] = React.useState([]);
    const [isRetirementDisabled, setIsRetirementDisabled] = React.useState(false);
    const [isSafetyNetDisabled, setIsSafetyNetDisabled] = React.useState(false);

    const handleResize = (goals: any) => {
        const currentWidth = size.width ?? 0;
        const downMobile = currentWidth < 576;
        const retirementDisabled = checkIsRetirementDisabled(goals, objectivesData, downMobile);
        const safetyNetDisabled = checkIsSafetyNetDisabled(goals, objectivesData, downMobile);
        if (safetyNetDisabled !== isSafetyNetDisabled) {
            setIsSafetyNetDisabled(safetyNetDisabled);
        }
        if (retirementDisabled !== isRetirementDisabled) {
            setIsRetirementDisabled(retirementDisabled);
        }
    };

    React.useEffect(() => {
        dispatch(addAllObjectives(objectives));
    }, [dispatch, objectives]);
    const getAllObjectives = async () =>
        getObjectives()
            .then((res) => {
                setObjectives(res);
                dispatch(setIsAuthDialogOpen(false));
                handleResize(res);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
            });

    const register = async () => {
        // eslint-disable-next-line no-undef
        const storage = globalThis?.sessionStorage;
        let token = '';
        if (storage) {
            // @ts-ignore
            token = storage.getItem('token') ?? null;
            if (token) {
                try {
                    const userInfo = await getUserIdApi(token);
                    if (!userInfo?.attributes?.user_id || userInfo?.attributes?.user_id === '') {
                        const email = `${token}@${process.env.REACT_APP_THEME}.com`;
                        const password = token.substring(0, 10);
                        await postRegisterUserWithEmailAndPostalCode(
                            email,
                            password,
                            name || 'Anon',
                            zipCode || '00000'
                        );
                        await authLoginUser({email, password});
                    }
                } catch (e) {
                    // @ts-ignore
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                }
            }
        }
    };
    React.useEffect(() => {
        setLoading(true);
        getAllObjectives()
            .then(() => {
                register().finally(() => {
                    setLoading(false);
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', () => handleResize(objectives));
        return () => {
            // @ts-ignore
            window.removeEventListener('keydown', () => handleResize(objectives));
        };
    }, [handleResize]);
    const hanldeMessage = (event: any) => {
        if (event.data.error?.message) {
            setLoading(false);
        } else if (event.data?.data?.token) {
            setLoading(true);
            const storage = globalThis?.sessionStorage;
            if (storage) {
                storage.setItem('token', event.data?.data?.token ?? '');
                getUserIdApi(event.data?.data?.token)
                    .then(() => {
                        setLoading(false);
                        router('/meta');
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        setLoading(false);
                    });
            } else {
                dispatch(
                    addAlert({
                        message: tr('No se ha podido iniciar sesión'),
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            }
        }
    };
    React.useEffect(() => {
        window.addEventListener('message', hanldeMessage);
        return () => {
            // @ts-ignore
            window.removeEventListener('message', hanldeMessage);
        };
    }, []);
    return (
        <>
            <PageLoading open={loading} />
            {process.env.REACT_APP_ENV !== 'pro' &&
                process.env.REACT_APP_ENV !== 'app' &&
                (process.env.REACT_APP_THEME || '').includes('finsei') && (
                    <inv-button-fab
                        style={{
                            '--inv-input-icon-color': '#fff',
                            fontSize: '18px',
                        }}
                        items={JSON.stringify([
                            {
                                icon: 'social-facebook',
                                href: `${apiUrl}/api/v2/oauth/v1/provider/facebook/request-token`,
                                label: tr('Iniciar sesión con Facebook'),
                                target: '_blank',
                                rel: 'opener',
                            },
                            {
                                icon: 'social_twitter',
                                href: `${apiUrl}/api/v2/oauth/v1/provider/twitter/request-token`,
                                label: tr('Iniciar sesión con Twitter'),
                                target: '_blank',
                                rel: 'opener',
                            },
                            {
                                icon: 'social-google-color',
                                href: `${apiUrl}/api/v2/oauth/v1/provider/google/request-token`,
                                label: tr('Iniciar sesión con Google'),
                                target: '_blank',
                                rel: 'opener',
                            },
                            {
                                icon: 'delete',
                                href: '/dev/delete-user',
                                label: tr('Borrar usuario actual'),
                            },
                        ])}
                    />
                )}
            <MetaContainer>
                {/* Need to wrap containers on item to apply maxwidth */}
                <inv-grid-row
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <inv-grid-col class="col">
                        <MetaContainerTitle>
                            <inv-grid-row>
                                <inv-grid-col class="col">
                                    <inv-text-l
                                        style={{
                                            '--inv-text-l-gradient': 'var(--color-tertiary)',
                                            '--inv-text-l-font-weight': 600,
                                        }}
                                    >
                                        {objectives.length === 0
                                            ? tr('¿Cuál es tu meta principal?')
                                            : tr('¿Tienes otras metas?')}
                                    </inv-text-l>
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row
                                style={{
                                    marginTop: '0.8em',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col class="col col-sm-9" style={{display: 'flex'}}>
                                    <inv-text-xxs
                                        style={{
                                            '--inv-text-xxs-color': 'var(--text-color-50)',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {tr(
                                            'Te guiaremos paso a paso, para entender perfectamente cuánto necesitas y para cuándo.'
                                        )}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                        </MetaContainerTitle>
                    </inv-grid-col>
                </inv-grid-row>
                <div className={meta.selectorContainer}>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-8">
                            <SelectorGoals
                                setLoading={setLoading}
                                isRetirementDisabled={isRetirementDisabled}
                                isSafetyNetDisabled={isSafetyNetDisabled}
                                setGoalType={setSelectedGoalType}
                                isDialog={!!goBack}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                {!((size.width ?? 0) < 576) && objectives.length >= 1 && (
                    <inv-grid-row class={`${meta.divider}`}>
                        <inv-grid-col class="col-12">
                            <div
                                style={{
                                    maxWidth: 'calc(100% - var(--inv-grid-gutter-x))',
                                }}
                            >
                                <SolidDividers orientation="horizontal" />
                            </div>
                        </inv-grid-col>
                    </inv-grid-row>
                )}
                {(objectives.length >= 1 || goBack) && (
                    <MetaContainerButtonItem>
                        <inv-grid-row class={meta.buttonRow}>
                            <inv-grid-col
                                class={`col ${meta.buttonCol}`}
                                style={{
                                    justifyContent: goBack ? 'space-between' : 'flex-end',
                                }}
                            >
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {goBack ? (
                                    <inv-button
                                        onClick={() => (goBack ? goBack() : router(-1))}
                                        class="button button--secondary-contained"
                                    >
                                        {tr('Atrás')}
                                    </inv-button>
                                ) : objectives.length >= 1 ? (
                                    <inv-button
                                        disabled={objectives.length < 1 ? true : undefined}
                                        onClick={() => router('/meta/fin')}
                                        class="button button--primary-contained"
                                    >
                                        {tr('Continuar sin añadir una meta')}
                                    </inv-button>
                                ) : (
                                    <div style={{height: '2.5em'}} />
                                )}
                            </inv-grid-col>
                        </inv-grid-row>
                    </MetaContainerButtonItem>
                )}
            </MetaContainer>
        </>
    );
}
export default Meta;
