import {createSlice} from '@reduxjs/toolkit';
import {cecabankJobs} from './contract.data';

export const initialState = {
    email: '',
    userForm: {
        name: '',
        lastName1: '',
        lastName2: '',
        type_id: null,
        document: '',
        expiration_date: null,
        maritalStatus: '',
        mobile: null,
        nationality: '',
        birth_country: '',
        birth_region: '',
        birth_city: '',
        varias_nacionalidades: false,
        primera_nacionalidad_extra: null,
        province: '',
        postal_code: null,
        region: '',
        city: '',
        address_type: '',
        address: '',
        address_number: '',
        address_floor: '',
        iban: '',
        economic_activity: '',
        occupation: '',
        company: '',
    },
    kycForm: {
        haveLinkCecabank: null,
        linkCecabank: cecabankJobs[cecabankJobs.length - 1],
        fiscal_obligations: null,
        public_functions: null,
        public_functions_family: null,
        other_sources_income: null,
    },
};

export const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setUserFormData(state, action) {
            return {...state, userForm: {...action.payload}};
        },
        setKycFormData(state, action) {
            return {...state, kycForm: {...action.payload}};
        },
        setFinanbestEmail(state, action) {
            return {...state, email: action.payload};
        },
    },
});

export const selectContract = (state: any) => state.contract;

export const {setUserFormData, setKycFormData, setFinanbestEmail} =
    contractSlice.actions;

export default contractSlice.reducer;
