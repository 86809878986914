import {formatISO, isValid} from 'date-fns';
import {es, ca} from 'date-fns/locale';
// @ts-ignore
import jfTpl from 'jf-tpl';
import {Objective} from '../modules/goal/goal.type';
// eslint-disable-next-line import/no-cycle
import {store} from '../store';
// Import translations as before
import ca_ES from '../locales/ca-ES.json';

const translations = {
    'ca-ES': ca_ES,
    // Add more locales as needed
};

export function isDomain(str: string) {
    const domainRegex = /^(?:[-A-Za-z0-9]+\.)+[A-Za-z]{2,6}$/;
    return domainRegex.test(str);
}

let apiUrl: string;
export function getApiUrl() {
    if (!apiUrl) {
        if (process.env.REACT_APP_ENV === 'local') {
            apiUrl = `http://localhost`;
        } else {
            const envSubdomain = process.env.REACT_APP_SUBDOMAIN || '';
            const isSubdomainADomain = isDomain(envSubdomain);
            const domain = isSubdomainADomain ? envSubdomain : 'inveert.com';
            if (isSubdomainADomain) {
                const subdomain = process.env.REACT_APP_ENV === 'pro' ? '' : `${process.env.REACT_APP_ENV}.`;
                apiUrl = `https://${subdomain}${domain}`;
            } else {
                const subdomain =
                    process.env.REACT_APP_ENV === 'pro' ? envSubdomain : `${envSubdomain}-${process.env.REACT_APP_ENV}`;
                apiUrl = `https://${subdomain}.${domain}`;
            }
        }
    }
    return apiUrl;
}

export const {fetch: originalFetch} = window;

window.fetch = async (...args) => {
    const [resource, config] = args;
    const storage = globalThis?.sessionStorage;
    const token = storage.getItem('token');
    const {locale} = store.getState().app;
    const newHeaders = new Headers({
        Accept: 'application/vnd.api+json',
        'Accept-Language': locale || 'es-ES',
    });
    if (config?.body) {
        newHeaders.append('Content-Type', 'application/vnd.api+json');
    }
    if (token) {
        newHeaders.append('X-Auth-Token', token);
    } else {
        store.dispatch({type: 'reset'});
        const sessionResponse = await originalFetch(`${getApiUrl()}/api/v2/auth/v1/sessions`, {
            method: 'POST',
        });
        if (sessionResponse.headers.get('X-Auth-token')) {
            storage.setItem('token', sessionResponse.headers.get('X-Auth-token') ?? '');
            newHeaders.append('X-Auth-Token', sessionResponse.headers.get('X-Auth-token') ?? '');
        }
    }
    const newConfig = {...config, headers: newHeaders};
    // request interceptor here
    // @ts-ignore
    const response = await originalFetch(resource, newConfig);
    // response interceptor here
    if (response.headers.get('X-Auth-token') && storage) {
        storage.setItem('token', response.headers.get('X-Auth-token') ?? '');
    }
    return response;
};

export function setIsAuthDialogOpenInStorage(isAuthDialogOpen: 'true' | 'false') {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    storage.setItem('isAuthDialogOpen', isAuthDialogOpen);
}

export function getTokenInStorage() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // eslint-disable-next-line consistent-return
    return storage.getItem('token');
}

export function setTokenInStorage(token: 'string') {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    storage.setItem('token', token);
}

function getIsAuthDialogOpenInStorage() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // eslint-disable-next-line consistent-return
    return storage.getItem('isAuthDialogOpen');
}

export const fetchApi = async (
    url: string,
    method: 'post' | 'get' | 'put' | 'delete' | 'patch',
    payload: any | undefined
) => {
    const methodUpperCase = method.toUpperCase();
    const finalUrl = `${getApiUrl()}/api/v2/${url}`;
    let res: Promise<Response>;
    let responseData;
    let returnedRes;
    if (methodUpperCase === 'GET' || methodUpperCase === 'DELETE' || !payload) {
        res = fetch(finalUrl, {method: methodUpperCase});
    } else {
        res = fetch(finalUrl, {
            body: JSON.stringify(payload),
            method: methodUpperCase,
        });
    }
    const resolvedResponse = await res;
    if (resolvedResponse.ok) {
        try {
            responseData = await resolvedResponse?.json();
        } catch {
            responseData = null;
        }
        if (
            responseData?.included ||
            (responseData?.meta && url?.includes('summary?include=GoalSummary,Probability,Scenarios'))
        ) {
            returnedRes = Promise.resolve({
                data: responseData.data,
                included: responseData?.included,
                meta: responseData?.meta,
            });
        } else {
            returnedRes = Promise.resolve(responseData?.data ?? null);
        }
    } else {
        responseData = await resolvedResponse.text();
        returnedRes = Promise.reject(responseData);
    }
    return returnedRes;
};

/**
 * Comprueba si es un JSON válido
 * @param {string} str - String a comprobar
 */
function isJSON(str: string) {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

export const refreshAuthLogic = (failedRequest: any) =>
    fetchApi('auth/login/refresh', 'post', {})
        .then((tokenRefreshResponse: any) => {
            const theFailedRequest = failedRequest;
            theFailedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.access_token}`;
            return Promise.resolve();
        })
        .catch(() => {
            if (getIsAuthDialogOpenInStorage() === 'false') {
                window.location.reload();
            }
        });

export const getMoneyInFutureWithInflation = (quantity: number, years: number, inflation: number) => {
    const inflationRate = 1 + inflation;
    let inflatedMoney = quantity;
    let i;
    for (i = 0; i < years; i += 1) {
        inflatedMoney *= inflationRate;
    }
    return inflatedMoney;
};

export function getMoneyInPresentWithoutInflation(
    quantity: number,
    yearsFromPresentToFuture: number,
    inflation: number
) {
    const inflationRate = 1 + inflation;
    return inflationRate ** -yearsFromPresentToFuture * quantity;
}

export const Capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const validateDniNifNie = (value: string | undefined | null) => {
    if (value) {
        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        const str = value.toString().toUpperCase();

        if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

        const nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');

        const letter = str.substr(-1);
        const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

        if (validChars.charAt(charIndex) === letter) return true;

        return false;
    }
    return false;
};

export const parseBarChartDataWhenMobile = (data: Array<any>) => {
    // this is the rate at wich we will be getting the data of the array
    // example if dataRate is 3 we will get 1 item of data and dismiss 3
    const dataRate = Math.trunc((data.length - 2) / 8);
    let parsedData;
    if (data.length > 10) {
        parsedData = data.filter((item, index) => {
            let returnedItem;
            if (index % dataRate === 0) {
                returnedItem = item;
            } else if (index === 0) {
                returnedItem = item;
            } else if (index === data.length - 1) {
                returnedItem = item;
            }
            return returnedItem;
        });
    } else {
        parsedData = data;
    }
    return parsedData;
};

export const checkIsRetirementDisabled = (
    objectives: Array<{attributes: Objective}>,
    objectivesData: {
        lastObjectiveId: number | null;
        lastObjectiveType: string | null;
        objectives: Array<{attributes: Objective}>;
    },
    isMobile?: boolean
) =>
    objectives.reduce((isDisabled: boolean, objective: {attributes: Objective}) => {
        let thisIsDisabled = isDisabled;
        if (
            (objective.attributes.type === 'RETIREMENT' || objective.attributes.type === 'RETIREMENT_ONLY_FUND') &&
            ((objectivesData.lastObjectiveType !== 'RETIREMENT' &&
                objectivesData.lastObjectiveType !== 'RETIREMENT_ONLY_FUND') ||
                isMobile === true)
        ) {
            thisIsDisabled = true;
        }
        return thisIsDisabled;
    }, false);

export const checkIsSafetyNetDisabled = (
    objectives: Array<{attributes: Objective}>,
    objectivesData: {
        lastObjectiveId: number | null;
        lastObjectiveType: string | null;
        objectives: Array<{attributes: Objective}>;
    },
    isMobile?: boolean
) =>
    objectives.reduce((isDisabled: boolean, objective: {attributes: Objective}) => {
        if (
            objective.attributes.type === 'SAFETY_NET' &&
            (objectivesData.lastObjectiveType !== 'SAFETY_NET' || isMobile === true)
        ) {
            // eslint-disable-next-line no-param-reassign
            isDisabled = true;
        }
        return isDisabled;
    }, false);

function mod97(string: string) {
    let checksum = string.slice(0, 2);
    let fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        // @ts-ignore
        checksum = parseInt(fragment, 10) % 97;
    }
    // @ts-ignore
    return checksum === 1;
}

export function ibanValidator(value: string | null | undefined) {
    let result = false;
    if (value) {
        const input = value ? value.replace(' ', '') : '';
        const CODE_LENGTHS = {
            AD: 24,
            AE: 23,
            AT: 20,
            AZ: 28,
            BA: 20,
            BE: 16,
            BG: 22,
            BH: 22,
            BR: 29,
            CH: 21,
            CR: 21,
            CY: 28,
            CZ: 24,
            DE: 22,
            DK: 18,
            DO: 28,
            EE: 20,
            ES: 24,
            FI: 18,
            FO: 18,
            FR: 27,
            GB: 22,
            GI: 23,
            GL: 18,
            GR: 27,
            GT: 28,
            HR: 21,
            HU: 28,
            IE: 22,
            IL: 23,
            IS: 26,
            IT: 27,
            JO: 30,
            KW: 30,
            KZ: 20,
            LB: 28,
            LI: 21,
            LT: 20,
            LU: 20,
            LV: 21,
            MC: 27,
            MD: 24,
            ME: 22,
            MK: 19,
            MR: 27,
            MT: 31,
            MU: 30,
            NL: 18,
            NO: 15,
            PK: 24,
            PL: 28,
            PS: 29,
            PT: 25,
            QA: 29,
            RO: 24,
            RS: 22,
            SA: 24,
            SE: 24,
            SI: 19,
            SK: 24,
            SM: 27,
            TN: 24,
            TR: 26,
            AL: 28,
            BY: 28,
            EG: 29,
            GE: 22,
            IQ: 23,
            LC: 32,
            SC: 31,
            ST: 25,
            SV: 28,
            TL: 23,
            UA: 29,
            VA: 22,
            VG: 24,
            XK: 20,
        };
        const iban = String(input)
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
        const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
        let digits;
        // check syntax and length
        // @ts-ignore
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        // @ts-ignore
        // eslint-disable-next-line prefer-const
        digits = (code[3] + code[1] + code[2]).replace(
            /[A-Z]/g,
            // @ts-ignore
            (letter) => letter.charCodeAt(0) - 55
        );
        // final check
        result = mod97(digits);
    } else {
        result = false;
    }
    return result;
}

export function uniq(a: Array<any>) {
    return Array.from(new Set(a));
}

export const getBase64 = (file: Blob) =>
    new Promise((resolve) => {
        let baseURL: string | ArrayBuffer | null = '';
        // Make new FileReader
        const reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            let result = '';
            if (typeof baseURL === 'string') {
                const base64Content = baseURL?.split(',');
                // eslint-disable-next-line prefer-destructuring
                result = base64Content[1] ?? '';
            }
            resolve(result);
        };
    });

export const formatDateIntoYyyyMmDd = (date: Date) => (isValid(date) ? formatISO(date).split('T')[0] : null);

export const handleInputFocusOut = (e: any, formik: any) => {
    const name = e.target.attributes.name.value;
    const {value} = e.detail;
    formik.setFieldTouched(name, true, false);
    if (e.type !== 'dropdown-focus-out') {
        formik
            .setFieldValue(name, value, false)
            // @ts-ignore
            .then(() => formik.validateField(name));
    }
};

export const handleInputChange = (e: any, formik: any, schemeObject: any) => {
    const {value} = e.detail;
    const name = e.target.attributes.name.value;
    if (formik.touched[name]) {
        if (e?.type === 'input-int-change') {
            // @ts-ignore
            schemeObject[name]
                .validate(value)
                .then(() => formik.setFieldError(name, undefined))
                .catch((error: {message: string}) => {
                    formik.setFieldError(name, error.message);
                });
        } else {
            formik
                .setFieldValue(name, value, false)
                // @ts-ignore
                .then(() => formik.validateField(name));
        }
    }
};

export const getYearAndMonth = (date: string) => {
    const dividedDate = date.split('-');
    return `${dividedDate[0]}-${dividedDate[1]}`;
};

export const checkFinanbestAuthError = async (e: string) => {
    let status;
    let title;
    try {
        const errorData = await JSON.parse(e);
        status = errorData?.errors[0]?.status ?? null;
        title = errorData?.errors[0]?.title ?? null;
    } catch {
        status = null;
        title = null;
    }
    return status === '401' && title === 'Finanbest';
};

export function getWordSizeInPixels(word: string, fontSize: string, fontWeight: string, fontFamily: string) {
    // Create a temporary span element
    const tempSpan = document.createElement('span');

    // Set font properties
    tempSpan.style.fontSize = fontSize;
    tempSpan.style.fontFamily = fontFamily;
    tempSpan.style.fontWeight = fontWeight;

    // Set innerHTML to word
    tempSpan.innerHTML = word;

    // Add span to the DOM (but keep it hidden)
    tempSpan.style.position = 'absolute';
    tempSpan.style.left = '-9999px';
    document.body.appendChild(tempSpan);

    // Measure the width and height
    const width = tempSpan.offsetWidth;
    const height = tempSpan.offsetHeight;

    // Remove the span from the DOM
    document.body.removeChild(tempSpan);

    // Return an object with the width and height
    return {
        width,
        height,
    };
}

/**
 * Convierte el objeto de catalogo en un array
 * @param catalogObject {Object} - Objeto de catalogo
 * @returns {Array<{label:string; value:string}>}
 */
export const catalogObjectToArray = (catalogObject: Object) =>
    Object.entries(catalogObject).map(([key, value]) => ({
        label: value,
        value: key,
    }));

/**
 * Obtiene el label de un catalogo por su valor
 * @param catalogsArray
 * @param catalogId
 * @param value
 * @returns {string}
 */
export const getCatalogLabelByValue = (catalogsArray: Array<any>, catalogId: string, value: string) => {
    const catalogOptions = catalogsArray?.find((catalog: {id: string}) => catalog.id === catalogId)?.attributes;
    return catalogOptions.find((option: {value: string}) => option.value === value)?.label;
};

/**
 * Comprueba si la fecha esta en formato ISO
 * @param date {string} - Fecha
 * @returns {boolean} - true si la fecha esta en formato ISO
 */
export const isDateInISOFormat = (date: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(date);
};

/**
 * Funcion que devuelve el texto sin traducir
 * @param text
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function tr(text: string) {
    const {locale} = store.getState().app;
    // @ts-ignore
    const currentTranslation = translations[locale];
    return currentTranslation?.[text] || text;
}

/**
 * Funcion que devuelve el plural o singular de un texto
 * @param text
 * @param textPlural
 * @param count
 */
export function trn(text: string, textPlural: string, count: number) {
    return count > 1 ? textPlural : text;
}

/**
 * Function to apply theming, iwith interpolation
 * @param template
 * @param context
 */
export function tpl(template: any, context: any) {
    return jfTpl({context, tpl: template, keep: true});
}

export const getErrorMessage = async (
    errorText: string | {message: string},
    defaultErrorMessage: string = tr('Ha habido un error')
): Promise<string> => {
    let message: string;
    try {
        if (typeof errorText === 'string') {
            if (isJSON(errorText)) {
                const errorData = await JSON.parse(errorText);
                message = errorData?.errors[0]?.detail;
            } else {
                message = errorText;
            }
        } else {
            message = errorText?.message;
        }
    } catch {
        message = defaultErrorMessage;
    }
    if (errorText || (message && process.env.REACT_APP_ENV !== 'pro' && process.env.REACT_APP_ENV !== 'app')) {
        // eslint-disable-next-line no-console
        console.error(errorText ?? message);
    }
    return message;
};

export const getDateFnsLocale = (locale: string) => {
    switch (locale) {
        case 'ca-ES':
            return ca;
        default:
            return es;
    }
};

export function formatPhoneNumber(phoneNumber: string) {
    const parsedPhone = phoneNumber.replace(/(\d{3})(\d)/, '$1-$2');
    const splittedPhone = parsedPhone.split('-');
    const secondPhonePart = splittedPhone[1].replace(/(\d{2})(?=\d{2}|$)/g, ' $1');
    return `${splittedPhone[0]} ${secondPhonePart}`;
}

export function removeAccents(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
