import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import '../App.css';
import '../styles/globals.css';
import '../styles/scss/global.scss';
import LayoutComplete from '../styles/layouts/LayoutComplete';
import EmptyLayoutComplete from '../styles/layouts/EmptyLayoutComplete';

import PageLoading from '../elements/loading/PageLoading';
import Resultado from '../pages/propuesta/resultado';
import InicioPerfil from '../pages/perfil/inicio';
import Meta from '../pages/meta';
import Educacion from '../pages/meta/educacion';
import Vivienda from '../pages/meta/vivienda';
import Emergencia from '../pages/meta/emergencia';
import Herencia from '../pages/meta/herencia';
import Personalizada from '../pages/meta/personalizada';
import {routerDev} from './router-dev';
import Plan from '../pages/plan';
import PlanMeta from '../pages/plan/meta';
import PlanInversiones from '../pages/plan/inversiones';
import {SeleccionarPerfil} from '../pages/perfil/seleccionar';
import {DescargarPlanificacion} from '../pages/gvc';

const Perfil = React.lazy(() => import('../pages/perfil'));
const Custom404 = React.lazy(() => import('../pages/404'));
const Jubilacion = React.lazy(() => import('../pages/meta/jubilacion'));
const Fin = React.lazy(() => import('../pages/meta/fin'));
const Propuesta = React.lazy(() => import('../pages/propuesta'));

function RouterB2b() {
    return (
        <Routes>
            <Route
                path="*"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Custom404 />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route path="/" element={<Navigate to="/meta" replace />} />
            <Route
                path="/meta"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Meta />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/jubilacion"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Jubilacion />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/educacion"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Educacion />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/vivienda"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Vivienda />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/emergencia"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Emergencia />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/herencia"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Herencia />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/personalizada"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Personalizada />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/meta/fin"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Fin />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/perfil"
                element={
                    <LayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Perfil />
                        </React.Suspense>
                    </LayoutComplete>
                }
            />
            <Route
                path="/propuesta"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Propuesta />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/plan"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Plan />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/plan/meta"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <PlanMeta />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/plan/inversiones"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <PlanInversiones />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/propuesta/resultado"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <Resultado />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/perfil/inicio"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <InicioPerfil />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            <Route
                path="/perfil/seleccionar"
                element={
                    <EmptyLayoutComplete>
                        <React.Suspense fallback={<PageLoading open />}>
                            <SeleccionarPerfil />
                        </React.Suspense>
                    </EmptyLayoutComplete>
                }
            />
            {process.env.REACT_APP_PROPUESTA_RESUMEN_NEXT_STEP_PATH && (
                <Route
                    path="/gvc"
                    element={
                        <EmptyLayoutComplete>
                            <React.Suspense fallback={<PageLoading open />}>
                                <DescargarPlanificacion />
                            </React.Suspense>
                        </EmptyLayoutComplete>
                    }
                />
            )}
            {process.env.REACT_APP_ENV !== 'pro' && routerDev.map((item) => item)}
        </Routes>
    );
}

export default RouterB2b;
