import * as React from 'react';
import * as yup from 'yup';
import {format} from 'date-fns';
import GoalTextInput from '../../../elements/input/GoalTextInput';
import {DiscreteSlider} from '../../../elements/slider/DiscreteSlider';
import AdviceAlert from '../../../elements/alert/AdviceAlert';
import WCIcons from '../../../elements/icons/WCIcons';
import {GoalCostComponent} from '../goal-cost.component';
import educacion from '../../../styles/Educacion.module.css';
import '@inveert/ui-kit/src/input/input-date';
import '@inveert/ui-kit/src/input/input-int';
import {handleInputChange, handleInputFocusOut, tr} from '../../../utils/functions';

export interface GoalForm {
    formik: any;
    questionIndex: number;
    inflation: number;
    minDate: Date;
    maxDate: Date;
}

export function EducacionFormComponent(props: GoalForm) {
    const {formik, questionIndex, inflation, minDate, maxDate} = props;
    const amountScheme = yup
        .number()
        .required(tr('Introduce un valor numérico'))
        .min(1, tr('El coste tiene que ser mayor que cero'))
        .nullable();
    const schemeObject = {
        amount: amountScheme,
    };
    const handleFocusOut = (e: any) => handleInputFocusOut(e, formik);

    const handleChange = (e: any) => handleInputChange(e, formik, schemeObject);
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-date-focus-out', handleFocusOut);
        window.addEventListener('input-int-change', handleChange);
        window.addEventListener('input-int-focus-out', handleFocusOut);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-int-change', handleChange);
            window.removeEventListener('input-date-focus-out', handleFocusOut);
            window.removeEventListener('input-int-focus-out', handleFocusOut);
        };
    }, [handleFocusOut, handleChange]);
    const amountLabel =
        formik?.values?.duration < 2 ? tr('Precio total de los estudios') : tr('Precio anual de los estudios');
    switch (questionIndex) {
        case 0:
            return (
                <>
                    <inv-grid-row
                        class={`${educacion.marginTopFirstQuestionRow}`}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-auto col-sm-6">
                            <GoalTextInput
                                handleChange={formik.setFieldValue}
                                text={tr('Para')}
                                color="var(--education-color)"
                                value={formik.values.recipient}
                                name="recipient"
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        class={`${educacion.marginTopAdviceQ1} ${educacion.marginBottomAdviceQ1}`}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-12 col-sm-7">
                            <AdviceAlert
                                icon={<WCIcons name="help" color="inherit" />}
                                text={tr(
                                    'Si quieres planificar la educación de varios miembros de la familia, debemos definir una meta por cada persona.'
                                )}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 1:
            return (
                <>
                    <inv-grid-row
                        style={{display: 'flex', justifyContent: 'center'}}
                        class={educacion.marginTopQ2Element1}
                    >
                        <inv-grid-col class="col-auto col-sm-4">
                            <inv-input-date
                                class="input input--primary"
                                type="month"
                                value={formik.values.init_date}
                                error={formik.errors.init_date}
                                name="init_date"
                                label={tr('Fecha inicio de los estudios (aprox.)')}
                                min={format(minDate, 'yyyy-MM')}
                                max={format(maxDate, 'yyyy-MM')}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        style={{display: 'flex', justifyContent: 'center'}}
                        class={educacion.marginTopQ2Element2}
                    >
                        <inv-grid-col class="col-auto">
                            <inv-text-s
                                style={{
                                    '--inv-text-s-color': 'var(--text-color-50)',
                                    '--inv-text-s-font-weight': 500,
                                }}
                            >
                                {tr('¿Cuántos años dura esta formación?')}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        style={{display: 'flex', justifyContent: 'center'}}
                        class={educacion.marginQ2LastElement}
                    >
                        <inv-grid-col class="col-8 col-sm-6">
                            <DiscreteSlider
                                value={formik.values.duration}
                                min={1}
                                max={6}
                                step={1}
                                handleChange={formik.setFieldValue}
                                name="duration"
                                valueLabelFormat={(value: number) =>
                                    value > 1 ? `${value} ${tr('años')}` : `${value} ${tr('año')}`
                                }
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 2:
            return (
                <>
                    <inv-grid-row
                        style={{display: 'flex', justifyContent: 'center'}}
                        class={educacion.marginTopQ3Element1}
                    >
                        <inv-grid-col class="col-12 col-sm-5" style={{display: 'flex', justifyContent: 'center'}}>
                            <inv-text-xs
                                style={{
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                    '--inv-text-xs-font-weight': 500,
                                    textAlign: 'center',
                                }}
                            >
                                {tr('No olvides incluir el alojamiento, manutención, transporte o material académico.')}
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        style={{display: 'flex', justifyContent: 'center'}}
                        class={educacion.marginQ3LastElement}
                    >
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-int
                                class="input input--primary"
                                value={formik.values.amount}
                                name="amount"
                                error={formik.errors.amount}
                                label={amountLabel}
                                placeholder={formik?.values?.duration < 2 ? '10.000' : '3000'}
                                suffix={`${process.env.REACT_APP_CURRENCY_SYMBOL || '€'}${
                                    formik?.values?.duration < 2 ? '' : tr('/año')
                                }`}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 3:
            return <GoalCostComponent inflation={inflation} />;
        default:
            return null;
    }
}
