import * as React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import CloseDialog from '../../../components/close-dialog/close-dialog.component';
import styles from './plan-contribution-extra-dialog.module.css';
import ToggleSwitch from '../../../elements/input/toggle-switch.component';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/input/input-int';
import '@inveert/ui-kit/src/text/text-xs';

/**
 * @typedef {Object} Props
 * @property {function} handleCloseDialogClick - Función que se ejecuta al hacer click en el icono
 */

/**
 * Componente que muestra un diálogo para realizar un reembolso
 * @return {JSX.Element}
 * @param {Props} props
 */
function PlanContributionRefundDialog({handleCloseDialogClick}) {
    const validationSchema = yup.object({
        amount: yup
            .number()
            .required('Introduce una cantidad numérica')
            .min(1, 'El coste tiene que ser mayor que cero')
            .nullable(),
        refund_destination: yup.string().required('Selecciona una opción').nullable(),
    });
    const formik = useFormik({
        initialValues: {
            amount: null,
            refund_destination: null,
        },
        validationSchema,
        validateOnBlur: true,
        onSubmit: () => {},
    });
    return (
        <>
            <CloseDialog closeDialog={handleCloseDialogClick} />
            <div className={styles.main}>
                <div className={styles.titleContainer}>
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-text-l>Reembolso</inv-text-l>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <inv-text-m>¿Donde quieres que te reembolsamos el dinero?</inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <div className={styles.radioContainer}>
                    <inv-grid-row>
                        <inv-grid-col class="col-auto">
                            <form>
                                <input type="radio" id="my-account" name="refund_destination" value="my-account" />
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor="my-account">
                                    <inv-text-xxs>A mi cuenta corriente</inv-text-xxs>
                                </label>
                                <br />
                                <input type="radio" id="other-account" name="refund_destination" value="my-account" />
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor="other-account">
                                    <inv-text-xxs>Otras fuentes</inv-text-xxs>
                                </label>
                                <br />
                            </form>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-sm-5">
                        <inv-input-int
                            class="input input--primary"
                            value={formik.values.amount}
                            name="amount"
                            error={formik.errors.amount}
                            label="Importe"
                            placeholder="Ej. 1000"
                            suffix={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        />
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        lineHeight: 0,
                    }}
                >
                    <inv-grid-col class="col-auto">
                        <inv-text-xs>Reembolso Parcial</inv-text-xs>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <ToggleSwitch />
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-text-xs>Reembolso Total</inv-text-xs>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row
                    style={{
                        justifyContent: 'center',
                        '--inv-grid-gutter-x': '0.8em',
                    }}
                >
                    <inv-grid-col class="col-auto">
                        <inv-button onClick={() => null} class="outlined button button--primary-outlined">
                            <div style={{width: '2.5em'}} />
                            Cancelar
                            <div style={{width: '2.5em'}} />
                        </inv-button>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-button class="button button--primary-contained" onClick={() => null}>
                            <div style={{width: '2.8em'}} />
                            Aportar
                            <div style={{width: '2.8em'}} />
                        </inv-button>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
        </>
    );
}

PlanContributionRefundDialog.propTypes = {
    handleCloseDialogClick: PropTypes.func.isRequired,
};

export default PlanContributionRefundDialog;
