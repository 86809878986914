import * as React from 'react';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/icon/icon';
import NumberFormat from 'react-number-format';
import {getYear, parseISO} from 'date-fns';
import styles from './plan-meta.module.css';
import PropuestaTextStructureComponent from '../propuesta/propuesta-text-structure.component';
import BarsChart from '../../components/chart/BarsChart';
import {getConfig, getOutputChartAllObjectives, getPlan} from '../propuesta/propuesta.service';
import {getObjectives} from '../goal/goal.service';
import {getPlanSummaryService} from './plan.service';
import PageLoading from '../../elements/loading/PageLoading';
import {goalColorByType, goalTertiaryColorByType} from '../../utils/componentData';
import {useFontSize} from '../../utils/hooks';
import {tpl, tr} from '../../utils/functions';
import Tpl from '../../elements/tpl/tpl';
import WCIcons from '../../elements/icons/WCIcons';

function PlanMetaComponent() {
    const [loading, setLoading] = React.useState(false);
    const [inflation, setInflation] = React.useState(0);
    const [goalsSummary, setGoalsSummary] = React.useState<any>(null);
    const [currentGoalIndex, setCurrentGoalIndex] = React.useState(0);
    const [firstChartLabelData, setFirstChartLabelData] = React.useState([0, 0, 0, 0]);
    const [firstChartTotalLabel, setFirstChartTotalLabel] = React.useState('2020');
    const fontSize = useFontSize();
    React.useEffect(
        // @ts-ignore
        // eslint-disable-next-line no-return-assign
        () => {
            setFirstChartLabelData([goalsSummary?.[currentGoalIndex]?.costs?.bar1, 0, 0, 0]);
            setFirstChartTotalLabel(goalsSummary?.[currentGoalIndex]?.costs?.name);
        },
        [currentGoalIndex, goalsSummary]
    );
    const goalColor = goalColorByType[goalsSummary?.[currentGoalIndex]?.attributes?.type ?? 'EDUCATION'];
    const goalTertiaryColor =
        goalTertiaryColorByType[goalsSummary?.[currentGoalIndex]?.attributes?.type ?? 'EDUCATION'];
    const getGoalsSummaryAsync = async () => {
        setLoading(true);
        const resPlan = await getPlan();
        const activePlan = resPlan.find((plan: {attributes: {status: string}}) => plan.attributes.status === 'ACTIVE');
        const planId = activePlan?.id;
        if (planId) {
            const promiseConfig = getConfig();
            const promiseOutput = getOutputChartAllObjectives();
            const promiseSummary = getPlanSummaryService(planId);
            const promiseGoals = getObjectives();
            const [resConfig, resOutput, resSummary, resGoals] = await Promise.all([
                promiseConfig,
                promiseOutput,
                promiseSummary,
                promiseGoals,
            ]);
            const configInflation = resConfig?.inflation;
            if (typeof configInflation === 'number') {
                setInflation(configInflation);
            }
            const unifiedGoals = resGoals.map((goal: {id: any; attributes: any; type: any}) => {
                const summary = resSummary.included.find((summaryItem: {id: any}) => summaryItem.id === goal.id);
                const output = resOutput.included.find(
                    (outputItem: {
                        attributes: {
                            goal_id: number;
                        };
                    }) => outputItem.attributes.goal_id.toString() === goal.id.toString()
                );
                if (summary && output) {
                    const costs =
                        output.attributes.monthly_withdrawals ??
                        output.attributes.withdrawal ??
                        output.attributes.yearly_withdrawals ??
                        [];
                    const outputData = costs.map((cost: any, index: number) => ({
                        name: getYear(parseISO(goal.attributes.init_date)) + index,
                        bar1: cost,
                    }));
                    return {
                        ...goal,
                        attributes: {
                            ...goal.attributes,
                            ...summary.attributes,
                        },
                        costs: outputData,
                        type: `${goal.type}+${summary.type}`,
                    };
                }
                return goal;
            });
            setGoalsSummary(unifiedGoals);
            setFirstChartLabelData([unifiedGoals?.[currentGoalIndex]?.costs?.[0]?.bar1, 0, 0, 0]);
            setFirstChartTotalLabel(unifiedGoals?.[currentGoalIndex]?.costs?.[0]?.name);
        }
        setLoading(false);
    };
    React.useEffect(() => {
        getGoalsSummaryAsync().then();
    }, []);
    const handleGoalChange = (isNext: boolean) => {
        if (isNext) {
            if (currentGoalIndex < goalsSummary.length - 1) {
                setCurrentGoalIndex(currentGoalIndex + 1);
            } else {
                setCurrentGoalIndex(0);
            }
        } else if (currentGoalIndex > 0) {
            setCurrentGoalIndex(currentGoalIndex - 1);
        } else {
            setCurrentGoalIndex(goalsSummary.length - 1);
        }
    };
    React.useEffect(() => {}, [currentGoalIndex]);
    return (
        <>
            <PageLoading open={loading} />
            <div
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <inv-grid-row style={{alignItems: 'center', '--inv-grid-gutter-x': '3.2em'}}>
                    <inv-grid-col class="col-auto">
                        <inv-grid-row
                            onClick={() => handleGoalChange(false)}
                            style={{'--inv-grid-gutter-x': 0, cursor: 'pointer'}}
                        >
                            <inv-grid-col class="col-auto">
                                <div
                                    style={{
                                        width: '1.6em',
                                        height: '1.6em',
                                    }}
                                >
                                    <WCIcons height="1.6em" width="1.6em" name="chevron-left" />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col-auto">
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'rgba(255, 255, 255, 0.7)',
                                        '--inv-text-xxs-font-weight': '400',
                                    }}
                                >
                                    {tr('Anterior meta')}
                                </inv-text-xxs>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-text-xxxl
                            style={{
                                '--inv-text-xxxl-color': goalColor,
                                '--inv-text-xxxl-font-weight': '700',
                            }}
                        >
                            {goalsSummary?.[currentGoalIndex]?.attributes?.name}
                        </inv-text-xxxl>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto">
                        <inv-grid-row
                            onClick={() => handleGoalChange(true)}
                            style={{'--inv-grid-gutter-x': 0, cursor: 'pointer'}}
                        >
                            <inv-grid-col class="col-auto">
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'rgba(255, 255, 255, 0.7)',
                                        '--inv-text-xxs-font-weight': '400',
                                    }}
                                >
                                    {tr('Siguiente meta')}
                                </inv-text-xxs>
                            </inv-grid-col>
                            <inv-grid-col class="col-auto">
                                <div
                                    style={{
                                        width: '1.6em',
                                        height: '1.6em',
                                    }}
                                >
                                    <WCIcons height="1.6em" width="1.6em" name="chevron-right" />
                                </div>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.headerContainer}>
                <inv-grid-row
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}
                >
                    <inv-grid-col
                        class={`${styles.textStructureContainer} col-auto`}
                        style={{
                            '--inv-text-l-color': goalColor,
                            '--inv-text-xs-color': goalColor,
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={tr('Debes aportar')}
                            unit={tpl(tr('{currency}/mes'), {currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€'})}
                        >
                            <NumberFormat
                                value={goalsSummary?.[currentGoalIndex]?.attributes?.next ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
                        <PropuestaTextStructureComponent
                            label={tr('Aportado')}
                            unit="%"
                            componentAfterUnit={
                                <div
                                    style={{
                                        marginLeft: '0.5em',
                                        background: goalTertiaryColor,
                                        borderRadius: '1.35em',
                                        width: '13.05em',
                                        height: '1.2em',
                                    }}
                                >
                                    <div
                                        style={{
                                            background: goalColor,
                                            borderRadius: '1.35em',
                                            width: `${
                                                ((goalsSummary?.[currentGoalIndex]?.attributes?.contributed ?? 0) /
                                                    (goalsSummary?.[currentGoalIndex]?.attributes?.total ?? 0)) *
                                                100
                                            }%`,
                                            height: '1.2em',
                                        }}
                                    />
                                </div>
                            }
                        >
                            <NumberFormat
                                value={
                                    ((goalsSummary?.[currentGoalIndex]?.attributes?.contributed ?? 0) /
                                        (goalsSummary?.[currentGoalIndex]?.attributes?.total ?? 0)) *
                                    100
                                }
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
                        <PropuestaTextStructureComponent
                            label={tr('Total aportado')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={goalsSummary?.[currentGoalIndex]?.attributes?.contributed ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col
                        class={`${styles.textStructureContainer} col-auto`}
                        style={{
                            '--inv-text-l-color': 'var(--color-secondary-100)',
                            '--inv-text-xs-color': 'var(--color-secondary-100)',
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={tr('Rentabilidad hasta hoy')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={goalsSummary?.[currentGoalIndex]?.attributes?.performance ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.whiteContainer} style={{right: 0}} />
                <div className={styles.whiteContainer} style={{left: 0}} />
                <div className={styles.titleWithSubtitleContainer}>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 700,
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                {tr('Así retirarás tu dinero')}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-8">
                            <inv-text-xs
                                style={{
                                    position: 'relative',
                                    zIndex: 1,
                                    '--inv-text-xs-font-weight': 300,
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                }}
                            >
                                <Tpl
                                    template={tr(
                                        'Planificamos una inflación anual del {inflation}% a cada retirada, para que no pierdas poder adquisitivo.'
                                    )}
                                    values={{
                                        inflation: (
                                            <NumberFormat
                                                value={inflation}
                                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                decimalScale={2}
                                                allowNegative={false}
                                                displayType="text"
                                            />
                                        ),
                                    }}
                                />
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                {goalsSummary?.[currentGoalIndex]?.costs?.length > 6 && (
                    <inv-grid-row class={styles.goalCostChartValue} style={{display: 'flex', justifyContent: 'center'}}>
                        <inv-grid-col class="col-10">
                            <inv-grid-row
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    lineHeight: 0,
                                }}
                            >
                                <inv-grid-col class="col-auto">
                                    <inv-text-xxs
                                        style={{
                                            '--in-text-xxs-font-weight': 500,
                                            '--inv-text-xxs-color': 'var(--text-color-50)',
                                        }}
                                    >
                                        {tpl(tr('Año {year}'), {year: firstChartTotalLabel})}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                            <inv-grid-row
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <inv-grid-col class="col-auto">
                                    <inv-text-xs
                                        style={{
                                            '--in-text-xs-font-weight': 600,
                                            '--inv-text-xs-color': goalColor,
                                        }}
                                    >
                                        <NumberFormat
                                            value={firstChartLabelData[0]}
                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                            decimalScale={0}
                                            allowNegative={false}
                                            displayType="text"
                                        />
                                    </inv-text-xs>
                                    <inv-text-xxs
                                        class={styles.goalCostChartValueUnit}
                                        style={{
                                            '--in-text-xxs-font-weight': 700,
                                            '--inv-text-xxs-color': goalColor,
                                        }}
                                    >
                                        {` ${
                                            goalsSummary?.[currentGoalIndex]?.attributes?.output_type === 'MONTHLY'
                                                ? tpl(tr('{currency}/mes'), {
                                                      currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                                  })
                                                : process.env.REACT_APP_CURRENCY_SYMBOL || '€'
                                        }`}
                                    </inv-text-xxs>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                    </inv-grid-row>
                )}
                <inv-grid-row class={styles.goalCostChart} style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-grid-col
                        class={goalsSummary?.[currentGoalIndex]?.costs?.length > 6 ? 'col-10' : 'col-4 col-sm-4'}
                    >
                        <BarsChart
                            height={15 * (fontSize ?? 0)}
                            name="output"
                            barColors={{bar1: 'var(--text-color-50)'}}
                            data={goalsSummary?.[currentGoalIndex]?.costs ?? []}
                            barsHandler={setFirstChartLabelData}
                            helperHandler={setFirstChartTotalLabel}
                        />
                    </inv-grid-col>
                    <inv-grid-col class="col-5 col-sm-4" style={{display: 'flex', alignItems: 'center'}}>
                        {goalsSummary?.[currentGoalIndex]?.costs?.length <= 6 && (
                            <inv-grid-row class={styles.goalCostChartValue}>
                                {goalsSummary?.[currentGoalIndex]?.costs?.map(
                                    (data: {name: React.Key | null | undefined; bar1: any}) => (
                                        <inv-grid-col
                                            class={
                                                goalsSummary?.[currentGoalIndex]?.costs?.length === 1
                                                    ? 'col-auto'
                                                    : 'col-4'
                                            }
                                            key={data.name}
                                        >
                                            <inv-grid-row
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    lineHeight: 0,
                                                }}
                                            >
                                                <inv-grid-col class="col-auto">
                                                    <inv-text-xxs
                                                        style={{
                                                            '--in-text-xxs-font-weight': 500,
                                                            '--inv-text-xxs-color': 'var(--text-color-50)',
                                                        }}
                                                    >
                                                        {tpl(tr('Año {year}'), {year: data.name})}
                                                    </inv-text-xxs>
                                                </inv-grid-col>
                                            </inv-grid-row>
                                            <inv-grid-row
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <inv-grid-col class="col-auto">
                                                    <inv-text-xs
                                                        style={{
                                                            '--in-text-xs-font-weight': 600,
                                                            '--inv-text-xs-color': goalColor,
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={data.bar1}
                                                            thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                            decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                            decimalScale={0}
                                                            allowNegative={false}
                                                            displayType="text"
                                                        />
                                                    </inv-text-xs>
                                                    <inv-text-xs
                                                        class={styles.goalCostChartValueUnit}
                                                        style={{
                                                            '--in-text-xs-font-weight': 700,
                                                            '--inv-text-xs-color': goalColor,
                                                        }}
                                                    >
                                                        {` ${
                                                            goalsSummary?.[currentGoalIndex]?.attributes
                                                                ?.output_type === 'MONTHLY'
                                                                ? tpl(tr('{currency}/mes'), {
                                                                      currency:
                                                                          process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                                                  })
                                                                : process.env.REACT_APP_CURRENCY_SYMBOL || '€'
                                                        }`}
                                                    </inv-text-xs>
                                                </inv-grid-col>
                                            </inv-grid-row>
                                        </inv-grid-col>
                                    )
                                )}
                            </inv-grid-row>
                        )}
                    </inv-grid-col>
                </inv-grid-row>
                <div className={styles.titleWithSubtitleContainer}>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 700,
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                {tr('También estimamos los impuestos')}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-7">
                            <inv-text-xs
                                style={{
                                    position: 'relative',
                                    zIndex: 1,
                                    '--inv-text-xs-font-weight': 300,
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                }}
                            >
                                {tr(
                                    'Todo está calculado para que, al rescatar tus inversiones, puedas obtener las cantidades planificadas para tu meta.'
                                )}
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <inv-grid-row
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        '--inv-grid-gutter-x': '1.6em',
                    }}
                >
                    <inv-grid-col
                        class={`${styles.textStructureContainer} col-auto`}
                        style={{
                            '--inv-text-l-color': 'var(--text-color-30)',
                            '--inv-text-xs-color': 'var(--text-color-30)',
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={tr('Total a aportar')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={goalsSummary?.[currentGoalIndex]?.attributes?.total ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col
                        class="col-auto"
                        style={{
                            alignSelf: 'flex-end',
                        }}
                    >
                        <inv-text-m
                            style={{
                                '--inv-text-m-font-weight': 600,
                                '--inv-text-m-color': 'var(--text-color-30)',
                            }}
                        >
                            +
                        </inv-text-m>
                    </inv-grid-col>
                    <inv-grid-col
                        class={`${styles.textStructureContainer} col-auto`}
                        style={{
                            '--inv-text-l-color': 'var(--color-secondary-100)',
                            '--inv-text-xs-color': 'var(--color-secondary-100)',
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={tr('Rentabilidad estimada')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={
                                    (goalsSummary?.[currentGoalIndex]?.attributes?.amount ?? 0) -
                                    (goalsSummary?.[currentGoalIndex]?.attributes?.total ?? 0)
                                }
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col
                        class="col-auto"
                        style={{
                            alignSelf: 'flex-end',
                        }}
                    >
                        <inv-text-m
                            style={{
                                '--inv-text-m-font-weight': 600,
                                '--inv-text-m-color': 'var(--text-color-30)',
                            }}
                        >
                            -
                        </inv-text-m>
                    </inv-grid-col>
                    <inv-grid-col
                        class={`${styles.textStructureContainer} col-auto`}
                        style={{
                            '--inv-text-l-color': '#FF5F29',
                            '--inv-text-xs-color': '#FF5F29',
                        }}
                    >
                        <PropuestaTextStructureComponent
                            label={tr('Estimación fiscal')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={goalsSummary?.[currentGoalIndex]?.attributes?.taxation ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col
                        class="col-auto"
                        style={{
                            alignSelf: 'flex-end',
                        }}
                    >
                        <inv-text-m
                            style={{
                                '--inv-text-m-font-weight': 600,
                                '--inv-text-m-color': 'var(--text-color-30)',
                            }}
                        >
                            =
                        </inv-text-m>
                    </inv-grid-col>
                    <inv-grid-col class={`${styles.textStructureContainer} col-auto`}>
                        <PropuestaTextStructureComponent
                            label={tr('Total que necesitas')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={
                                    (goalsSummary?.[currentGoalIndex]?.attributes?.amount ?? 0) -
                                    (goalsSummary?.[currentGoalIndex]?.attributes?.taxation ?? 0)
                                }
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row style={{justifyContent: 'center', marginTop: '3.4em'}}>
                    <inv-grid-col
                        class="col-6"
                        style={{
                            background: 'rgba(255, 242, 227, 0.6)',
                            border: '0.05em solid #F5931F',
                            borderRadius: '0.8em',
                            padding: '0.8em',
                        }}
                    >
                        <inv-grid-row
                            style={{
                                '--inv-grid-gutter-x': '0.8em',
                                alignItems: 'center',
                            }}
                        >
                            <inv-grid-col class="col-auto">
                                <div
                                    style={{
                                        height: '1.6em',
                                        width: '1.6em',
                                    }}
                                >
                                    <WCIcons color="#F5931F" name="info" height="1.6em" width="1.6em" />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col-10">
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr(
                                        'Esto no puede considerarse como asesoramiento fiscal. Se trata de una estimación, que asume numerosas hipótesis.'
                                    )}
                                </inv-text-xxs>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                </inv-grid-row>
            </div>
        </>
    );
}

export default PlanMetaComponent;
