import {Typography} from '@mui/material';
import * as React from 'react';

interface IText {
    size: string;
    weight: 'bold' | 'semibold' | 'regular' | 'medium' | 'light';
    fontColor?: string;
    gradient?: string;
    align?: string;
    children: JSX.Element | string | undefined;
    onClick?: () => void;
    noWrap?: boolean;
    display?: 'inline';
    textDecoration?: 'underline';
}

const sizeValues = {
    '5xl': 'h1',
    '4xl': 'h2',
    '3xl': 'h3',
    '2xl': 'h4',
    xl: 'h5',
    l: 'h6',
    m: 'body1',
    s: 'body2',
    xs: 'subtitle1',
    xxs: 'subtitle2',
};

const weightValues = {
    bold: 'bold',
    semibold: 600,
    medium: 500,
    regular: 'normal',
    light: 300,
};

function Text(props: IText) {
    const {
        size,
        weight,
        children,
        fontColor,
        gradient,
        align,
        onClick,
        noWrap,
        display,
        textDecoration,
    } = props;
    const finalFontColor = fontColor || 'var(text-color-100)';
    const finalAlign = align || 'inherit';
    const finalDisplay = display || 'inherit';
    return (
        <Typography
            component="span"
            noWrap={noWrap}
            onClick={onClick}
            // @ts-ignore
            variant={sizeValues[size]}
            fontWeight={weightValues[weight]}
            // @ts-ignore
            align={finalAlign}
            color={finalFontColor}
            display={finalDisplay}
            sx={
                gradient
                    ? {
                          background: gradient,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                      }
                    : {
                          textDecoration: textDecoration ?? 'none',
                          textDecorationColor: textDecoration
                              ? finalFontColor
                              : 'none',
                      }
            }
        >
            {children}
        </Typography>
    );
}
Text.defaultProps = {
    fontColor: undefined,
    gradient: undefined,
    align: undefined,
    onClick: undefined,
    noWrap: undefined,
    display: undefined,
    textDecoration: undefined,
};

export default Text;
