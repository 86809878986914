import NumberFormat from 'react-number-format';
import * as React from 'react';
import {useMediaQuery} from '@mui/material';

import {differenceInYears, parseISO} from 'date-fns';
import theme from '../../styles/theme';
import BarsChart from '../../components/chart/BarsChart';
import ContainerDialog from '../../elements/dialog/ContainerDialog';
import {TaxationDialogContent} from './propuesta-taxation-dialog.component';
import {PerformanceDialog} from './propuesta-performance-dialog.component';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/button/button';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/icon/icon';
import PropuestaTextStructureComponent from './propuesta-text-structure.component';
import styles from './propuesta-con-inveert.module.css';
import {useIsMobile} from '../../utils/hooks';
import PlanTotalsWithPercentageBar from '../../components/planTotalsWithPercentageBar/planTotalsWithPercentageBar';
import {goalColorByType} from '../../utils/componentData';
import WCIcons from '../../elements/icons/WCIcons';
import {tpl, tr} from '../../utils/functions';
import Tpl from '../../elements/tpl/tpl';

export function PropuestaConInveertComponent(props: any) {
    const isMobile = useIsMobile();
    const {
        // pensionPlan,
        saving,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        initial_investment,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        remaining_saving,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        adjusted_objective_without_taxation,
        performance,
        plan,
        objectives,
        finalInputChart,
        totalCumulativeChart,
        planTax,
        tir,
        savingYears,
        openAddGoalDialogFunc,
    } = props;
    const downXl = useMediaQuery(theme.breakpoints.down('xl'));
    const [firstChartLabelData, setFirstChartLabelData] = React.useState([
        totalCumulativeChart[totalCumulativeChart.length - 1].bar1,
        totalCumulativeChart[totalCumulativeChart.length - 1].bar2,
    ]);
    const [showTaxationDialog, setShowTaxationDialog] = React.useState(false);

    const [showPerformanceDialog, setShowPerformanceDialog] = React.useState(false);

    React.useEffect(() => {
        setFirstChartLabelData([
            totalCumulativeChart[totalCumulativeChart.length - 1].bar1,
            totalCumulativeChart[totalCumulativeChart.length - 1].bar2,
        ]);
    }, [totalCumulativeChart, finalInputChart, plan]);

    const [fontSize, setFontSize] = React.useState<null | number>(null);

    React.useEffect(() => {
        const chartFontSize = window.getComputedStyle(document.body).getPropertyValue('font-size');
        const fontSizeNumber = parseInt(chartFontSize, 10);
        setFontSize(fontSizeNumber);
    }, []);

    return (
        <>
            <ContainerDialog
                open={showTaxationDialog}
                onClose={() => setShowTaxationDialog(false)}
                fullWidth
                scroll="body"
            >
                <TaxationDialogContent
                    taxation={planTax}
                    saving={saving}
                    performance={performance}
                    totalCost={adjusted_objective_without_taxation}
                    closeDialog={() => setShowTaxationDialog(false)}
                />
            </ContainerDialog>
            <ContainerDialog
                open={showPerformanceDialog}
                onClose={() => setShowPerformanceDialog(false)}
                fullWidth
                maxWidth={downXl ? 'md' : 'lg'}
                scroll="body"
            >
                <PerformanceDialog closeDialog={() => setShowPerformanceDialog(false)} />
            </ContainerDialog>
            <div className={styles.container}>
                <div className={styles.sectionTextRow}>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto" style={{textAlign: 'center'}}>
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 600,
                                    '--inv-text-m-color': 'var(--color-primary-100)',
                                }}
                            >
                                {tr('El plan es invertir con inteligencia')}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-12" style={{textAlign: 'center'}}>
                            <inv-text-s
                                style={{
                                    '--inv-text-s-font-weight': 300,
                                    '--inv-text-s-color': 'var(--text-color-50)',
                                }}
                            >
                                <Tpl
                                    template={tr('Aportando sólo el {percentage}% del valor de tus metas,')}
                                    values={{
                                        percentage: (
                                            <NumberFormat
                                                key="saving_percentage_number"
                                                value={
                                                    ((remaining_saving + initial_investment) /
                                                        (remaining_saving +
                                                            initial_investment +
                                                            performance -
                                                            planTax)) *
                                                    100
                                                }
                                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                decimalScale={2}
                                                allowNegative={false}
                                                displayType="text"
                                            />
                                        ),
                                    }}
                                />
                            </inv-text-s>
                            {!isMobile && <br />}
                            <inv-text-s
                                style={{
                                    '--inv-text-s-font-weight': 300,
                                    '--inv-text-s-color': 'var(--text-color-50)',
                                }}
                            >
                                {tr('el resto lo obtendrías mediante rentabilidad.')}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <inv-grid-row
                    style={{justifyContent: 'center', alignItems: 'center'}}
                    class={styles.apportationsContainer}
                >
                    <inv-grid-col class="col-auto">
                        <PlanTotalsWithPercentageBar
                            totalTitle={tr('Necesitas para tus metas')}
                            investmentTitle={isMobile ? tr('Ap. inicial') : tr('Aportación inicial')}
                            performance={performance}
                            pending={remaining_saving}
                            performanceTitle={isMobile ? tr('Rent. bruta') : tr('Rentabilidad bruta')}
                            investment={initial_investment}
                            taxes={planTax}
                            pendingTitle={isMobile ? tr('Ap. mensuales') : tr('Aportaciones mensuales')}
                            performanceInfoClickHandler={() => setShowTaxationDialog(true)}
                        />
                    </inv-grid-col>
                </inv-grid-row>
                <div className={styles.sectionTextRow}>
                    <inv-grid-row class={styles.stepTitleContainer} style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-s
                                style={{
                                    '--inv-text-s-font-weight': 700,
                                    '--inv-text-s-color': 'var(--color-primary-100)',
                                }}
                            >
                                {tr('¿Qué depende de ti?')}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto" style={{textAlign: 'center'}}>
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 600,
                                }}
                            >
                                {tr('Que inviertas mes a mes')}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-5" style={{textAlign: 'center'}}>
                            <inv-text-s
                                style={{
                                    '--inv-text-s-font-weight': 300,
                                    '--inv-text-s-color': 'var(--text-color-50)',
                                }}
                            >
                                <Tpl
                                    template={tr(
                                        'Aportarías un total de {amount}{currency} durante los próximos {duration} años'
                                    )}
                                    values={{
                                        currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                        duration: savingYears.toString(),
                                        amount: (
                                            <NumberFormat
                                                key="saving_amount"
                                                value={remaining_saving}
                                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                decimalScale={0}
                                                displayType="text"
                                            />
                                        ),
                                    }}
                                />
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <inv-grid-row class={styles.monthlySavingGoalsContainer}>
                    {objectives.map(
                        (objective: {
                            id: any;
                            attributes: {
                                type: string | number;
                                name: string;
                                init_date: any;
                            };
                            result: {
                                monthly_saving: string | number | null | undefined;
                            };
                        }) => (
                            <inv-grid-col
                                key={objective.id}
                                class="col-auto"
                                style={{
                                    '--inv-text-l-color': goalColorByType[objective.attributes.type],
                                    '--inv-text-xs-color': goalColorByType[objective.attributes.type],
                                }}
                            >
                                <PropuestaTextStructureComponent
                                    label={tr(objective.attributes.name)}
                                    unit={tpl(tr('{currency}/mes'), {
                                        currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                                    })}
                                    helperElement={
                                        <div className={styles.monthlySavingGoalsHelper}>
                                            <inv-text-xxs>
                                                {tpl(tr('Durante {duration} años'), {
                                                    duration: differenceInYears(
                                                        parseISO(objective?.attributes?.init_date ?? 0),
                                                        new Date()
                                                    ),
                                                })}
                                            </inv-text-xxs>
                                        </div>
                                    }
                                >
                                    <NumberFormat
                                        value={objective.result.monthly_saving}
                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                        decimalScale={0}
                                        allowNegative={false}
                                        displayType="text"
                                    />
                                </PropuestaTextStructureComponent>
                            </inv-grid-col>
                        )
                    )}
                    {objectives.length < 4 && (
                        <inv-grid-col class={`col-auto ${styles.monthlySavingAddGoal}`}>
                            <PropuestaTextStructureComponent label={tr('Otra meta')}>
                                <button onClick={openAddGoalDialogFunc} type="button" className={styles.buttonLink}>
                                    <div
                                        style={{
                                            height: '1.1em',
                                            marginRight: '0.2em',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <WCIcons
                                            color="inherit"
                                            name="add-inverse"
                                            width="1.1em"
                                            height="1.1em"
                                            hoverColor="inherit"
                                        />
                                    </div>
                                    {tr('Añadir meta')}
                                </button>
                            </PropuestaTextStructureComponent>
                        </inv-grid-col>
                    )}
                </inv-grid-row>
                <div className={styles.sectionTextRow}>
                    <inv-grid-row class={styles.stepTitleContainer} style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-s
                                style={{
                                    '--inv-text-s-font-weight': 600,
                                    '--inv-text-s-color': 'var(--color-primary-100)',
                                }}
                            >
                                {tpl(tr('¿Qué depende de {siteTitle}?'), {
                                    siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la entidad',
                                })}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                        <inv-grid-col class="col-auto">
                            <inv-text-m
                                style={{
                                    '--inv-text-m-font-weight': 600,
                                }}
                            >
                                {tr('Ayudarte a rentabilizar tus aportaciones')}
                            </inv-text-m>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-12 col-sm-10" style={{textAlign: 'center'}}>
                            <inv-text-s
                                style={{
                                    '--inv-text-s-font-weight': 300,
                                    '--inv-text-s-color': 'var(--text-color-50)',
                                }}
                            >
                                {tr(
                                    'Te acompañaremos en el camino para que consigas, al menos, la rentabilidad esperada'
                                )}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                </div>
                <inv-grid-row style={{justifyContent: 'center'}}>
                    <inv-grid-col class="col-12 col-sm-10">
                        <inv-grid-row class={styles.finalChartLabelContainer}>
                            <inv-grid-col class={`col-12 ${styles.tirLabel}`}>
                                <inv-text-xxs>{tr('Rentabilidad media')}</inv-text-xxs>
                            </inv-grid-col>
                            <inv-grid-col class="col-auto">
                                <inv-grid-row
                                    class={styles.tirContainer}
                                    style={{
                                        border: `0.05em solid var(--text-color-10)`,
                                    }}
                                >
                                    <inv-grid-col class="col-auto">
                                        <inv-text-xxs>{tr('Escenario')}</inv-text-xxs>
                                        <br />
                                        <inv-text-xxs>{tr('esperado')}</inv-text-xxs>
                                    </inv-grid-col>
                                    <inv-grid-col class="col-auto">
                                        <inv-text-s
                                            style={{
                                                '--inv-text-s-color': 'var(--color-secondary-100)',
                                                '--inv-text-s-font-weight': 700,
                                            }}
                                        >
                                            <NumberFormat
                                                value={tir}
                                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                decimalScale={2}
                                                allowNegative={false}
                                                displayType="text"
                                            />
                                            %
                                        </inv-text-s>
                                    </inv-grid-col>
                                </inv-grid-row>
                            </inv-grid-col>
                            <inv-grid-col class="col-auto">
                                <inv-grid-row class={styles.totalCapitaApportationContainer}>
                                    <inv-grid-col
                                        class="col-auto"
                                        style={{
                                            '--inv-text-l-color': 'var(--text-color-30)',
                                            '--inv-text-xs-color': 'var(--text-color-30)',
                                        }}
                                    >
                                        <PropuestaTextStructureComponent
                                            label={isMobile ? tr('Ap. acumuladas') : tr('Aportaciones acumuladas')}
                                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                        >
                                            <NumberFormat
                                                value={firstChartLabelData[0]}
                                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                decimalScale={0}
                                                displayType="text"
                                            />
                                        </PropuestaTextStructureComponent>
                                    </inv-grid-col>
                                    <inv-grid-col
                                        class="col-auto"
                                        style={{
                                            '--inv-text-l-color': 'var(--color-secondary-100)',
                                            '--inv-text-xs-color': 'var(--color-secondary-100)',
                                        }}
                                    >
                                        <PropuestaTextStructureComponent
                                            label={isMobile ? tr('Rent. acumulada') : tr('Rentabilidad acumulada')}
                                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                                        >
                                            <NumberFormat
                                                value={firstChartLabelData[1]}
                                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                decimalScale={0}
                                                displayType="text"
                                            />
                                        </PropuestaTextStructureComponent>
                                    </inv-grid-col>
                                </inv-grid-row>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.performanceChartContainer}>
                    <inv-grid-col class="col-12 col-sm-10">
                        <BarsChart
                            height={isMobile ? 11.75 * (fontSize ?? 0) : 15.7 * (fontSize ?? 0)}
                            highlightPrevious
                            name="performance"
                            barColors={{
                                bar1: 'var(--text-color-30)',
                                bar2: 'var(--color-secondary-100)',
                            }}
                            data={totalCumulativeChart}
                            barsHandler={setFirstChartLabelData}
                            hideXAxisTicks
                            hideYAxis={isMobile}
                        />
                    </inv-grid-col>
                </inv-grid-row>
            </div>
        </>
    );
}

export default PropuestaConInveertComponent;
