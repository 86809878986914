import * as React from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {add, addYears, differenceInMonths, differenceInYears, format, isValid, parseISO, startOfMonth} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from '@mui/material';
import theme from '../../styles/theme';
import {customFormQuestionTitle, lastQuestionIndex} from '../../utils/componentData';
import {addObjectiveApi, getObjectiveCostApi, modifyObjectiveApi} from '../../modules/goal/goal.service';
import {AmountType, OutputType} from '../../modules/goal/goal.type';
import {addLastObjectiveIdAndType, selectObjectives, setLastObjectiveForm} from '../../modules/goal/goal.slice';
import {PersonalizadaFormComponent} from '../../modules/goal/personalizada/personalizada-form.component';
import PageLoading from '../../elements/loading/PageLoading';
import {GoalFooterMeta} from '../../components/meta/GoalFooterMeta';
import {setIsAuthDialogOpen} from '../../modules/auth/auth.slice';
import {addAlert, selectApp} from '../../modules/app/app.slice';
import {getConfig, getMinRecommendationPeriod} from '../../modules/propuesta/propuesta.service';
import {getDateFnsLocale, getErrorMessage, getYearAndMonth, tpl, tr} from '../../utils/functions';
import personalizada from '../../styles/Personalizada.module.css';
import {selectUser} from '../../modules/user/user.slice';
import {useGoalsDate} from '../../utils/hooks';

function Personalizada(props: any) {
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {goBack, finish, closeDialog} = props;
    const {birthday} = useSelector(selectUser);
    const {locale} = useSelector(selectApp);
    // eslint-disable-next-line react/destructuring-assignment
    const router = useNavigate();
    const dispatch = useDispatch();
    const objectivesData = useSelector(selectObjectives);
    const storage = globalThis?.sessionStorage;
    const [inflation, setInflation] = React.useState(0.01);
    const [deathAge, setDeathAge] = React.useState(0);
    const [totalCost, setTotalCost] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [questionIndex, setQuestionIndex] = React.useState(0);

    const [minRecommendationPeriod, setMinRecommendationPeriod] = React.useState(1);

    const [minDate, maxDate] = useGoalsDate(deathAge, birthday, minRecommendationPeriod);

    const yearDifference = differenceInYears(minDate, new Date());
    const minDateMessage =
        yearDifference === 1
            ? tr('Planifica tu meta con 1 año de margen')
            : tpl(tr('Planifica tu meta con {yearDifference} años de margen'), {yearDifference});

    React.useEffect(() => {
        const logo = document.getElementById('header-onboarding');
        if (logo) {
            logo.scrollIntoView({behavior: 'smooth'});
        }
    }, [questionIndex]);
    React.useEffect(() => {
        setQuestionIndex(storage?.getItem('currentPath') === '/meta/fin' && !downMobile ? lastQuestionIndex.custom : 0);
    }, [downMobile, storage]);
    const validationSchema = yup.object({
        name: yup.string().max(20).required(),
        output_type: yup.mixed<keyof typeof AmountType>().oneOf(Object.values(AmountType)).nullable().required(),
        init_date: yup
            .date()
            .typeError(tr('La fecha no es valida'))
            .nullable()
            .max(
                maxDate,
                tpl(tr(`La fecha no puede ser posterior a {date}`), {
                    date: format(maxDate, 'MMMM yyyy', {locale: getDateFnsLocale(locale)}),
                })
            )
            .min(startOfMonth(minDate), minDateMessage)
            .required(tr('Fecha de inicio requerida')),
        // retirement_age is term on RETIREMENT
        end_date: yup.date().when(['output_type', 'init_date'], (output_type, init_date) => {
            const parsedDateInit = isValid(init_date) ? init_date : parseISO(init_date);
            return output_type === 'MONTHLY' && isValid(parsedDateInit)
                ? yup
                      .date()
                      .typeError(tr('La fecha no es valida'))
                      .nullable()
                      .max(
                          maxDate,
                          tpl(tr(`La fecha no puede ser posterior a {date}`), {
                              date: format(maxDate, 'MMMM yyyy', {locale: getDateFnsLocale(locale)}),
                          })
                      )
                      .min(
                          startOfMonth(add(parsedDateInit, {months: 12})),
                          tr('Debe haber 12 meses de diferencia con el inicio')
                      )
                      .required(tr('Fecha de inicio requerida'))
                      .min(
                          startOfMonth(add(parsedDateInit, {months: 12})),
                          tr('Debe haber 12 meses de diferencia con el inicio')
                      )
                      .required(tr('Fecha de inicio requerida'))
                : yup.date().nullable();
        }),
        duration: yup
            .number()
            .when(['output_type'], (output_type) =>
                output_type === 'ANNUAL'
                    ? yup
                          .number()
                          .nullable()
                          .required(tr('Duración de la meta requerido'))
                          .min(2, tr("Si solo es 1 año escoge la opción 'De una vez'"))
                    : yup.number().nullable()
            ),
        amount: yup
            .number()
            .required(tr('Introduce una cantidad numérica'))
            .min(1, tr('El coste tiene que ser mayor que cero'))
            .nullable(),
    });
    const formik = useFormik({
        initialValues:
            objectivesData.lastObjectiveType === 'CUSTOM' && objectivesData.lastObjectiveForm
                ? {
                      ...objectivesData.lastObjectiveForm,
                      init_date: objectivesData.lastObjectiveForm?.init_date
                          ? getYearAndMonth(objectivesData.lastObjectiveForm.init_date)
                          : null,
                      end_date: objectivesData.lastObjectiveForm?.end_date
                          ? getYearAndMonth(objectivesData.lastObjectiveForm.end_date)
                          : null,
                  }
                : {
                      name: '',
                      init_date: null,
                      end_date: null,
                      duration: null,
                      amount: null,
                      output_type: null,
                  },
        validationSchema,
        onSubmit: (values) => {
            const parsedInitDate = isValid(values.init_date) ? values.init_date : parseISO(values.init_date);
            let dateEnd: number | Date | null;
            const dateInit = startOfMonth(parsedInitDate);
            if (values.output_type === OutputType.ANNUAL) {
                dateEnd = addYears(dateInit, values.duration - 1);
            } else if (values.output_type === OutputType.MONTHLY) {
                const parsedEndDate = isValid(values.end_date) ? values.end_date : parseISO(values.end_date);
                dateEnd = startOfMonth(parsedEndDate);
            } else {
                dateEnd = null;
            }
            const customObjective = {
                amount: values.amount,
                type: 'CUSTOM' as const,
                output_type:
                    // eslint-disable-next-line no-nested-ternary
                    values.output_type === OutputType.UNIQUE
                        ? OutputType.UNIQUE
                        : values.output_type === OutputType.ANNUAL
                        ? OutputType.ANNUAL
                        : OutputType.MONTHLY,
                protection_type: 'CAPITAL' as const,
                name: values.name,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date:
                    // eslint-disable-next-line no-nested-ternary
                    values.output_type === AmountType.UNIQUE ? null : dateEnd ? format(dateEnd, 'yyyy-MM-dd') : '',
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            if (objectivesData.lastObjectiveType === 'CUSTOM') {
                modifyObjectiveApi(customObjective, objectivesData.lastObjectiveId)
                    .then(() => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                                end_date: dateEnd ? format(dateEnd, 'yyyy-MM-dd') : '',
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            } else {
                addObjectiveApi(customObjective)
                    .then((lastObjective: any) => {
                        dispatch(
                            setLastObjectiveForm({
                                ...formik.values,
                                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                                end_date: dateEnd ? format(dateEnd, 'yyyy-MM-dd') : '',
                            })
                        );
                        dispatch(
                            addLastObjectiveIdAndType({
                                lastObjectiveType: lastObjective.attributes.type,
                                lastObjectiveId: lastObjective.id,
                            })
                        );
                        if (finish) {
                            finish();
                            closeDialog();
                            formik.setSubmitting(false);
                        } else {
                            router('/meta/fin');
                        }
                    })
                    .catch(async (e) => {
                        const message = await getErrorMessage(e);
                        dispatch(
                            addAlert({
                                message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                        formik.setSubmitting(false);
                    });
            }
        },
    });
    React.useEffect(() => {
        setLoading(true);
        const resMinDate = getMinRecommendationPeriod();
        const resConfig = getConfig();
        Promise.all([resMinDate, resConfig])
            .then(([minPeriod, config]) => {
                const configInflation = config.inflation;
                if (typeof configInflation === 'number') {
                    setInflation(configInflation);
                }
                if (typeof configInflation === 'number') {
                    setDeathAge(config.death_age);
                }
                setMinRecommendationPeriod(minPeriod);
                dispatch(setIsAuthDialogOpen(false));
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
        return () => {
            setInflation(0);
            setLoading(false);
        };
    }, []);
    React.useEffect(() => {
        if (questionIndex === 3) {
            setLoading(true);
            let dateEnd: number | Date;
            const dateInit = startOfMonth(parseISO(formik.values.init_date));
            if (formik.values.output_type === OutputType.ANNUAL) {
                dateEnd = addYears(dateInit, formik.values.duration - 1);
            } else {
                dateEnd = startOfMonth(parseISO(formik.values.end_date));
            }
            const customObjective = {
                amount: formik.values.amount,
                type: 'CUSTOM' as const,
                output_type:
                    // eslint-disable-next-line no-nested-ternary
                    formik.values.output_type === OutputType.UNIQUE
                        ? OutputType.UNIQUE
                        : formik.values.output_type === OutputType.ANNUAL
                        ? OutputType.ANNUAL
                        : OutputType.MONTHLY,
                protection_type: 'CAPITAL' as const,
                name: formik.values.name,
                duration: undefined,
                duration_type: undefined,
                init_date: dateInit ? format(dateInit, 'yyyy-MM-dd') : '',
                end_date:
                    // eslint-disable-next-line no-nested-ternary
                    formik.values.output_type === AmountType.UNIQUE
                        ? null
                        : dateEnd
                        ? format(dateEnd, 'yyyy-MM-dd')
                        : '',
                is_active: undefined,
                percentage_completed: undefined,
                probability: undefined,
                time_horizon: undefined,
            };
            getObjectiveCostApi(customObjective)
                .then((res) => {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const {withdrawal, yearly_withdrawals} = res.attributes;

                    const arrayToReduce = yearly_withdrawals ?? withdrawal;
                    let customTotalCost = 0;
                    if (arrayToReduce) {
                        customTotalCost = arrayToReduce.reduce((acc: any, current: any) => acc + current, 0);
                    }
                    setTotalCost(customTotalCost);
                    setLoading(false);
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    setLoading(false);
                });
        }
        return () => {
            setTotalCost(0);
            setLoading(false);
        };
    }, [questionIndex]);
    return (
        <div className={personalizada.container}>
            <PageLoading open={formik.isSubmitting || loading} />
            <inv-grid-row>
                <inv-grid-col class="col" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--custom-color)',
                            '--inv-text-l-font-weight': 600,
                        }}
                    >
                        {formik.values.name && questionIndex > 0 ? `${formik.values.name}` : tr('Meta personalizada')}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            {
                customFormQuestionTitle(
                    formik.values.output_type,
                    formik.values.amount,
                    format(formik.values.init_date ? parseISO(formik.values.init_date) : 0, 'MMMM yyyy', {
                        locale: getDateFnsLocale(locale),
                    }),
                    format(formik.values.end_date ? parseISO(formik.values.end_date) : 0, 'MMMM yyyy', {
                        locale: getDateFnsLocale(locale),
                    }),
                    totalCost,
                    formik?.values?.duration ??
                        differenceInMonths(parseISO(formik.values.end_date), parseISO(formik.values.init_date))
                )[questionIndex]
            }
            <inv-grid-row style={{display: 'flex', justifyContent: 'center'}}>
                <inv-grid-col class="col">
                    <PersonalizadaFormComponent
                        formik={formik}
                        questionIndex={questionIndex}
                        inflation={inflation}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row>
                <inv-grid-col class="col">
                    <GoalFooterMeta
                        hideDots={questionIndex >= 3}
                        questionIndex={questionIndex}
                        goBack={goBack}
                        setQuestionIndex={setQuestionIndex}
                        formik={formik}
                        questionNameOrder={[1, 2, 3]}
                        goalType="CUSTOM"
                    />
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
export default Personalizada;
