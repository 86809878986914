import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as RealStateSvg} from './goals/real-state.svg';
import {ReactComponent as RetirementSvg} from './goals/retirement.svg';
import {ReactComponent as EducationSvg} from './goals/education.svg';
import {ReactComponent as SafetySvg} from './goals/safety.svg';
import {ReactComponent as HeritageSvg} from './goals/herencia.svg';
import {ReactComponent as CustomSvg} from './goals/custom.svg';
import {goalColorByType} from '../../utils/componentData';

/**
 * Function that returns the icon of the goal
 * @param type
 * @param x
 * @param y
 * @param width
 * @param height
 * @return {JSX.Element}
 */
function IconGoal({type, x, y, width}) {
    const style = {
        color: goalColorByType[type],
        height: width,
    };
    switch (type) {
        case 'REAL_STATE':
            return <RealStateSvg width={width} x={x} y={y} style={style} />;
        case 'RETIREMENT_ONLY_FUND':
        case 'RETIREMENT':
            return <RetirementSvg width={width} x={x} y={y} style={style} />;
        case 'EDUCATION':
            return <EducationSvg width={width} x={x} y={y} style={style} />;
        case 'SAFETY_NET':
            return <SafetySvg width={width} x={x} y={y} style={style} />;
        case 'HERITAGE':
            return <HeritageSvg width={width} x={x} y={y} style={style} />;
        case 'CUSTOM':
            return <CustomSvg width={width} x={x} y={y} style={style} />;
        default:
            return <RealStateSvg width={width} x={x} y={y} style={style} />;
    }
}

IconGoal.propTypes = {
    type: PropTypes.string.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.string,
};

IconGoal.defaultProps = {
    x: undefined,
    y: undefined,
    width: '2.5em',
};
export default IconGoal;
