import Box from '@mui/material/Box';

import * as React from 'react';
import Button from '@mui/material/Button';
import Text from '../../styles/textStyles';
import theme from '../../styles/theme';

interface IButtons {
    children: JSX.Element | string | undefined;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
    onClick?: () => void;
    borderColor?: string;
    selected?: boolean;
    fontColor?: string;
    background?: string;
    size?: 'extraBig' | 'big' | 'medium' | 'small';
    width?: number;
    disabled?: boolean;
    hoverBackground?: string;
    isPaddingXs?: boolean;
    selectedBackground?: string;
    selectedFontColor?: string;
    hoverFontColor?: string;
    className?: string;
    isUpload?: boolean;
    id?: string;
}

function Buttons(props: IButtons) {
    const {
        id,
        className,
        hoverFontColor,
        selectedBackground,
        selectedFontColor,
        children,
        width,
        variant,
        onClick,
        borderColor,
        selected,
        fontColor,
        background,
        size,
        disabled,
        hoverBackground,
        isPaddingXs,
        isUpload,
    } = props;
    const finalFontColor =
        // eslint-disable-next-line no-nested-ternary
        fontColor ||
        // eslint-disable-next-line no-nested-ternary
        (variant === 'contained'
            ? 'var(--accent-color-1)'
            : selected === true
            ? selectedFontColor ?? 'var(--color-primary-100)'
            : fontColor || 'var(--text-color-50)');
    const finalBackground =
        selected === true
            ? selectedBackground ??
              (background ||
                  (variant === 'contained'
                      ? 'var(--color-tertiary)'
                      : 'transparent'))
            : background ||
              (variant === 'contained'
                  ? 'var(--color-tertiary)'
                  : 'transparent');
    const finalBorder =
        // eslint-disable-next-line no-nested-ternary
        selected === true && variant === 'outlined'
            ? `2px solid var(--color-primary-100)`
            : selected === false && variant === 'outlined'
            ? `2px solid ${borderColor}`
            : 'inherit';
    const paddingXl =
        // eslint-disable-next-line no-nested-ternary
        size === 'medium'
            ? `${theme.spacing(2.375)} ${theme.spacing(6)}`
            : // eslint-disable-next-line no-nested-ternary
            size === 'small'
            ? `${theme.spacing(2)} ${theme.spacing(4)}`
            : size === 'big'
            ? `${theme.spacing(3.375)} ${theme.spacing(8)}`
            : `${theme.spacing(3.75)} ${theme.spacing(8)}`;
    const paddingXs =
        // eslint-disable-next-line no-nested-ternary
        size === 'medium'
            ? `${theme.spacing(1.187)} ${theme.spacing(3)}`
            : // eslint-disable-next-line no-nested-ternary
            size === 'small'
            ? `${theme.spacing(1)} ${theme.spacing(2)}`
            : size === 'big'
            ? `${theme.spacing(3.375)} ${theme.spacing(8)}`
            : `${theme.spacing(1.875)} ${theme.spacing(4)}`;
    // @ts-ignore
    return (
        <Box sx={{width: '100%'}} id={id}>
            <Button
                // @ts-ignore
                component={isUpload === true ? 'span' : undefined}
                className={className}
                disabled={disabled}
                disableRipple
                onClick={onClick}
                sx={{
                    color: finalFontColor,
                    opacity: disabled === true ? 0.5 : 1,
                    width: width ?? 'inherit',
                    borderRadius: 36,
                    textTransform: 'none',
                    [theme.breakpoints.up('xs')]: {
                        padding: isPaddingXs === true ? paddingXs : paddingXl,
                    },
                    [theme.breakpoints.up('xl')]: {
                        padding: isPaddingXs === true ? paddingXs : paddingXl,
                    },
                    background: finalBackground,
                    transition: 'background 0s',
                    border: finalBorder,
                    '&:hover': {
                        background:
                            // eslint-disable-next-line no-nested-ternary
                            hoverBackground ||
                            // eslint-disable-next-line no-nested-ternary
                            (variant === 'outlined'
                                ? 'transparent'
                                : variant === 'text'
                                ? hoverBackground
                                : background || 'var(--color-primary-100)'),
                        border:
                            // eslint-disable-next-line no-nested-ternary
                            variant === 'outlined' && selected === false
                                ? `2px solid ${fontColor}`
                                : variant === 'outlined' && selected === true
                                ? `2px solid var(--color-primary-100)`
                                : 'inherit',
                        color: hoverFontColor ?? finalFontColor,
                    },
                    '&.Mui-disabled': {
                        color:
                            variant === 'contained'
                                ? 'var(--accent-color-1)'
                                : undefined,
                    },
                }}
                variant={variant}
            >
                <Text
                    size={
                        // eslint-disable-next-line no-nested-ternary
                        size === 'medium' || size === 'big'
                            ? 's'
                            : size === 'small'
                            ? 'xxs'
                            : 'm'
                    }
                    weight="semibold"
                    fontColor="inherit"
                >
                    {children}
                </Text>
            </Button>
        </Box>
    );
}

Buttons.defaultProps = {
    variant: undefined,
    onClick: undefined,
    borderColor: undefined,
    selected: undefined,
    fontColor: undefined,
    background: undefined,
    size: undefined,
    width: undefined,
    disabled: undefined,
    hoverBackground: undefined,
    isPaddingXs: undefined,
    selectedBackground: undefined,
    selectedFontColor: undefined,
    hoverFontColor: undefined,
    className: undefined,
    isUpload: undefined,
    id: undefined,
};

export default Buttons;
