import NumberFormat from 'react-number-format';
import React from 'react';
import * as yup from 'yup';
import {DiscreteSlider} from '../../../elements/slider/DiscreteSlider';
import Selector from '../../../elements/selector/Selector';
import {GoalCostComponent} from '../goal-cost.component';
import emergencia from '../../../styles/Emergencia.module.css';
import '@inveert/ui-kit/src/input/input-int';
import {handleInputChange, handleInputFocusOut, tr} from '../../../utils/functions';

export interface GoalFormSafetyNet {
    formik: any;
    questionIndex: number;
    inflation: number;
    minInitYear: number;
}

export function EmergenciaFormComponent(props: GoalFormSafetyNet) {
    const {formik, questionIndex, inflation, minInitYear} = props;
    const expensesScheme = yup
        .number()
        .required(tr('Introduce un valor numérico'))
        .min(0, tr('El gasto no puede ser negativo'))
        .nullable();
    const objectScheme = {
        expenses: expensesScheme,
    };
    const handleFocusOut = (e: any) => handleInputFocusOut(e, formik);
    const handleChange = (e: any) => handleInputChange(e, formik, objectScheme);
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-int-change', handleChange);
        window.addEventListener('input-int-focus-out', handleFocusOut);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-int-change', handleChange);
            window.removeEventListener('input-int-focus-out', handleFocusOut);
        };
    }, [handleFocusOut, handleChange]);
    const safetyNetSelectorData = [
        {
            title: tr('Si'),
            subtitle: tr('Ya dispongo de esa cantidad'),
            name: 'is_completed',
            value: true,
        },
        {
            title: tr('No'),
            subtitle: tr('Necesito ahorrarlos'),
            name: 'is_completed',
            value: false,
        },
    ];
    switch (questionIndex) {
        case 0:
            return (
                <>
                    <inv-grid-row
                        class={emergencia.marginTopQ1Element1}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col style={{display: 'flex', justifyContent: 'center'}} class="col col-sm-5">
                            <inv-text-xs
                                style={{
                                    '--inv-text-xs-color': 'var(--text-color-50)',
                                    '--inv-text-xs-font-weight': 400,
                                    '--inv-text-xs-font-size': '0.8em',
                                    textAlign: 'center',
                                }}
                            >
                                {tr(
                                    'Incluye solo gastos básicos: (1) vivienda, (2) suministros, (3) alimentación, (4) salud y (5) personas a tu cargo.'
                                )}
                            </inv-text-xs>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        class={emergencia.marginQ1Element2}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-12 col-sm-4">
                            <inv-input-int
                                class="input input--primary"
                                value={formik.values.expenses}
                                name="expenses"
                                error={formik.errors.expenses}
                                label={tr('Gastos fijos para afrontar cada mes (aprox.)')}
                                placeholder="2000"
                                suffix={`${process.env.REACT_APP_CURRENCY_SYMBOL || '€'}/mes`}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 1:
            return (
                <>
                    <inv-grid-row
                        class={emergencia.marginTopQ2Element1}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-10 col-sm-7">
                            <DiscreteSlider
                                value={formik.values.months_income}
                                min={1}
                                max={6}
                                step={1}
                                handleChange={formik.setFieldValue}
                                name="months_income"
                                valueLabelFormat={(value: number) =>
                                    value > 1 ? `${value} ${tr('meses')}` : `${value} ${tr('mes')}`
                                }
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        class={emergencia.marginTopQ2Element2}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-auto">
                            <inv-text-xl
                                style={{
                                    '--inv-text-xl-color': 'var(--color-secondary-100)',
                                    '--inv-text-xl-font-weight': 600,
                                }}
                            >
                                <NumberFormat
                                    value={formik.values.months_income * formik.values.expenses}
                                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix={` ${process.env.REACT_APP_CURRENCY_SYMBOL || '€'}`}
                                />
                            </inv-text-xl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        class={emergencia.marginBottomQ2Element2}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <inv-grid-col class="col-auto" style={{display: 'flex', justifyContent: 'center'}}>
                            <inv-text-s
                                style={{
                                    '--inv-text-s-color': 'var(--text-color-50)',
                                    '--inv-text-s-font-weight': 600,
                                    textAlign: 'center',
                                }}
                            >
                                {tr('Es la cantidad que necesitas para tu tranquilidad')}
                            </inv-text-s>
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            );
        case 2:
            return (
                <>
                    <inv-grid-row
                        class={emergencia.marginQ3Element1}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        {safetyNetSelectorData.map((data) => (
                            <inv-grid-col key={data.title} class="col-12 col-sm-3">
                                <Selector
                                    title={data.title}
                                    /* @ts-ignore */
                                    name={data.name}
                                    subtitle={data.subtitle}
                                    selected={formik.values.is_completed === data.value}
                                    onClick={() => {
                                        formik.setFieldValue(data.name, data.value, false);
                                    }}
                                />
                            </inv-grid-col>
                        ))}
                    </inv-grid-row>
                    {formik.values.is_completed !== null &&
                        (formik.values.is_completed === false ? (
                            <inv-grid-row
                                class={emergencia.marginQ3Element2}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col
                                    class="col-auto col-sm-8"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <inv-text-xs
                                        style={{
                                            '--inv-text-xs-color': 'var(--text-color-50)',
                                            '--inv-text-xs-font-weight': 300,
                                            lineHeight: '140%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {tr('Entendido, ¡vamos a por ello!')}
                                    </inv-text-xs>
                                </inv-grid-col>
                            </inv-grid-row>
                        ) : (
                            <inv-grid-row
                                class={emergencia.marginQ3Element2}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <inv-grid-col
                                    class="col-auto col-sm-8"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <inv-text-xs
                                        style={{
                                            '--inv-text-xs-color': 'var(--text-color-50)',
                                            '--inv-text-xs-font-weight': 300,
                                            lineHeight: '140%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {tr(
                                            '¡Enhorabuena! Te recomendamos que guardes esa cantidad y que inviertas el resto de tus ahorros para alcanzar tus otras metas.'
                                        )}
                                    </inv-text-xs>
                                </inv-grid-col>
                            </inv-grid-row>
                        ))}
                </>
            );
        case 3:
            return (
                <inv-grid-row class={emergencia.marginQ4Element1} style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-grid-col class="col-10 col-sm-7">
                        <DiscreteSlider
                            value={formik.values.duration}
                            min={minInitYear < 4 ? 4 : minInitYear + 1}
                            max={minInitYear < 4 ? 9 : minInitYear + 10}
                            step={1}
                            handleChange={formik.setFieldValue}
                            name="duration"
                            valueLabelFormat={(value: number) =>
                                value > 1 ? `${value} ${tr('años')}` : `${value} ${tr('año')}`
                            }
                        />
                    </inv-grid-col>
                </inv-grid-row>
            );
        case 4:
            return <GoalCostComponent inflation={inflation} />;
        default:
            return null;
    }
}

export default EmergenciaFormComponent;
