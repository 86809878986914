import {DataLayerObject} from './gtm.type';

const pushToDataLayer = (obj: DataLayerObject) => {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer?.push(obj);
};

export const setUserDataLayer = (
    email: string | null,
    age: number | null = null,
    profileId: number | null = null,
    annualGrossIncome: number | null = null,
    postalCode: string | null = null
) => {
    const dataLayer = (<any>window).dataLayer || [];
    const previousData = dataLayer?.findLast((obj: DataLayerObject) => obj.event === 'customUser');
    pushToDataLayer({
        event: 'customUser',
        userData: {
            email: email || previousData?.userData?.email,
            age: age || previousData?.userData?.age,
            profileId: profileId || previousData?.userData?.profileId,
            annualGrossIncome: annualGrossIncome || previousData?.userData?.annualGrossIncome,
            postalCode: postalCode || previousData?.userData?.postalCode,
        },
    });
};

export const setGoalDataLayer = (type: string, timeHorizonInYears: number) => {
    const dataLayer = (<any>window).dataLayer || [];
    const previousData = dataLayer?.findLast((obj: DataLayerObject) => obj.event === 'customGoal');
    pushToDataLayer({
        event: 'customGoal',
        goalData: {
            type: type || previousData?.goalData?.type,
            timeHorizonInYears: timeHorizonInYears || previousData?.goalData?.timeHorizonInYears,
        },
    });
};

export const leadGeneration = (phone: string, email: string) => {
    pushToDataLayer({
        event: 'leadGeneration',
        leadGenerationData: {
            phone,
            email,
        },
    });
};

export const setInitialInvestmentDataLayer = (privatePensionPlan: number, initialInvestment: number) => {
    pushToDataLayer({
        event: 'customInitialInvestment',
        initialInvestmentData: {
            privatePensionPlan,
            initialInvestment,
        },
    });
};

export const setPlanDataLayer = (monthlySaving: number) => {
    pushToDataLayer({
        event: 'customPlan',
        planData: {
            monthlySaving,
        },
    });
};

export const createGenericEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean
) => {
    const obj: DataLayerObject = {
        event: 'customEvent',
        eventData: {
            category,
            action,
            label,
            value,
            nonInteraction,
        },
    };
    pushToDataLayer(obj);
};

export default {
    createGenericEvent,
    setUserDataLayer,
};

export const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url: any) => {
    // @ts-ignore
    window.dataLayer({
        event: 'pageview',
        page: url,
    });
};
