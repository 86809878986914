import {compareAsc, differenceInCalendarYears, getYear, max, parseISO} from 'date-fns';
import {store} from '../../store';
import {goalColorByType} from '../../utils/componentData';
import {getErrorMessage} from '../../utils/functions';
import {addAlert} from '../app/app.slice';
import {getObjectivesWithCosts} from '../goal/goal.service';
import {getActiveRiskProfileService, getAllRiskProfilesApi, getMaxRiskProfileService} from '../risk/risk.service';
import {getUserDataApi, getUserIdApi} from '../user/user.service';
import {setRiskProfile, setRiskProfileArray, setUserId, setUserNameAndBirthday} from '../user/user.slice';
import {
    getAccumulatedNetWorthChartData,
    getConfig,
    getFinancialServicesService,
    getGlobalConfigWithoutInveert,
    getNotAccumulatedNetWorthChartData,
    getOutputChartAllObjectives,
    getPlan,
    getPlanWithGlobalConfig,
    getPlanWithGoalResult,
    getRecommendationsService,
    getTirExistingUser,
} from './propuesta.service';

/**
 * Función que obtiene el plan con los escenarios
 * @param {({saving: number; performance: number; min_performance: number; initial_investment: number; remaining_saving: number; fixed_income: number; max_performance: number; yearly_saving: number; adjusted_objective: number; equity: number; monthly_saving: number; objective: {end_date: string; init_date: string; amount: number; is_active: boolean; time_horizon: number; output_type: string; probability: null; duration_type: null; protection_type: string; type: string; duration: null; name: string; percentage_completed: null; id: number; adjusted_objective: number}} | {saving: number; performance: number; min_performance: number; initial_investment: number; remaining_saving: number; fixed_income: number; max_performance: number; yearly_saving: number; adjusted_objective: number; equity: number; monthly_saving: number; objective: {end_date: string; init_date: string; amount: number; is_active: boolean; time_horizon: number; output_type: string; probability: null; duration_type: string; protection_type: string; type: string; duration: number; name: string; percentage_completed: null; id: number; adjusted_objective: number}})[]} plan
 * @param {({scenario_data: {optimistic_performance: number; expected_amount: number; expected_performance: number; pessimistic_TIR: number; optimistic_amount: number; pessimistic_performance: number; pessimistic_amount: number; optimistic_TIR: number; expected_TIR: number}; objective: number} | {scenario_data: {optimistic_performance: number; expected_amount: number; expected_performance: number; pessimistic_TIR: number; optimistic_amount: number; pessimistic_performance: number; pessimistic_amount: number; optimistic_TIR: number; expected_TIR: number}; objective: number})[]} scenarios
 * @return {*}
 */
export const getPlanWithScenarios = (plan, scenarios) =>
    plan.map((planObjective) => {
        const scenarioObjectiveId = scenarios.reduce((selectedScenario, item) => {
            if (item.objective === planObjective.objective.id) {
                // eslint-disable-next-line no-param-reassign
                selectedScenario = item;
            }
            return selectedScenario;
        }, null);
        return {...planObjective, ...scenarioObjectiveId?.scenario_data};
    });

/**
 * Obtiene los datos del usuario desde la API de forma asíncrona
 * @param {Function} setLoadingUser - Función que setea el estado de carga del usuario
 * @param {Function} router - Función que redirecciona a una ruta
 * @returns {Promise<void>}
 */
export const getUserAsync = async (setLoadingUser, router) => {
    try {
        setLoadingUser(true);
        // eslint-disable-next-line no-undef
        const storage = globalThis?.sessionStorage;
        const token = storage?.getItem('token');
        if (token) {
            const userIdResponse = await getUserIdApi(token);
            if (userIdResponse?.attributes?.user_id) {
                const resPlan = await getPlan();
                const activePlan = resPlan.find(
                    /**
                     * @param {Object} plan - The plan object
                     * @param {Object} plan.attributes - The attributes object
                     * @param {string} plan.attributes.status - The status property of the attributes object
                     * @returns {boolean} - Whether the plan's status is 'ACTIVE'
                     */
                    (plan) => plan.attributes.status === 'ACTIVE'
                );
                const planId = activePlan?.id;
                if (!planId) {
                    store.dispatch(setUserId(userIdResponse?.attributes?.user_id));
                    const userDataResponse = await getUserDataApi(userIdResponse?.attributes?.user_id?.toString());
                    store.dispatch(
                        setUserNameAndBirthday({
                            birthday: userDataResponse.attributes.birthday,
                            name: userDataResponse.attributes.name,
                        })
                    );
                } else {
                    router('/plan');
                }
            } else {
                if (process.env.REACT_APP_THEME === 'finsei') {
                    router('/login');
                }
                throw new Error('Usuario no logueado');
            }
        } else {
            if (process.env.REACT_APP_THEME === 'finsei') {
                router('/login');
            }
            throw new Error('Usuario no logueado');
        }
        setLoadingUser(false);
    } catch (e) {
        // @ts-ignore
        const message = await getErrorMessage(e, e.message);
        setLoadingUser(false);
        if (process.env.REACT_APP_THEME === 'finsei') {
            router('/login');
        }
        throw new Error(message);
    }
};

/**
 * Funcion para procesar la recomendacion
 * @param recommendationRes
 * @return {Promise<(*&{goal_ids: *})[]>}
 */
export const processRecommendation = async (recommendationRes) => {
    const finalRecommendation = recommendationRes.included.map(
        /**
         * Funcion para obtener los servicios financieros de la recomendacion
         *
         * @param {Object} recommendationItem - Recommendation item parameter
         * @param {any} recommendationItem.attributes - Attributes of the recommendation item
         * @param {string} recommendationItem.id - ID of the recommendation item
         */
        (recommendationItem) =>
            getFinancialServicesService(recommendationItem.id).then((data) => ({
                ...recommendationItem,
                attributes: {
                    ...recommendationItem.attributes,
                    services: data.included,
                },
            }))
    );
    const data = await Promise.all(finalRecommendation);
    const filteredRecommendation = recommendationRes.data.reduce(
        /**
         * Funcion para filtrar los servicios financieros que no tienen objetivos relacionados
         *
         * @param {Array} acc - Accumulator array parameter
         * @param {Object} curr - Current object parameter
         * @param {Object} curr.attributes - Attributes of the current object
         * @param {*} curr.attributes.financial_service_id - Financial service ID attribute of the current object
         * @param {*} curr.attributes.initial_investment - Initial investment attribute of the current object
         * @param {*} curr.attributes.monthly_saving - Monthly saving attribute of the current object
         */
        (acc, curr) => {
            const existingObject = acc.find(
                /**
                 * Funcion para encontrar un objeto en el array de acumulador
                 *
                 * @param {Object} obj - Object parameter
                 * @param {Object} obj.attributes - Attributes object of the parameter
                 * @param {*} obj.attributes.financial_service_id - Financial service ID attribute of the parameter
                 */
                (obj) => obj.attributes.financial_service_id === curr.attributes.financial_service_id
            );

            if (existingObject) {
                existingObject.attributes.initial_investment += curr.attributes.initial_investment;
                existingObject.attributes.monthly_saving += curr.attributes.monthly_saving;
            } else {
                acc.push({...curr});
            }

            return acc;
        },
        []
    );
    const recommendationWithInitialInvestment = data.map(
        /**
         * Funcion para agregar el monto de inversion inicial y el ahorro mensual a los servicios financieros
         *
         * @param {Object} recommendationItem - Recommendation item parameter
         * @param {*} recommendationItem.attributes - Attributes of the recommendation item
         * @param {string} recommendationItem.id - ID of the recommendation item
         * @param {'FinancialService'} recommendationItem.type - Type of the recommendation item, which should be 'FinancialService'
         */
        (recommendationItem) => {
            const existingObject = filteredRecommendation.find(
                /**
                 * Funcion para encontrar un objeto en el array de recomendaciones filtradas
                 *
                 * @param {Object} obj - Object parameter
                 * @param {Object} obj.attributes - Attributes object of the parameter
                 * @param {*} obj.attributes.financial_service_id - Financial service ID attribute of the parameter
                 */
                (obj) => obj?.attributes?.financial_service_id?.toString() === recommendationItem?.id?.toString()
            );
            return {
                ...recommendationItem,
                attributes: {
                    ...recommendationItem.attributes,
                    total_initial_investment: existingObject?.attributes?.initial_investment,
                    total_monthly_saving: existingObject?.attributes?.monthly_saving,
                },
            };
        }
    );
    return recommendationWithInitialInvestment.map((item) => {
        const financialServiceId = item.id;
        const goalIds = recommendationRes.data
            .filter(
                /**
                 * Funcion para filtrar los objetivos que tienen el servicio financiero
                 *
                 * @param {Object} obj - Object parameter
                 * @param {Object} obj.attributes - Attributes object of the parameter
                 * @param {Object} obj.attributes.financial_service_id - Financial service ID attribute of the parameter
                 * @param {Function} obj.attributes.financial_service_id.toString - Function that converts the financial service ID to a string
                 * @returns {string} - The string representation of the financial service ID
                 */
                (obj) => obj.attributes.financial_service_id.toString() === financialServiceId.toString()
            )
            .map(
                /**
                 * Funcion para obtener los IDs de los objetivos
                 *
                 * @param {Object} obj - Object parameter
                 * @param {Object} obj.attributes - Attributes object of the parameter
                 * @param {*} obj.attributes.goal_id - Goal ID attribute of the parameter
                 */
                (obj) => obj.attributes.goal_id
            );
        return {
            ...item,
            goal_ids: goalIds,
        };
    });
};

/**
 * Obtiene los datos de la propuesta desde la API de forma asíncrona
 * @param {Function} setLoading
 * @param {Function} setLoadingUser
 * @param {Function}  setPlanId
 * @param {Function}  setGlobalInfo
 * @param {Function}  setMonthlyWithoutInveert
 * @param {Function}  setTir
 * @param {Function}  setIsRetirement
 * @param {Function}  setCumulativeNetWorthChartData
 * @param {Function}  setInflation
 * @param {Function}  setInputChartData
 * @param {Function}  setObjectives
 * @param {Function}  setNotCumulativeNetWorthChartData
 * @param {Function}  setRecomm
 * @param {NavigateFunction}  router
 * @return {Promise<void>}
 */
export const getPropuestaDataAsync = async (
    setLoading,
    setLoadingUser,
    setGlobalInfo,
    setTir,
    setCumulativeNetWorthChartData,
    setInputChartData,
    setObjectives,
    router,
    setNotCumulativeNetWorthChartData = null,
    setRecomm = null,
    setConfigConst = null,
    setIsRetirement = null,
    setPlanId = null,
    setMonthlyWithoutInveert = null
) => {
    try {
        setLoading(true);
        await getUserAsync(setLoadingUser, router);

        let resConfig;
        const resMaxRiskProfile = getMaxRiskProfileService();
        const resActiveRiskProfile = getActiveRiskProfileService();
        if (setConfigConst) {
            resConfig = getConfig();
        }
        const resGoalResult = getPlanWithGoalResult();
        const resObjectives = getObjectivesWithCosts();
        const resRiskProfiles = getAllRiskProfilesApi();
        const resGlobalConfig = getPlanWithGlobalConfig();
        /**
         * @param {Array} resPropuesta - An array of various data objects
         */
        const resPropuesta = await Promise.all([
            resGlobalConfig,
            resObjectives,
            resGoalResult,
            resRiskProfiles,
            resConfig,
            resMaxRiskProfile,
            resActiveRiskProfile,
        ]);
        const resPlan = resPropuesta[0];
        const id = resPlan?.data[0]?.id;
        if (id) {
            let notAccNetWorthRes;
            let globalConfigWithoutInveert;
            let recommendation;
            if (setPlanId) {
                setPlanId(id);
            }
            setGlobalInfo({
                ...resPlan.included[0].attributes,
                user_input_initial_investment: resPlan.data[0].attributes.initial_investment ?? 0,
                user_input_pension_plan: resPlan.data[0].attributes.pension_plan ?? 0,
                totalInvestment: resPlan.included[0].attributes.initial_investment,
            });
            if (setNotCumulativeNetWorthChartData) {
                notAccNetWorthRes = getNotAccumulatedNetWorthChartData(id);
            }
            if (setMonthlyWithoutInveert) {
                globalConfigWithoutInveert = getGlobalConfigWithoutInveert(id);
            }
            const outputRes = getOutputChartAllObjectives();
            const accNetWorthRes = getAccumulatedNetWorthChartData(id);
            if (setRecomm) {
                recommendation = getRecommendationsService(id);
            }
            const tirRes = getTirExistingUser(id);
            const results = await Promise.all([
                outputRes,
                accNetWorthRes,
                notAccNetWorthRes,
                recommendation,
                tirRes,
                globalConfigWithoutInveert,
            ]);
            resPropuesta[0] = {
                outputChart: results[0],
                accNetWorthChart: results[1],
                notAccNetWorthChart: results[2],
                recommendation: results[3],
                tir: results[4],
                globalConfigWithoutInveert: results[5],
            };
            if (setMonthlyWithoutInveert) {
                setMonthlyWithoutInveert(resPropuesta[0].globalConfigWithoutInveert.attributes.monthly_savings);
            }
            setTir(resPropuesta[0].tir.attributes.tir * 100);
            if (setIsRetirement) {
                const retirementExists = !!resPropuesta[1].find(
                    /**
                     * @param {{ attributes: { type: string } }} goal
                     */
                    (goal) => goal.attributes.type === 'RETIREMENT' || goal.attributes.type === 'RETIREMENT_ONLY_FUND'
                );
                setIsRetirement(retirementExists);
            }
            const userCurrentRisk = resPropuesta[6].data.findLast(
                /**
                 * @param {{ attributes: { is_active: boolean } }} riskElement
                 */
                (riskElement) => riskElement.attributes.is_active === true
            );
            const userMaxRisk = resPropuesta[5]?.data?.findLast(
                /**
                 * @param {{ attributes: { main: boolean } }} riskElement
                 */
                (riskElement) => riskElement.attributes.main === true
            );
            const aliasCurrentRisk = resPropuesta[3].find(
                /**
                 * @param {{ id: any }} riskItem
                 */
                (riskItem) => riskItem.id === userCurrentRisk.attributes.risk_profile_id.toString()
            ).attributes.name;
            // @ts-ignore
            setCumulativeNetWorthChartData(resPropuesta[0].accNetWorthChart);
            if (setConfigConst) {
                const configInflation = resPropuesta[4].inflation;
                const minFunds = resPropuesta[4].min_funds;
                const minPension = resPropuesta[4].min_pension;
                const deathAge = resPropuesta[4].death_age;
                if (
                    typeof configInflation === 'number' &&
                    typeof minFunds === 'number' &&
                    typeof minPension === 'number' &&
                    typeof deathAge === 'number'
                ) {
                    setConfigConst({
                        inflation: configInflation,
                        min_funds: minFunds,
                        min_pension: minPension,
                        deathAge,
                    });
                }
            }
            store.dispatch(
                setRiskProfile({
                    profile_id: userCurrentRisk?.attributes?.risk_profile_id,
                    max_profile_id: userMaxRisk?.attributes?.risk_profile_id,
                    alias: aliasCurrentRisk,
                })
            );
            store.dispatch(setRiskProfileArray({riskProfileArray: resPropuesta[3]}));

            const objectivesWithCosts = resPropuesta[1];
            const objectivesWithCostsAndResults = objectivesWithCosts.map(
                /**
                 * @param {{
                 *     id: { toString: () => string },
                 *     attributes: { init_date: string }
                 * }} objective
                 */
                (objective) => {
                    const result = resPropuesta[2].included.filter(
                        /**
                         * @param {{ attributes: { goal_id: number } }} objectiveResult
                         */
                        (objectiveResult) => objectiveResult.attributes.goal_id?.toString() === objective.id?.toString()
                    )[0];
                    const outputData = resPropuesta[0].outputChart.included.reduce(
                        /**
                         * @param {Array} outputOfGoal
                         * @param {{
                         *     attributes: {
                         *         goal_id: number,
                         *         monthly_withdrawals?: Array<any>,
                         *         withdrawal?: Array<any>,
                         *         yearly_withdrawals?: Array<any>
                         *     }
                         * }} currentData
                         */
                        (outputOfGoal, currentData) => {
                            /**
                             * @param {Array<{name:number,bar1:*}>} data
                             */
                            let data = outputOfGoal;
                            if (currentData.attributes.goal_id?.toString() === objective.id?.toString()) {
                                const costs =
                                    currentData.attributes.monthly_withdrawals ??
                                    currentData.attributes.yearly_withdrawals ??
                                    currentData.attributes.withdrawal ??
                                    [];
                                data = costs.map(
                                    /**
                                     *
                                     * @param cost
                                     * @param {number} index
                                     * @return {{bar1: *, name: number}}
                                     */
                                    (cost, index) => ({
                                        name: getYear(parseISO(objective.attributes.init_date)) + index,
                                        bar1: cost,
                                    })
                                );
                            }
                            return data;
                        },
                        []
                    );
                    return {
                        ...objective,
                        result: result.attributes,
                        outputChartData: outputData,
                    };
                }
            );

            // Crear el input data para la grafica de los inputs
            const sortedObjectivesWithCostsAndResults = objectivesWithCostsAndResults.sort((a, b) => {
                const aDate = a.attributes.init_date ? parseISO(a.attributes.init_date) : new Date(-8640000000000000); // Earliest possible date
                const bDate = b.attributes.init_date ? parseISO(b.attributes.init_date) : new Date(-8640000000000000); // Earliest possible date

                // dateFns.compareAsc returns a positive number if the first date is after the second, a negative number if the first date is before the second, and 0 if dates are equal.
                // By reversing the parameters we get a descending sort.
                return compareAsc(bDate, aDate);
            });
            const maxInitDate = sortedObjectivesWithCostsAndResults.reduce((maxDate, obj) => {
                const currentInitDate = parseISO(obj.attributes.init_date);
                return max([maxDate, currentInitDate]);
            }, new Date(0));
            const difference = differenceInCalendarYears(maxInitDate, new Date());
            const currentYear = getYear(new Date());
            const inputArray = [];
            const goal1InitDate = sortedObjectivesWithCostsAndResults[0]?.attributes?.init_date
                ? parseISO(sortedObjectivesWithCostsAndResults[0].attributes.init_date)
                : undefined;
            const goal2InitDate = sortedObjectivesWithCostsAndResults[1]?.attributes?.init_date
                ? parseISO(sortedObjectivesWithCostsAndResults[1].attributes.init_date)
                : undefined;
            const goal3InitDate = sortedObjectivesWithCostsAndResults[2]?.attributes?.init_date
                ? parseISO(sortedObjectivesWithCostsAndResults[2].attributes.init_date)
                : undefined;
            const goal4InitDate = sortedObjectivesWithCostsAndResults[3]?.attributes?.init_date
                ? parseISO(sortedObjectivesWithCostsAndResults[3].attributes.init_date)
                : undefined;
            const difference1 = goal1InitDate ? differenceInCalendarYears(goal1InitDate, new Date()) : 0;
            const difference2 = goal2InitDate ? differenceInCalendarYears(goal2InitDate, new Date()) : 0;
            const difference3 = goal3InitDate ? differenceInCalendarYears(goal3InitDate, new Date()) : 0;
            const difference4 = goal4InitDate ? differenceInCalendarYears(goal4InitDate, new Date()) : 0;
            for (let i = 0; i <= difference; i += 1) {
                inputArray.push({
                    name: `${currentYear + i}`,
                    bar1: i <= difference1 ? sortedObjectivesWithCostsAndResults[0]?.result?.monthly_saving ?? 0 : 0,
                    bar2: i <= difference2 ? sortedObjectivesWithCostsAndResults?.[1]?.result?.monthly_saving ?? 0 : 0,
                    bar3: i <= difference3 ? sortedObjectivesWithCostsAndResults?.[2]?.result?.monthly_saving ?? 0 : 0,
                    bar4: i <= difference4 ? sortedObjectivesWithCostsAndResults?.[3]?.result?.monthly_saving ?? 0 : 0,
                });
            }
            const idBars = {
                bar1: undefined,
                bar2: undefined,
                bar3: undefined,
                bar4: undefined,
            };

            const colorBars = {
                bar1: undefined,
                bar2: undefined,
                bar3: undefined,
                bar4: undefined,
            };

            sortedObjectivesWithCostsAndResults.forEach((item, index) => {
                if (index < 4) {
                    idBars[`bar${index + 1}`] = item.id;
                }
            });

            sortedObjectivesWithCostsAndResults.forEach((item, index) => {
                if (index < 4) {
                    colorBars[`bar${index + 1}`] = goalColorByType[item.attributes.type];
                }
            });

            setInputChartData({
                barsColor: colorBars,
                barsId: idBars,
                inputData: inputArray,
                sortedObjectivesWithCostsAndResults,
            });
            if (setNotCumulativeNetWorthChartData) {
                setNotCumulativeNetWorthChartData({
                    data: resPropuesta[0].notAccNetWorthChart.data,
                    dotsData: resPropuesta[0].notAccNetWorthChart.dotsData,
                });
            }
            if (setRecomm) {
                const financialServiceWithRelatedGoalIds = resPropuesta[0].recommendation.data.reduce(
                    /**
                     * @typedef {Object} Acc
                     * @property {number} financial_service_id - ID of the financial service
                     * @property {Array.<number>} goalIds - Array of goal IDs related to the financial service
                     */

                    /**
                     * @typedef {Object} Attributes
                     * @property {number} financial_service_id - ID of the financial service
                     * @property {number} goal_id - ID of the goal
                     */

                    /**
                     * @typedef {Object} Obj
                     * @property {Attributes} attributes - Object with attributes
                     */

                    /**
                     * Funcion para reducir el array de recomendaciones
                     *
                     * @param {Acc} acc - Accumulator
                     * @param {Obj} obj - Object parameter
                     * @returns {Array.<{financial_service_id: *, goal_ids: *[]}>} Array of objects with financial_service_id and goal_ids properties
                     */
                    (acc, obj) => {
                        const index = acc.findIndex(
                            (a) => a.financial_service_id === obj.attributes.financial_service_id
                        );
                        if (index >= 0) {
                            acc[index].goal_ids.push(obj.attributes.goal_id);
                        } else {
                            acc.push({
                                financial_service_id: obj.attributes.financial_service_id,
                                goal_ids: [obj.attributes.goal_id],
                            });
                        }
                        return acc;
                    },
                    []
                );
                const obejctivesWithCostsResultsAndFinancialServices = objectivesWithCostsAndResults.map(
                    /**
                     * Funcion para agregar los servicios financieros a los objetivos
                     *
                     * @typedef {Object} Objective
                     * @property {Object} id - Object representing the ID
                     * @property {Object} attributes - Object representing the attributes
                     *
                     * @param {Objective} objective - The objective parameter
                     * @return {{id: {toString}, attributes: {[p: string]: *}}} - Object with ID and attributes
                     */
                    (objective) => {
                        const financialServices = financialServiceWithRelatedGoalIds.filter((financialService) =>
                            financialService.goal_ids.find((goal_id) => goal_id.toString() === objective.id.toString())
                        );
                        return {
                            ...objective,
                            attributes: {
                                ...objective.attributes,
                                financial_services: financialServices,
                            },
                        };
                    }
                );
                // @ts-ignore
                setObjectives(obejctivesWithCostsResultsAndFinancialServices);
                const recommendationFinal = await processRecommendation(resPropuesta[0].recommendation);
                setRecomm(recommendationFinal);
            } else {
                setObjectives(objectivesWithCostsAndResults);
            }
            setLoading(false);
        }
    } catch (e) {
        // @ts-ignore
        const message = await getErrorMessage(e);
        store.dispatch(
            addAlert({
                message,
                isError: true,
                isOpen: true,
            })
        );
        setLoading(false);
    }
};
