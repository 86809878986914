import * as React from 'react';
// @ts-ignore
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import SolidDividers from '../../elements/dividers/SolidDividers';
import DotStepsFragment from '../../elements/fragments/DotStepsFragment';
import {ComplementSimulationRequiredData, ObjectiveType} from '../../modules/goal/goal.type';
import {retirementSimulation} from '../../modules/goal/goal.service';
import {addAlert, resetAlert} from '../../modules/app/app.slice';
import goalFooterMeta from '../../styles/GoalFooterMeta.module.css';
import '@inveert/ui-kit/src/button/button';
import {getErrorMessage, tpl, tr} from '../../utils/functions';

interface IGoalFooterMeta {
    questionIndex: number;
    goBack: any;
    setQuestionIndex: any;
    formik: any;
    questionNameOrder: Array<any>;
    goalType: ObjectiveType;
    setSimulatedRetirement?: any;
    birthday?: string | null;
    hideDots?: boolean;
    hideBirthdayQuestion?: boolean;
    setLoading?: (loading: boolean) => void;
}

export function GoalFooterMeta(props: IGoalFooterMeta) {
    const {
        questionIndex,
        goBack,
        setQuestionIndex,
        formik,
        questionNameOrder,
        goalType,
        birthday,
        setSimulatedRetirement,
        hideDots,
        hideBirthdayQuestion,
        setLoading,
    } = props;
    const router = useNavigate();
    const dispatch = useDispatch();
    const getTextOfNextButton = () => {
        let text = tr('Continuar');
        if (questionIndex === questionNameOrder.length - 1) {
            text = tr('Calcular ahorro necesario');
        } else if (goalType === 'SAFETY_NET' && questionIndex === 2 && formik.values?.is_completed === true) {
            text = tr('Definir otra meta');
        } else if (questionIndex === questionNameOrder.length) {
            text = tpl(tr('Continuar'), {siteTitle: process.env.REACT_APP_SITE_TITLE ?? ''});
        }
        return text;
    };
    const getNextButtonColumnSize = () => {
        let colSize = '4';
        if (questionIndex === questionNameOrder.length) {
            colSize = 'auto';
        }
        return colSize;
    };
    const getTextOfBackButton = () => {
        let text = tr('Atrás');
        if (
            (questionIndex === 0 && process.env.REACT_APP_THEME !== 'finsei') ||
            (goalType === 'HERITAGE' && questionIndex === 1 && hideBirthdayQuestion === true)
        ) {
            text = tr('Volver a metas');
        }
        return text;
    };
    const handleClickOnBack = () => {
        if (
            (questionIndex === 0 && process.env.REACT_APP_THEME !== 'finsei') ||
            (goalType === 'HERITAGE' && questionIndex === 1 && hideBirthdayQuestion === true)
        ) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            goBack ? goBack() : router('/meta');
        } else if (process.env.REACT_APP_THEME === 'finsei' && questionIndex === 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            goBack ? goBack() : router('/inicio');
        } else {
            setQuestionIndex(questionIndex - 1);
        }
    };
    const handleClickOnNext = () => {
        if (goalType === 'REAL_STATE') {
            formik
                .validateForm(formik.values)
                .then(
                    (err: {
                        real_state_value: undefined;
                        init_date: undefined;
                        is_home: undefined;
                        percentage: undefined;
                    }) => {
                        if (questionIndex === 1 && err.percentage === undefined) {
                            setQuestionIndex(questionIndex + 1);
                            formik.setFieldError('init_date', undefined);
                        } else if (questionIndex === 2 && err.init_date === undefined) {
                            setQuestionIndex(questionIndex + 1);
                        } else if (
                            questionIndex === 0 &&
                            err.real_state_value === undefined &&
                            err?.is_home === undefined
                        ) {
                            setQuestionIndex(questionIndex + 1);
                        } else if (questionIndex === 3) {
                            formik.handleSubmit();
                        }
                    }
                );
        } else if (goalType === 'CUSTOM') {
            formik
                .validateForm(formik.values)
                .then(
                    (err: {
                        init_date: undefined;
                        end_date: undefined;
                        output_type: undefined;
                        amount: undefined;
                        name: undefined;
                        duration: undefined;
                    }) => {
                        if (
                            questionIndex === 1 &&
                            err.init_date === undefined &&
                            err?.end_date === undefined &&
                            err.output_type === undefined &&
                            err?.duration === undefined
                        ) {
                            setQuestionIndex(questionIndex + 1);
                            formik.setFieldError('amount', undefined);
                        } else if (questionIndex === 2 && err.amount === undefined) {
                            setQuestionIndex(questionIndex + 1);
                        } else if (questionIndex === 0 && err.name === undefined) {
                            formik.setErrors({});
                            setQuestionIndex(questionIndex + 1);
                        } else if (questionIndex === 3) {
                            formik.handleSubmit();
                        }
                    }
                );
        } else if (goalType === 'SAFETY_NET') {
            formik
                .validateForm(formik.values)
                .then(
                    (err: {
                        duration: undefined;
                        months_income: undefined;
                        expenses: undefined;
                        is_completed: undefined;
                    }) => {
                        if (questionIndex === 1 && err.months_income === undefined) {
                            setQuestionIndex(questionIndex + 1);
                        } else if (questionIndex === 2 && err.is_completed === undefined) {
                            if (formik.values.is_completed === true) {
                                router('/meta');
                            } else {
                                setQuestionIndex(questionIndex + 1);
                            }
                        } else if (questionIndex === 0 && err.expenses === undefined) {
                            setQuestionIndex(questionIndex + 1);
                        } else if (questionIndex === 3 && err.duration === undefined) {
                            setQuestionIndex(questionIndex + 1);
                        } else if (questionIndex === 4) {
                            formik.handleSubmit();
                        }
                    }
                );
        } else if (goalType === 'RETIREMENT' || goalType === 'RETIREMENT_ONLY_FUND') {
            formik
                .validateForm(formik.values)
                .then(
                    (err: {
                        net_income: undefined;
                        monthly_self_employed_fee: undefined;
                        years_contributed: undefined;
                        retirement_age: undefined;
                        disability: undefined;
                    }) => {
                        if (
                            questionIndex === 1 &&
                            err.net_income === undefined &&
                            err.monthly_self_employed_fee === undefined &&
                            err.years_contributed === undefined &&
                            err.disability === undefined
                        ) {
                            if (setLoading) {
                                setLoading(true);
                            }
                            const requiredData: ComplementSimulationRequiredData = {
                                years_contributed: formik.values.years_contributed,
                                net_income:
                                    // eslint-disable-next-line no-nested-ternary
                                    formik.values.is_freelance === true
                                        ? formik.values.monthly_self_employed_fee === null
                                            ? 0
                                            : formik.values.monthly_self_employed_fee
                                        : formik.values.net_income,
                                is_freelance: formik.values.is_freelance,
                                disability: parseInt(formik.values.disability, 10),
                                birthday,
                            };
                            retirementSimulation(requiredData)
                                .then((response) => {
                                    setSimulatedRetirement(response);
                                    setQuestionIndex(questionIndex + 1);
                                    if (setLoading) {
                                        setLoading(false);
                                    }
                                })
                                .catch(async (e) => {
                                    const message = await getErrorMessage(e);
                                    dispatch(
                                        addAlert({
                                            message,
                                            isError: true,
                                            isOpen: true,
                                        })
                                    );
                                    if (setLoading) {
                                        setLoading(false);
                                    }
                                });
                        } else if (questionIndex === 2 && err.retirement_age === undefined) {
                            setQuestionIndex(questionIndex + 1);
                        } else if (questionIndex === 3) {
                            if (formik.values.percentage === 0) {
                                dispatch(
                                    addAlert({
                                        message: tr('El complemento no puede ser cero'),
                                        isError: true,
                                        isOpen: true,
                                    })
                                );
                            } else {
                                dispatch(resetAlert());
                                setQuestionIndex(questionIndex + 1);
                            }
                        } else if (questionIndex === 4) {
                            formik.handleSubmit();
                        }
                    }
                );
        } else {
            formik
                .setFieldTouched(questionNameOrder[questionIndex], true, true)
                .then((err: {hasOwnProperty: (arg0: string) => any}) => {
                    if (
                        // eslint-disable-next-line no-prototype-builtins
                        !err?.hasOwnProperty(questionNameOrder[questionIndex])
                    ) {
                        if (questionIndex === questionNameOrder.length) {
                            formik.handleSubmit();
                        } else {
                            formik.setErrors({});
                            setQuestionIndex(questionIndex + 1);
                        }
                    }
                });
        }
    };
    return (
        <>
            <inv-grid-row class={`${goalFooterMeta.divider}`}>
                <inv-grid-col class="col-12">
                    <div style={{maxWidth: 'calc(100% - var(--inv-grid-gutter-x))'}}>
                        <SolidDividers orientation="horizontal" />
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1.2em 0 0',
                    '--inv-grid-gutter-x': 0,
                }}
            >
                <inv-grid-col class={`col-12 col-sm-4 ${goalFooterMeta.buttonElement1}`}>
                    <inv-button onClick={handleClickOnBack} class="button button--secondary-contained full-width">
                        {getTextOfBackButton()}
                    </inv-button>
                </inv-grid-col>
                {hideDots === false && (
                    <inv-grid-col
                        class={`col-auto ${goalFooterMeta.buttonElement2}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            zIndex: 2,
                        }}
                    >
                        <DotStepsFragment steps={questionNameOrder.length} activeStep={questionIndex} />
                    </inv-grid-col>
                )}

                <inv-grid-col class={`col-12 col-sm-${getNextButtonColumnSize()} ${goalFooterMeta.buttonElement3}`}>
                    {!((goalType === 'RETIREMENT' || goalType === 'RETIREMENT_ONLY_FUND') && questionIndex === 0) && (
                        <inv-button class="button button--primary-contained full-width" onClick={handleClickOnNext}>
                            {getTextOfNextButton()}
                        </inv-button>
                    )}
                </inv-grid-col>
            </inv-grid-row>
        </>
    );
}

GoalFooterMeta.defaultProps = {
    setSimulatedRetirement: undefined,
    birthday: null,
    hideDots: false,
    hideBirthdayQuestion: undefined,
    setLoading: undefined,
};
export default GoalFooterMeta;
