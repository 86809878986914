import * as React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import styles from '../../styles/Contratacion.module.css';

import SolidDividers from '../../elements/dividers/SolidDividers';
import DotStepsFragment from '../../elements/fragments/DotStepsFragment';
import ContractSignCards from '../../elements/cards/ContractSignCards';
import {contractStatusMockedData, contractsToSign} from '../../modules/contract/contract.data';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-xxl';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/icon/icon';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/button/button';
import '@inveert/ui-kit/src/input/input-text';
import '@inveert/ui-kit/src/input/input-checkbox';
import parseSaml from '../../utils/parseSaml';
import {getUserDataService} from '../../modules/user/user.service';
import {addAlert} from '../../modules/app/app.slice';
import PageLoading from '../../elements/loading/PageLoading';
import {
    getContractListService,
    getContractPdfService,
    postCreateContractService,
} from '../../modules/contract/contract.service';
import {getPlanWithGlobalConfig, getRecommendationsService} from '../../modules/propuesta/propuesta.service';
import {checkFinanbestAuthError, getApiUrl, getErrorMessage, tr} from '../../utils/functions';
import ContainerDialog from '../../elements/dialog/ContainerDialog';
import {ContractFinanbestLoginDialogComponent} from '../../modules/contract/contract-finanbest-login-dialog.component';

function FirmaContratacion() {
    const router = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [isAuthOpen, setIsAuthOpen] = React.useState(false);
    const [userId, setUserId] = React.useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams] = useSearchParams();
    const [contractIsDownloading, setContractIsDownloading] = React.useState(false);
    const [contractsWithStatus, setContractsWithStatus] = React.useState(contractStatusMockedData);

    React.useEffect(() => {
        const backUrl = searchParams.get('back_url');
        if (isAuthOpen === false && backUrl === '1') {
            setLoading(true);
            const storage = globalThis?.sessionStorage;
            const token = storage.getItem('token');
            if (!token) {
                router('/login');
            } else {
                getUserDataService(token)
                    .then((res) => {
                        setUserId(res?.attributes?.user_id ?? null);
                        const resRecommendation = getPlanWithGlobalConfig()
                            .then((resPlan) => getRecommendationsService(resPlan.data[0].id))
                            .catch(async (e) => {
                                const message = await getErrorMessage(e);
                                dispatch(
                                    addAlert({
                                        message,
                                        isError: true,
                                        isOpen: true,
                                    })
                                );
                                setLoading(false);
                            });
                        const getContractList = getContractListService(res?.attributes?.user_id ?? 0);
                        Promise.all([resRecommendation, getContractList])
                            .then((contractRes) => {
                                let contractWithStatus: Array<any> = [];
                                let notCreatedContracts = contractRes[0].included.map(
                                    (contract: {id: number; attributes: {profile: any}}) => ({
                                        profile: contract.attributes.profile,
                                        financial_service_id: contract.id,
                                    })
                                );
                                const existingContractsProfile = contractRes[1].map(
                                    (contract: {attributes: any; id: string}) => ({
                                        ...contract.attributes,
                                        id: contract.id,
                                    })
                                );
                                if (existingContractsProfile && existingContractsProfile?.length > 0) {
                                    notCreatedContracts = notCreatedContracts.filter(
                                        (contractProfile: {financial_service_id: any}) =>
                                            !existingContractsProfile.some(
                                                (existingContract: {financial_service_id: any}) =>
                                                    existingContract.financial_service_id.toString() ===
                                                    contractProfile.financial_service_id.toString()
                                            )
                                    );
                                }
                                contractWithStatus = contractRes[0].included?.map(
                                    (contract: {id: {toString: () => any}; attributes: any}) => {
                                        const selectedExistingContract = existingContractsProfile?.find(
                                            (existingContract: {
                                                financial_service_id: {
                                                    toString: () => any;
                                                };
                                            }) =>
                                                existingContract?.financial_service_id?.toString() ===
                                                contract?.id?.toString()
                                        );
                                        return {
                                            ...contract,
                                            attributes: {
                                                ...contract.attributes,
                                                saml: selectedExistingContract?.saml ?? null,
                                                status: selectedExistingContract?.status ?? null,
                                                contract_id: selectedExistingContract?.id ?? null,
                                            },
                                        };
                                    }
                                );
                                const createdContracts = contractWithStatus.filter(
                                    (contract) => contract?.attributes?.saml
                                );
                                setContractsWithStatus(createdContracts);
                                if (notCreatedContracts && notCreatedContracts?.length > 0) {
                                    const contractsAndSamlRes = notCreatedContracts.map((contract: {profile: string}) =>
                                        postCreateContractService(res?.attributes?.user_id ?? 0, contract.profile)
                                    );
                                    Promise.all(contractsAndSamlRes)
                                        .then(() => {
                                            getContractListService(res?.attributes?.user_id ?? 0)
                                                .then((allContractsRes) => {
                                                    const existingContractsWithProfile = allContractsRes.map(
                                                        (contract: {attributes: any; id: string}) => ({
                                                            ...contract.attributes,
                                                            id: contract.id,
                                                        })
                                                    );
                                                    if (
                                                        existingContractsWithProfile &&
                                                        existingContractsWithProfile?.length > 0
                                                    ) {
                                                        notCreatedContracts = notCreatedContracts.filter(
                                                            (contractProfile: {financial_service_id: any}) =>
                                                                !existingContractsWithProfile.some(
                                                                    (existingContract: {financial_service_id: any}) =>
                                                                        existingContract.financial_service_id.toString() ===
                                                                        contractProfile.financial_service_id.toString()
                                                                )
                                                        );
                                                    }
                                                    contractWithStatus = contractRes[0].included?.map(
                                                        (contract: {
                                                            id: {
                                                                toString: () => any;
                                                            };
                                                            attributes: any;
                                                        }) => {
                                                            const selectedExistingContract =
                                                                existingContractsWithProfile?.find(
                                                                    (existingContract: {
                                                                        financial_service_id: {
                                                                            toString: () => any;
                                                                        };
                                                                    }) =>
                                                                        existingContract?.financial_service_id?.toString() ===
                                                                        contract?.id?.toString()
                                                                );
                                                            return {
                                                                ...contract,
                                                                attributes: {
                                                                    ...contract.attributes,
                                                                    saml: selectedExistingContract?.saml ?? null,
                                                                    status: selectedExistingContract?.status ?? null,
                                                                    contract_id: selectedExistingContract?.id ?? null,
                                                                },
                                                            };
                                                        }
                                                    );
                                                    const allCreatedContracts = contractWithStatus.filter(
                                                        (contract) => contract?.attributes?.saml
                                                    );
                                                    setContractsWithStatus(allCreatedContracts);
                                                    setLoading(false);
                                                })
                                                .catch(async (e) => {
                                                    const isFinanbestAuthError = await checkFinanbestAuthError(e);
                                                    if (isFinanbestAuthError) {
                                                        setIsAuthOpen(isFinanbestAuthError);
                                                    } else {
                                                        const message = await getErrorMessage(e);
                                                        dispatch(
                                                            addAlert({
                                                                message,
                                                                isError: true,
                                                                isOpen: true,
                                                            })
                                                        );
                                                    }
                                                    setLoading(false);
                                                });
                                        })
                                        .catch(async (e) => {
                                            const isFinanbestAuthError = await checkFinanbestAuthError(e);
                                            if (isFinanbestAuthError) {
                                                setIsAuthOpen(isFinanbestAuthError);
                                            } else {
                                                const message = await getErrorMessage(e);
                                                dispatch(
                                                    addAlert({
                                                        message,
                                                        isError: true,
                                                        isOpen: true,
                                                    })
                                                );
                                            }
                                            setLoading(false);
                                        });
                                } else {
                                    setLoading(false);
                                }
                            })
                            .catch(async (e) => {
                                const isFinanbestAuthError = await checkFinanbestAuthError(e);
                                if (isFinanbestAuthError) {
                                    setIsAuthOpen(isFinanbestAuthError);
                                } else {
                                    const message = await getErrorMessage(e);
                                    dispatch(
                                        addAlert({
                                            message,
                                            isError: true,
                                            isOpen: true,
                                        })
                                    );
                                }
                                setLoading(false);
                            });
                    })
                    .catch((e) => {
                        setLoading(false);
                        if (e?.response?.data?.message) {
                            dispatch(
                                addAlert({
                                    message: e.response.data.message,
                                    isError: true,
                                    isOpen: true,
                                })
                            );
                        }
                    });
            }
        }
    }, [isAuthOpen]);

    React.useEffect(() => {
        const backUrl = searchParams.get('back_url');
        const apiUrl = getApiUrl();
        if (backUrl !== '1') {
            const form = document.createElement('form');
            // eslint-disable-next-line no-underscore-dangle
            form._submit_function_ = form.submit;
            form.setAttribute('method', 'POST');
            form.setAttribute('action', process.env.REACT_APP_FINANBEST_URL ?? '');
            const hiddenFieldKey = document.createElement('input');
            hiddenFieldKey.setAttribute('type', 'hidden');
            // Fixed value for finanbest
            hiddenFieldKey.setAttribute('value', 'asjkd7238enos5wqsd');
            hiddenFieldKey.setAttribute('name', 'key');
            form.appendChild(hiddenFieldKey);
            const hiddenFieldClient = document.createElement('input');
            hiddenFieldClient.setAttribute('type', 'hidden');
            hiddenFieldClient.setAttribute('value', 'fb-inveert');
            hiddenFieldClient.setAttribute('name', 'client');
            form.appendChild(hiddenFieldClient);
            const hiddenFieldBackUrl = document.createElement('input');
            hiddenFieldBackUrl.setAttribute('type', 'hidden');
            hiddenFieldBackUrl.setAttribute('value', `${apiUrl}/${process.env.REACT_APP_CONTRACT_BACK_URL}`);
            hiddenFieldBackUrl.setAttribute('name', 'back_thanks');
            form.appendChild(hiddenFieldBackUrl);
            const hiddenFieldThankskUrl = document.createElement('input');
            hiddenFieldThankskUrl.setAttribute('type', 'hidden');
            hiddenFieldThankskUrl.setAttribute(
                'value',
                `${apiUrl}/${process.env.REACT_APP_CONTRACT_BACK_URL}?back_url=1`
            );
            hiddenFieldThankskUrl.setAttribute('name', 'back_url');
            form.appendChild(hiddenFieldThankskUrl);
            document.body.appendChild(form);
            // eslint-disable-next-line no-underscore-dangle
            form._submit_function_();
        }
    }, []);
    const backUrl = searchParams.get('back_url');
    return (
        <div className={styles.container}>
            <ContainerDialog open={isAuthOpen} onClose={() => null} scroll="body">
                <ContractFinanbestLoginDialogComponent closeFunction={setIsAuthOpen} />
            </ContainerDialog>
            <PageLoading open={loading || backUrl !== '1'} />
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-8" style={{display: 'flex'}}>
                    <inv-text-xxxl
                        style={
                            {
                                '--inv-text-xxxl-gradient': 'var(--color-tertiary)',
                                '--inv-text-xxxl-font-weight': 700,
                                '--inv-text-xxxl-font-size': '2em',
                                textAlign: 'center',
                            } as React.CSSProperties
                        }
                    >
                        {tr('Contratos')}
                    </inv-text-xxxl>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center', marginTop: '0.5em'}}>
                <inv-grid-col class="col-6" style={{display: 'flex'}}>
                    <inv-text-xl
                        style={
                            {
                                '--inv-text-xl-font-weight': 300,
                                '--inv-text-xl-color': 'var(--text-color-50)',
                                textAlign: 'center',
                            } as React.CSSProperties
                        }
                    >
                        {tr('Aquí podras firmar tus contratos digitalmente y comenzar el viaje para cumplir tus metas')}
                    </inv-text-xl>
                </inv-grid-col>
            </inv-grid-row>
            <div style={{marginTop: '4.1em', padding: '0 3.2em'}}>
                {contractsWithStatus?.map((contract, index) => (
                    <inv-grid-row
                        key={contract.attributes.profile}
                        style={{
                            justifyContent: 'center',
                            marginBottom: index !== contractsToSign.length - 1 ? '1.35em' : 'initial',
                        }}
                    >
                        <inv-grid-col class="col-10">
                            <ContractSignCards
                                isDisabled={contractIsDownloading}
                                title={contract.attributes.name}
                                description=""
                                isSigned={contract.attributes.status !== 'SIGNATURE_PENDING'}
                                buttonAction={() => {
                                    if (
                                        contract.attributes.saml &&
                                        contract.attributes.status === 'SIGNATURE_PENDING'
                                    ) {
                                        const form = document.createElement('form');
                                        // eslint-disable-next-line no-underscore-dangle
                                        form._submit_function_ = form.submit;
                                        form.setAttribute('method', 'POST');
                                        form.setAttribute('action', process.env.REACT_APP_LOGALTY_URL ?? '');
                                        const hiddenFieldSaml = document.createElement('input');
                                        hiddenFieldSaml.setAttribute('type', 'hidden');
                                        hiddenFieldSaml.setAttribute('value', parseSaml(contract.attributes.saml));
                                        hiddenFieldSaml.setAttribute('name', 'saml_assertion');
                                        form.appendChild(hiddenFieldSaml);
                                        document.body.appendChild(form);
                                        // eslint-disable-next-line no-underscore-dangle
                                        form._submit_function_();
                                    } else if (userId) {
                                        setContractIsDownloading(true);
                                        getContractPdfService(userId, contract.attributes.contract_id)
                                            .then(() => setContractIsDownloading(false))
                                            .catch(async (e) => {
                                                setContractIsDownloading(false);
                                                dispatch(
                                                    addAlert({
                                                        message: e.message ?? tr('Error al obtener el contrato'),
                                                        isError: true,
                                                        isOpen: true,
                                                    })
                                                );
                                                setLoading(false);
                                            });
                                    }
                                }}
                            />
                        </inv-grid-col>
                    </inv-grid-row>
                ))}
            </div>

            <inv-grid-row style={{marginTop: '5.2em', padding: ''}}>
                <inv-grid-col class="col-12">
                    <div
                        style={{
                            maxWidth: 'calc(100% - var(--inv-grid-gutter-x,1.5em))',
                        }}
                    >
                        <SolidDividers orientation="horizontal" />
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '1.2em 3.6em 0',
                }}
            >
                <inv-grid-col class="col-4">
                    <inv-button
                        onClick={() => router('/contratacion/informacion-usuario')}
                        class="button button--secondary-contained"
                    >
                        {tr('Atrás')}
                    </inv-button>
                </inv-grid-col>
                <inv-grid-col class="col-auto">
                    <DotStepsFragment steps={3} activeStep={2} />
                </inv-grid-col>
                <inv-grid-col class="col-4">
                    <inv-button disabled class="button button--primary-contained">
                        {tr('Finalizar contratación')}
                    </inv-button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

FirmaContratacion.layout = 'EmptyLayout';
FirmaContratacion.mobileLayout = 'MobileEmptyLayout';
export default FirmaContratacion;
