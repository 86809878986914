import * as React from 'react';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/text/text-xxs';
import NumberFormat from 'react-number-format';
import styles from '../../styles/Jubilacion.module.css';
import WCIcons from '../../elements/icons/WCIcons';
import AdviceAlert from '../../elements/alert/AdviceAlert';
import Tpl from '../../elements/tpl/tpl';
import {tr} from '../../utils/functions';

interface IGoalCostComponent {
    inflation: number;
}

export function GoalCostComponent(props: IGoalCostComponent) {
    const {inflation} = props;
    return (
        <inv-grid-row
            class={styles.adviceContainer}
            style={{
                display: 'flex',
                justifyContent: 'center',
                '--inv-grid-gutter-x': 0,
            }}
        >
            <inv-grid-col class="col-auto col-sm-6">
                <AdviceAlert
                    icon={<WCIcons name="info" color="inherit" />}
                    text={
                        <Tpl
                            key="goal-cost-component-text"
                            template={tr(
                                'Se ha estimado un incremento del coste de la vida del {inflation}% anual, para contrarrestar el efecto de la inflación.'
                            )}
                            values={{
                                inflation: (
                                    <NumberFormat
                                        key="goal-cost-component-inflation"
                                        value={inflation ?? 0}
                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                        decimalScale={2}
                                        allowNegative={false}
                                        displayType="text"
                                    />
                                ),
                            }}
                        />
                    }
                />
            </inv-grid-col>
        </inv-grid-row>
    );
}

export default GoalCostComponent;
