import {styled} from '@mui/material/styles';
import * as React from 'react';
import {Breakpoint, Dialog} from '@mui/material';

interface IContainerDialog {
    onClose: any;
    open: boolean;
    fullWidth?: boolean;
    maxWidth?: false | Breakpoint | undefined;
    children: JSX.Element;
    scroll?: 'body' | 'paper';
}

const StyledDialog = styled((props: IContainerDialog) => (
    <Dialog
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        BackdropProps={{
            style: {
                backgroundColor: 'transparent',
                background: 'rgba(6, 73, 173, 0.5)',
                backdropFilter: 'blur(12px)',
            },
        }}
    />
))(() => ({
    '& .MuiDialog-paper': {
        borderRadius: '32px',
        overflowX: 'hidden',
    },
}));

function ContainerDialog(props: IContainerDialog) {
    const {children} = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <StyledDialog {...props}>{children}</StyledDialog>;
}
ContainerDialog.defaultProps = {
    fullWidth: undefined,
    maxWidth: undefined,
    scroll: undefined,
};

export default ContainerDialog;
