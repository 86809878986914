import {fetchApi} from '../../utils/functions';
import {ISessionApi, IUserApi, IUserAttributes} from './auth.type';

export const deleteSession = async (token: string) => {
    const response = fetchApi(`auth/v1/sessions/${token}`, 'delete', '');
    return response;
};

export const createSession = async () => {
    const response = fetchApi('auth/v1/sessions', 'post', undefined);
    return response;
};

export const createUser = async (attributes: IUserAttributes) => {
    const requestData: IUserApi = {
        data: {
            type: 'User',
            attributes: {
                name: attributes.name,
                email: attributes.email,
                password: attributes.password,
                birthday: attributes?.birthday,
            },
        },
    };
    const response = fetchApi('user/v1/users', 'post', requestData);
    return response;
};

export const updateReferredBy = async (token: string, referredBy: any) => {
    const requestData: ISessionApi = {
        data: {
            type: 'Session',
            id: token,
            attributes: {
                referred_by: referredBy,
            },
        },
    };
    const response = fetchApi(`auth/v1/sessions/${token}`, 'patch', requestData);
    return response;
};

export const authLoginUser = async (loginData: {email: string; password: string}) => {
    const payload = {
        data: {
            type: 'User',
            attributes: {
                email: loginData.email,
                password: loginData.password,
            },
        },
    };
    return fetchApi('auth/v1/login', 'post', payload);
};

export const authRegisterUser = async (
    registerData: {
        email: string;
        password: string;
        accept_terms: boolean;
        accept_communication: boolean;
        is_active: boolean;
    },
    referred_by?: string | string[] | null | any
) => {
    const response = fetchApi(`auth/signup${referred_by ? `?referred_by=${referred_by}` : ''}`, 'post', registerData);
    return response;
};

export const authResendVerificationUser = async (email: string) => {
    const response = fetchApi('auth/email/resend', 'post', {email});
    return response;
};

export const authSendChangePassUser = async (resendData: {email: string}) => {
    const response = fetchApi('auth/remember_password', 'post', resendData);
    return response;
};

export const authIsValidChangePassUser = async (token: string) => {
    const response = fetchApi(`auth/restore-password/${token}`, 'get', {});
    return response;
};

export const authChangePassUser = async (changePassData: {nuevaContraseña: string; uuid: any}) => {
    const response = fetchApi('auth/restore-password', 'post', changePassData);
    return response;
};

export const authGetUser = async () => {
    const response = fetchApi('auth/user', 'get', {});
    return response;
};

export const postRegisterUserWithEmail = async (email: string, password: string, name: string) => {
    const payload = {
        data: {
            type: 'User',
            attributes: {
                email,
                password,
                name,
            },
        },
    };
    return fetchApi('user/v1/users', 'post', payload);
};

export const postRegisterUserWithEmailAndPostalCode = async (
    email: string,
    password: string,
    name: string,
    postalCode: string
) => {
    const payload = {
        data: {
            type: 'User',
            attributes: {
                email,
                password,
                name,
            },
            relationships: {
                address: {
                    type: 'Address',
                    attributes: {
                        postal_code: postalCode,
                    },
                },
            },
        },
    };
    return fetchApi('user/v1/users', 'post', payload);
};

export const postSendVerificationEmail = async (userId: string) =>
    fetchApi(`user/v1/users/${userId}/verification-codes`, 'post', undefined);

export const patchVerifyVerificationCode = async (code: string, userId: string) =>
    fetchApi(`user/v1/users/${userId}/verification-codes/${code}`, 'patch', undefined);
