import {createSlice} from '@reduxjs/toolkit';
import {Plan} from './propuesta.type';

export const initialState: Plan = {
    tabName: 'plan',
    apportationDialogOpen: false,
    newPlanDialogOpen: false,
    resultNewPlanDialogOpen: false,
    confirmationNewPlanDialogOpen: false,
    initialInvestment: 0,
    pensionPlan: 0,
};

export const propuestaSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        changeTabNamePlan(state, action) {
            return {...state, tabName: action.payload};
        },
        openApportationDialog(state) {
            return {
                ...state,
                confirmationNewPlanDialogOpen: false,
                newPlanDialogOpen: false,
                resultNewPlanDialogOpen: false,
                apportationDialogOpen: true,
            };
        },
        closeApportationDialog(state) {
            return {...state, apportationDialogOpen: false};
        },
        openNewPlanDialog(state) {
            return {
                ...state,
                confirmationNewPlanDialogOpen: false,
                newPlanDialogOpen: true,
                resultNewPlanDialogOpen: false,
                apportationDialogOpen: false,
            };
        },
        closeNewPlanDialog(state) {
            return {...state, newPlanDialogOpen: false};
        },
        openResultNewPlanDialog(state) {
            return {
                ...state,
                confirmationNewPlanDialogOpen: false,
                newPlanDialogOpen: false,
                resultNewPlanDialogOpen: true,
                apportationDialogOpen: false,
            };
        },
        closeResultNewPlanDialog(state) {
            return {...state, resultNewPlanDialogOpen: false};
        },
        openConfirmationNewPlanDialog(state) {
            return {
                ...state,
                confirmationNewPlanDialogOpen: true,
                newPlanDialogOpen: false,
                resultNewPlanDialogOpen: false,
                apportationDialogOpen: false,
            };
        },
        closeConfirmationNewPlanDialog(state) {
            return {...state, confirmationNewPlanDialogOpen: false};
        },
        resetPropuesta() {
            return initialState;
        },
        setInitialInvestment(state, action) {
            return {...state, initialInvestment: action.payload};
        },
        setPensionPlan(state, action) {
            return {...state, pensionPlan: action.payload};
        },
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPlan = (state: any) => state.plan;

export const {
    changeTabNamePlan,
    openApportationDialog,
    closeApportationDialog,
    closeNewPlanDialog,
    openNewPlanDialog,
    closeResultNewPlanDialog,
    openResultNewPlanDialog,
    openConfirmationNewPlanDialog,
    closeConfirmationNewPlanDialog,
    resetPropuesta,
    setInitialInvestment,
    setPensionPlan,
} = propuestaSlice.actions;

export default propuestaSlice.reducer;
