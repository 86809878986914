import * as React from 'react';
import {useLocation} from 'react-router-dom';
import SolidDividers from '../../elements/dividers/SolidDividers';
import WCIcons from '../../elements/icons/WCIcons';
import '@inveert/ui-kit/src/text/text-s';
import {tr} from '../../utils/functions';

export function FooterNavigation() {
    const location = useLocation();
    return (
        <div
            style={{
                maxWidth: '72em',
                width: '100%',
                marginTop: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                alignSelf: 'flex-end',
                height: 120,
            }}
        >
            {!location.pathname.includes('/plan') && (
                <div style={{width: '100%', marginBottom: '1.2em'}}>
                    <SolidDividers orientation="horizontal" />
                </div>
            )}
            <div style={{width: '100%', margin: 'auto'}}>
                <inv-grid-row style={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <inv-grid-col
                        class="col-3"
                        style={{
                            display: 'flex',
                            justifyContent: location.pathname.includes('/plan') ? 'center' : 'initial',
                        }}
                    >
                        <img
                            style={{
                                opacity: 'var(--logo-footer-opacity)',
                                content: 'var(--logo-footer)',
                                maxWidth: 'var(--logo-footer-width)',
                                maxHeight: 'var(--logo-footer-height)',
                            }}
                            alt="logo-grey"
                        />
                    </inv-grid-col>
                    {location.pathname === '/meta' && (
                        <inv-grid-col class="col-auto">
                            <inv-grid-row style={{'--inv-grid-gutter-x': '0.4em'}}>
                                <inv-grid-col class="col-auto">
                                    <inv-text-s
                                        style={{
                                            margin: 0,
                                            '--inv-text-s-color': 'var(--line-color-footer)',
                                        }}
                                    >
                                        <a href="https://inveert.com/aviso-legal" target="_blank" rel="noreferrer">
                                            {tr('Aviso legal')}
                                        </a>
                                    </inv-text-s>
                                </inv-grid-col>
                                <inv-grid-col class="col-auto">
                                    <inv-text-s
                                        style={{
                                            margin: 0,
                                            '--inv-text-s-color': 'var(--line-color-footer)',
                                        }}
                                    >
                                        <inv-text-s
                                            style={{
                                                margin: 0,
                                                '--inv-text-s-color': 'var(--line-color-footer)',
                                            }}
                                        >
                                            |
                                        </inv-text-s>
                                    </inv-text-s>
                                </inv-grid-col>

                                <inv-grid-col class="col-auto">
                                    <inv-text-s
                                        style={{
                                            margin: 0,
                                            '--inv-text-s-color': 'var(--line-color-footer)',
                                        }}
                                    >
                                        <a
                                            href="https://inveert.com/politica-de-privacidad"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {tr('Política de privacidad')}
                                        </a>
                                    </inv-text-s>
                                </inv-grid-col>
                                <inv-grid-col class="col-auto">
                                    <inv-text-s
                                        style={{
                                            margin: 0,
                                            '--inv-text-s-color': 'var(--line-color-footer)',
                                        }}
                                    >
                                        <inv-text-s
                                            style={{
                                                margin: 0,
                                                '--inv-text-s-color': 'var(--line-color-footer)',
                                            }}
                                        >
                                            |
                                        </inv-text-s>
                                    </inv-text-s>
                                </inv-grid-col>
                                <inv-grid-col class="col-auto">
                                    <inv-text-s
                                        style={{
                                            margin: 0,
                                            '--inv-text-s-color': 'var(--line-color-footer)',
                                        }}
                                    >
                                        <a
                                            href="https://inveert.com/politica-de-cookies"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {tr('Cookies')}
                                        </a>
                                    </inv-text-s>
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                    )}
                    <inv-grid-col
                        class="col-3"
                        style={{
                            order: location.pathname.includes('/plan') ? -1 : 1,
                            display: 'flex',
                            justifyContent: !location.pathname.includes('/plan') ? 'flex-end' : 'initial',
                        }}
                    >
                        <inv-text-s
                            style={{
                                margin: 0,
                                '--inv-text-s-color': 'var(--line-color-footer)',
                            }}
                        >
                            <a
                                href="https://www.inveert.com"
                                target="_blank"
                                rel="noreferrer"
                                style={{display: 'flex', alignItems: 'baseline'}}
                            >
                                <div style={{marginRight: '0.6em', marginTop: '0.1em'}}>Powered by</div>
                                <img
                                    alt="inveert-logo"
                                    style={{height: '0.852em'}}
                                    src="/assets/images/logos/logo-inveert-footer.svg"
                                />
                            </a>
                        </inv-text-s>
                    </inv-grid-col>
                    {location.pathname.includes('/plan') && (
                        <inv-grid-col class="col-4">
                            <inv-grid-row style={{justifyContent: 'flex-end'}}>
                                <inv-grid-col class="col-auto">
                                    <WCIcons color="#fff" name="social-instagram" height="24" width="24" />
                                </inv-grid-col>
                                <inv-grid-col class="col-auto">
                                    <WCIcons color="#fff" name="social-facebook" height="24" width="24" />
                                </inv-grid-col>
                                <inv-grid-col class="col-auto">
                                    <WCIcons color="#fff" name="social_twitter" height="24" width="24" />
                                </inv-grid-col>
                            </inv-grid-row>
                        </inv-grid-col>
                    )}
                </inv-grid-row>
            </div>
        </div>
    );
}

export default FooterNavigation;
