import React from 'react';
import PropTypes from 'prop-types';
import styles from './disclaimer.module.css';
import {tr, tpl} from '../../utils/functions';

export function Disclaimer(props) {
    const {companyName} = props;
    return (
        <div className={styles.disclaimer}>
            <p>
                {tpl(
                    tr(
                        'Smart Advisor es un servicio de {companyName} que está constituido por la herramienta Planificación Metas Vitales (titularidad de Inveert Smart Planning S.L - B90411349).'
                    ),
                    {companyName}
                )}
            </p>
            <p>
                {tr(
                    'Inveert Smart Planning S.L es una compañía tecnológica proveedora de herramientas y soluciones para el análisis de información. Mediante su tecnología genera información procedente de fuentes accesibles al público y, mediante su combinación con algoritmos, ofrece información de interés de varios sectores, entre ellos el financiero.'
                )}
            </p>
            <p>
                {tr(
                    'El planificador Smart Advisor (planificador financiero) utiliza algoritmos para prever escenarios de evolución de la inversión y el ahorro sobre la base de datos de mercado. En relación con Smart Advisor (planificador financiero), toda la información se obtiene a partir de los datos facilitados por el usuario, por lo que pueden ser no ajustados a la realidad esperada por el usuario o no corresponder su perfil inversor.'
                )}
            </p>
            <p>
                {tpl(
                    tr(
                        'Los cálculos de Smart Advisor (planificador financiero), así como la información que se le facilita a través de cada una de las diferentes pantallas son datos estimados, y tienen una finalidad meramente informativa u orientadora, y no contiene sugerencia, asesoramiento financiero o recomendación para contratar un producto o servicio de inversión por parte de {companyName}. Las rentabilidades recogidas en Smart Advisor (planificador financiero), no han incluido los efectos de los costes directos o indirectos que pudiesen soportar, por lo que no garantizan rentabilidades futuras para el usuario que tenga acceso.'
                    ),
                    {companyName}
                )}
            </p>
            <p>
                {tpl(
                    tr(
                        'En caso de que el usuario esté interesado en solicitar algún servicio de inversión a través de {companyName}, deberá existir una aprobación previa de {companyName}, por lo que esta planificación teórica obtenida con la información facilitada por el usuario, no constituye una oferta de contratación de ningún producto o servicio, que vincule a {companyName} ni al usuario.'
                    ),
                    {companyName}
                )}
            </p>
            <p>
                {tpl(
                    tr(
                        'Una vez contratado el servicio de inversión correspondiente, {companyName} sólo le ofrecerá los instrumentos financieros que considere adecuados para usted, ofreciéndole toda la documentación precontractual y contractual necesaria para su contratación. En caso de no ser cliente de {companyName} o de no contar con perfil inversor en nuestra entidad, la contratación de cualquier producto queda condicionada a que el usuario esté comprendido dentro del público objetivo definido para el producto y cuente con perfil inversor suficiente a criterio de {companyName}.'
                    ),
                    {companyName}
                )}
            </p>
        </div>
    );
}

Disclaimer.propTypes = {
    companyName: PropTypes.string,
};

Disclaimer.defaultProps = {
    companyName: 'la entidad',
};

export default Disclaimer;
