import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {onClickSelector} from './SelectorGoalsScripts';
import '@inveert/ui-kit/src/input/input-radio';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import styles from '../../styles/SelectorGoals.module.css';
import Selector from '../../elements/selector/Selector';
import WCIcons from '../../elements/icons/WCIcons';
import {tr} from '../../utils/functions';

interface ISelectorGoals {
    setLoading: (loading: boolean) => void;
    isRetirementDisabled: boolean;
    isSafetyNetDisabled: boolean;
    setGoalType?: (goalType: string) => void;
    isDialog?: boolean;
}

function SelectorGoals(props: ISelectorGoals) {
    const goalsSelectorData = [
        {
            title: tr('Complemento\njubilación'),
            name: 'RETIREMENT',
            iconName: 'goal-retirement',
            iconColor: 'var(--retirement-color)',
            route: '/meta/jubilacion',
        },
        {
            title: tr('La educación\nde mis hijos'),
            name: 'EDUCATION',
            iconName: 'goal-education',
            iconColor: 'var(--education-color)',
            route: '/meta/educacion',
        },
        {
            title: tr('Entrada para\nuna vivienda'),
            name: 'REAL_STATE',
            iconName: 'home',
            iconColor: 'var(--real-state-color)',
            route: '/meta/vivienda',
        },
        {
            title: tr('Colchón\nemergencias'),
            name: 'SAFETY_NET',
            iconName: 'goal-emergency-fund',
            iconColor: 'var(--safety-color)',
            route: '/meta/emergencia',
        },
        {
            title: tr('Dejar una\nherencia'),
            name: 'HERITAGE',
            iconName: 'financial-freedom',
            iconColor: 'var(--heritage-color)',
            route: '/meta/herencia',
        },
        {
            title: tr('Una meta\npersonalizada'),
            name: 'CUSTOM',
            iconName: 'target',
            iconColor: 'var(--custom-color)',
            route: '/meta/personalizada',
        },
    ];
    const {setLoading, isSafetyNetDisabled, isRetirementDisabled, setGoalType, isDialog} = props;
    const router = useNavigate();
    const handleChange = (e: {detail: {value: any}}) =>
        onClickSelector({
            goalType: e?.detail?.value,
            isSafetyNetDisabled,
            isRetirementDisabled,
            router,
            setLoading,
            setGoalType,
            isDialog,
        });
    // @ts-ignore
    // eslint-disable-next-line import/extensions
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-radio-change', handleChange);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-radio-change', handleChange);
        };
    }, [onClickSelector, isDialog]);
    return (
        <div className={styles.container}>
            <inv-grid-row class={styles.selectorRow} style={{justifyContent: 'center'}}>
                {goalsSelectorData.map((data: any) => {
                    const disabled = !(
                        ((data.name === 'RETIREMENT' || data.name === 'RETIREMENT_ONLY_FUND') &&
                            isRetirementDisabled) ||
                        (data.name === 'SAFETY_NET' && isSafetyNetDisabled)
                    )
                        ? null
                        : true;
                    return (
                        <div className={styles.selectorRowInnerContainer} key={data.name + Math.random()}>
                            <inv-grid-col class={`${styles.newSelector} col-12 col-sm-auto`}>
                                <inv-input-radio
                                    class="input input--primary-radio"
                                    icon-src="/assets/images/icons.svg"
                                    disabled={disabled}
                                    value={data.name}
                                    icon={data.iconName}
                                    title={data.title}
                                    style={{
                                        '--inv-input-icon-color': data.iconColor,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        '--inv-form-text-font-size': '1.1em',
                                    }}
                                />
                            </inv-grid-col>
                            <inv-grid-col class={`${styles.oldSelector} col-12`}>
                                <Selector
                                    onClick={() =>
                                        onClickSelector({
                                            goalType: data.name,
                                            isSafetyNetDisabled,
                                            isRetirementDisabled,
                                            router,
                                            setLoading,
                                            setGoalType,
                                        })
                                    }
                                    icon={
                                        <WCIcons
                                            height="2.4em"
                                            width="2.4em"
                                            name={data.iconName}
                                            color={data.iconColor}
                                        />
                                    }
                                    title={data.title}
                                    /* @ts-ignore */
                                    name={data.name}
                                    subtitle={data.subtitle}
                                    disabled={!!disabled}
                                />
                            </inv-grid-col>
                        </div>
                    );
                })}
            </inv-grid-row>
        </div>
    );
}

SelectorGoals.defaultProps = {
    setGoalType: undefined,
    isDialog: false,
};
export default SelectorGoals;
