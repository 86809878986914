import {Container, Grid, useMediaQuery} from '@mui/material';
import * as React from 'react';

import {useSelector} from 'react-redux';
import Text from '../textStyles';
import theme from '../theme';
import {selectUser} from '../../modules/user/user.slice';
import {userMockedData} from '../../modules/user/user.data';
import {OnboardingHeaderNavigation} from '../../components/navigation/OnboardingHeaderNavigation';
import {FooterNavigation} from '../../components/navigation/FooterNavigation';
import {MobileHeaderNavigation} from '../../components/navigation/MobileHeaderNavigation';

function EmptyLayoutComplete({children}: any) {
    const downXl = useMediaQuery(theme.breakpoints.down('xl'));
    const downMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const {name} = useSelector(selectUser);
    if (children.props.error && process.env.REACT_APP_ENV !== 'pro' && process.env.REACT_APP_ENV !== 'app') {
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                sx={{
                    paddingLeft: 20,
                }}
            >
                <Grid item>
                    <Text size="l" weight="regular">
                        {`Mensaje: ${children.props.error.message}`}
                    </Text>
                </Grid>
                <Grid item>
                    <Text size="l" weight="regular">
                        {`Mensaje: ${children.props.error.code}`}
                    </Text>
                </Grid>
                <Grid item>
                    <Text size="l" weight="regular">
                        {`Fichero: ${children.props.error?.file}`}
                    </Text>
                </Grid>
                <Grid item>
                    <Text size="l" weight="regular">
                        {`Linea: ${children.props.error?.line}`}
                    </Text>
                </Grid>
            </Grid>
        );
    }
    const pathname = window?.location?.pathname ?? '';
    const {
        document,
        email,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        zip_code,
        riskProfiles,
        riskProfile,
        user,
        financialWealth,
        userIncome,
        objectives,
        riskProfileMax,
        isAuthDialogOpen,
        plan,
    } = children?.props?.data || {
        document: '',
        email: '',
        user: userMockedData,
        isAuthDialogOpen: false,
    };
    return (
        <>
            {pathname.includes('/plan') && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: 'var(--dark-background-header)',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            background: 'var(--dark-background-footer)',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            )}
            <Grid
                id="Decoration"
                sx={{
                    width: '100vw',
                    height: '100vh',
                    overflowX: 'hidden',
                    position: 'relative',
                    left: '0%',
                    right: '0%',
                    top: '0%',
                    bottom: '0%',
                    background: pathname.includes('plan') ? 'transparent' : 'var(--background)',
                    backgroundSize: 'cover',
                }}
            >
                <Container
                    disableGutters={downMobile ? true : undefined}
                    sx={{
                        position: 'relative',
                        height: '100%',
                        scrollbarWidth: 'none',
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                        [theme.breakpoints.down('mobile')]: {
                            background: pathname.includes('/plan')
                                ? 'none'
                                : 'linear-gradient(180deg, transparent 40%, white 60%)',
                        },
                    }}
                    maxWidth={downXl ? 'xl' : false}
                >
                    <Grid
                        container
                        direction="column"
                        sx={{
                            minHeight: '100%',
                            flex: '0 0 auto',
                        }}
                    >
                        {downMobile ? (
                            <MobileHeaderNavigation
                                isAuthDialogOpen={isAuthDialogOpen}
                                document={document}
                                email={email}
                                zip_code={zip_code}
                                riskProfileMax={riskProfileMax}
                                user={user}
                                financialWealth={financialWealth}
                                userIncome={userIncome?.total}
                                plan={plan}
                                name={name}
                                riskProfile={riskProfile}
                                riskProfiles={riskProfiles}
                            />
                        ) : (
                            <OnboardingHeaderNavigation
                                isAuthDialogOpen={isAuthDialogOpen}
                                document={document}
                                email={email}
                                zip_code={zip_code}
                                riskProfileMax={riskProfileMax}
                                user={user}
                                financialWealth={financialWealth}
                                userIncome={userIncome?.total}
                                objectives={objectives}
                                name={name}
                                riskProfile={riskProfile}
                                riskProfiles={riskProfiles}
                            />
                        )}
                        {children}
                        {!downMobile && <FooterNavigation />}
                    </Grid>
                </Container>
            </Grid>
        </>
    );
}
export default EmptyLayoutComplete;
