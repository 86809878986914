import {configureStore, createAction} from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import {AnyAction, CombinedState, combineReducers} from 'redux';
import {persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, createMigrate} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer, {initialState as initialStateUser} from './modules/user/user.slice';
import objectiveReducer, {initialState as initialStateGoal} from './modules/goal/goal.slice';
import planReducer, {initialState as initialStatePlan} from './modules/propuesta/propuesta.slice';
import authReducer, {initialState as initialStateAuth} from './modules/auth/auth.slice';
//  @ts-ignore
import appReducer, {initialState as initialStateApp} from './modules/app/app.slice';
import contractReducer, {initialState as initialStateContract} from './modules/contract/contract.slice';
import {Objectives} from './modules/goal/goal.type';
import {User} from './modules/user/user.type';
import {Plan} from './modules/propuesta/propuesta.type';
import {Auth} from './modules/auth/auth.type';

const migrations = {
    7: (state: any) => ({
        ...state,
        user: initialStateUser,
        objectives: initialStateGoal,
        plan: initialStatePlan,
        auth: initialStateAuth,
        app: initialStateApp,
        contract: initialStateContract,
    }),
};

const persistConfig = {
    key: 'root',
    storage,
    version: 6,
    migrate: createMigrate(migrations, {debug: false}),
};

const reducers = combineReducers({
    user: userReducer,
    objectives: objectiveReducer,
    plan: planReducer,
    auth: authReducer,
    app: appReducer,
    contract: contractReducer,
});

const resetAction = createAction('reset');

const resettableReducer = (
    state:
        | CombinedState<{
              plan: Plan;
              user: User;
              objectives: {
                  lastObjectiveId: string | null;
                  lastObjectiveType: string | null;
                  objectives: Objectives[];
                  lastObjectiveForm: any;
              };
              auth: Auth;
              app: {
                  alert: {message: string; isError: boolean; isOpen: boolean};
                  reloadApiFlag: boolean;
                  alertFlag: boolean;
                  showRiskProfileDialog: boolean;
                  onboardingStep: number;
                  locale: string;
                  toTopFlag: boolean;
              };
              contract: {
                  email: string;
                  userForm: any;
                  kycForm: any;
              };
          }>
        | undefined,
    action: AnyAction
) => {
    if (resetAction.match(action)) {
        return reducers(undefined, action);
    }
    return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, resettableReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_ENV !== 'pro' && process.env.REACT_APP_ENV !== 'app',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export default store;
