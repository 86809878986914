import * as React from 'react';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import styles from '../../styles/Meta.module.css';

export function MetaContainer(props: {children: any}) {
    const {children} = props;
    return <div className={styles.container}>{children}</div>;
}

export function MetaContainerTitle(props: {children: any}) {
    const {children} = props;
    return <div className={styles.container}>{children}</div>;
}

export function MetaContainerButtonItem(props: {children: any}) {
    const {children} = props;
    return <div className={styles.container}>{children}</div>;
}

export function MetaContainerButtonItemDividerItem(props: {children: any}) {
    const {children} = props;
    return (
        <div className={`${styles.container} ${styles.divider}`}>
            {children}
        </div>
    );
}
