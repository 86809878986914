import PropTypes from 'prop-types';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import SolidDividers from '../../elements/dividers/SolidDividers';
import PropuestaTextStructureComponent from '../../modules/propuesta/propuesta-text-structure.component';
import {tpl, tr, trn} from '../../utils/functions';
import {useFontSize} from '../../utils/hooks';
import CircleChart from '../chart/CircleChart';
import {Tables} from '../tables/Tables';
import styles from './investment-product-card.module.css';
/**
 * Componente que renderiza la tarjeta de producto de inversión
 */

/**
 * @typedef {Object} ObjectiveDataAttributes
 * @property {string} name
 * @property {number} total_initial_investment
 * @property {number} total_monthly_saving
 * @property {number} performance
 */

/**
 * @typedef {Object} ObjectiveData
 * @property {ObjectiveDataAttributes} attributes
 * @property {string} id
 * @property {Array<{id:number}>} goal_ids
 */

/**
 * @typedef {Object} Objective
 * id: string,
 */

/**
 * @typedef {Object} ProductAttributes
 * @property {string} alias
 * @property {string} asset
 * @property {string} asset_geography
 * @property {number} portfolio_weight
 */

/**
 * @typedef {Object} Product
 * @property {ProductAttributes} attributes
 */

/**
 * @typedef {Object} InvestmentProductCardComponentProps
 * @property {ObjectiveData} objectiveData
 * @property {string} serviceLabel
 * @property {Array<Objective>} objectives
 * @property {string} description
 * @property {number} fixedIncome
 * @property {number} equity
 * @property {Array<Product>} products
 * @property {string} risk
 * @property {string} availability
 * @property {string} management
 * @property {string} commission
 * @property {string} transfer
 * @property {number} recommendedPeriod
 * @property {HTMLElement | ReactElement} [footer] - Elemento que se renderizará en el footer de la tarjeta
 */

/**
 * @returns {JSX.Element}
 * @param {InvestmentProductCardComponentProps} props
 */
function InvestmentProductCardComponent({
    objectiveData,
    description,
    fixedIncome,
    equity,
    products,
    risk,
    availability,
    management,
    commission,
    transfer,
    recommendedPeriod,
    footer,
    serviceLabel,
}) {
    const fontSize = useFontSize();
    const typeTransform = (type) => {
        switch (type) {
            case 'EQUITY':
                return tr('Renta variable');
            case 'FIXED':
                return tr('Renta fija');
            case 'MONETARY':
                return tr('Monetario');
            case 'MIXED':
                return tr('Renta mixta');
            default:
                return tr('Otros');
        }
    };
    const sortProducts = (initialProducts) =>
        initialProducts.sort((a, b) => {
            const type1 = a.attributes.type;
            const type2 = b.attributes.type;
            const weight1 = a.attributes.weight ?? 0;
            const weight2 = b.attributes.weight ?? 0;
            let result = 0;

            if (type1 === type2) {
                result = weight2 - weight1;
            } else if (type1 === 'EQUITY') {
                result = -1;
            } else if (type2 === 'EQUITY') {
                result = 1;
            } else if (type1 === 'FIXED') {
                result = -1;
            } else if (type2 === 'FIXED') {
                result = 1;
            } else if (type1 === 'MIXED') {
                result = -1;
            } else if (type2 === 'MIXED') {
                result = 1;
            } else if (type1 === 'MONETARY') {
                result = -1;
            } else if (type2 === 'MONETARY') {
                result = 1;
            }

            return result;
        });
    return (
        <inv-grid-row class={styles.recommendationContainer}>
            <inv-grid-col class="col-12">
                <inv-grid-row class={styles.recommendationHeaderInfo}>
                    <inv-grid-col class={`col-auto ${styles.textStructureContainer}`}>
                        <PropuestaTextStructureComponent label={serviceLabel}>
                            {
                                // eslint-disable-next-line no-nested-ternary
                                objectiveData.attributes.type === 'FUND'
                                    ? tr('Fondos de Inversión')
                                    : objectiveData.attributes.type === 'PENSION'
                                    ? tr('Planes de Pensiones')
                                    : tr('Otros')
                            }
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class="col-auto" style={{height: '2.2em'}}>
                        <SolidDividers orientation="vertical" />
                    </inv-grid-col>
                    <inv-grid-col class={`col-auto ${styles.textStructureContainer}`}>
                        <PropuestaTextStructureComponent
                            label={tr('De tu inversión inicial')}
                            unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        >
                            <NumberFormat
                                value={objectiveData?.attributes?.total_initial_investment ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                    <inv-grid-col class={`col-auto ${styles.textStructureContainer}`}>
                        <PropuestaTextStructureComponent
                            label={tr('De la aportación mensual')}
                            unit={`${tpl(tr('{currency}/mes'), {
                                currency: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
                            })}`}
                        >
                            <NumberFormat
                                value={objectiveData?.attributes?.total_monthly_saving ?? 0}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                displayType="text"
                            />
                        </PropuestaTextStructureComponent>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.recommendationDescriptionAndCircleChart}>
                    <inv-grid-col class={`col-12 ${styles.equityChartContainer}`}>
                        <inv-grid-row
                            style={{
                                '--inv-grid-gutter-x': '0.55em',
                                justifyContent: 'space-between',
                            }}
                        >
                            <inv-grid-col class={`col-auto col-sm-7 ${styles.descriptionCol}`}>
                                <inv-grid-row>
                                    <inv-grid-col class="col-auto">
                                        <inv-text-xs
                                            style={{
                                                '--inv-text-xs-font-size': '0.9',
                                                '--inv-text-xs-font-weight': 600,
                                                paddingLeft: '0.7em',
                                            }}
                                        >
                                            {objectiveData?.attributes?.name}
                                        </inv-text-xs>
                                    </inv-grid-col>
                                </inv-grid-row>
                                <inv-grid-row style={{paddingLeft: '0.7em', paddingTop: '0.4em'}}>
                                    <inv-grid-col class="col-auto col-sm-11">
                                        <inv-text-xxs
                                            style={{
                                                '--inv-text-xxs-font-weight': 300,
                                                '--inv-text-xxs-color': 'var(--text-color-50)',
                                            }}
                                        >
                                            {description}
                                        </inv-text-xxs>
                                    </inv-grid-col>
                                </inv-grid-row>
                            </inv-grid-col>
                            <inv-grid-col class="col-auto">
                                <inv-grid-row class={styles.equityChartRow}>
                                    <inv-grid-col class="col-auto">
                                        <CircleChart
                                            // 4 is the size of the circle in em
                                            width={(fontSize ?? 0) * 4}
                                            height={(fontSize ?? 0) * 4}
                                            data={[
                                                {
                                                    name: 'equity',
                                                    value: equity * 100,
                                                    color: 'var(--color-secondary-100)',
                                                },
                                                {
                                                    name: 'fixed',
                                                    value: fixedIncome * 100,
                                                    color: 'var(--fixed-income-color)',
                                                },
                                            ]}
                                        />
                                    </inv-grid-col>
                                    <inv-grid-col class={`col-auto ${styles.equityChartTextContainer}`}>
                                        <inv-grid-row>
                                            <inv-grid-col class="col-auto">
                                                <inv-text-xxs
                                                    style={{
                                                        '--inv-text-xxs-color': 'var(--fixed-income-color)',
                                                        '--inv-text-xxs-font-weight': 700,
                                                    }}
                                                >
                                                    {tr('Máxima exposición')}
                                                </inv-text-xxs>
                                            </inv-grid-col>
                                        </inv-grid-row>
                                        <inv-grid-row>
                                            <inv-grid-col class="col-auto">
                                                <inv-text-xxs
                                                    style={{
                                                        '--inv-text-xxs-color': 'var(--color-secondary-100)',
                                                        '--inv-text-xxs-font-weight': 500,
                                                    }}
                                                >
                                                    <NumberFormat
                                                        value={equity * 100}
                                                        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                                        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                                        decimalScale={0}
                                                        allowNegative={false}
                                                        displayType="text"
                                                        suffix="% "
                                                    />
                                                    &nbsp;{tr('Renta variable')}
                                                </inv-text-xxs>
                                            </inv-grid-col>
                                        </inv-grid-row>
                                    </inv-grid-col>
                                </inv-grid-row>
                            </inv-grid-col>
                        </inv-grid-row>
                    </inv-grid-col>
                </inv-grid-row>
                <inv-grid-row class={styles.recommendationTableContainer}>
                    <inv-grid-col class="col-12">
                        <Tables
                            data={sortProducts(products).map((product) => ({
                                col1: product?.attributes?.name,
                                col2: `${typeTransform(product.attributes.type)}${
                                    product?.attributes?.category ? ` - ${product?.attributes?.category}` : ''
                                }`,
                                col3: product.attributes.geography,
                                col4:
                                    // @ts-ignore
                                    parseFloat(product.attributes.weight) * 100,
                                url: product?.attributes?.url,
                            }))}
                        />
                    </inv-grid-col>
                </inv-grid-row>
                {process.env.REACT_APP_SHOW_INVESTMENT_PRODUCT_CARD_FOOTER !== 'false' && (
                    <>
                        <inv-grid-row class={styles.recommendationBottomInfoRow}>
                            <inv-grid-col class="col-auto">
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr('Tipo de servicio:')}&nbsp;
                                </inv-text-xxs>
                                <inv-text-xs
                                    style={{
                                        '--inv-text-xs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {`${management} | `}
                                </inv-text-xs>
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr('Coste anual:')}&nbsp;
                                </inv-text-xxs>
                                <inv-text-xs
                                    style={{
                                        '--inv-text-xs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {`${commission} | `}
                                </inv-text-xs>
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr('Disponibilidad del dinero:')}&nbsp;
                                </inv-text-xxs>
                                <inv-text-xs
                                    style={{
                                        '--inv-text-xs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {availability}
                                </inv-text-xs>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row class={styles.recommendationBottomInfoRow}>
                            <inv-grid-col class="col-auto">
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr('Riesgo de la inversión:')}&nbsp;
                                </inv-text-xxs>
                                <inv-text-xs
                                    style={{
                                        '--inv-text-xs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {`${risk} | `}
                                </inv-text-xs>
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr('Traspasos:')}&nbsp;
                                </inv-text-xxs>
                                <inv-text-xs
                                    style={{
                                        '--inv-text-xs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {`${transfer} | `}
                                </inv-text-xs>
                                <inv-text-xxs
                                    style={{
                                        '--inv-text-xxs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr('Plazo recomendado:')}&nbsp;
                                </inv-text-xxs>
                                <inv-text-xs
                                    style={{
                                        '--inv-text-xs-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {`+${recommendedPeriod} ${trn(tr('año'), tr('años'), recommendedPeriod)}`}
                                </inv-text-xs>
                            </inv-grid-col>
                        </inv-grid-row>
                    </>
                )}
                {footer && footer}
            </inv-grid-col>
        </inv-grid-row>
    );
}

InvestmentProductCardComponent.propTypes = {
    serviceLabel: PropTypes.string.isRequired,
    objectiveData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        attributes: PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            total_initial_investment: PropTypes.number.isRequired,
            total_monthly_saving: PropTypes.number.isRequired,
            performance: PropTypes.number.isRequired,
        }).isRequired,
        goal_ids: PropTypes.arrayOf(PropTypes.number.isRequired),
    }).isRequired,
    description: PropTypes.string.isRequired,
    equity: PropTypes.number.isRequired,
    fixedIncome: PropTypes.number.isRequired,
    management: PropTypes.string,
    commission: PropTypes.string,
    availability: PropTypes.number,
    risk: PropTypes.string.isRequired,
    transfer: PropTypes.number,
    recommendedPeriod: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            attributes: PropTypes.shape({
                alias: PropTypes.string,
                asset: PropTypes.string,
                asset_geography: PropTypes.string,
                portfolio_weight: PropTypes.number,
            }).isRequired,
        })
    ).isRequired,
    footer: PropTypes.node,
};

InvestmentProductCardComponent.defaultProps = {
    footer: null,
    management: '',
    commission: '',
    availability: 0,
    transfer: 0,
};

export default InvestmentProductCardComponent;
