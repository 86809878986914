import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './close-dialog.module.css';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/icon/icon';
import WCIcons from '../../elements/icons/WCIcons';

/**
 * @typedef {Object} Props
 * @property {function} handleCloseDialogClick - Función que se ejecuta al hacer click en el icono
 * */

/**
 * Componente que muestra un icono de cerrar diálogo
 * @returns {JSX.Element}
 * @param {Props} props
 */
function CloseDialog({closeDialog}) {
    return (
        <inv-grid-row style={{'--inv-grid-gutter-x': 0, justifyContent: 'center'}}>
            <inv-grid-col class={`col-12 ${styles.closeIconContainer}`}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div
                    role="button"
                    onKeyDown={() => null}
                    tabIndex={0}
                    className={styles.closeIconContainer}
                    onClick={closeDialog}
                >
                    <WCIcons name="close-filled" width="1.5em" height="1.5em" hoverColor="inherit" />
                </div>
            </inv-grid-col>
        </inv-grid-row>
    );
}

CloseDialog.propTypes = {
    closeDialog: PropTypes.func.isRequired,
};

export default CloseDialog;
