import {
    Area,
    ReferenceLine,
    XAxis,
    YAxis,
    ReferenceDot,
    ResponsiveContainer,
    CartesianGrid,
    Tooltip,
    ComposedChart,
    Line,
} from 'recharts';
import React from 'react';
import {Grid, useMediaQuery} from '@mui/material';
import NumberFormat from 'react-number-format';
import theme from '../../styles/theme';
import Text from '../../styles/textStyles';
import AreasChartTooltip from './AreasChartTooltip';
import IconGoal from '../../elements/icons/icon-goal';
import {Size, useFontSize, useIsMobile, useWindowSize} from '../../utils/hooks';

interface IAreasChart {
    name: string;
    data: Array<{
        name: string | number;
        area1?: number;
        date?: string | number;
        real?: number | null;
    }>;
    isRisk?: boolean;
    hideYAxis?: boolean;
    height?: number;
    customDotsData?: Array<{
        name: string | number;
        area1?: number;
        type: string;
    }>;
    xPropertyName?: string;
}

export function CustomReferenceDot(props: any) {
    const {cx, cy, fillColor} = props;
    return (
        <circle cx={cx} r="3" cy={cy} fill="var(--accent-color-1)" stroke={fillColor ?? 'var(--color-secondary-100)'} />
    );
}

export function CustomReferenceDotEducation(props: any) {
    const {cx, cy} = props;
    let fontSize = useFontSize();
    const isMobile = useIsMobile();
    const width = isMobile ? 0.7 : 2.5;
    const cyOffset = isMobile ? 1.8 : 2.5;
    if (fontSize === 0) {
        fontSize = 15;
    }
    return (
        <IconGoal
            x={cx - (width / 2) * (fontSize ?? 15)}
            y={cy - cyOffset * (fontSize ?? 15)}
            type="EDUCATION"
            width={`${width}em`}
        />
    );
}

export function CustomReferenceDotSafetyNet(props: any) {
    const {cx, cy} = props;
    const isMobile = useIsMobile();
    const width = isMobile ? 0.7 : 2.5;
    const cyOffset = isMobile ? 1.8 : 2.5;
    let fontSize = useFontSize();
    if (fontSize === 0) {
        fontSize = 15;
    }
    return (
        <IconGoal
            x={cx - (width / 2) * (fontSize ?? 15)}
            y={cy - cyOffset * (fontSize ?? 15)}
            type="SAFETY_NET"
            width={`${width}em`}
        />
    );
}

export function CustomReferenceDotRetirement(props: any) {
    const {cx, cy} = props;
    const isMobile = useIsMobile();
    const width = isMobile ? 0.7 : 2.5;
    const cyOffset = isMobile ? 1.8 : 2.5;
    let fontSize = useFontSize();
    if (fontSize === 0) {
        fontSize = 15;
    }
    return (
        <IconGoal
            x={cx - (width / 2) * (fontSize ?? 15)}
            y={cy - cyOffset * (fontSize ?? 15)}
            type="RETIREMENT"
            width={`${width}em`}
        />
    );
}

export function CustomReferenceDotRealState(props: any) {
    const {cx, cy} = props;
    const isMobile = useIsMobile();
    const width = isMobile ? 0.7 : 2.5;
    const cyOffset = isMobile ? 1.8 : 2.5;
    let fontSize = useFontSize();
    if (fontSize === 0) {
        fontSize = 15;
    }
    return (
        <IconGoal
            x={cx - (width / 2) * (fontSize ?? 15)}
            y={cy - cyOffset * (fontSize ?? 15)}
            type="REAL_STATE"
            width={`${width}em`}
        />
    );
}
export function CustomReferenceDotHeritage(props: any) {
    const {cx, cy} = props;
    const isMobile = useIsMobile();
    const width = isMobile ? 0.7 : 2.5;
    const cyOffset = isMobile ? 1.8 : 2.5;
    let fontSize = useFontSize();
    if (fontSize === 0) {
        fontSize = 15;
    }
    return (
        <IconGoal
            x={cx - (width / 2) * (fontSize ?? 15)}
            y={cy - cyOffset * (fontSize ?? 15)}
            type="HERITAGE"
            width={`${width}em`}
        />
    );
}

export function CustomReferenceDotCustom(props: any) {
    const {cx, cy} = props;
    const isMobile = useIsMobile();
    const width = isMobile ? 0.7 : 2.5;
    const cyOffset = isMobile ? 1.8 : 2.5;
    let fontSize = useFontSize();
    if (fontSize === 0) {
        fontSize = 15;
    }
    return (
        <IconGoal
            x={cx - (width / 2) * (fontSize ?? 15)}
            y={cy - cyOffset * (fontSize ?? 15)}
            type="CUSTOM"
            width={`${width}em`}
        />
    );
}
const getCustomDotByType = (type: string) => {
    let customDot;
    if (type === 'RETIREMENT' || type === 'RETIREMENT_ONLY_FUND') {
        customDot = CustomReferenceDotRetirement;
    } else if (type === 'CUSTOM') {
        customDot = CustomReferenceDotCustom;
    } else if (type === 'EDUCATION') {
        customDot = CustomReferenceDotEducation;
    } else if (type === 'REAL_STATE') {
        customDot = CustomReferenceDotRealState;
    } else if (type === 'HERITAGE') {
        customDot = CustomReferenceDotHeritage;
    } else {
        customDot = CustomReferenceDotSafetyNet;
    }
    return customDot;
};

function CustomLabel(props: any) {
    const size: Size = useWindowSize();
    const downMobile = (size?.width ?? 0) < 576;
    const downXl = (size?.width ?? 0) < 1400;
    const {viewBox} = props;
    const {x, y} = viewBox;
    const {value, yOffset, xOffset, noText} = props;
    return (
        <g>
            <foreignObject
                x={xOffset ? x - xOffset : x}
                y={yOffset ? y - yOffset : y}
                width={downXl ? 140 : 160}
                height={70}
            >
                <Grid container>
                    <Grid item>
                        <Text size="xs" weight="bold" fontColor="var(--color-secondary-100)" display="inline">
                            <NumberFormat
                                value={value}
                                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                                decimalScale={0}
                                allowNegative={false}
                                displayType="text"
                                suffix="% "
                            />
                        </Text>
                        {noText !== true && (
                            <Text
                                size={downMobile ? 'xs' : 's'}
                                weight="regular"
                                fontColor="var(--text-color-50)"
                                display="inline"
                            >
                                Renta variable
                            </Text>
                        )}
                    </Grid>
                </Grid>
            </foreignObject>
        </g>
    );
}

export function CustomDot(props: any) {
    const {cx, cy} = props;

    return (
        cx &&
        cy && (
            <g transform={`translate(${cx},${cy})`}>
                <circle r="0.6em" fill="#083577" />
                <circle r="0.27em" fill="#fff" />
            </g>
        )
    );
}

export function AreasChart(props: IAreasChart) {
    const {name, data, isRisk, customDotsData, height, xPropertyName, hideYAxis} = props;
    const maxValue = data?.reduce((maxValueData, dataValue) => {
        let thisMaxValueData = maxValueData;

        // @ts-ignore
        if (dataValue.area1 > maxValueData) {
            // @ts-ignore
            thisMaxValueData = dataValue.area1;
        }
        return thisMaxValueData;
    }, 0);
    const digitsOfMaxValue = Math.round(maxValue).toString().length ?? 0;
    const yAxisWidth = 25 + digitsOfMaxValue * 7;
    const downXl = useMediaQuery(theme.breakpoints.down('xl'));
    const fontSize = downXl ? 12 : 14;
    const [marginLeft, setMarginLeft] = React.useState(0);
    const [marginTop, setMarginTop] = React.useState(0);
    const [position, setPosition] = React.useState({x: 0, y: 0});
    const areaRef = React.useRef(null);
    const lineRef = React.useRef(null);
    const tooltipRef = React.useRef<null | {current: {state: any}}>(null);
    return (
        <ResponsiveContainer width="100%" height={height} key={name}>
            <ComposedChart
                data={data}
                onMouseMove={(e) => {
                    const ref = typeof data[0]?.real === 'number' ? lineRef : areaRef;
                    if (e?.isTooltipActive && ref?.current) {
                        // @ts-ignore
                        const {x, y} = ref.current.state.curPoints.find(
                            (point: any) => point?.payload?.date === e?.activeLabel
                        );
                        setPosition({x: x ?? null, y: y ?? null});
                        // @ts-ignore
                        const tooltipState = tooltipRef?.current?.state;
                        setMarginLeft(-(tooltipState?.boxWidth ?? 0) / 2);
                        const offset = fontSize + 0.6 * fontSize;
                        setMarginTop(
                            // @ts-ignore
                            -((tooltipState?.boxHeight ?? 0) + offset)
                        );
                    }
                }}
            >
                <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="var(--color-secondary-100)" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="var(--color-secondary-100)" stopOpacity={0} />
                    </linearGradient>
                </defs>
                {!isRisk && <CartesianGrid strokeDasharray="1.5" vertical={false} stroke="var(--text-color-30)" />}
                {!isRisk && (
                    <Tooltip
                        // @ts-ignore
                        ref={tooltipRef}
                        // @ts-ignore
                        content={<AreasChartTooltip />}
                        wrapperStyle={{outline: 'none', marginLeft, marginTop}}
                        position={position}
                        cursor={false}
                    />
                )}

                <XAxis
                    padding={isRisk ? {left: 12, right: 28} : undefined}
                    fontWeight={400}
                    dataKey={xPropertyName}
                    fontSize={fontSize}
                    tickMargin={5}
                    axisLine={false}
                    ticks={
                        isRisk
                            ? [6]
                            : [
                                  data[0]?.date ?? 0,
                                  data[Math.trunc(data.length / 6)]?.date ?? 0,
                                  data[Math.trunc(data.length / 3)]?.date ?? 0,
                                  data[Math.trunc(data.length / 2)]?.date ?? 0,
                                  data[Math.trunc((2 * data.length) / 3)].date ?? 0,
                                  data[Math.trunc((5 * data.length) / 6)].date ?? 0,
                                  data[data.length - 1]?.date ?? 0,
                              ]
                    }
                    domain={isRisk ? undefined : [data[0]?.date ?? 0, data[data.length - 1]?.date ?? 0]}
                    tickLine={false}
                    tick={{fill: 'var(--text-color-50)'}}
                    tickFormatter={(tick: string, index: number) => {
                        let tickText;
                        if (isRisk) {
                            tickText = `Año ${data[0].date}`;
                        } else {
                            tickText = `${index === 0 ? 'Año ' : ''}${
                                typeof tick === 'string' ? tick?.split('-')[0] : ''
                            }`;
                        }
                        return tickText;
                    }}
                    allowDuplicatedCategory={!isRisk}
                    type="category"
                />
                {isRisk ? (
                    <YAxis hide padding={{top: 25}} />
                ) : (
                    <YAxis
                        hide={hideYAxis === true}
                        domain={[0, () => Math.ceil((maxValue + maxValue * 0.3) / 100) * 100]}
                        ticks={[
                            (Math.ceil((maxValue + maxValue * 0.3) / 100) * 100) / 2,
                            Math.ceil((maxValue + maxValue * 0.3) / 100) * 100,
                        ]}
                        dx={-6}
                        type="number"
                        interval="preserveStartEnd"
                        axisLine={false}
                        unit={process.env.REACT_APP_CURRENCY_SYMBOL || '€'}
                        fontWeight={400}
                        tickLine={false}
                        fontSize={fontSize}
                        orientation="left"
                        tickCount={4}
                        width={yAxisWidth}
                        tick={{fill: 'var(--text-color-50)'}}
                        tickFormatter={(tick: number | bigint) => new Intl.NumberFormat('es').format(tick)}
                    />
                )}
                <Area
                    activeDot={typeof data[0]?.real === 'number' ? <div /> : CustomDot}
                    ref={areaRef}
                    type={isRisk ? 'stepAfter' : 'basis'}
                    dataKey="area1"
                    strokeWidth={2}
                    stroke="var(--color-secondary-100)"
                    fill={isRisk ? 'var(--color-secondary-20)' : 'url(#gradient)'}
                />
                {customDotsData?.length &&
                    customDotsData.map((customDot) => (
                        <ReferenceDot
                            key={`objective-${customDot.type}-${customDot?.area1 ?? 0}-${Math.random()}`}
                            // @ts-ignore
                            x={customDot[xPropertyName]}
                            y={(customDot.area1 ?? 0) + (maxValue ?? 0) * 0.07}
                            shape={getCustomDotByType(customDot.type)}
                        />
                    ))}
                {isRisk && (
                    <>
                        <ReferenceLine
                            segment={[
                                {x: 1, y: 0},
                                {x: 1, y: data[0]?.area1 ?? 0},
                            ]}
                            strokeWidth={2}
                            stroke="var(--color-secondary-100)"
                        />
                        {data.length > 1 && (
                            <ReferenceLine
                                segment={[
                                    {
                                        // @ts-ignore
                                        x: data[data.length - 1][xPropertyName],
                                        y: 0,
                                    },
                                    {
                                        // @ts-ignore
                                        x: data[data.length - 1][xPropertyName],
                                        y: data[data.length - 2]?.area1 ?? 0,
                                    },
                                ]}
                                strokeWidth={2}
                                stroke="var(--color-secondary-100)"
                            />
                        )}
                        <ReferenceDot
                            x={1}
                            y={data[0]?.area1 ?? 0}
                            shape={CustomReferenceDot}
                            label={<CustomLabel value={data[0]?.area1 ?? 0} yOffset={15} />}
                        />
                        {data.length > 1 && (
                            <ReferenceDot
                                // @ts-ignore
                                x={data[data.length - 1][xPropertyName]}
                                y={data[data.length - 1]?.area1 ?? 0}
                                shape={CustomReferenceDot}
                                label={
                                    <CustomLabel
                                        yOffset={15}
                                        xOffset={-15}
                                        value={data[data.length - 1]?.area1 ?? 0}
                                        noText
                                    />
                                }
                            />
                        )}
                    </>
                )}
                <Line ref={lineRef} dot={false} activeDot={CustomDot} dataKey="real" stroke="#083577" strokeWidth={2} />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

AreasChart.defaultProps = {
    isRisk: false,
    customDotsData: undefined,
    height: 180,
    xPropertyName: 'name',
    hideYAxis: false,
};
