import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    alert: {message: '', isError: false, isOpen: false},
    reloadApiFlag: false,
    alertFlag: false,
    showRiskProfileDialog: false,
    onboardingStep: 0,
    locale: '',
    toTopFlag: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        addAlert(
            state,
            action: {
                payload: {message: string; isError: boolean; isOpen: boolean};
            }
        ) {
            return {
                ...state,
                alert: action.payload,
                alertFlag: !state.alertFlag,
            };
        },
        resetAlert(state) {
            return {
                ...state,
                alertFlag: !state.alertFlag,
                alert: {message: '', isError: false, isOpen: false},
            };
        },
        setOnboardingStep(state, action: {payload: number}) {
            return {...state, onboardingStep: action.payload};
        },
        reloadApi(state) {
            return {...state, reloadApiFlag: !state.reloadApiFlag};
        },
        openRiskProfileDialog(state) {
            return {...state, showRiskProfileDialog: true};
        },
        closeRiskProfileDialog(state) {
            return {...state, showRiskProfileDialog: false};
        },
        setLocale(state, action: {payload: string}) {
            return {...state, locale: action.payload};
        },
        toTop(state) {
            return {...state, toTopFlag: !state.toTopFlag};
        },
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectApp = (state: any) => state.app;

export const {
    addAlert,
    resetAlert,
    reloadApi,
    closeRiskProfileDialog,
    openRiskProfileDialog,
    setOnboardingStep,
    setLocale,
    toTop,
} = appSlice.actions;

export default appSlice.reducer;
