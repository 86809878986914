import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/text/text-xl';

import * as React from 'react';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import styles from './contract-finanbest-login-dialog.module.css';
import TextInput from '../../elements/input/TextInput';
import {selectContract, setFinanbestEmail} from './contract.slice';
import {postLoginFinanbestService} from './contract.service';
import {getErrorMessage, tpl, tr} from '../../utils/functions';
import {addAlert} from '../app/app.slice';
import {getUserDataService} from '../user/user.service';
import PageLoading from '../../elements/loading/PageLoading';
import WCIcons from '../../elements/icons/WCIcons';

interface IContractFinanbestLoginDialogComponent {
    closeFunction: (isClosed: boolean) => void;
}

export function ContractFinanbestLoginDialogComponent(props: IContractFinanbestLoginDialogComponent) {
    const {closeFunction} = props;
    const dispatch = useDispatch();
    const router = useNavigate();
    const {email} = useSelector(selectContract);
    const [userId, setUserId] = React.useState<null | number>(null);
    const [loading, setLoading] = React.useState(false);
    const validationSchema = yup.object({
        password: yup
            .string()
            .required(tr('Introduce tu contraseña'))
            .min(
                8,
                tr('La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula')
            )
            .matches(
                /^.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/u,
                tr('La contraseña debe contener como minimo 8 letras, 1 número, 1 letra minúscula y 1 letra mayúscula')
            ),
        email: yup.string().required(tr('Introduce un email')).email(tr('No es un email válido')),
    });

    const formik = useFormik({
        initialValues: {
            email,
            password: '',
        },
        validationSchema,
        onSubmit: (values, action) => {
            action.setSubmitting(true);
            postLoginFinanbestService(userId ?? 0, values.email, values.password)
                .then(() => {
                    dispatch(setFinanbestEmail(values.email));
                    closeFunction(false);
                    action.setSubmitting(false);
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    action.setSubmitting(false);
                });
        },
    });
    React.useEffect(() => {
        setLoading(true);
        const storage = globalThis?.sessionStorage;
        const token = storage.getItem('token');
        if (!token) {
            router('/login');
        } else {
            getUserDataService(token)
                .then((res) => {
                    setUserId(res.attributes.user_id);
                    setLoading(false);
                })
                .catch(async (e) => {
                    setLoading(false);
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                });
        }
    }, []);

    return (
        <div className={styles.container}>
            <PageLoading open={loading || formik.isSubmitting} />
            <inv-grid-row style={{'--inv-grid-gutter-x': 0, justifyContent: 'center'}}>
                <inv-grid-col
                    class="col-12"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        top: 0,
                    }}
                >
                    <div className={styles.closeIconContainer}>
                        <WCIcons name="close-filled" width="2em" height="2em" hoverColor="inherit" />
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center', textAlign: 'center'}}>
                <inv-grid-col class="col-auto">
                    <inv-text-xxxl
                        style={{
                            '--inv-text-xxxl-color': 'var(--error-color)',
                            '--inv-text-xxxl-font-weight': 700,
                        }}
                    >
                        {tpl(tr('!La conexión con {entity} ha caducado!'), {entity: 'Finanbest'})}
                    </inv-text-xxxl>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class="row" style={{justifyContent: 'center', textAlign: 'center'}}>
                <inv-grid-col class="col-auto">
                    <inv-text-xl
                        style={{
                            '--inv-text-xl-color': 'var(--text-color-50)',
                            '--inv-text-xl-font-weight': 300,
                        }}
                    >
                        {tpl(tr('Logueate otra vez en tu cuenta de {entity}.'), {entity: 'Finanbest'})}
                    </inv-text-xl>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class="row" style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-auto">
                    <img style={{width: '10.8em'}} alt="perfil-euro-flecha" src="/env-assets/logos/finanbest.png" />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-12 col-sm-6">
                    <TextInput
                        value={formik.values.email ?? ''}
                        handleChange={formik.setFieldValue}
                        formik={formik}
                        name="email"
                        label={tr('Correo electrónico')}
                        // @ts-ignore
                        error={formik.errors.email}
                        placeholder="luis@gmail.com"
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-12 col-sm-6">
                    <TextInput
                        isPassword
                        value={formik.values.password ?? ''}
                        handleChange={formik.setFieldValue}
                        formik={formik}
                        name="password"
                        label={tr('Contraseña')}
                        // @ts-ignore
                        error={formik.errors.password}
                        placeholder="*******"
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row style={{justifyContent: 'center'}}>
                <inv-grid-col class="col-auto">
                    <inv-button class="button button--primary-contained" onClick={() => formik.submitForm()}>
                        {tr('Login')}
                    </inv-button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}

export default ContractFinanbestLoginDialogComponent;
